import {
  Box,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Divider } from "@mui/material";
import '../../../translator';
import { useTranslation } from 'react-i18next';
import { formatoDecimales } from "../../../commons/Formatos";

const useStyles = makeStyles(() => ({
  selBox: {
    width: "100%",
    backgroundColor: '#2f4554',
    borderRadius: "2px",
    padding:"30px 15px",
    pointerEvents:"none"
  },
  unSelBox: {
    width: "100%",
    backgroundColor: '#f4f4f4',
    borderRadius: "2px",
    padding:"30px 15px",
    cursor:"pointer",
    '&:hover': {
      backgroundColor: '#e9e9e9'
    }
  },
  selDescripcion: {
    color: "#fff",
    fontSize: "12px !important",
    fontWeight:"bold !important"
  },
  unSelDescripcion: {
    color: "#2f4554",
    fontSize: "12px !important",
    fontWeight:"bold !important"
  },
  selValue:{
    color: "#fff",
    fontSize: "24px !important",
    fontWeight:"bold !important"
  },
  unSelValue:{
    color: "#00b7c4",
    fontSize: "24px !important",
    fontWeight:"bold !important"
  },
  selValueDes:{
    color: "#fff",
    fontSize: "12px !important",
  },
  unSelValueDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  }
}));

const ResumenDiarioCard1 = ({id,titulo1,titulo2,horas,horasMotor,isSelect, onClick, tipoFlota = "Jumbo", loading}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return(
    <>        
        <Box className={isSelect?classes.selBox:classes.unSelBox} onClick={()=>onClick(id)}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item lg={3} md={3} xs={3}>
              <div className={isSelect?classes.selDescripcion:classes.unSelDescripcion}>
                <div>{`${titulo1||""} `}</div><div>{titulo2||""}</div>
              </div>
            </Grid>

            <Grid item lg={3} md={3} xs={3}>
              <Grid
                container
                direction="column"
                alignItems="center"
              >
                <div className={isSelect?classes.selValue:classes.unSelValue}>
                  {
                    loading ?
                    <CircularProgress style = {{color: isSelect ? "white": "#2f4554"}}/> :
                    formatoDecimales(horas)
                  }                  
                </div>
                <div className={isSelect?classes.selValueDes:classes.unSelValueDes}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                  >
                    {(tipoFlota == "Jumbo") ? t("voladuras") : t("frentes")}
                  </Grid>
                </div>
              </Grid>
            </Grid> 
            <Divider orientation="vertical" flexItem sx={{background:isSelect?"#fff":"#d7d7d7"}}/>

            <Grid item lg={3} md={3} xs={3}>
              <Grid
                container
                direction="column"
                alignItems="center"
                sx={{marginLeft:"10%"}}
              >
                {
                  loading ?
                  <CircularProgress style = {{color: isSelect ? "white": "#2f4554"}}/>:
                  <div className={isSelect?classes.selValue:classes.unSelValue}>
                    {formatoDecimales(horasMotor)}
                  </div>
                }
                <div className={isSelect?classes.selValueDes:classes.unSelValueDes}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                  >
                     {(tipoFlota == "Jumbo") ? t("tiros") : t("pernos")}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
    </>
  )
}

export default ResumenDiarioCard1;