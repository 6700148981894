import React from 'react'
import { Box, Button, Grid, InputLabel, Link, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { GenericPopUp } from '../molecules/GenericPopUp';

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

export const PopUpConfirmDelete = ({show, onHandleShowPopUp, msg, onHandleDeactivateGeocerca}) => {

    const { t } = useTranslation();
    const classes = useStyles();

  return (
    <>
        <GenericPopUp show={show} maxwidth='sm' handleShowPopUp={onHandleShowPopUp} showCancel={false} Children={
            <Grid container justifyContent='center' alignItems='center' direction='column' style={{paddingBottom:'50px'}}>
                <Grid style={{marginTop:'20px'}} container item justifyContent='center' alignItems='center' direction='row'>
                    <Grid style={{margin:'0px 20px'}}>
                        <WarningAmberIcon fontSize='large'/>
                    </Grid>
                    <Typography
                        style={{fontWeight: "bolder",fontSize: "15px",color: "#2f4554", textAlign:'center'}}
                    >
                       {msg}
                    </Typography>
                </Grid>
                
                <Grid container direction='column' justifyContent='center' alignItems='center'>
                    <Button
                            type="button"
                            variant="contained"
                            className={classes.root}
                            style={{textAlign:'center', marginTop:'35px', padding:'8px 80px'}}
                            onClick={onHandleDeactivateGeocerca}
                            >
                            {t("eliminar")}
                    </Button>
                    <Box sx={{display:'flex',justifyContent:'center', marginTop:'30px'}}>
                        <Link 
                        style={{cursor:'pointer'}} 
                        onClick={()=>{
                            onHandleShowPopUp(false);
                        }} 
                        underline='always' 
                        color='inherit'>{t('cancelar')}</Link>
                    </Box>
                </Grid>
            </Grid>
        }/>
    </>
  )
}
