import React from 'react'
import { GenericPopUp } from '../molecules/GenericPopUp'
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

export const PopUpSuccess = ({show, onHandleShowPopUp,verSubtitulo = true, maxwidth='md', onHandleViewform=()=>{}, onHandleEditVicons=()=>{}, texto='cambios_guardados_title',subtitulo='cambios_guardados_subtitle'}) => {

    const { t } = useTranslation();
    const classes = useStyles();

  return (
    <>
        <GenericPopUp maxwidth={maxwidth} show={show} handleShowPopUp={onHandleShowPopUp} showCancel={false} Children={
            <Grid container justifyContent='center' alignItems='center' direction='column' style={{paddingBottom:'50px'}}>
                <Grid container item justifyContent='center' alignItems='center' direction='column'>
                    <Grid style={{margin:'20px 0px'}}>
                        <CheckCircleOutlineIcon fontSize='large'/>
                    </Grid>
                    <Typography
                        style={{fontWeight: "bolder",fontSize: "15px",color: "#2f4554",maxWidth:'250px', textAlign:'center'}}
                    >
                       {t(texto)}
                    </Typography>
                </Grid>
                <Grid style={{margin: '20px 0px',display:verSubtitulo ? '' : 'none'}} item>
                    <Box sx={{color:'#2f4554', fontWeight:'500' ,padding:'10.5px 25px', backgroundColor:'#f3f4f7',textAlign:'center'}}>{t(subtitulo)}</Box>
                </Grid>
                <Button
                        type="button"
                        variant="contained"
                        className={classes.root}
                        style={{textAlign:'center', marginTop:'35px', padding:'10px 40px'}}
                        onClick={() =>{
                            onHandleShowPopUp(false);
                            if(onHandleEditVicons){
                              onHandleEditVicons(false)
                            }
                            if(onHandleViewform){
                              onHandleViewform(false, false)
                            }
                        }
                        }
                        >
                          {t("entendido")}
                </Button>
            </Grid>
        }/>
    </>
  )
}
