import { useEffect, useState } from "react";
import { getSolicitudesReglasVueltasGPS } from "../../services/solicitudes";

export const useGetSolicitudReglasVueltasGPS = (id_area_operacion, reloadData = false) => {
    const [SolicitudReglasVueltasGps, setSolicitudReglasVueltasGps] = useState([]);
    const [loadingSolicitudReglasVueltasGps, setLoadingSolicitudReglasVueltasGps] = useState(false);

    useEffect(() => {
        setLoadingSolicitudReglasVueltasGps(true)
        getSolicitudesReglasVueltasGPS(id_area_operacion)
            .then((res) => {
                setSolicitudReglasVueltasGps(res.data);
                setLoadingSolicitudReglasVueltasGps(false);
            })
            .catch((err) => {
                console.log(err);
                setLoadingSolicitudReglasVueltasGps(false);
            });
    }, [id_area_operacion, reloadData]);

    return { loadingSolicitudReglasVueltasGps, SolicitudReglasVueltasGps };
};
