import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    Tab,
    Tabs,
  } from "@material-ui/core";

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
      style={{borderTop:'10px solid rgb(0, 183, 196)'}}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box boxShadow={2} sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  /*
  props
  [{
    label:'pendientes',
    children:<h1>modelo</h1>
  }]
  */

export const TabGenerica = ({tabs = [], selected = 0}) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(selected);
    const [setsubMenuOpen, setSetsubMenuOpen] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      

  return (
    <div>
       <Box sx={{ width: '100%',marginBottom:'40px' }}>
              <Box sx={{ 
                borderBottom: 1, 
                borderColor: 'divider', 
                color:'white'
              
                }}
              >
                <Tabs TabIndicatorProps={{style:{display:'none'}}} value={value} onChange={handleChange} aria-label="simple tabs example">
                  {
                    tabs.map((tab, index) => (
                        <Tab 
                          key={index} 
                          style={{minWidth:"18%",marginLeft:index > 0 ? '10px' : '0px',backgroundColor:value== index ? 'rgb(0, 183, 196)' : '#2f4554',opacity:1,borderRadius:'5px 5px 0px 0px'}}
                          label={t(tab.label)}
                          {...a11yProps(index)} 
                        />
                    ))
                  }
                
                </Tabs>
              </Box>
              {
                tabs.map((tab,index) => (
                    <TabPanel key={index} value={value} index={index} >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            style={{ marginBottom: "16px" }}
                        >
                            <Grid item lg={12} md={12} xs={12}>
                            {
                                tab.children
                            }
                            </Grid>
                      </Grid>
                    </TabPanel>
                ))
              }
        </Box>
    </div>
  )
}
