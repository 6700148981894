import { format } from "date-fns";


export function obtenerFechaEnFormato() {
  // Obtén la fecha actual
  const fechaActual = new Date();

  // Formatea la fecha en el formato "dia-mes-año"
  const fechaFormateada = format(fechaActual, 'dd-MM-yyyy');

  return fechaFormateada;
}

export function decimalToHexadecimal(decimalNumber) {
  // Convierte el número a hexadecimal
  const hexadecimalString = decimalNumber.toString(16).toUpperCase();

  return hexadecimalString;
}

export function translateMachineName(name, t, idMina = 0){
  const [type, id] = name?.split(' ');
  if(type.toLowerCase() == "dumper" && idMina == 24){
    const translatedType = t(type.toLowerCase());
    return `${translatedType} ${id}`;
  }else{
    return name
  }
}

export function getCurrentShift(date) {
  const hour = date.getHours();
  
  return (hour >= 7 && hour < 19) ? "dia" : "noche";
}

// Función para convertir un array de blobs a base64
export async function convertBlobsToBase64(blobs) {
  const promises = blobs.map(blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(blob);
    });
  });

  try {
    const base64Array = await Promise.all(promises);
    return base64Array;
  } catch (err) {
    console.error("Error converting blobs to base64", err);
    throw err;
  }
}

function base64ToBlob(base64, contentType = 'image/jpeg') {
  if (!base64 || typeof base64 !== 'string' || !base64.includes(',')) {
    throw new Error('Invalid base64 input');
  }

  const byteCharacters = atob(base64.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length)
    .fill(0)
    .map((_, i) => byteCharacters.charCodeAt(i));
  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type: contentType });
}

export function base64ToUrl(base64, contentType = 'image/jpeg') {
  const blob = base64ToBlob(base64, contentType);
  return URL.createObjectURL(blob);
}
