export const filtrarPorTurno = (fechaDia,turno,filas) => {
  switch(Number(turno.id_tipo_turno)){
    case 1:
      let fechaAnterior = new Date(`${fechaDia} ${turno.hora_inicio}`);
      fechaAnterior.setDate(fechaAnterior.getDate() - 1);
      return (
        filas.filter(obj => 
          (
            (new Date(obj.inicio) >=  fechaAnterior ) && 
            (new Date(obj.inicio) < new Date(fechaDia + " " + turno.hora_fin))
          )   
        )
      )
    case 2:
      return (
        filas.filter(obj => 
          (
            (new Date(obj.inicio) >=  new Date(fechaDia + " " + turno.hora_inicio )) && 
            (new Date(obj.inicio) < new Date(fechaDia + " " + turno.hora_fin))
          )   
        )
      )
    case 3:
      let fechaPosterior = new Date(`${fechaDia} ${turno.hora_fin}`);
      fechaPosterior.setDate(fechaPosterior.getDate() + 1);
      return (
        filas.filter(obj =>
          (
            (new Date(obj.inicio) >=  new Date(fechaDia + " " + turno.hora_inicio ) ) &&
            (new Date(obj.inicio) < fechaPosterior)
          )
        )
      )
    default:
      return filas;
  }
};

export const sortByDate = (arr,keyDate) => {
  const sortedArr = arr.sort(function(x, y) {
    let firstDate = new Date(x[keyDate]);
    let secondDate = new Date(y[keyDate]);
      
    if (firstDate < secondDate) return -1;
    if (firstDate > secondDate) return 1;
    return 0;
  });
  return sortedArr;
}

export function eliminarDuplicadosPorPropiedad(arr, propiedad) {
  let elementosUnicos = {};
  return arr.filter(obj => {
    if (!elementosUnicos[obj[propiedad]]) {
      elementosUnicos[obj[propiedad]] = true;
      return true;
    }
    return false;
  });
}