export const formatearFecha = (fecha, tipo) => {
  // No convertir la fecha original a un objeto Date
  const fechaOriginal = new Date(fecha);

  // Obtener los componentes de fecha y hora directamente
  const dia = fechaOriginal.getUTCDate();
  const mes = fechaOriginal.getUTCMonth() + 1; // Los meses en JavaScript son base 0, por eso sumamos 1
  const anio = fechaOriginal.getUTCFullYear();
  const horas = fechaOriginal.getUTCHours();
  const minutos = fechaOriginal.getUTCMinutes();
  const segundos = fechaOriginal.getUTCSeconds();

  // Formatear la fecha al formato 'dd-mm-yyyy hh:mm:ss'
  const fechaFormateada = `${dia.toString().padStart(2, '0')}-${mes.toString().padStart(2, '0')}-${anio} ${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;

  return fechaFormateada;
}

export function convertirFechadeCLaUS(fechaEnFormatoDmy) {
//formato entrada 12-09-2023 12:34:45
//formato salida 2023-09-12 12:34:45

  // Dividir la fecha en partes: [dd, mm, aaaa, hh:mm:ss]
  const partes = fechaEnFormatoDmy.split(' ');

  // Dividir la parte de la fecha en [dd, mm, aaaa]
  const fechaPartes = partes[0].split('-');

  // Reorganizar las partes de la fecha en el nuevo formato: [aaaa, mm, dd]
  const nuevaFecha = `${fechaPartes[2]}-${fechaPartes[1]}-${fechaPartes[0]}`;

  // Combinar la nueva fecha con la parte de hora original
  const fechaConvertida = `${nuevaFecha} ${partes[1]}`;

  return fechaConvertida;
}


export const sumarDias = (fecha, dias) => {
  fecha.setDate(fecha.getDate() + dias);
  return fecha;
}

export const restarDias = (fecha, dias) => {
  fecha.setDate(fecha.getDate() - dias);
  return fecha;
}

export const generateDatabaseDateTimeES = (fecha) => {
  const date = new Date(fecha);

  // Obtener las partes de la fecha y hora en formato local
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // Crear la cadena de fecha y hora en formato "dd-MM-yyyy HH:mm:ss"
  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

  return formattedDateTime;
};


export const generateDatabaseDateTimeENUTC = (fecha) => {
  let date = new Date(fecha+"UTC");
  return date.toISOString().replace("T"," ").substring(0, 19);
};

export const generateDatabaseDateTimeEN = (fecha) => {
  let date = new Date(fecha);
  return date.toISOString().replace("T"," ").substring(0, 19);
};

export const formatearMinutos = (minutes) => {
  
  if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      const formattedHours = hours.toString();
      const formattedMinutes = remainingMinutes === 0 ? '' : `:${Math.round(remainingMinutes).toString().padStart(2, "0")}`;
      return `${formattedHours}${formattedMinutes} hr`;
    } else {
      return Math.round(minutes).toString()+ "min";
    }

}

export const formatearUsToClDate = (dateString) =>{
  //2023-08-05 a 05-08-2023
  const [year, month, day] = dateString.split("-");
  return `${day}-${month}-${year}`;
}

export const formatearFechaISO =(fechaISO) =>{
  // Tu fecha en formato ISO

// Crear un objeto de fecha
const fechaObjeto = new Date(fechaISO);

// Obtener componentes de fecha y hora
const dia =agregarCero(fechaObjeto.getUTCDate());
const mes = agregarCero(fechaObjeto.getUTCMonth() + 1); // ¡Recuerda que los meses comienzan desde 0!
const anio = fechaObjeto.getUTCFullYear();
const horas = agregarCero(fechaObjeto.getUTCHours());
const minutos = agregarCero(fechaObjeto.getUTCMinutes());
const segundos = agregarCero(fechaObjeto.getUTCSeconds());

// Formatear la fecha en el formato deseado
const fechaFormateada = `${dia}-${mes}-${anio} ${horas}:${minutos}:${segundos}`;
return fechaISO ? fechaFormateada : ''
}

export const formatearFechaISOtoUSFormat =(fechaISO) =>{
  // Tu fecha en formato ISO

// Crear un objeto de fecha
const fechaObjeto = new Date(fechaISO);

// Obtener componentes de fecha y hora
const dia =agregarCero(fechaObjeto.getUTCDate());
const mes = agregarCero(fechaObjeto.getUTCMonth() + 1); // ¡Recuerda que los meses comienzan desde 0!
const anio = fechaObjeto.getUTCFullYear();
const horas = agregarCero(fechaObjeto.getUTCHours());
const minutos = agregarCero(fechaObjeto.getUTCMinutes());
const segundos = agregarCero(fechaObjeto.getUTCSeconds());

// Formatear la fecha en el formato deseado
const fechaFormateada = `${anio}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;
return fechaISO ? fechaFormateada : ''
}

export const formatearSoloFechaISOtoCL =(fechaISO) =>{
  // Tu fecha en formato ISO

// Crear un objeto de fecha
const fechaObjeto = new Date(fechaISO);

// Obtener componentes de fecha y hora
const dia =agregarCero(fechaObjeto.getUTCDate());
const mes = agregarCero(fechaObjeto.getUTCMonth() + 1); // ¡Recuerda que los meses comienzan desde 0!
const anio = fechaObjeto.getUTCFullYear();

// Formatear la fecha en el formato deseado
const fechaFormateada = `${dia}-${mes}-${anio}`;
return fechaFormateada
}

export const formatearFechaISOEnPalabras = (fechaISO) => {
  const meses = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
  const dias = ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"];

  // Crear un objeto de fecha
  const fechaObjeto = new Date(fechaISO);

  // Obtener componentes de fecha y hora
  const diaSemana = dias[fechaObjeto.getUTCDay()];
  const dia = agregarCero(fechaObjeto.getUTCDate());
  const mes = meses[fechaObjeto.getUTCMonth()];
  const anio = fechaObjeto.getUTCFullYear();
  const horas = agregarCero(fechaObjeto.getUTCHours());
  const minutos = agregarCero(fechaObjeto.getUTCMinutes());

  // Formatear la fecha en el formato deseado
  const fechaFormateada = `${diaSemana} ${dia} ${mes}, ${horas}:${minutos}`;
  return fechaFormateada;
}

export const formatearSoloFechaISO =(fechaISO) =>{
  // Tu fecha en formato ISO 2024-03-24T00:00:00.000Z 

// Crear un objeto de fecha
const fechaObjeto = new Date(fechaISO);

// Obtener componentes de fecha y hora
const dia =agregarCero(fechaObjeto.getUTCDate());
const mes = agregarCero(fechaObjeto.getUTCMonth() + 1); // ¡Recuerda que los meses comienzan desde 0!
const anio = fechaObjeto.getUTCFullYear();

// Formatear la fecha en el formato deseado
const fechaFormateada = `${anio}-${mes}-${dia}`;
return fechaFormateada
}

export const formatearSoloHoraISO =(fechaISO) =>{
   // Tu fecha en formato ISO 2024-03-24T00:00:00.000Z 

// Crear un objeto de fecha
const fechaObjeto = new Date(fechaISO);

// Obtener componentes de fecha y hora

const horas = agregarCero(fechaObjeto.getUTCHours());
const minutos = agregarCero(fechaObjeto.getUTCMinutes());
const segundos = agregarCero(fechaObjeto.getUTCSeconds());

// Formatear la fecha en el formato deseado
const fechaFormateada = `${horas}:${minutos}`;
return fechaISO ? fechaFormateada : ''
}

function agregarCero(num) {
  return num < 10 ? `0${num}` : num;
}

export const formatDateToUTC = (fechaAux) =>{
  let fecha = new Date(fechaAux).getUTCDate();
  let mes = new Date(fechaAux).getUTCMonth();
  let año = new Date(fechaAux).getUTCFullYear();

  return new Date(año,mes,fecha)
}

export const formatDate = (date, locales = "es-CL", options = {}) => {
  return new Intl.DateTimeFormat(locales, {
    ...options
  }).format(date instanceof Date ? date : new Date(date));
};

export function getDateInTimezone(date) {
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: "America/Hermosillo",
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });

  const parts = formatter.formatToParts(date);
  const year = parts.find(part => part.type === 'year').value;
  const month = parts.find(part => part.type === 'month').value;
  const day = parts.find(part => part.type === 'day').value;
  const hour = parts.find(part => part.type === 'hour').value;
  const minute = parts.find(part => part.type === 'minute').value;
  const second = parts.find(part => part.type === 'second').value;

  // fecha sin el sufijo `Z` para evitar interpretaciones UTC
  return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
}
