import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import LogoMineWatch from "../../assets/logos-empresa/mw-logo.png";
import LogoDumas from "../../assets/logos-empresa/dumas-logo.png";

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "#f4f4f4",
    marginBlockStart: "2rem",
    display: "flex",
    justifyContent: "space-between",
    paddingInline: "2.3rem",
    paddingBlock: "1rem"
  },
  img: {
    maxWith: "100%",
    height: "60px",
    aspectRatio: "10/3",
    display: "block"
  }
})

export function LogosEmpresas() {
  const classes = useStyles();

  return <section className={classes.root}>
    <img className={classes.img} alt="mw-logo" src={LogoMineWatch} />
    <img className={classes.img} alt="dumas-logo" src={LogoDumas} />
    </section>
}
