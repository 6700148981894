import ErrorIcon from "@mui/icons-material/Error";
import React from "react";

export function CellComentario({ comentario }) {
  return (
    <section
      style={{
        display: "flex",
        alignItems: "center",
        columnGap: "5px",
        filter: "drop-shadow(.5px .5px .75px rgba(0,0,0,.08))",
        marginInline: "5px"
      }}>
      {comentario && (
        <ErrorIcon sx={{ margin: "0" }} fontSize="small" color="error" />
      )}
      <div
        title={
          comentario?.length ? comentario?.length > 50 && comentario : null
        }
        style={{
          display: !comentario ? "none" : "block",
          whiteSpace: "nowrap",
          minWidth: "185px",
          height: "46px",
          backgroundColor: "#f3f3f3",
          borderRadius: "5px",
          clipPath:
            "polygon(100% 0%, 100% 50%, 100% 100%, 13.5% 100%, 0% 50%, 13.5% 0)",
          marginInlineEnd: "3px"
        }}>
        <p
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingInline: "25px 5px",
            fontSize: "12px",
            fontWeight: "700",
            color: "#2C4352",
            maxWidth: "180px",
            textWrap: "wrap",
            height: "46px"
          }}>
          {comentario && comentario?.length > 50
            ? `${comentario?.slice(0, 50)}...`
            : !comentario
              ? null
              : comentario}
        </p>
      </div>
    </section>
  );
}
