import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Grid,
    Checkbox,
    FormControlLabel
  } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const PopupRegistroJustificacionSinHorometro = ({
  open,
  handleClose,
  formik,
  loadingPopUp,
  ubicacion,
  ListadoMaquinas,
  FormJustificados,
  handleErrorForm,
  initialState,
  classes,
  gruposJustificaciones,
  ListadoMotivos,
  verCheckbox  = false
}) => {

    const paperStyle = {
        padding: 20,
        width: 480,
      };

    const initialStateInfo = {
      id_t_registro_movimiento:undefined,
      fecha_inicio:undefined,
      fecha_termino:undefined
    }

    const [unlockDates, setUnlockDates] = useState(true);
    const [prevInfo, setPrevInfo] = useState(initialStateInfo);
    const { t } = useTranslation();

    const handleChangeCheckbox = (event) => {
      setUnlockDates(!(event.target.checked));
      //se guarda la informacion seleccionada si pasan de justificacion personalizada a no personalizada
      if(event.target.checked){
        setPrevInfo({
          fecha_inicio:formik.values.fecha_inicio,
          fecha_termino:formik.values.fecha_termino,
          id_t_registro_movimiento:formik.values.id_registro_movimiento
        });
        formik.initialValues['id_registro_movimiento'] = 0;
        formik.setFieldValue('id_registro_movimiento', 0);
      }else{
        formik.initialValues['fecha_inicio'] = prevInfo.fecha_inicio;
        formik.initialValues['fecha_termino'] = prevInfo.fecha_termino;
        formik.initialValues['id_registro_movimiento'] = prevInfo.id_t_registro_movimiento;
        formik.setFieldValue('fecha_inicio', prevInfo.fecha_inicio);
        formik.setFieldValue('fecha_termino', prevInfo.fecha_termino);
        formik.setFieldValue('id_registro_movimiento', prevInfo.id_t_registro_movimiento);
        setPrevInfo(initialStateInfo);
      }
    };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Grid container item xs direction="row" spacing={0}>
          <Paper elevation={0} style={paperStyle}>
            <form onSubmit={formik.handleSubmit}>
              <Grid>
                {!loadingPopUp ? (
                  <TextField
                    id="id_ultima_ubicacion"
                    name="ultima_ubicacion"
                    label={t("ubicacion")}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    //onChange={(e) =>formik.setFieldValue("fecha_inicio",e.target.value.toString())}
                    value={ubicacion ? ubicacion?.nombre : t("sin_info")}
                    style={{ marginTop: "15px" }}
                    fullWidth
                    disabled
                  />
                ) : (
                  <Skeleton animation="wave" />
                )}

                <br />

                <Box sx={{ minWidth: 120 }} style={{ marginTop: "15px" }}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      {t("equipo")}
                    </InputLabel>
                    <Select
                      name="id_maquina"
                      onBlur={formik.handleBlur}
                      defaultValue={0}
                      labelId="razon-select-label"
                      id="razon-select"
                      value={formik.values.id_maquina}
                      label="Seleccione una opción"
                      onChange={(e) =>
                        formik.setFieldValue("id_maquina", e.target.value)
                      }
                      disabled
                    >
                      <MenuItem key={0} value={0}>
                        {t("seleccione_opcion")}
                      </MenuItem>
                      {ListadoMaquinas.map((maquina) => (
                        <MenuItem key={maquina.id} value={maquina.id}>
                          {maquina.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <TextField
                  id="datetime-local-desde"
                  name="fecha_inicio"
                  onBlur={formik.handleBlur}
                  label={t("fecha_inicio")}
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // onChange={formik.handleChange}
                  onChange={(e) =>{
                    formik.setFieldValue(
                      "fecha_inicio",
                      e.target.value.toString()
                    )
                  }
                  }
                  value={formik.values.fecha_inicio}
                  style={{ marginTop: "15px" }}
                  fullWidth
                  disabled={unlockDates}
                />

                <TextField
                  id="datetime-local-hasta"
                  name="fecha_termino"
                  onBlur={formik.handleBlur}
                  label={t("fecha_fin")}
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // onChange={formik.handleChange}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "fecha_termino",
                      e.target.value.toString()
                    )
                  }
                  value={formik.values.fecha_termino}
                  style={{ marginTop: "15px" }}
                  fullWidth
                  disabled={unlockDates}
                />
                {formik.values.nombre_motivo ? (
                  <>
                    <TextField
                      id="id_ultima_ubicacion"
                      name="ultima_ubicacion"
                      label={t("motivo")}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //onChange={(e) =>formik.setFieldValue("fecha_inicio",e.target.value.toString())}
                      value={formik.values.nombre_motivo}
                      style={{ marginTop: "15px" }}
                      fullWidth
                      disabled
                    />
                  </>
                ) : (
                  <>
                    <Box sx={{ minWidth: 120 }} style={{ marginTop: "15px" }}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          {t("motivo")}
                        </InputLabel>
                        <Select
                          disabled={FormJustificados}
                          name="id_grupo_tipo_justificacion"
                          onBlur={formik.handleBlur}
                          defaultValue={0}
                          labelId="id_grupo_tipo_justificacion"
                          id="id_grupo_tipo_justificacion"
                          value={formik.values.id_grupo_tipo_justificacion}
                          label="Seleccione una opción"
                          onChange={(e) => {
                            formik.setFieldValue("observacion", "");
                            formik.setFieldValue("id_tipo_justificacion", 0);
                            formik.setFieldValue(
                              "id_grupo_tipo_justificacion",
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem key={0} value={0}>
                            {t("seleccione_opcion")}
                          </MenuItem>
                          {gruposJustificaciones.map((grupoMotivo) => (
                            <MenuItem
                              key={grupoMotivo.id}
                              value={grupoMotivo.id}
                            >
                              {grupoMotivo.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                )}
                <Box
                  sx={{ minWidth: 120 }}
                  style={{
                    marginTop: "15px",
                    display: !formik.values.id_grupo_tipo_justificacion
                      ? "none"
                      : "",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      {t("motivo_detalle")}
                    </InputLabel>
                    <Select
                      disabled={FormJustificados}
                      name="id_tipo_justificacion"
                      onBlur={formik.handleBlur}
                      defaultValue={0}
                      labelId="razon-select-label"
                      id="razon-select"
                      value={formik.values.id_tipo_justificacion}
                      label="Seleccione una opción"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "id_tipo_justificacion",
                          e.target.value
                        );
                      }}
                    >
                      <MenuItem key={0} value={0}>
                        {t("seleccione_opcion")}
                      </MenuItem>
                      {ListadoMotivos.map((motivo) => (
                        <MenuItem key={motivo.id} value={motivo.id}>
                          {motivo.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {[7, 17, 27, 32, 40, 43, 49].includes(
                  formik.values.id_tipo_justificacion
                ) && (
                  <TextField
                    disabled={FormJustificados}
                    onBlur={formik.handleBlur}
                    name="observacion"
                    type="text"
                    value={formik.values.observacion}
                    onChange={formik.handleChange}
                    label="Otro motivo"
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={4}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ marginTop: "15px" }}
                  />
                )}

                {/* <Checkbox defaultChecked /> */}
                {
                  verCheckbox &&
                  <FormControlLabel
                    style={{marginTop:'10px'}}
                    control={
                      <Checkbox size="small" color="primary" value={unlockDates} onChange={handleChangeCheckbox} defaultChecked={!unlockDates}/>
                    }
                    label="Justificación personalizada"
                  />
                }
                <Button
                  onClick={handleErrorForm}
                  style={{
                    marginTop: "35px",
                    display: FormJustificados ? "none" : "",
                  }}
                  type="submit"
                  variant="contained"
                  /* style={btnstyle} */
                  fullWidth
                  disabled={formik.isSubmitting}
                  className={classes.root}
                >
                  {t("registrar")}
                </Button>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            formik.setValues(initialState);
            handleClose();
          }}
        >
          {t("cancelar")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};