import React from 'react'
import { useTranslation } from 'react-i18next';
import Grid from "@mui/material/Grid";
import { HtmlTooltip } from '../atoms/StyledComponents';
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatoDecimales } from '../../commons/Formatos';

const useStyles = makeStyles({
    buttonSelected: {
        backgroundColor: "#2f4554",
        color: "white",
        padding: "5px 30px",
        fontSize: "14.4px",
        textTransform: "none",
        borderRadius: "4px",
        margin: "3px",
        height: "38px",
        width: "160px",
        alignSelf: "center",
        "&:hover": {
            backgroundColor: "#00B7C4"
        }
    },
    root: {
        backgroundColor: "#2f4554",
        margin: "8px 0",
        color: "white",
        "&:hover": {
            backgroundColor: "#253642",
        },
        "&:disabled": {
            backgroundColor: "gray",
        },
    },
    buttonUnselected: {
        backgroundColor: "#f4f4f4",
        padding: "5px 30px",
        fontSize: "14.4px",
        textTransform: "none",
        borderRadius: "4px",
        margin: "3px",
        height: "38px",
        width: "160px",
        alignSelf: "center",
        "&:hover": {
            backgroundColor: "#00B7C4"
        }
    },
    prodDes: {
        color: "#2f4554",
        fontSize: "12px !important",
    },
    prodValue: {
        fontSize: "20px",
        fontWeight: "bold"
    },
    bordermd: {
        ['@media (min-width:1200px)']: {
            borderRight: "2px solid #d7d7d7"
        }
    },
    marginmd: {
        ['@media (min-width:1200px)']: {
            marginLeft: "10px"
        }
    },
    datepicker: {
        width: "190px",
        backgroundColor: "white",
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00B7C4"
        },
        "& .MuiInputBase-root": {
            backgroundColor: "#ffffff",
            borderColor: "error.main",
            "& .MuiButtonBase-root": {

                borderColor: "error.main"

            },
            "& .MuiInputBase-input": {
                backgroundColor: "#ffffff",
                borderColor: "error.main",
                /* fontSize:"14.4px" */
            }
        },

        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: "#00B7C4",
            }
        },
    }
});

let dataEjemplo = [{
    titulo: 'Distancia promedio',
    valor: 20,
    toolTip: ''
}, {
    titulo: 'Velocidad promedio',
    valor: 20,
    toolTip: undefined
},
]

export const MultipleSimpleIndicator = ({ columnas = dataEjemplo }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                columns={columnas.length}
                sx={{ padding: "0px 30px 0px 0px" }}
            >
                {
                    columnas.map((columna, index) => {
                        let lenghColumnasFromZero = columnas.length - 1;
                        let colorCeleste = "#01b7c4"
                        let colorNaranjo = "#ff6600"
                        return (
                            <Grid key={index} item lg={1} md={2} xs={2} sx={{ borderRight: dataEjemplo.length > 1 && index != lenghColumnasFromZero ? "2px solid #d7d7d7" : "" }}
                            >
                                {columna?.toolTip ?
                                    <HtmlTooltip
                                        enterDelay={0}
                                        title={
                                            <Typography color="inherit" fontSize="12px">
                                                {columna.toolTip}
                                            </Typography>
                                        }
                                    >
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            sx={{ cursor: "help" }}
                                        >
                                            <div className={classes.prodValue} style={{ color: ((index+1) % 2) == 0 ? colorNaranjo : colorCeleste  }}>
                                                {formatoDecimales(columna.valor)}
                                            </div>
                                            <div className={classes.prodDes}>
                                                {columna.titulo}
                                            </div>
                                        </Grid>
                                    </HtmlTooltip> :
                                    <Grid
                                        container
                                        direction="column"
                                        alignItems="center"
                                        sx={{ cursor: "help" }}
                                    >
                                        <div className={classes.prodValue} style={{ color: ((index+1) % 2) == 0 ? colorNaranjo : colorCeleste  }}>
                                            {formatoDecimales(columna.valor)}
                                        </div>
                                        <div className={classes.prodDes}>
                                            {columna.titulo}
                                        </div>
                                    </Grid>

                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
        </div>
    )
}

