import React from "react";
import Chart from "react-apexcharts";
import { format } from "date-fns";

const HistoricalChartApex = ({series, limites}) => {

  const getDiasMes = (month,year) => {
    return (new Date(year,month+1,0)).getDate()-1;
  };



  let options = {

    annotations: {
      yaxis: [{
        y: limites.yaxis.max,
        borderColor: '#3474E0',
        borderWidth:2,
        strokeDashArray: 2,
        label: {
          show: false,
          text: '',
          /* style: {
            color: "#fff",
            background: '#00E396'
          } */
        }
      }],
      /* xaxis:[
        {
          x: (new Date("2022-02-17 00:00:03")).getTime(),
          borderWidth: 0,
          label: {
            offsetY: -10,
            orientation:'horizontal',
            borderColor: 'none',
            style: {
              color: '#2f4554',
              fontSize: '11px',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: "bold",
            },
            text: "17/02",
          }
        },
        {
          x: (new Date("2022-02-18 00:00:03")).getTime(),
          borderWidth: 0,
          label: {
            offsetY: -10,
            orientation:'horizontal',
            borderColor: 'none',
            style: {
              color: '#2f4554',
              fontSize: '11px',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: "bold",
            },
            text: "18/02",
          }
        },
        {
          x: (new Date("2022-02-20 00:00:03")).getTime(),
          borderWidth: 0,
          label: {
            offsetY: -10,
            orientation:'horizontal',
            borderColor: 'none',
            style: {
              color: '#2f4554',
              fontSize: '11px',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: "bold",
            },
            text: "20/02",
          }
        },
        {
          x: (new Date("2022-02-19 00:00:03")).getTime(),
          borderWidth: 0,
          label: {
            offsetY: -10,
            orientation:'horizontal',
            borderColor: 'none',
            style: {
              color: '#2f4554',
              fontSize: '11px',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: "bold",
            },
            text: "19/02",
          }
        },
        {
          x: (new Date("2022-02-20 00:00:03")).getTime(),
          borderWidth: 0,
          label: {
            offsetY: -10,
            orientation:'horizontal',
            borderColor: 'none',
            style: {
              color: '#2f4554',
              fontSize: '11px',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: "bold",
            },
            text: "20/02",
          }
        },
        {
          x: (new Date("2022-02-21 00:00:03")).getTime(),
          borderWidth: 0,
          label: {
            offsetY: -10,
            orientation:'horizontal',
            borderColor: 'none',
            style: {
              color: '#2f4554',
              fontSize: '11px',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: "bold",
            },
            text: "21/02",
          }
        },
        {
          x: (new Date("2022-02-22 00:00:03")).getTime(),
          borderWidth: 0,
          label: {
            offsetY: -10,
            orientation:'horizontal',
            borderColor: 'none',
            style: {
              color: '#2f4554',
              fontSize: '11px',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              fontWeight: "bold",
            },
            text: "22/02",
          }
        }
      ] */
    },
    legend:{
      show:false
    },
    dataLabels: {
      enabled: false,
    },
    colors:['#00b7c4', '#00b7c4', '#2f4554'],
    chart: {
      stacked: false,
      toolbar:{
        show:true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: true,
          reset: true
        }
      }
    },
    stroke: {
      width: 1.5,
      curve: 'smooth'
    },  
    fill: {
      opacity: [0.25, 0.25, 1],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
    xaxis: {
      tooltip: {
        enabled: false
      },
      labels: {
        format: 'hh:mm dd/MM',
        style: {
          colors: ["#2f4554"],
          fontSize: '11px',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontWeight: "bold"
        }
      },
      type: "datetime",
    },
    yaxis: [{
      min: 0,
      max: 200/* limites.yaxis.max */,
      tickAmount: 4,
      labels: {
        formatter: function (val) {
            return `${val.toFixed(0)} Lts`
        },
        style: {
          colors: ["#2f4554"],
          fontSize: '11px',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontWeight: "bold"
        }
      }
    }],
    grid: {
      row: {
        colors: ['#f3f4f5', '#fff'],
        opacity: 1
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(1);
          }
          return y;
    
        }
      },
      x:{
        formatter: function (x) {
          return format(new Date(x), "HH:mm:ss dd/MM/yy ");
        }
      }
    }
  };

  /* let series = [
    {
      name: '',
      data: [
        [1641006000000, 44], 
        [1641009600000, 55], 
        [1641013200000, 41],
        [1641016800000, 67],
        [1641020400000, 22], 
        [1641022200000, 43]
      ]
    }
  ]; */


  return(
    <>
      <Chart
        options={options}
        series={series}
        type="area"
        height={250}
      />
    </>
  );
};

export default HistoricalChartApex;