import React, { useEffect, useState, useContext } from "react";
import LiquidFillGauge from 'react-liquid-gauge';
import { color } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';
import NavBar from "../../components/organisms/NavBar";
import { Breadcrumbs, Container, Typography, Link } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { CardMedia, Card, CardContent, CardHeader} from "@mui/material";
import ImagenGrua from './grua.png';
import ImagenAljibe from './camionAljibe.png';
import getNivelCombustible from './getNivelCombustible';
import OpacityIcon from '@mui/icons-material/Opacity';
import TimelineIcon from '@mui/icons-material/Timeline';
import HistoricalChart from "./HistoricalChart";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { es } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from "@material-ui/core/styles";
import HistoricalChartApex from "./HistoricalChartApex";
import { format } from "date-fns";

const useStyles = makeStyles({
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },

    "& .MuiInputBase-root": {

      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main"
      }
    },

    '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
      /* '& fieldset': {            // - The <fieldset> inside the Input-root
          borderColor: 'pink',   // - Set the Input border
      }, */
      '&:hover fieldset': {
          borderColor: "#00B7C4", // - Set the Input border when parent has :hover
      }/* ,
      '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
          borderColor: 'green',
      }, */
  },

  }
});


const getVolumenCilindro = (r, h, l) => {
  const area = Math.acos( (r-h)/r) * Math.pow(r,2) - (r-h) * Math.sqrt(2*r*h - Math.pow(h,2)) ;
  const volumenCM3 = area*l;
  const volumenLitros = volumenCM3/1000;
  return volumenLitros;
};

const NivelCombustible = (props) => {
  const [volumenActual, setVolumenActual] = useState(0);
  const [porcentajeCombustible, setPorcentajeCombustible] = useState(0);
  const [datosNivelCombustible, setDatosNivelCombustible] = useState({
    combustible: 0,
    fecha: "",
    hora: "00:00:00"
  });

  const [seriesNivelCombustibleApex, setSeriesNivelCombustibleApex] = useState([]);

  const [fecha, setFecha] = useState(new Date());

  const classes = useStyles();

  const volumenTotal = getVolumenCilindro(30,60,70) /* (80*56*91)/1000 */;
  const startColor = '#00b7c4'; // cornflowerblue
  const endColor = '#00b7c4'; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = interpolate(porcentajeCombustible / 100);

  const gradientStops = [
    {
        key: '0%',
        stopColor: color(fillColor).darker(0.5).toString(),
        stopOpacity: 1,
        offset: '0%'
    },
    {
        key: '50%',
        stopColor: fillColor,
        stopOpacity: 0.75,
        offset: '50%'
    },
    {
        key: '100%',
        stopColor: color(fillColor).brighter(0.5).toString(),
        stopOpacity: 0.5,
        offset: '100%'
    }
  ];

  useEffect(()=>{
    const today = new Date();
    let sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate()-7);
    getNivelCombustible(1, 6, format(sevenDaysAgo, "yyyy-MM-dd"), format(today, "yyyy-MM-dd"))
    .then(({success, data}) => {
      if(success){
        const volumen = getVolumenCilindro(30, data[data.length-1].combustible/10, 70)
        setDatosNivelCombustible(data[data.length-1]);
        setVolumenActual(volumen);
        setPorcentajeCombustible((volumen/volumenTotal)*100);

        const dataSeriesApex = data.map((item)=>{
          return(
            [
              (new Date(`${item.fecha.split("T")[0]} ${item.hora}`)).getTime(),
              getVolumenCilindro(30, item.combustible/10, 70).toFixed(1)
            ]
          )
        })

        setSeriesNivelCombustibleApex(
          [
            {
              name: 'Litros',
              data: dataSeriesApex
            }
          ]
        );


      }
    })
  },[]);



  return(
    <>
      <NavBar/>
      <br/>  
      <Container>
        <Typography
          style={{ color: "#323b41", fontWeight: "bold", fontSize: "18px" }}
        >
          NIVEL DE COMBUSTIBLE
        </Typography>
        <Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px"}}
            color="inherit"
            href="/"
          >
            Mine-Watch
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px", color:"#00b7c4"}}
            color="inherit"
            /* href={"/inicio} */
          >
            {"Nivel de combustible"}
          </Link>
        </Breadcrumbs>
        <br/>  
        <br/>  

        <Card>       
          <CardHeader
            style={{borderBottom:"1px solid rgba(0, 0, 0, 0.1)"}}
            title={
              <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0}
                  > 

                      <Grid item>
                        <Grid container alignItems="center">
                          <Grid item>
                            <OpacityIcon style={{marginRight:"15px", marginLeft:"10px" , color: "2f4554", fontSize:"30px"}}/>
                          </Grid>

                          <Grid item>
                          <Grid 
                          container
                          direction="column"
                        >
                          <Grid item>
                            <Typography
                              style={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                color: "#2f4554"
                              }}
                            >
                              Camión Aljibe
                            </Typography>
                          </Grid>
                        </Grid>
                          </Grid>
                        </Grid>                  
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: "14.4px",
                            color: "#0000008a",
                            fontStyle: "italic",
                            marginRight: "10px"
                          }}
                        >
                          {`Última Actualización ${datosNivelCombustible.hora.split(":")[0]}:${datosNivelCombustible.hora.split(":")[1]} hrs ${datosNivelCombustible.fecha.split("T")[0]}`}
                        </Typography>                                           
                      </Grid>
                    {/* </Grid>
                  </Grid>  */}  

              </Grid>
            }
          />
          <CardContent style={{padding:"40px 20px"}} >
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >           
              <Grid item lg={4}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >   
                  <CardMedia
                    component="img"
                    image={ImagenAljibe}
                    alt="Paella dish"
                    style={{ width: "400px", marginRight: "20px" }}
                  />
                </Grid>
              </Grid>
              <Grid item lg={4}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >   
                  <Grid item >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-end"
                      
                    >   
                      <Grid item lg={5}>
                        <Typography
                          style={{
                            /* fontWeight: "bold", */
                            fontSize: "65px",
                            color: "#2f4554",
                            padding: "0px",
                          }}
                        >
                          {volumenTotal.toFixed(1)}
                        </Typography>

                      </Grid>
                      <Grid item lg={2}>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "#2f4554",
                            marginBottom: "18px"
                          }}
                        >
                          Litros
                        </Typography>
                      </Grid>   
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            color: "#0000008a",
                            padding: "0px"
                          }}
                        >
                          Volumen total estanque
                        </Typography>
                      </Grid>             

                    </Grid>
                  </Grid>

                  <Grid item >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-end"
                      
                    >     
                      <Grid item lg={5}>
                        <Typography
                          style={{
                            /* fontWeight: "bold", */
                            fontSize: "65px",
                            color: "#2f4554",
                            padding: "0px"
                          }}
                        >
                          {volumenActual.toFixed(1)}
                        </Typography>
                      </Grid>
                      <Grid item lg={2}>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "#2f4554",
                            marginBottom: "18px"
                          }}
                        >
                          Litros
                        </Typography>
                      </Grid>  
                      <Grid item lg={12} md={12} xs={12}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            color: "#0000008a",
                            padding: "0px"
                          }}
                        >
                          Volumen actual estanque
                        </Typography>
                      </Grid>           
                    </Grid>
                  </Grid>

                </Grid>
              </Grid> 
              <Grid item>
                <Grid container justifyContent="flex-end">
                    <LiquidFillGauge
                      style={{ margin: '0 0' }}
                      width={217.45}
                      height={217.45}
                      value={porcentajeCombustible}
                      percent="%"
                      textSize={1}
                      textOffsetX={0}
                      textOffsetY={20}
                      textRenderer={(props) => {
                          const value = Math.round(props.value);
                          const radius = Math.min(props.height / 2, props.width / 2);
                          const textPixels = (props.textSize * radius / 2);
                          const valueStyle = {
                              fontSize: textPixels,
                              color: "red"
                          };
                          const percentStyle = {
                              fontSize: textPixels * 0.6
                          };

                          return (
                              <tspan>
                                  <tspan className="value" style={valueStyle}>{value}</tspan>
                                  <tspan style={percentStyle}>{props.percent}</tspan>
                              </tspan>
                          );
                      }}
                      riseAnimation
                      waveAnimation
                      waveFrequency={3}
                      waveAmplitude={5}
                      /* gradient */
                      gradientStops={gradientStops}
                      circleStyle={{
                          fill: fillColor,
                          
                          /* filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))" */

                      }}
                      waveStyle={{
                          fill: fillColor
                      }}
                      textStyle={{
                          fill: color('#2f4554').toString(),
                          
                      }}
                      waveTextStyle={{
                          fill: color('#fff').toString(),
                          
                      }}
                    />
                  </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <br/>

        <Card>       
          <CardHeader
            style={{borderBottom:"1px solid rgba(0, 0, 0, 0.1)"}}
            title={
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
              > 
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid item>
                      <TimelineIcon style={{marginRight:"15px", marginLeft:"10px" , color: "2f4554", fontSize:"30px"}}/>
                    </Grid>

                    <Grid item>
                    <Grid 
                    container
                    direction="column"
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          color: "#2f4554"
                        }}
                      >
                        Volumen histórico
                      </Typography>
                    </Grid>
                  </Grid>
                    </Grid>
                  </Grid>                  
                </Grid>
                {/* <Grid item>
                  <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      cancelLabel="Cancelar"
                      className={classes.datepicker}
                      size="small"
                      inputVariant="outlined"
                      openTo="month"
                      minDate={new Date('2021-11-01 00:00:00')}
                      maxDate={(new Date().setHours(23,59,59))}
                      views={["year", "month"]}
                      value={fecha}
                    />
                  </MuiPickersUtilsProvider>
                </Grid> */}
              </Grid>
            }
          />
          <CardContent>
            <HistoricalChartApex
              series = {seriesNivelCombustibleApex}
              limites = {
                {
                  yaxis:{
                    min: 0,
                    max: volumenTotal
                  },
                  xaxis:{
                    min:0,
                    max: 10
                  }
                }
              }
            />
          </CardContent>
        </Card>   
        <br/>
      </Container>
    </>
  );
};

export default NivelCombustible;