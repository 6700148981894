import { Grid, Typography } from "@mui/material";
import React from "react";

const SectionHeader = ({
	title,
	subtitle,
	icon,
	colorTitle = "#2f4554",
	aumentarFuente = 0,
	bold = false,
	children
}) => {
	return (
		<Grid item container spacing={1} alignItems="center" sx={{ py: 3, pl: 1, columnGap: "3px", width: "fit-content", flexWrap: "nowrap", whiteSpace: "nowrap" }}>
			<Grid item>{icon}</Grid>
			<Grid item xs={7}>
				<Grid container>
					<Grid item lg={12} md={12} xs={12}>
						<Typography
							style={{
								fontWeight: "bold",
								fontSize: `${14.4 + aumentarFuente}px`,
								color: colorTitle,
								marginBottom: subtitle ? "0px" : "10px",
								whiteSpace: "nowrap !important"
							}}>
							{title}
						</Typography>
					</Grid>
					<Grid container item lg={12} md={12} xs={12} alignItems="center" justifyItems="center" alignSelf="center" columnGap={1}>
						<div
							style={{
								display: "inline-block",
                				width: "fit-content",
								fontStyle: "italic",
								fontWeight: bold ? "bold" : "",
								fontSize: `${12 + aumentarFuente}px`,
								color: "#717171",
								justifySelf: "center",
								whiteSpace: "nowrap",
								overflow: "hidden",
								paddingInlineEnd: "0.5rem"
							}}>
							{subtitle}
						</div>
						{children}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default SectionHeader;
