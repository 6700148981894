import React, { useState } from 'react';
import { TableContainer, TableHead, TableRow, TableCell, Table, TablePagination, TableBody } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { TablaDeCarga } from './TablaDeCarga';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#2f4554",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        width: "100%"
    },
    root: {
        backgroundColor: "#2f4554",
        margin: "8px 0",
        color: "white",
        "&:hover": {
            backgroundColor: "#253642"
        },
        "&:disabled": {
            backgroundColor: "gray"
        }
    }
});

let dataDefault = [{ id: 1, tiempo_sesion: 24, usuario: "thomas.c.arcos@v.cl", endpoint: "/test" }];
let columnsDefault = ["tiempo sesion", "usuario", "endpoint"];
// Mapeo de columnas a campos de datos, {nombre_columna: key json del api}
const columnMappingAux = {
    "tiempo sesion": "tiempo_sesion",
    "usuario": "usuario",
    "endpoint": "endpoint"
};

export const TablaGenerica = ({ data = dataDefault, columns = columnsDefault, loadingData = false, columnMapping = columnMappingAux }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    if (loadingData) {
        return (
            <>
                <TablaDeCarga columnas={[...columns]} />
            </>
        )
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {columns.map((col) => (
                                <StyledTableCell key={col} align="center">{t(col)}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            (!loadingData && data.length < 1) &&
                            <StyledTableRow>
                                <StyledTableCell colSpan={6} align='center'><b>{t("sin_info")}</b></StyledTableCell>
                            </StyledTableRow>
                        }
                        {
                            data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <StyledTableRow key={row.id || index}>
                                    {columns.map((col) => (
                                        <StyledTableCell key={col} align="center">
                                            {row[columnMapping[col]]}
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[20, 30]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("filas_por_pagina")}
                />
            </TableContainer>
        </>
    );
};
