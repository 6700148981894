import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles({
    prodDes:{
      color: "#2f4554",
      fontSize: "12px !important",
    },
    prodValue:{
      fontSize: "20px",
      fontWeight:"bold"
    }
  });


export const HeaderEstadoEquipo = ({ listadoFlotaSeleccionada }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  let contadorEstados = {
    fuera_de_servicio:0,
    ralenti:0,
    mantencion:0,
    ralenti:0,
    sininfo:0,
    operativo:0
  }

  listadoFlotaSeleccionada.map(el => {
    let obj = {};
    let key =el?.id_tipo_movimiento.replace(/ /g, "_"); 
    if(!contadorEstados[key]){
      contadorEstados[key] = 1;
    }else{
      contadorEstados[key] += 1;
    }
    return obj;
  });
  
  return (
    <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    <Grid item lg={2} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
      <Grid
        container
        direction="column"
        alignItems="center"
      >
        <div className={classes.prodValue} style={{color:"#01b7c4"}}>
          {contadorEstados?.operativo ? contadorEstados.operativo : "0"}
        </div>
        <div className={classes.prodDes}>
          {t("operativo")}
        </div>
      </Grid>
    </Grid>
    <Grid item lg={2} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
      <Grid
        container
        direction="column"
        alignItems="center"
      >
        <div className={classes.prodValue} style={{color:"#E9724C"}}>
          {contadorEstados?.ralenti ? contadorEstados.ralenti : "0"}
        </div>
        <div className={classes.prodDes}>
          {t("ralenti")}
        </div>
      </Grid>
    </Grid>
    <Grid item lg={2} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
      <Grid
        container
        direction="column"
        alignItems="center"
      >
        <div className={classes.prodValue} style={{color:"#ffbf00"}}>
          {contadorEstados?.mantencion ? contadorEstados.mantencion : "0"}
        </div>
        <div className={classes.prodDes}>
          {t("en_mantencion")}
        </div>
      </Grid>
    </Grid>
    <Grid item lg={2} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
      <Grid
        container
        direction="column"
        alignItems="center"
      >
        <div className={classes.prodValue} style={{color:"#C5283D"}}>
          {contadorEstados?.fuera_de_servicio ? contadorEstados.fuera_de_servicio : "0"}
        </div>
        <div className={classes.prodDes}>
          {t("fuera_de_servicio")}
        </div>
      </Grid>
    </Grid>
    <Grid item lg={2} md={2} xs={2}>
      <Grid
        container
        direction="column"
        alignItems="center"
      >
        <div className={classes.prodValue} style={{color:"#d6dce4"}}>
          {contadorEstados?.sininfo ? contadorEstados.sininfo : "0"}
        </div>
        <div className={classes.prodDes}>
          {t("sin_info")}
        </div>
      </Grid>
    </Grid>
  </Grid>
  )
}
