import { Tooltip } from "@mui/material";
import { useScreenshots } from "../../hooks/general/useScreenshots";
import { DownloadIcon, Spinner } from "./TemporalEmails/IconsPopover";
import { formatDate } from "../../commons/FormatearFecha";

export function DownloadImageButton({ 
  elementId, 
  currentShift,
  nombreSubMina,
  date
}) {
  const { loadingDownload, handleJustDownload } = useScreenshots();

  const formatedDate = formatDate(date, "es-CL", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
    .split("/")
    .reverse()
    .join("-");

  return (
    <Tooltip title={`Descargar Imagen Turno ${currentShift}`}>
      <button
        disabled={loadingDownload}
        onClick={() => handleJustDownload(elementId, currentShift, nombreSubMina, formatedDate)}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          padding: "10px",
          backgroundColor: "#01B7C4",
          borderRadius: "100vw",
          border: "none",
          cursor: loadingDownload ? "default" : "pointer",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
        }}>
        {loadingDownload ? (
          <Spinner style={{ width: "28px", height: "28px", color: "#fff" }} />
        ) : (
          <DownloadIcon
            style={{ width: "28px", height: "28px", color: "#fff" }}
          />
        )}
      </button>
    </Tooltip>
  );
}
