import { EyeIcon } from "./IconsPopover";

export function CustomButton({
  disabled,
  onClick,
  icon = null,
  text,
  width = "fit-content",
  backgroundColor = "#EF4444",
  color = "#fff",
  paddingBlock = "4px",
  style = {}
}) {
  return (
    <button
      onClick={onClick}
      disabled={!disabled}
      style={{
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingInline: "10px",
        paddingBlock: paddingBlock,
        backgroundColor: backgroundColor,
        color: color,
        border: "none",
        borderRadius: "4px",
        columnGap: "6px",
        opacity: disabled ? 1 : 0.5,
        cursor: disabled ? "pointer" : "default",
        ...style
      }}>
      {icon}
      <p style={{ padding: 0 }}>{text}</p>
    </button>
  );
}

export function PreviewImageButton({ url }) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      style={{
        width: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingInline: "10px",
        paddingBlock: "4px",
        backgroundColor: "#fff",
        border: "1px solid #a5a5a5",
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        columnGap: "6px",
        opacity: url ? 1 : 0.5,
        color: "#000",
        textDecoration: "none",
        cursor: url ? "pointer" : "default"
      }}>
      <EyeIcon />
      <p style={{ padding: 0 }}>Preview</p>
    </a>
  );
}
