import {
  Box,
  Grid
} from "@material-ui/core";
/* import { makeStyles } from '@mui/styles'; */
/* import { createTheme, ThemeProvider } from '@mui/material/styles'; */
 import { makeStyles } from "@material-ui/core/styles";

import IconoNotificacion from '../../assets/icons/icono-notificacion.png';
import IconoCerrar from '../../assets/icons/icono-cerrar.png';

/* const theme = createTheme(); */
const useStyles = makeStyles(() => ({
  box: {
    width: "100%",
    backgroundColor: '#00B7C4',
    borderRadius: "2px",
    padding:"7px 15px 4px 25px",
    color: "#fff",
    fontSize: "12px",
    cursor: "default"
  }
}))

const NotificationCard = ({message, time, show, onClickHide}) => {
  //const [show, setShow] = useState(true);
  const classes = useStyles();

  return(
    <>
      {
        show &&
          <Box className={classes.box}>
            <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item>
                <img src={IconoNotificacion} width="23px" alt="Alerta"/>
              </Grid>
              <Grid item sx={{fontWeight: "bold", marginRight:"30px"}}>
                {message}
              </Grid>
              <Grid item>
                <span style={{fontStyle:"italic"}}>{`${time} hrs`}</span>
              </Grid>
              <Grid item>
                <img src={IconoCerrar} width="10px" style={{cursor:"pointer"}} onClick={()=>onClickHide(false)} alt="Cerrar"/>
              </Grid>
            </Grid> 
          </Box>
      }
    </>
  )
}

export default NotificationCard;