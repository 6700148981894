import React, { useRef } from 'react'
import SectionHeader from '../../../components/molecules/SectionHeader'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { Box, Button, Grid, Link, makeStyles } from '@material-ui/core';
import iconoUndo from '../../../assets/icons/undo.svg'
import iconoRedo from '../../../assets/icons/redo.svg'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useState } from 'react';
import { TablaVicons } from './TablaVicons';
import { ViconForm } from '../Formularios/ViconForm';
import { PopUpDelete } from './PopUpDelete';
import { useGetViconsByIdOperacion } from '../../../hooks/useGetViconsByIdOperacion';
import { useFormik } from 'formik';
import * as Yup from "yup";

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });
  const initialState = ({
    editingViconTable:false,
    editingViconRow:false,
    AddingViconRow:false
  });

  const initialStatePoUps = ({
    showPopUpSuccess:false,
    showPopUpDelete:false,
    showPopUpConfirmEdit:false
  });



export const AdministracionVicons = ({nombreMina, id_area_operacion}) => {

const classes = useStyles();
const { t } = useTranslation();
const location = useLocation();

const refForm = useRef(null);
const [estadoAdmVicons, setEstadoAdmVicons] = useState(initialState);
const [estadoPopUps,setEstadoPopUps] = useState(initialStatePoUps);
const [dataFormEditVicon, setDataFormEditVicon] = useState({
  id_area_operacion: id_area_operacion,
  nombreMina: nombreMina,
  mac: "",
  major: 0,
  minor:0,
  id_tipo_beacon:0,
  id_grupo:0,
  orden:0,
  descripcion:"",
  editing:false
});
const { loading, vicons, setVicons } = useGetViconsByIdOperacion(id_area_operacion);

const onHandleEditVicons = (edit,formData) =>{
  setEstadoAdmVicons((prevState) =>({...prevState,editingViconTable:edit}));
}

const onHandleViewViconForm = (isEdit, isAdding) =>{
  setEstadoAdmVicons((prevState) => ({...prevState,editingViconRow:isEdit,AddingViconRow:isAdding}))
}

const onHandleShowPopUpSuccess = (show) =>{
  setEstadoPopUps((prevState) => ({...prevState,showPopUpSuccess:show}))
}

const onHandleShowPopUpDelete = (show) =>{
  setEstadoPopUps((prevState) => ({...prevState,showPopUpDelete:show}))
}

const onHandleShowPopUpConfirmEdit = (show) =>{
  setEstadoPopUps((prevState) =>({...prevState,showPopUpConfirmEdit:show}))
}

const onHandleSetFormikValues = (dataViconTabla) =>{
  setDataFormEditVicon(dataViconTabla)
}
  return (
    <>
     <SectionHeader
          title={`Vicons ${nombreMina}`}
          subtitle={t("dispositivos_instalados")}
          icon={
            <SpeakerPhoneIcon fontSize='large'/>
          }
        />
        {/* HEADER BOTONES EDITAR Y AGREGAR*/}
        <Grid container direction='row' wrap='nowrap' alignItems='center' justifyContent='flex-end' style={{marginBottom:'15px'}}>
          {/* {
          estadoAdmVicons.editingViconTable && (
              <Grid container justifyContent='flex-end' style={{marginRight:'15px'}}>
                <Box  sx={{
                          "&:hover": {
                            backgroundColor: "rgb(0, 183, 196)",
                            borderRadius: "50%",
                            padding: "7px",
                          },
                            padding: "7px",
                            width: "44px",
                            marginRight:'10px'
                        }}>
                  <img src={iconoUndo} style={{cursor:'pointer',width:'100%'}}/>
                </Box>
                <Box
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgb(0, 183, 196)",
                      borderRadius: "50%",
                      padding: "7px",
                    },
                    padding: "7px",
                    width: "44px"
                  }}
                >
                  <img src={iconoRedo} style={{cursor:'pointer',width:'100%'}}/>
                </Box>
              </Grid>
            )
          } */}
            <Box sx={{ minWidth: '240px' ,marginLeft:20}}>
                <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    className={classes.root}
                    disabled={estadoAdmVicons.editingViconTable}
                    onClick={() =>{
                      onHandleViewViconForm(false,false);
                      onHandleEditVicons(true);
                    }
                    }
                    >
                    <Grid container alignItems="center" spacing={7}>
                        <Grid item>
                            <BorderColorIcon style={{ color: 'white', display:'block', margin:'auto' }} />
                        </Grid>
                        <Grid item>
                            {t("editar")}
                        </Grid>
                    </Grid>
                </Button>
            </Box>
            <Box sx={{ minWidth: '240px' ,marginLeft:20}}>
                <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    className={classes.root}
                    disabled={estadoAdmVicons.AddingViconRow}
                    onClick={() =>{
                      onHandleViewViconForm(false,true);
                      onHandleEditVicons(false);
                    }
                    }
                    >
                    <Grid container alignItems="center" spacing={4}>
                        <Grid item>
                            <ControlPointIcon style={{ color: 'white', display:'block', margin:'auto' }} />
                        </Grid>
                        <Grid item>
                            {t("agregar")}
                        </Grid>
                    </Grid>
                </Button>
            </Box>
        </Grid>

        {/* TABLA  VICONS */}
        <TablaVicons
          editingViconTable={estadoAdmVicons.editingViconTable}
          onHandleViewViconForm={onHandleViewViconForm}
          onHandleEditVicons={onHandleEditVicons}
          vicons = {vicons}
          loading={loading}
          setVicons={setVicons}
          onHandleSetFormikValues={onHandleSetFormikValues}
          refForm={refForm}
          id_area_operacion={id_area_operacion}
          nombre_mina={nombreMina}
          pathMina={(location.pathname.split("/"))[2]}
        />
        {/* FOOTER GUARDAR CAMBIOS */}
        {
          estadoAdmVicons.editingViconTable && (
            <Grid container direction='row' wrap='nowrap' justifyContent='space-between' style={{margin:'40px 0px'}}>
              {
                !estadoAdmVicons.editingViconRow
                ?
                (<Grid item xs={9} container wrap='nowrap' justifyContent='flex-start' alignItems='flex-start'>
                    <Box sx={{backgroundColor:'rgb(0, 183, 196)'}}>
                          <InfoOutlinedIcon  fontSize='large' sx={{color:'white',padding:'5px'}}/>
                    </Box>
                    <Box sx={{color:'#666666' ,padding:'10.5px 25px', backgroundColor:'#eaedf1'}}>{t('recuerdo_aprobacion')}</Box>
                </Grid>)
                :
                (<Grid item xs={9} container wrap='nowrap' justifyContent='flex-start' alignItems='flex-start'></Grid>)
              }
              <Grid item xs={3}>
                {/* <Box>
                    <Button
                        fullWidth
                        type="button"
                        variant="contained"
                        className={classes.root}
                        style={{textAlign:'center', marginBottom:'15px'}}
                        disabled={estadoAdmVicons.editingViconRow}
                        onClick={() =>{
                          onHandleShowPopUpSuccess(true);
                        }
                        }
                        >
                          {t("guardar_cambios")}
                    </Button>
                </Box> */}
                {
                  !estadoAdmVicons.editingViconRow &&
                  <Box sx={{display:'flex',justifyContent:'center'}}>
                    <Link style={{cursor:'pointer'}} onClick={()=>onHandleEditVicons(false)} underline='always' color='inherit'>{t("cancelar")}</Link>
                  </Box>
                }
              </Grid>
            </Grid>
          )
        }

        {/* Formulario de edicion de vicons */}
        {
          estadoAdmVicons.editingViconRow &&
          <ViconForm
            onHandleViewViconForm = {onHandleViewViconForm}
            idMina ={id_area_operacion}
            vicons = {vicons}
            editing = {true}
            id_area_operacion={id_area_operacion}
            nombreMina={nombreMina}
            formData={dataFormEditVicon}
            refForm={refForm}
            pathMina={(location.pathname.split("/"))[2]}
            onHandleEditVicons={onHandleEditVicons}
          />
        }

        {/*Formulario de adicion de vicons*/}
        {
          estadoAdmVicons.AddingViconRow &&
          <ViconForm
            onHandleViewViconForm = {onHandleViewViconForm}
            idMina = {id_area_operacion}
            vicons = {vicons}
            editing = {false}
            id_area_operacion={id_area_operacion}
            nombreMina={nombreMina}
            refForm={refForm}
            onHandleEditVicons={onHandleEditVicons}
            pathMina={(location.pathname.split("/"))[2]}
          />
        }
    </>
  )
}
