import { styled } from "@mui/material/styles";
import {
	Autocomplete,
  TextField,
} from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


export const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#2f4554' : '#308fe8',
  },
}));

export const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '"#00b7c4"',
  },
  '& .MuiOutlinedInput-root': {
    /* '& fieldset': {
      borderColor: 'white',
    }, */
    '&:hover fieldset': {
      borderColor: "#00b7c4",
    },
    '&.Mui-focused fieldset': {
      borderColor: "#00b7c4",
    },
  },
});

export const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
    fontSize: "14.4px",
  },
  ".MuiInputBase-root":{
    backgroundColor:"white"
  },
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    transform: "translate(34px, 20px) scale(1);",
  },
  "& .MuiAutocomplete-inputRoot": {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
      paddingLeft: 6    
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00b7c4",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00b7c4"
    }
  }
});

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fafafa'/* 'rgba(0, 183, 196, .5)' */,
    color: '#2f4554',
    minWidth: 350,
    maxWidth: 1000,
    /* border: '1px solid #2f4554', */
    padding:"25px 25px 25px 35px",
    boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"
  },
}));