import React, { useRef } from 'react'
import SectionHeader from '../../../components/molecules/SectionHeader'
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone';
import { MapaGPSEdicion } from '../../MapaGPS/MapaGpsEdicion';
import { TablaGeocercas } from './TablaGeocercas';
import { useGetGeoCercas } from '../../../hooks/useGetGeoCercas';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Box, Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { PopUpNombreGeocerca } from './PopUpNombreGeocerca';
import { PopUpSuccess } from '../../../components/organisms/PopUpSuccess';
import { deactivateGeocerca, insertGeocerca } from '../../../services/administracion';
import { PopUpConfirmDelete } from '../../../components/organisms/PopUpConfirmDelete';
import { useGetTiposDeGeocerca } from '../../../hooks/administracion/gps/useGetTiposDeGeocerca';
import { AlertaGenerica } from '../../../components/molecules/AlertaGenerica';

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

export const AdministracionGeocercas = ({nombreMina, id_area_operacion}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const {loadingTiposGeocerca,tiposDeGeocerca} = useGetTiposDeGeocerca(id_area_operacion);
  const [recargarTabla, setRecargarTabla] = useState(false);
  const { loadingGeoCercas, GeoCercas } = useGetGeoCercas(id_area_operacion,recargarTabla,false);
  const { loadingGeoCercas:loadingGeoCercasVelocidad, GeoCercas:GeoCercasVelocidad } = useGetGeoCercas(id_area_operacion,recargarTabla,true);
  const [ubicacionGeoCerca, setUbicacionGeoCerca] = useState([-70.597872, -33.399104]);
  const [showPopUpNombreGeocerca, setshowPopUpNombreGeocerca] = useState(false);
  const [geoCercasMemoria, setGeoCercasMemoria] = useState([]);
  const [showPopUpSuccess, setshowPopUpSuccess] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState(false);
  const [esGeoCercaVelocidad, setEsGeoCercaVelocidad] = useState(false);
  const [geocercaSeleccionada, setGeocercaSeleccionada] = useState({id:0, nombre:''});
  const ReferenciaIniciarDibujoGeocerca = useRef(null);
  const ReferenciaMapa = useRef(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "Seleccione los puntos en el mapa",
    severity: "info",
    variant: "filled",
    duration: 15000
  });

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ open: false, message: alert.message, severity: alert.severity });
  };

  const handleStartDrawGeocerca = () => {
    setAlert({ 
      open: true,
      message: `-Haz clic en el mapa para seleccionar puntos y crear una geocerca?
                -Luego:?
                -Presiona enter para continuar? 
                -Presiona escape para cancelar?`, 
      severity: alert.severity,
      duration:15000 });
    if (ReferenciaIniciarDibujoGeocerca.current) {
        ReferenciaIniciarDibujoGeocerca.current.changeMode("draw_polygon");
    }
  };

  const onHandleDeactivateGeocerca = () =>{
    if(geocercaSeleccionada.id != 0){
        try {
            deactivateGeocerca(geocercaSeleccionada.id)
            .then((resp) => {
                setRecargarTabla(!recargarTabla);
                setShowPopupConfirmDelete(false);
                setGeocercaSeleccionada({id:0,nombre:''})
                setshowPopUpSuccess(true);
                
            })
            .catch(err => console.log(err))
        } catch (error) {
            setRecargarTabla(!recargarTabla);
            setShowPopupConfirmDelete(false);
            setGeocercaSeleccionada({id:0,nombre:''})
        }
    }
    
  }

  const onHandleSaveGeocercas = () =>{
    setIsFormSubmitting(true);
    insertGeocerca(id_area_operacion,geoCercasMemoria)
    .then((res) => {
        setshowPopUpSuccess(true);
        setRecargarTabla(!recargarTabla);
        setIsFormSubmitting(false)
        setGeoCercasMemoria([]);
    })
    .catch((err) => {
        setIsFormSubmitting(false)
        setGeoCercasMemoria([]);
    })
  }

  const onHandleSaveGeoCercaEnMemoria = (event) => {
      setGeoCercasMemoria((prevState) => [...prevState,event.features[0]]);
      setshowPopUpNombreGeocerca(true);
  };

  const onHandleUpdateGeoCercaEnMemoria = (event) => {

    let idGeoCerca = event.features[0].id;
    let geomtry = event.features[0].geometry

    setGeoCercasMemoria((prevState) =>
          prevState.map((geoCerca) => {
            if (geoCerca.id === idGeoCerca) {
            geoCerca.geometry = geomtry;
            return geoCerca;
        }
            return geoCerca;
          })
        );
    };

  const onHandleDeleteGeocercaOnMemoryByID = (idGeocerca) =>{
    ReferenciaIniciarDibujoGeocerca.current.delete(idGeocerca);
  }

  return (
    <>
        <SectionHeader
          title={`${t("geocercas")} ${nombreMina}`}
          subtitle={t('creadas_actualmente')}
          icon={
            <SpeakerPhoneIcon fontSize='large'/>
          }
        />

        <br/>
        <br/>

        <Grid>
            <TablaGeocercas
                geocercas={GeoCercas.coordsGeoCerca}
                loading={loadingGeoCercas}
                setUbicacionGeoCerca={setUbicacionGeoCerca}
                showPopupConfirmDelete={setShowPopupConfirmDelete}
                setGeocercaSeleccionada={setGeocercaSeleccionada}
                geocercasGps={GeoCercasVelocidad?.coordsGeoCerca}
                loadingGeoCercasVelocidad={loadingGeoCercasVelocidad}
            />
        </Grid>

        <br/>
        <br/>

        <Grid container direction='row' wrap='nowrap' alignItems='center' justifyContent='flex-start' style={{marginBottom:'15px'}}>         
            <Box sx={{ minWidth: '240px' ,marginLeft:20}}>
                <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    className={classes.root}
                    onClick={()=>{
                      setEsGeoCercaVelocidad(false)
                      handleStartDrawGeocerca()
                    }}
                    >
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <ControlPointIcon style={{ color: 'white', display:'block', margin:'auto' }} />
                        </Grid>
                        <Grid item>
                            {t("geocerca")}
                        </Grid>
                    </Grid>
                </Button>
            </Box>
            <Box sx={{ minWidth: '240px' ,marginLeft:20}}>
                <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    className={classes.root}
                    // disabled={estadoAdmVicons.AddingViconRow}
                    onClick={()=>{
                      setEsGeoCercaVelocidad(true)
                      handleStartDrawGeocerca()
                    }}
                    >
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                            <ControlPointIcon style={{ color: 'white', display:'block', margin:'auto' }} />
                        </Grid>
                        <Grid item>
                            {t("geocerca_velocidad")}
                        </Grid>
                    </Grid>
                </Button>
            </Box>
            <Box sx={{ minWidth: '240px' ,marginLeft:20}}>
                <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    className={classes.root}
                    style={{textAlign:'center'}}
                    disabled={geoCercasMemoria.length < 1}
                    onClick={() =>{
                        onHandleSaveGeocercas();
                    }
                    }
                    >
                        <Grid container justifyContent='center' direction='row'>
                            {
                                isFormSubmitting && (
                                <Grid item>
                                    <CircularProgress style={{color:'white'}} size={'20px'} />
                                </Grid>
                                )
                            }
                            <Grid item style={{marginLeft:'13px'}}>
                                {t("guardar_cambios")}
                            </Grid>
                        </Grid>
                </Button>
            </Box>        
        </Grid>
        
        <Grid>
            <MapaGPSEdicion 
                GeoCercas={GeoCercas}
                centroMapa={ubicacionGeoCerca}
                ReferenciaIniciarDibujoGeocerca={ReferenciaIniciarDibujoGeocerca}
                onHandleSaveGeoCercaEnMemoria={(event)=>onHandleSaveGeoCercaEnMemoria(event)}
                ReferenciaMapa={ReferenciaMapa}
                onHandleUpdateGeoCercaEnMemoria={onHandleUpdateGeoCercaEnMemoria}
                GeoCercasVelocidad={GeoCercasVelocidad}
            />
        </Grid>
          {
            !loadingTiposGeocerca &&(
              <PopUpNombreGeocerca
              show={showPopUpNombreGeocerca}
              onHandleShowPopUp={setshowPopUpNombreGeocerca}
              idGeoCerca = {geoCercasMemoria[geoCercasMemoria.length-1]}
              setGeoCercasMemoria={setGeoCercasMemoria}
              onHandleDeleteGeocercaOnMemoryByID={onHandleDeleteGeocercaOnMemoryByID}
              tiposGeocercas={tiposDeGeocerca}
              controlaVelocidad={esGeoCercaVelocidad}
              />
            )
          }
          <PopUpSuccess maxwidth='sm' onHandleShowPopUp={setshowPopUpSuccess} show={showPopUpSuccess} verSubtitulo={false}/>
          <PopUpConfirmDelete 
            show={showPopupConfirmDelete} 
            onHandleShowPopUp={setShowPopupConfirmDelete}
            msg={'¿Estas seguro de eliminar la geocerca '+geocercaSeleccionada.nombre+'?'}
            onHandleDeactivateGeocerca={onHandleDeactivateGeocerca}
        />

        <AlertaGenerica alert={alert} handleCloseAlert={handleCloseAlert} duration={5000} esAlertaError={false} />
    </>
  )
}
