import React, {useState, useEffect} from "react";
import {
  Area,
  AreaChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  linearGradient,
  ReferenceArea,
  ResponsiveContainer,
  Label
} from "recharts";
import { add, format, differenceInCalendarDays, isFuture, differenceInHours } from "date-fns";
import formatearFecha from "../../commons/FormatearFecha"
import HomeIcon from '@mui/icons-material/Home';

const dateFormatter = date => {

  /* return formatearFecha(new Date(date)); */
  return format(new Date(date), "HH:mm");
};

const getTicks = (startDate, endDate) => {
  const diffDays = differenceInHours(endDate, startDate);
  let ticks = [startDate.getTime()];
  
  for(let i=0; i<(diffDays-1); i++){
    startDate.setHours(startDate.getHours() + 1);
    ticks.push(startDate.getTime());
  }
  ticks.push(endDate.getTime());

  return ticks;
};

export default function MotionChart({series, limites, fecha}) {

  const startDate = new Date(limites.min);
  const endDate = new Date(limites.max);
  const domain = [dataMin => dataMin, () => endDate.getTime()];
  const ticks = getTicks(startDate, endDate);
  const axesColor = '#e0e0e0'/* 'rgb(0,0,0,0.15)' */;

  // Datos nulos para ordenar ylabels 
  const ordenMina = [
    //General
    "Cancha", 
    "Camino a Cancha", 
    "Bocamina", 
    "Acopio 0", 
    "Cargadero 5 Camion", 
    "Cargadero 5 LHD", 
    "Caseron 5", 
    "Cargadero 7 Camion", 
    "Cargadero 7 LHD", 
    "Caseron 7",
    //HINDUSTAN1
    "Workshop",
  	"Portal entry",
  	"192mRL",
  	"57mRL CWO",
  	"57 mRL CWM",
  	"-2mRL CWO",
  	"1mRL CWM",
  	"-23mRL Workshop CWO",
  	"-23mRL CWO",
    "-48mRL CWO",
    "-48mRL CWM",
    "-73mRL CWO",
    "-73mRL/LP CWO",
    "-98mRL CWO",
    "-98mRL/LP CWO",
    "-98mRL CWM",
    "-98mRL/HE CWM",
    "-98mRL/2WE LP CWM",
    "-123mRL CWO",
    "-123mRL CWM",
    "-148mRL CWO",
    "-148mRL CWM"
  ];

  // Se definen los beacons que contienen los registros y se construyen los objetos de series para graficar
  let beaconsVisitados=[];
  const seriesMovimientoMina = series.map((dato) => {
    if(!beaconsVisitados.includes(dato.nombre)){
      beaconsVisitados.push(dato.nombre)
    }
    return(
      {
        name: dato.nombre,
        fechaInicio: (new Date(dato.inicio.replace("T"," ").split(".")[0])).getTime(),
        fechaFin: (new Date(dato.fin.replace("T"," ").split(".")[0])).getTime()
      }
    )
  });

  // Se genera array beacons de registros ordenados según orden de la mina (ordenMina)
  let beaconsOrdenados = [];
  ordenMina.forEach((beacon) => {
    if(beaconsVisitados.includes(beacon)){
      beaconsOrdenados.push(beacon);
    }
  });

  // Se crean las eries nulas que definen el orden de ylabel categorico
  const seriesOrdenMina = beaconsOrdenados.map((dato) => {
    return(
      {
        name: dato,
        fechaInicio: null,
        fechaFin: null
      }
    )
  });

  // Se junta array con nulos (solo para orden) y series generadas para graficar
  const dataSeries = [...seriesOrdenMina, ...seriesMovimientoMina];

  /* const dataSeries = [...seriesInicialesOrdenMina, ...seriesSinOrden]; */

  const getAxisYDomain  = (
    from,
    to,
    ref,
    offset
  ) => {
    const refData = dataSeries.slice(from - 1, to);
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
  
    refData.forEach((d) => {
      if (d[ref] > top) top = d[ref];
      if (d[ref] < bottom) bottom = d[ref];
    });
  
    return [(bottom | 0) - offset, (top | 0) + offset];
  };
  

  const estadoData = {
    data: dataSeries,
    left: "dataMin",
    right: "dataMax",
    refAreaLeft: "",
    refAreaRight: "",
    /* top: "dataMax+1",
    bottom: "dataMin-1",
    top2: "dataMax+20",
    bottom2: "dataMin-20", */
    animation: true
  };

  const [initialState, setInitialState] = useState(estadoData);

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = initialState;
    const { data } = initialState;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setInitialState({
        ...initialState,
        refAreaLeft: "",
        refAreaRight: ""
      });
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // yAxis domain
    /* const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, "name", 1); */
    /* const [bottom2, top2] = getAxisYDomain(
      refAreaLeft,
      refAreaRight,
      "impression",
      50
    ); */

    setInitialState({
      refAreaLeft: "",
      refAreaRight: "",
      data: data.slice(),
      left: refAreaLeft,
      right: refAreaRight,
      /* bottom: bottom,
      top: top, */
      /* bottom,top,
      bottom2: initialState.bottom,
      top2: initialState.top2 */
    });
  }

  const zoomOut = () => {
    const { data } = initialState;
    setInitialState({
      data: data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
      left: "dataMin",
      right: "dataMax",
      /* top: "dataMax+1",
      bottom: "dataMin",
      top2: "dataMax+50",
      bottom2: "dataMin+50" */
    });
  }

  const getLimiteDiarioLabel = () => {
    const fechaActual = new Date(`${fecha} 00:00:00`);
    let fechaAnterior = new Date(`${fecha} 00:00:00`);
    fechaAnterior.setDate(fechaAnterior.getDate() - 1);
    const diaActualLabel = fechaActual.getDate();
    const diaAnteriorLabel= fechaAnterior.getDate();
    const mesAnteriorLabel = fechaAnterior.toLocaleString('default', { month: 'long' });
    const mesActualLabel = fechaActual.toLocaleString('default', { month: 'long' }); 

    return (`${diaAnteriorLabel} ${mesAnteriorLabel} - ${diaActualLabel} ${mesActualLabel}`);

  
  };

  return (
    <div className="highlight-bar-charts" style={{ userSelect: "none" }}>
      <HomeIcon 
        style={{color:"#6E8192", fontSize:"20.5px", float: "right", marginRight:"15px", cursor:"pointer"}}
        onClick={()=>zoomOut()}
      />
      <br/>
      <ResponsiveContainer width="100%" height={500}>

      <LineChart
        onMouseDown={(e) =>{
          if(e){
            setInitialState({ ...initialState,refAreaLeft: e.activeLabel })
          }
        }}
        onMouseMove={(e) =>
          initialState.refAreaLeft &&
          setInitialState({...initialState, refAreaRight: e.activeLabel })
        }
        // eslint-disable-next-line react/jsx-no-bind
        onMouseUp={()=>zoom()}

        /* width={1200}
        height={300} */
        data={dataSeries/* data */}
        margin={{
          top: 15,
          right: 15,
          left: 10,
          bottom: 5
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#00b7c4" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#00b7c4" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid stroke="transparent" vertical={false} horizon horizontalFill={['#f3f4f5', '#ffffff']}/>
        <XAxis 
          /* axisLine={false} */
          allowDataOverflow

          tickLine={{ stroke: axesColor }}
          axisLine={{ stroke: axesColor }} 
          style = {{fontSize:"12px"/* "11px" */, fill:"#2f4554"/* , fontWeight:"bold" */}}
          dataKey="fechaInicio" 
          type="number" 
          scale="time" 
          hasTick
          /* domain={domain} */
          domain={[initialState.left,initialState.right]}
          ticks={ticks}
          tickFormatter={dateFormatter}
        />
        <YAxis 
          /* axisLine={false} */
          yAxisId="1"
          axisLine={{ stroke: axesColor }} 
          tickLine={false}
          style = {{fontSize:"12px"/* "11px" */, fill:"#2f4554"/* , fontWeight:"bold" */}}
          width = {90}
          type="category" 
          dataKey="name" 
          padding={{ top: 30, bottom: 30 }}
        />
        <Tooltip 
          itemStyle={{color:"#2f4554"}}
          formatter={(value, name, props) => [`${format(new Date(props?.payload?.fechaInicio), "dd/MM/yy HH:mm:ss")} - ${format(new Date(props?.payload?.fechaFin), "dd/MM/yy HH:mm:ss")}`, value]}
          labelFormatter={(value)=> ""/* format(new Date(value), "dd/MM/yy HH:mm:ss") */}
        />

        <Line isAnimationActive={false} yAxisId="1" type="monotone" dataKey="name" stroke="#00b7c4" /* fill="#2f4554" */ /* fill="url(#colorUv)" *//>

        <ReferenceLine 
          x={(new Date(`${fecha} 00:00:00`)).getTime()} 
          label={{fontStyle:"italic", position: 'top',  value: getLimiteDiarioLabel(), fill: '#3474E0'/* '#2f4554' */, fontSize: 12, fontWeight:"bold"}}
          yAxisId="1"
          stroke=/* 'rgb(249,173,61)' */'#3474E0'
          strokeDasharray="2 2"
          strokeWidth={2}
        />

          {initialState.refAreaLeft && initialState.refAreaRight ? (
            <ReferenceArea
              yAxisId="1"
              x1={initialState.refAreaLeft}
              x2={initialState.refAreaRight}
              stroke={"rgb(133,165,208)"}
              strokeOpacity={1}
              fill={"rgb(133,165,208)"}
              fillOpacity={0.3}
            />
          ) : null}     

      </LineChart>
      </ResponsiveContainer>
    </div>
  );
}