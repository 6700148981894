import React, { useState } from 'react'
import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, makeStyles, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {TablaDeCarga} from '../../../components/organisms/TablaDeCarga'
import { formatearFecha } from '../../../commons/FormatearFecha';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useFormik } from 'formik';
import * as Yup from "yup";
// import { useGetSolicitudReglasVueltasGPS } from '../../../hooks/solicitudes/useGetSolicitudReglasVueltasGPS';
// import { PopUpConfirmarSolicitudCiclosGPS } from './PopUpConfirmarSolicitudCiclosGPS';
import Pool from '../../../authentication/../UserPool'
import { acciones_tipo_geocerca_by_version, acciones_vueltas_gps_by_vueltas_gps_control_version } from '../../../services/administracion';
import { PopUpSuccess } from '../../../components/organisms/PopUpSuccess';
import { useGetSolicitudesTipoGeocercaGPS } from '../../../hooks/solicitudes/useGetSolicitudesTipoGeocercaGPS';
import { PopUpConfirmacionGenerico } from '../../../components/organisms/PopUpConfirmacionGenerico';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#2f4554",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });


export const TablaTipoGeocercaSolicitud = ({id_area_operacion, nombreMina}) => {
    
    const { t } = useTranslation();
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [reloadData, setreloadData] = useState(false);
    const [alert, setAlert] = useState({
      open: false,
      message: "",
      severity: "success",
    });
    const { SolicitudesTipoGeocerca, loadingSolicitudesTipoGeocerca } = useGetSolicitudesTipoGeocercaGPS(id_area_operacion, reloadData)
    const [verPopUpConfirmacion,setVerPopUpConfirmacion] = useState(false);
    const [verPopUpSuccess,setVerPopUpSuccess] = useState(false);
  
    const onHandleVerPopUpConfirmacion = (boolVal) =>{
      setVerPopUpConfirmacion(boolVal)
    }
    const onHandleVerPopUpSuccess = (boolVal) =>{
      setVerPopUpSuccess(boolVal)
      if(!boolVal){
        setreloadData(!reloadData);
      }
    }
  
    const initialStateForm = {
      id:0,
      id_area_operacion: id_area_operacion,
      id_tipo_operacion:0,
      tipo_geocerca:'',
      solicitante:'',
      tipo_operacion:''
    }
  
    const formik = useFormik({
      initialValues:initialStateForm,
      validationSchema:Yup.object({}),
      onSubmit:async(values) =>{
        let aprobador = Pool.getCurrentUser().username;
        const {id_tipo_operacion,id} = values;
        await acciones_tipo_geocerca_by_version(id_tipo_operacion,aprobador,id);
          setVerPopUpSuccess(true)
      }
    })
  
    const handleErrorForm = () => {
  
      if (!formik.isValid) {
        let msg = "";
        const claves = Object.keys(formik.errors);
        for (const clave of claves) {
          const valor = "* " + formik.errors[clave] + "?";
          msg += valor;
        }
        setAlert({
          open: true,
          message: msg,
          severity: "warning",
        });
        return true;
      }else{
          return false
      }
    };
  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    const handleOnClickSetFormikValues = (ciclo) =>{
      formik.setFieldValue("id",ciclo.id);
      formik.setFieldValue("id_area_operacion",ciclo.id_area_operacion);
      formik.setFieldValue("id_tipo_operacion",ciclo.id_tipo_operacion);
      formik.setFieldValue("tipo_operacion",ciclo.tipo_operacion)
      formik.setFieldValue("solicitante",ciclo.solicitante);
      formik.setFieldValue("tipo_geocerca",ciclo.descripcion)
    }

    if(loadingSolicitudesTipoGeocerca){
       return <TablaDeCarga
        columnas={[
          t("Tipo de geocerca"), 
          t("Tipo de solicitud"), 
          t("Fecha de solicitud"), 
          t("solicitante"),
          t("acción")
        ]}
      />
    }
  return (
    <div>
         <Grid container style={{marginBottom:'20px'}}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                        <StyledTableCell align="center">{t("Tipo de geocerca")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Tipo de solicitud")}</StyledTableCell>
                            <StyledTableCell align="center">{"Fecha de solicitud"}</StyledTableCell>
                            <StyledTableCell align="center">{t("Solicitante")}</StyledTableCell>
                            <StyledTableCell align="center">{t("accion")}</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                          SolicitudesTipoGeocerca.length < 1 ?
                          (
                            <StyledTableRow key={9999}>
                              <StyledTableCell colSpan={9} align="center" component="th" scope="row">{"Sin solicitudes pendientes"}</StyledTableCell>
                            </StyledTableRow>
                          ) :
                          
                          SolicitudesTipoGeocerca.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ciclo,index) =>{
                            return(
                              <StyledTableRow key={ciclo.id+"-"+index}>
                                <StyledTableCell align="center" component="th" scope="row">{t(ciclo.descripcion)}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{(ciclo.id_tipo_operacion == 1) ? 'Nuevo' : (ciclo.id_tipo_operacion == 2) ? "Edición" : "Descartar"}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{formatearFecha(ciclo.created)}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{t(ciclo.solicitante)}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">
                                  <Grid container alignItems='center' justifyContent='space-evenly' spacing={3}>
                                    <CheckCircleIcon onClick={() => {
                                       handleOnClickSetFormikValues(ciclo);
                                       formik.setFieldValue("id_tipo_operacion",ciclo.id_tipo_operacion);
                                        onHandleVerPopUpConfirmacion(true);
                                      }} sx={{color:"green", cursor:"pointer"}}/>
                                    <CancelIcon onClick={() =>{
                                       handleOnClickSetFormikValues(ciclo);
                                       formik.setFieldValue("id_tipo_operacion",4);
                                        onHandleVerPopUpConfirmacion(true);
                                    }} sx={{color:"red", cursor:"pointer"}}/> 
                                  </Grid>
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          })
                        }
                    </TableBody>
                </Table>
                <TablePagination
                rowsPerPageOptions={[30, 40]}
                component="div"
                count={SolicitudesTipoGeocerca.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("filas_por_pagina")}
              />
            </TableContainer>
        </Grid>
            
        {/* PopUp Success */}
        <PopUpSuccess 
            onHandleShowPopUp={onHandleVerPopUpSuccess}
            show={verPopUpSuccess}
            texto='Operación realizada con exito'
            subtitulo='Se han realizado los cambios en los registros'
        />


        {/* pop up confirmar o rechazar cambio */}
        <PopUpConfirmacionGenerico
            editing={true}
            formik={formik}
            handleErrorForm={handleErrorForm}
            onHandleShowPopUp={onHandleVerPopUpConfirmacion}
            onHandleShowPopUpSuccess={onHandleVerPopUpSuccess}
            show={verPopUpConfirmacion}
            dataColumnas={{
                columna1:[{
                    label:'Mina',
                    value:nombreMina
                },{
                    label:'Solicitante',
                    value:formik.values.solicitante
                }],
                columna2:[{
                    label:'Tipo de geocerca',
                    value:formik.values.tipo_geocerca
                },{
                    label:'Tipo de operacion',
                    value:(formik.values.id_tipo_operacion == 1) ? 'Nuevo' :
                          (formik.values.id_tipo_operacion == 2) ? "Edición" :
                          (formik.values.id_tipo_operacion == 3) ? "Descartar" :
                          "Descartar version"
                }]
            }}
        />
    </div>
  )
}
