import { useEffect, useState } from 'react'
import { getReglasVueltasGps } from '../../../services/administracion';

export const useGetReglasVueltasGps = (id_area_operacion) => {
  const [dataReglasVueltasGps, setDataReglasVueltasGps] = useState();
  const [loadingReglasVueltasGps, setloadingReglasVueltasGps] = useState(false)

  useEffect(() => {
    setloadingReglasVueltasGps(true);
    getReglasVueltasGps(id_area_operacion)
    .then(resp => {
        setDataReglasVueltasGps(resp.data)
        setloadingReglasVueltasGps(false);
    })
    .catch(err => {
        console.log(err)
        setloadingReglasVueltasGps(false)
    })
  }, [])

  return { dataReglasVueltasGps, loadingReglasVueltasGps }
  
}
