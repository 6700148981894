import React from "react";
import { Grid } from "@mui/material";
import {CircularProgress, Dialog} from "@material-ui/core";
const PantallaCarga = (props) => {
    return(
      <>
        <Dialog
          hideBackdrop={true}
          open={props.loading}
          aria-labelledby='max-width-dialog-title'
          PaperProps={{
          style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
          },
          }}
        >
            <Grid container direction='column' justify='center' alignItems='center'>
                <Grid item xs={12}>
                    <CircularProgress size={100} />
                </Grid>
            </Grid>
        </Dialog>
      </>
    );
};


export default PantallaCarga;