import { useEffect, useState } from "react";
import { getRutaRedireccionByIdEmpresa } from "../../services/general";

export const useGetRutaRedireccionEmpresa = (id_empresa) => {
  const [RutaRedireccionEmpresa, setRutaRedireccionEmpresa] = useState({});
  const [loadingRutaRedireccionEmpresa, setLoadingRutaRedireccionEmpresa] = useState(false);
    
  useEffect(() => {
    if(id_empresa != 0){
        getRutaRedireccionByIdEmpresa(id_empresa)
          .then((res) => {
            setRutaRedireccionEmpresa(res.data);
            setLoadingRutaRedireccionEmpresa(false);
          })
          .catch((err) => {
            console.log(err);
            setLoadingRutaRedireccionEmpresa(false);
          });
    }
  }, [id_empresa]);


  return { loadingRutaRedireccionEmpresa, RutaRedireccionEmpresa };
};
