import React, { useContext, useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Container
} from "@material-ui/core";
import {
  Card,
  CardMedia,
  Grid,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import "moment/locale/es";
import { useLocation } from "react-router-dom";
import NavBar from "../../components/organisms/NavBar.js";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SectionHeader from "../../components/molecules/SectionHeader.js";
import { format } from "date-fns";
import {
  getVueltasDia, 
  getInfoGeneralMina,
} from "../../services/operaciones";
import IconoVueltasDark from '../../assets/icons/icono-vueltas-dark.png';
import ImagenScoop from "../../assets/images/lhd.png";
import ImagenCamion from "../../assets/images/camion.png";
import VueltaEquipoCard from "./VueltaEquipoCard";
import { sortByDate } from "../../commons/Filtros.js";
import IconoPortal from '../../assets/icons/icono-prod-portal.png';
import IconoDescargar from '@mui/icons-material/FileDownload';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableChartIcon from '@mui/icons-material/TableChart';

import { es } from "date-fns/locale";
import {KeyboardDatePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ModalDescarga from './ModalDescarga';

import '../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from "../../commons/Idiomas";
import { TablaControlDiario } from "./TablaControlDiario.js";
import { GeneralMinaContext } from "../../context/GeneralMina.js";
import { usePageTimingContext } from "../../context/PageTimingContext.js";
import { BreadCrumbsGenerico } from "../../components/molecules/BreadCrumbsGenerico.js";

const useStyles = makeStyles({
  iconClick:{
    width:"25px",
    color: "#2f4554",
    cursor: "pointer",
    "&:hover": {
      color: "#00b7c4"
    }
  },
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "0px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    pointerEvents:"none"
  },
  buttonUnselected: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "0px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4",
      color:"#fff"
    }
  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main"
      }
    },

    '& .MuiOutlinedInput-root': { 
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
  },

  }
});

const ControlDiario = ({idMina,nombreMina}) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { sendTime } = usePageTimingContext();

  const [fechaActual, setFechaActual] = useState(new Date());
  const [loading, setLoading] = useState(true)
  const [tipoFlota, setTipoFlota] = useState("Camion");
  const [tipoTurno, setTipoTurno] = useState("");
  const [datosMina, setDatosMina] = useState({
    camiones:{},
    scoops:{},
    turnos:{}
  })
  const [listaTurnos, setListaTurnos] = useState([]);
  const [primerasVueltas, setPrimerasVueltas] = useState({});
  const [ultimasVueltas, setUltimasVueltas] = useState({});

  const [openModalDescarga, setOpenModalDescarga] = useState(false);

  const [listaTiposDeFlota, setListaTiposDeFlota] = useState([]);
  const [vistaModoTablaPrimeraVuelta, setVistaModoTablaPrimeraVuelta] = useState(true);
  const { ListadoMaquinas, loadingMaquinas} = useContext(GeneralMinaContext);

  const getDatosMina = (fecha) => {
    const controller = new AbortController();    
    const resInfo = getInfoGeneralMina(idMina,format(fecha, "yyyy-MM-dd"), controller);
    resInfo.then(({success,data}) => {
      if(success){
        const lTiposFlota = [...new Set(ListadoMaquinas.map( m => m.tipo))]
        lTiposFlota.push("todos")
        setListaTiposDeFlota(lTiposFlota)
        if(!lTiposFlota.includes(tipoFlota)) {setTipoFlota(lTiposFlota[0]||tipoFlota)};
        const turnosConKey= data.turnos.reduce((obj, item) => ((obj[item.nombre] = item, obj)),{});
        const listScoops= ListadoMaquinas.filter(m => m.tipo === "LHD").reduce((obj, item) => ((obj[item.nombre] = item, obj)),{});
        const listCamiones = ListadoMaquinas.filter(m => m.tipo === "Camion").reduce((obj, item) => ((obj[item.nombre] = item, obj)),{});
        const listTodos = ListadoMaquinas.filter(m => m.tipo === "Camion" || m.tipo === "LHD").reduce((obj, item) => ((obj[item.nombre] = item, obj)),{});

        // toLowerCase provisorio para traducir turnos (agregar columna nombre ingles peticion)
        setListaTurnos(data.turnos);
        setTipoTurno(data.turnos[0]?.nombre || "");
        setDatosMina({
          scoops:listScoops,
          camiones:listCamiones,
          todos:listTodos,
          turnos: turnosConKey,
        })
      }
      else{
        setLoading(false)
      }
    });
  }

  const onChangeFechaActual = (fecha) => {
    setPrimerasVueltas({});
    setUltimasVueltas({});
    setLoading(true);
    setFechaActual(fecha);
    getDatosMina(fecha)
  }

  useEffect(()=>{
    setTipoFlota("todos");
    setTipoTurno("");
    setLoading(true);
    const nuevaFecha = new Date();
    setFechaActual(nuevaFecha);
    getDatosMina(nuevaFecha)
  },[idMina])


  useEffect(()=>{
    if(Object.keys(datosMina.turnos).length>0){
      const controller = new AbortController();
      const res = getVueltasDia(idMina,format(fechaActual, "yyyy-MM-dd"), controller);
      res.then(({success,data}) => {
        if(success){
          const objCamiones = {};
          Object.keys(datosMina.camiones).forEach(c=>{objCamiones[c]={ubicacion:"-",fecha:"-"}})

          const objScoops = {};
          Object.keys(datosMina.scoops).forEach(s=>{objScoops[s]={ubicacion:"-",fecha:"-"}})

          const objTurnosCamiones = {};
          const objTurnosScoops = {};
          const objTurnosTodos = {};

          Object.keys(datosMina.turnos).forEach(t => {
            objTurnosCamiones[t]=JSON.parse(JSON.stringify(objCamiones)); 
            objTurnosScoops[t]=JSON.parse(JSON.stringify(objScoops)); 
            objTurnosTodos[t] = {...JSON.parse(JSON.stringify(objCamiones)), ...JSON.parse(JSON.stringify(objScoops))};
          })

          const objPrimerasVueltas = {Camion: JSON.parse(JSON.stringify(objTurnosCamiones)), LHD: JSON.parse(JSON.stringify(objTurnosScoops)), todos: JSON.parse(JSON.stringify(objTurnosTodos))};
          const objUltimasVueltas = {Camion: JSON.parse(JSON.stringify(objTurnosCamiones)), LHD: JSON.parse(JSON.stringify(objTurnosScoops)), todos: JSON.parse(JSON.stringify(objTurnosTodos))};


          Object.entries(data).forEach(([keyMaq,valueMaq])=>{
            Object.entries(valueMaq).forEach(([keyTurno,valueTurno])=>{
              const listaMaquinas = keyMaq==="Camion"?Object.keys(objCamiones):Object.keys(objScoops);
              const keyPrimeraVuelta = keyMaq==="Camion"?"fin ":"inicio";
              listaMaquinas.forEach( m => {
                const vueltasOrdenadas = sortByDate(valueTurno.vueltas.filter( v => v.nombre_maquina == m), "fin ");
                if(vueltasOrdenadas.length>0){
                  objPrimerasVueltas[keyMaq][keyTurno][m]["ubicacion"] = vueltasOrdenadas[0].ubicacion;
                  objPrimerasVueltas[keyMaq][keyTurno][m]["fecha"] = vueltasOrdenadas[0][keyPrimeraVuelta];

                  //todas las maquinas
                  objPrimerasVueltas["todos"][keyTurno][m]["ubicacion"] = vueltasOrdenadas[0].ubicacion;
                  objPrimerasVueltas["todos"][keyTurno][m]["fecha"] = vueltasOrdenadas[0][keyPrimeraVuelta];
                }
                if(vueltasOrdenadas.length>1){
                  objUltimasVueltas[keyMaq][keyTurno][m]["ubicacion"] = vueltasOrdenadas[vueltasOrdenadas.length-1].ubicacion;
                  objUltimasVueltas[keyMaq][keyTurno][m]["fecha"] = vueltasOrdenadas[vueltasOrdenadas.length-1]["fin "];

                  //todas las maquinas
                  objUltimasVueltas["todos"][keyTurno][m]["ubicacion"] = vueltasOrdenadas[vueltasOrdenadas.length-1].ubicacion;
                  objUltimasVueltas["todos"][keyTurno][m]["fecha"] = vueltasOrdenadas[vueltasOrdenadas.length-1]["fin "];
                }
              })
            })
          })

          setUltimasVueltas(objUltimasVueltas);
          setPrimerasVueltas(objPrimerasVueltas);
          setLoading(false);
        }
        else{setLoading(false)}
      });
    }
  },[datosMina])

  useEffect(() => {
    return () => {
      sendTime(location.pathname)
    }
  }, [])


  /* useEffect(()=>{
    const timer = setTimeout(function() {
      const nuevaFecha = new Date();
      setFechaActual(nuevaFecha);
      setLoading(true);
      getDatosMina(nuevaFecha)
    }, 300000)
     return () => {
      clearTimeout(timer);
     };
   }) */

   const onHandleVistaPrimerVuelta = (event, esVistaModoTabla) =>{
    setVistaModoTablaPrimeraVuelta(esVistaModoTabla)
   }

  return(
    <>
      <NavBar />
      <Container>
        <br/>
        <br/>

        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <BreadCrumbsGenerico
            nombreMina={nombreMina}
            dataRuta={ [
              { id: 1, endpoint: "/", texto: `Mine-Watch` },
              { id: 2, texto: `${t('minaSingular')} ${nombreMina}` },
              { id: 3, texto: t("control_diario"),destacar:true }
            ]}
            />
          </Grid>
        </Grid>

        <br/>
        <br/>


        <Grid item lg={8} md={8} >
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item lg={4} md={4} xs={12}>  
              <Grid 
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <div style={{width: "120px"}}>
                  <CardMedia
                    component="img"
                    image={tipoFlota==="Camion"?ImagenCamion: tipoFlota =="LHD" ? ImagenScoop : ImagenCamion}
                    alt="Imagen Maquina"
                    style={{ width: "95px", margin: "0 auto" }}
                  />
                </div>
                <Grid item>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "14.4px",
                      color: "#00b7c4"
                    }}
                  >
                    {tipoFlota==="Camion"?t("flota_camion"): tipoFlota === "LHD" ? t("flota_lhd") : t('todos_los_equipos')}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "14.4px",
                    }}
                  >
                    {(i18n.language === 'es')?
                      (`Mostrando el ${fechaActual.getDate()} de ${fechaActual.toLocaleString('es-ES', { month: 'long' }).substr(0,1).toUpperCase()}${fechaActual.toLocaleString('es-ES', { month: 'long' }).substr(1)}`)
                    :
                      (`Data of ${fechaActual.toLocaleString('en-EN', { month: 'long', year:'numeric', day:'numeric' })}`)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="center"
                justifyContent=""
                spacing={2}
              >     
                <Grid item>
                  <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      cancelLabel={t("cancelar")}
                      className={classes.datepicker}
                      size="small"
                      inputVariant="outlined"
                      minDate={new Date('2021-11-01 00:00:00')}
                      maxDate={(new Date().setHours(23,59,59))}
                      value={fechaActual}
                      onChange={(newValue) => {
                        onChangeFechaActual(newValue);
                      }}     
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {/* <Grid item>    
                  <IconoDescargar
                    className={classes.iconClick} 
                    onClick={()=>setOpenModalDescarga(true)}
                  />
                </Grid> */}
              </Grid>
            </Grid>
            {/* <Grid item>

            </Grid> */}
          </Grid>

        </Grid>

        <br/>
        <br/>

        <Grid container justifyContent='space-between' alignItems="center"> 
          <Grid item lg={6} md={6} xs={12}>  
            <Grid container justifyContent='flex-start' alignItems="center" gap={1}>    
            {listaTiposDeFlota.includes("todos") &&
              <Button
                  onClick={()=>setTipoFlota("todos")}
                  className={tipoFlota==="todos"?classes.buttonSelected:classes.buttonUnselected}
              >
                {t('todos_los_equipos')}
              </Button>
            }
              {listaTiposDeFlota.includes("Camion") &&
                <Button
                    onClick={()=>setTipoFlota("Camion")}
                    className={tipoFlota==="Camion"?classes.buttonSelected:classes.buttonUnselected}
                >
                  {t('flota_camion')}
                </Button>
              }

              {listaTiposDeFlota.includes("LHD") &&
                <Button
                    onClick={()=>setTipoFlota("LHD")}
                    className={tipoFlota==="LHD"?classes.buttonSelected:classes.buttonUnselected}
                >
                  {t('flota_lhd')}
                </Button>
              }

            </Grid>
          </Grid>
          <Grid item lg={5} md={5} xs={12}>  
            <Grid container justifyContent='flex-end' alignItems="center">  
              {
                listaTurnos.map((itemTurno,index) => {
                  return(
                      <Button
                          key={index+1}
                          onClick={()=>setTipoTurno(itemTurno.nombre)}
                          className={(tipoTurno===itemTurno.nombre)?classes.buttonSelected:classes.buttonUnselected}
                      >
                        {t(`turno_${itemTurno.nombre.toLowerCase()}`)}
                      </Button>
                  )
                })
              }  
            </Grid>
          </Grid>
        </Grid>

        <br/>
        <br/>
        <Grid item xs={12} justifyContent={"flex-end"}>
              <Stack direction={'row'} justifyContent={"flex-end"}>
                  <ToggleButtonGroup
                    value={vistaModoTablaPrimeraVuelta}
                    exclusive
                    onChange={onHandleVistaPrimerVuelta}
                    aria-label="text alignment"
                  >
                    <ToggleButton value={false} aria-label="left aligned">
                      <ViewModuleIcon style={{color:'#2f4554'}}/>
                    </ToggleButton>
                    <ToggleButton value={true} aria-label="centered">
                      <TableChartIcon style={{color:'#2f4554'}}/>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
            </Grid>
            <br/>
       
        <Card sx={{padding:"25px"}}>  
          <SectionHeader 
            title={tipoFlota==="Camion"?t("primera_vuelta"): tipoFlota === "LHD" ? t("primer_ingreso") : t("primer_ingreso_vuelta")}
            subtitle={`${t("ultima_actualizacion")} ${format(fechaActual, "HH:mm")} hrs`}
            icon={<img src={IconoPortal} width="25px" alt="IconoVueltasDark"/>}
          />

          <br/>          
          
          {
            loading &&
            <Grid container justifyContent="center" alignItems="center">          
              <CircularProgress/>
            </Grid>
          }

          {
            vistaModoTablaPrimeraVuelta && !loading ? 
            <TablaControlDiario data={Object.keys(primerasVueltas).length >0 ? primerasVueltas[tipoFlota][tipoTurno] : {}}/> :
          
            <Grid container spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              { (tipoTurno!=="" && Object.keys(primerasVueltas).length>0) &&
                Object.entries(primerasVueltas[tipoFlota][tipoTurno]).map( ([key,value]) => {
                  return(
                    <Grid key={key} item lg={4} md={6} xs={12}>
                      <VueltaEquipoCard
                        nombre={key}
                        ubicacion={value.ubicacion}
                        fecha={value.fecha}
                      />   
                    </Grid>

                  )
                })
              }
            </Grid>
          }



          <br/>

        </Card>

        <br/>

        <Card sx={{padding:"25px"}}>
          <SectionHeader 
            title={tipoFlota==="Camion"?t("ultima_vuelta"): tipoFlota === "LHD" ? t("ultima_salida") : t("ultima_salida_vuelta")}
            subtitle={`${t("ultima_actualizacion")} ${format(fechaActual, "HH:mm")} hrs`}
            icon={<img src={IconoPortal} width="25px" alt="IconoVueltasDark"/>}
          />

          <br/>          
          
          {
            loading &&
            <Grid container justifyContent="center" alignItems="center">          
              <CircularProgress/>
            </Grid>
          }
          {
            vistaModoTablaPrimeraVuelta && !loading ? 
            <TablaControlDiario data={Object.keys(ultimasVueltas).length >0 ? ultimasVueltas[tipoFlota][tipoTurno] : {}}/> :

            <Grid container spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              { (tipoTurno!=="" && Object.keys(ultimasVueltas).length>0) &&
                Object.entries(ultimasVueltas[tipoFlota][tipoTurno]).map( ([key,value]) => {
                  return(
                    <Grid key={key} item lg={4} md={6} xs={12}>
                      <VueltaEquipoCard
                        nombre={key}
                        ubicacion={value.ubicacion}
                        fecha={value.fecha}
                      />   
                    </Grid>

                  )
                })
              }
            </Grid>
          }


          <br/>

        </Card>

        <ModalDescarga open={openModalDescarga} onClickClose={setOpenModalDescarga}/>

      </Container>

      <br/>

    </>
  )
} 

export default ControlDiario;