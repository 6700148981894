import React from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, InputLabel, Link, makeStyles, Typography } from '@material-ui/core'
import { GenericPopUp } from '../molecules/GenericPopUp';

import iconoEditar from '../../assets/icons/icono-editar.svg';

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

  let columnsParameterExample = {
    columna1:[{
        label:'label1',
        value:'value1'
    }],
    columna2:[{
        label:'label2',
        value:'value2'
    }]
  };

export const PopUpConfirmacionGenerico = ({formik, show, onHandleShowPopUp,onHandleShowPopUpSuccess,handleErrorForm, editing, dataColumnas= columnsParameterExample}) => {
    
    const { t } = useTranslation();
    const classes = useStyles();

  return (
    <>
        <GenericPopUp show={show} handleShowPopUp={onHandleShowPopUp} showCancel={false} Children={
            <Grid container justifyContent='center' alignItems='center' direction='column' style={{paddingBottom:'50px'}}>
                <Grid container item justifyContent='center' alignItems='center' direction='row'>
                    <Grid style={{margin:'20px 20px'}}>
                       <img style={{width:'32px'}} alt='editarVicon' src={iconoEditar}/>
                    </Grid>
                    <Typography
                        style={{fontWeight: "bolder",fontSize: "15px",color: "#2f4554", textAlign:'center'}}
                    >
                       { editing ? t('confirmar_cambios') : t("agregar_nuevo_registro")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Box sx={{color:'#2f4554', fontWeight:'500' ,padding:'45.5px 45px', backgroundColor:'#f3f4f7',textAlign:'center',minWidth:'600px'}}>
                        <Grid container item xs={12} spacing={2}>

                            {
                                Object.entries(dataColumnas).map(([clave, valor],index) =>{
                                    return (
                                        <Grid key={index} container item xs={6} >
                                            {valor.map((datoColumn,idx) => 
                                                (
                                                    <Grid key={idx} container item xs={12} justifyContent='flex-start' alignItems='flex-start' wrap='nowrap'>
                                                        <Grid item xs={6} style={{minWidth:'100px'}}>
                                                            <InputLabel >
                                                                <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>{datoColumn.label}</Typography>
                                                            </InputLabel>
                                                        </Grid>
                                                        <Grid item xs={6} style={{minWidth:'70px'}}>
                                                            <InputLabel >
                                                                <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{datoColumn.value}</Typography>
                                                            </InputLabel>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            )}

                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </Grid>
                <Grid container direction='column' justifyContent='center' alignItems='center'>
                    <Button
                            type="button"
                            variant="contained"
                            className={classes.root}
                            style={{textAlign:'center', marginTop:'35px', padding:'8px 80px'}}
                            onClick={() =>{
                               if( handleErrorForm() == true){
                                   handleErrorForm()
                                }else{
                                   formik.submitForm();
                                   onHandleShowPopUp(false);
                                   onHandleShowPopUpSuccess(true);
                               }
                            }
                            }
                            >
                            {t("confirmar_cambios")}
                    </Button>
                    <Box sx={{display:'flex',justifyContent:'center', marginTop:'30px'}}>
                        <Link 
                        style={{cursor:'pointer'}} 
                        onClick={()=>{
                            onHandleShowPopUp(false);
                        }} 
                        underline='always' 
                        color='inherit'>{t('cancelar')}</Link>
                    </Box>
                </Grid>
            </Grid>
        }/>
    </>
  )
}

