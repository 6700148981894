import React, { useContext, useState } from 'react'

import { useHistory } from "react-router";
import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemText,
    makeStyles,
  } from "@material-ui/core";
import Collapse from '@mui/material/Collapse';
import { GeneralMinaContext } from '../../context/GeneralMina';
import { ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { format } from 'date-fns';
import PantallaCarga from '../molecules/PantallaCarga';
import { formatDateToUTC } from '../../commons/FormatearFecha';
import { useTranslation } from 'react-i18next';
import { translateMachineName } from '../../commons/utiles';



  const useStyles = makeStyles({
    list: {
      width: 220,
    },
    info: {
      "text-align": "center",
      "font-weight": "bold",
    },
    paper: {
      background: "#2f4554"
    }
  });

  const turnoTodos = {
    nombre: "turnotodos",
    hora_inicio: "00:00:00",
    hora_fin: "23:59:59",
    id_tipo_turno: 0,
    descripcion: "",
    orden: 0
  }

export const NavDrawerEquipos = ({toggleDrawer,drawerOpened}) => {
    const classes = useStyles();
    let history = useHistory();
    const { t, i18n } = useTranslation();
    const {onHandleGetGeneralData,onHandleGetMaquinasTipo} = useContext(GeneralMinaContext);
    const [expandEquipo, setExpandEquipo] = React.useState({});
    const [turnoSelec, setTurnoSelec] = useState(turnoTodos);
    const [loading, setLoading] = useState(false);
    const getMinMax = (turnoSelec) => {
        const soloFecha = format(new Date, "yyyy-MM-dd");
        let fechaInicio = new Date(`${soloFecha} ${turnoSelec.hora_inicio}`);
        let fechaFin = new Date(`${soloFecha} ${turnoSelec.hora_fin}`);
        switch(Number(turnoSelec.id_tipo_turno)){
          case 1:
            // El turno se encuentro entre dos dias, partiendo por la madrugada del dia anterior
            fechaInicio.setDate(fechaInicio.getDate() - 1);
            return ({
              min: fechaInicio,
              max: fechaFin
            })
          case 2:
            // El turno es dentro de horario de un mismo dia
            return ({
              min: fechaInicio,
              max: fechaFin
            })
            // Case 3 representaria turno entre dos dias que termina en el dia siguiente
          case 3:
            fechaFin.setDate(fechaFin.getDate() + 1);
            return ({
              min: fechaInicio,
              max: fechaFin
            })
    
        }
      };

        const handleClickExpandMina = (equipo) => {
        if(equipo.tipo===expandEquipo.tipo){
            setExpandEquipo({...expandEquipo, expand: !expandEquipo.expand});
        }
        else{
            setExpandEquipo({...equipo, expand: true})
        }
        };

      const onClickMaquina = (maq) =>{
        setLoading(true);
        toggleDrawer(false)

        if(formatDateToUTC(maq?.ultimo_dato_movimiento)){
          localStorage.setItem("fechaObjetivo", formatDateToUTC(maq?.ultimo_dato_movimiento))
        }

        if(maq.tipo_maquina == 3 || maq.tipo_maquina == 4){
            let minMax = getMinMax(turnoSelec)
            localStorage.setItem("dataOpMaq",JSON.stringify({...maq,tipo:maq.tipo}))
            localStorage.setItem("estadosJumbo",JSON.stringify({tipoTurno:"turnotodos",minMax}));
            localStorage.setItem("IdMina",maq.id_area_operacion);
            history.push(`/operaciones/maquina/jumbo`);
            window.location.reload();
            setLoading(false);

        } else{
            setLoading(true);
            localStorage.setItem("dataOpMaq",JSON.stringify({...maq,tipo:maq.tipo,toneladas:30}))
            localStorage.setItem("IdMina",maq.id_area_operacion);
            history.push(`/operaciones/maquina`);
            window.location.reload();
            setLoading(false);
        }
        
        
    } 
    if(loading){
      return <PantallaCarga loading={loading}/>
    }
  return (
    <Drawer 
        classes={{paper:classes.paper}}
        anchor='left'
        open={drawerOpened}
        onClose={() =>toggleDrawer(false)}
    >
        <div
            className={classes.list}
            onKeyDown={() =>toggleDrawer(false)}
            style={{height:'100%', color:"white"}}
        >
            <List>
                <ListItem className={classes.info}>
                    <ListItemText primary={t("equipos")}/>
                </ListItem>
            </List>
            <Divider/>

          
            {
                onHandleGetMaquinasTipo() && Object.keys(onHandleGetMaquinasTipo()).map((tipo,idx) =>{
                const maquinasDelTipo = onHandleGetMaquinasTipo()[tipo];
                return(
                    <div key={`${tipo}-${idx}`}>
                        <ListItemButton onClick={() => handleClickExpandMina({tipo})}>
                            <ListItemText primary={onHandleGetGeneralData()?.idMina == 24 ? t(tipo.toLowerCase()+"s_traducir") : t(tipo.toLowerCase()+"s")}/>
                            {((expandEquipo.tipo===tipo) && (expandEquipo.expand)) ? 
                            <ExpandLess /> : 
                            <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={expandEquipo.tipo===tipo && expandEquipo.expand} timeout={'auto'} unmountOnExit>
                        {
                            maquinasDelTipo.map((maq,index)=>{
                                return(
                                    <List key={`${maq.modelo}-${maq.id}`} component={'div'} disablePadding>
                                        <ListItemButton onClick={() =>onClickMaquina(maq)} sx={{ pl: 4 }} >
                                            <ListItemText primary={translateMachineName(maq.nombre,t,onHandleGetGeneralData()?.idMina)} />
                                        </ListItemButton>
                                    </List>
                                )
                            })
                        }
                        </Collapse>
                    </div>
                )
                })
            }
        </div>
    </Drawer>
  )
}