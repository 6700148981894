import { useEffect, useState } from 'react'
import { getGeoCercasGPS } from '../services/operaciones';

export const useGetGeoCercas = (id_area_operacion, recargarTabla, controlaVelocidad = false) => {
 const [loadingGeoCercas, setLoadingGeoCercas] = useState(true);
 const [GeoCercas, setGeoCercas] = useState([]);

 useEffect(() => {
    setLoadingGeoCercas(true);
   getGeoCercasGPS(id_area_operacion,controlaVelocidad)
    .then(res => {
            setGeoCercas(res.data)
            setLoadingGeoCercas(false);
        })
    .catch(err => {
            console.log(err)
            setLoadingGeoCercas(false);
        })

 }, [id_area_operacion, recargarTabla])
 
 return {loadingGeoCercas, GeoCercas}
}
