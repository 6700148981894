import { useState, useEffect } from 'react';
import { getMovimientosGPS } from '../services/operaciones';

export const useGetMovimientosGPS = (id_area_operacion,id_maquina,fecha) => {

    const [movimientosGPS, setMovimientosGPS] = useState([]);
    const [loadingMovimientosGPS, setloadingMovimientosGPS] = useState(false);


    useEffect(() => {
        setloadingMovimientosGPS(true);
        getMovimientosGPS(id_area_operacion,id_maquina,fecha)
        .then((resp) =>{
            setMovimientosGPS(resp.data);
            setloadingMovimientosGPS(false);
        })
        .catch(err => console.log(err))
        .finally(() => setloadingMovimientosGPS(false));

    }, [fecha])

    return { movimientosGPS, loadingMovimientosGPS}
    
}
