import {React, useContext, useEffect, useMemo, useRef} from "react";
import Chart from "react-apexcharts";
import '../../translator';
import { GeneralChartsContext } from "../../context/GeneralCharts";
import { useTranslation } from 'react-i18next';
import { generateDatabaseDateTimeENUTC } from "../../commons/FormatearFecha";
import { translateMachineName } from "../../commons/utiles";
import { GeneralMinaContext } from "../../context/GeneralMina";

  const HorizontalTimeChart = ({series, turno, numCategorias,abrirModalJustificaciones=undefined,idMina=undefined, MostrarLabelCompleto = false, fullHeight=false, maxWidthYLabel=185}) => {
  const { t, i18n } = useTranslation();
  const altoGrafico = 60+55*numCategorias;
  const {onHandleGetGeneralData} = useContext(GeneralMinaContext);
  const { horoumeterLimits, onHandleSetHoroumeterLimits } = useContext(GeneralChartsContext);
  useEffect(() => {
      onHandleSetHoroumeterLimits(turno?.min?.getTime(),turno?.max?.getTime())
  }, [turno?.min,turno?.max])

  const options =  useMemo(() => {
  return {
    states: {
      hover: {
          filter: {
              type: 'darken',
              value: 0.8,
          }
      },
    },
    legend: {
      show: false
    },
    chart: {
      type: 'rangeBar',
      // sparkline:{enabled:true},
      // parentHeightOffset: 0,
      toolbar:{show:false},  
      zoom: {enabled: false},
      defaultLocale: i18n?.language ? i18n.language : "en",
      locales: [{
        name: 'en',
        options: {
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          days: ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
          shortDays: ['Dom','Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          toolbar: {
            download: 'Descargar SVG',
            selection: 'Selección',
            selectionZoom: 'Selección Zoom',
            zoomIn: 'Zoom',
            zoomOut: 'Alejar',
            pan: 'Panning',
            reset: 'Reset Zoom',
          }
        }
      },
      {
        name: 'es',
        options: {
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          days: ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
          shortDays: ['Dom','Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          toolbar: {
            download: 'Descargar SVG',
            selection: 'Selección',
            selectionZoom: 'Selección Zoom',
            zoomIn: 'Zoom',
            zoomOut: 'Alejar',
            pan: 'Panning',
            reset: 'Reset Zoom',
          }
        }
      }
    ],
      events:{
        dataPointSelection: function (event, chartContext, config) {

          if(abrirModalJustificaciones){
            const seriesIndex = config.seriesIndex;
            const dataPointIndex = config.dataPointIndex;
            const selectedData = chartContext.w.config.series[seriesIndex].data[dataPointIndex];
            // Aquí puedes acceder a la fecha de inicio y fecha de terminación
            const startDate = new Date(selectedData.y[0]);
            const endDate = new Date(selectedData.y[1]);

              const id_tipo_movimiento = selectedData.id_tipo_movimiento || 0;
              const id_t_registro_movimiento = selectedData.id_t_registro_movimiento || 0;
              const id_maquina = selectedData.id_maquina;
              const justificado = selectedData.justificado

              //si el tipo de movimiento es mantencion, fuera de servicio, detendido, ralenti
              if((id_tipo_movimiento == 2 || id_tipo_movimiento == 3 || id_tipo_movimiento == 4 || id_tipo_movimiento == 5) && !justificado){
                abrirModalJustificaciones(id_tipo_movimiento,generateDatabaseDateTimeENUTC(startDate),generateDatabaseDateTimeENUTC(endDate),id_t_registro_movimiento,id_maquina)
              }
            
          }
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: fullHeight ? altoGrafico : "85%",//'85%',
        rangeBarGroupRows: true,
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      min:  horoumeterLimits?.min ? horoumeterLimits?.min : turno.min?.getTime(),
      max:  horoumeterLimits?.max ? horoumeterLimits?.max : turno.max?.getTime(),
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false
      },
      type: "datetime",
      /* categories: fechas, */
      labels:{
        datetimeUTC: false,
        format: 'HH:mm',
        style: {
          fontSize: "12px"
        },
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      labels:{
        maxWidth: maxWidthYLabel,
        minWidth:72,
        style: {
          fontSize: "12px"
        },
        formatter: function(val) {
          if(typeof(val)=== "string"){
            const translatorList = [
              "horometro","brazo_1", "brazo_2", "horometro_jumbo", "arm1_motor",
              "arm1_percutor","arm2_motor","arm2_percutor", "brazo1_motor",
              "brazo2_motor", "brazo1_percutor", "brazo2_percutor", "horometro_bolter",
              "brazo1_compresor","brazo2_compresor"
            ]
            if(translatorList.includes(val.toLowerCase().replace(" ","_"))){
              let result = t(val.toLowerCase().replace(" ","_"));
              const index = result.indexOf(" "); // Encuentra el primer espacio en la cadena
              const resultEnArray = [result.substring(0, index), result.substring(index + 1)];
              return resultEnArray
            }else{
              return translateMachineName(val,t,onHandleGetGeneralData().idMina)
            }
          }

          return val;
        }
      }
    },
    fill: {
      type: 'solid'
    },
    tooltip: {
      enabled:true,
      x: {
        format: 'HH:mm',
      },
      y: {
        title: {
          formatter: (seriesName, objj) => {
            if(seriesName == "justificado"){
              let tipo_justificacion = objj.w.config.series[objj.seriesIndex].data[objj.dataPointIndex].tipo_justificacion
              let grupo_justificacion = objj.w.config.series[objj.seriesIndex].data[objj.dataPointIndex].justificacion
              return `
                Justificado<br />
                ${grupo_justificacion ? 'Tipo: '+grupo_justificacion+ '<br />' : ''}
              `
            }
            if([
              "operativo",
              "ralenti",
              "en_mantencion",
              "sin_info",
              "fuera_de_servicio",
              "horometro",
              "justificado",
              "encuentro"].includes(seriesName)) return t(seriesName);
            return seriesName;
          }
        },
      }

    }
  }},[horoumeterLimits,turno, i18n.language]);
  
  return (
    <div id="rangetimebar" style={{marginLeft:MostrarLabelCompleto ? '15px' : '0px'}}>
      <Chart 
        height={altoGrafico} 
        width={"100%"} 
        options={options} 
        series={series} 
        type="rangeBar" 
      />
    </div>
  );
  
}

export default HorizontalTimeChart;