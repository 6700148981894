import React, { forwardRef } from "react";

const textColors = {
  primary: "#2f4554",
  secondary: "#00b7c4",
}

const InfoCard = ({ style, ...props}) => (
  <div
    style={{
      width: "fit-content",
      padding: "3.5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "6px",
      ...style
    }}
    {...props}
  />
)

InfoCard.displayName = "InfoCard";

const InfoIcon = ({ style, src, ...props }) => (
  <img
    src={src}
    alt="icon"
    style={{
      maxWidth: "100%",
      height: "auto",
      objectFit: "contain",
      aspectRatio: "1/1",
      ...style
    }}
    {...props}
  />
)

InfoIcon.displayName = "InfoIcon";

const InfoContent = forwardRef(({ style, ...props }, ref) => (
  <section
    ref={ref}
    style={{
      display: "flex",
      flexDirection: "column",
      gap: "3px",
      flexWrap: "nowrap",
      textWrap: "nowrap",
      whiteSpace: "nowrap",
      ...style
    }}
    {...props}
  />
))

InfoContent.displayName = "InfoContent";

const InfoTitle = ({ style, color, children, ...props}) => {
  return (
    <h4
      style={{
        margin: 0,
        fontSize: "1.025rem",
        fontFamily: "Roboto, Arial, sans-serif",
        fontWeight: "bold",
        whiteSpace: "nowrap !important",
        textWrap: "nowrap !imporant",
        color: textColors[color] || textColors.primary,
        ...style
      }}
      {...props}
    >
      {children}
    </h4>
  )
}

InfoTitle.displayName = "InfoTitle";

const InfoText = ({ style, color, children, ...props }) => (
  <p
    style={{
      margin: 0,
      fontFamily: "Roboto, Arial, sans-serif",
      fontSize: "0.925rem",
      fontWeight: "600",
      whiteSpace: "nowrap !important",
      textWrap: "nowrap !imporant",
      color: textColors[color] || textColors.secondary,
      ...style
    }}
    {...props}
  >
    {children}
  </p>
)

InfoText.displayName = "InfoText";

export { InfoCard, InfoIcon, InfoContent, InfoTitle, InfoText };