import React, { useRef, useState } from 'react'

import {
  Box,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";

import { CiclosGPSForm } from '../Formularios/CiclosGPSForm';
import { TablaCiclosGps } from './TablaCiclosGps'

import { useGetReglasVueltasGps } from '../../../hooks/administracion/gps/useGetReglasVueltasGps'
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import BorderColorIcon from '@mui/icons-material/BorderColor';
import ControlPointIcon from '@mui/icons-material/ControlPoint';


import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const useStyles = makeStyles({
  table: {
    width:"100%"
  },
  root: {
    backgroundColor: "#2f4554",
    margin: "px 0",
    textAlign:'center',
    color: "white",
    "&:hover": {
      backgroundColor: "rgb(0, 183, 196)"
    },
    "&:disabled": {
      backgroundColor: "gray"
    }
  }
});

export const AdministracionCiclosGps = ({id_area_operacion, nombreMina}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const refForm = useRef(null);
  
  const { dataReglasVueltasGps,loadingReglasVueltasGps } = useGetReglasVueltasGps(id_area_operacion);

  const [tipoFlota, setTipoFlota] = useState("todos");
  const [filteredData, setFilteredData] = useState([]);
  const [FormActions, setFormActions] = useState({
    isAdding:false,
    isEditting:false,
    isEdittingRowSelected:false
  });
  let editInitialState={
    id:0,
    id_area_operacion: id_area_operacion,
    nombreMina: nombreMina,
    id_tipo_maquina:0,
    nombre_tipo_maquina:'',
    id_tipo_geocerca_inicio:0,
    nombre_tipo_geocerca_inicio:'',
    id_tipo_geocerca_fin:0,
    nombre_tipo_geocerca_fin:'',
    editing:false,
  };
  const [dataFormEditVicon, setDataFormEditVicon] = useState(editInitialState);

  let idTipoMaquinasUnicos ={}
  let arrayResultado = [];

  if(dataReglasVueltasGps?.length > 0){
      dataReglasVueltasGps.forEach(objeto =>{
        if(!idTipoMaquinasUnicos[objeto.id_tipo_maquina]){
          arrayResultado.push({id_tipo_maquina:objeto.id_tipo_maquina,nombre_tipo_maquina:objeto.nombre_tipo_maquina})
          idTipoMaquinasUnicos[objeto.id_tipo_maquina] = true;
        }
      });
  }

  const onChangeFlota = (e) =>{
    let tipoFlotaAux = e.target.value;
    setTipoFlota(tipoFlotaAux)
    
    if(e.target.value=='todos'){
      setFilteredData(dataReglasVueltasGps)
    }else{
      let dataAux = dataReglasVueltasGps.filter(el => el.nombre_tipo_maquina == e.target.value);
      setFilteredData(dataAux)
    }
  }

  const onHandleAddNewCiclo = () =>{
    setDataFormEditVicon(editInitialState);
    setFormActions({isEditting:false,isAdding:true, isEdittingRowSelected:false});
  }

  const onHandleEditNewCiclo = () =>{
    setDataFormEditVicon(editInitialState);
    setFormActions({isEditting:true,isAdding:false,isEdittingRowSelected:false})
  }

  const onHandleEditNewCicloRowSelected= () =>{
    setFormActions({...FormActions,isEdittingRowSelected:true})
  }

  const onHandleCancelOperations = () =>{
    setDataFormEditVicon(editInitialState);
    setFormActions({isEditting:false,isAdding:false,isEdittingRowSelected:false});
  }

  const onHandleSetFormikEditValues= (dataRow) =>{
      setDataFormEditVicon({...dataFormEditVicon,
        id:dataRow.id,
        id_tipo_geocerca_inicio:dataRow.id_tipo_geocerca_inicio,
        id_tipo_geocerca_fin:dataRow.id_tipo_geocerca_fin,
        id_tipo_maquina:dataRow.id_tipo_maquina,
        nombre_tipo_maquina:dataRow.nombre_tipo_maquina,
        nombre_tipo_geocerca_fin:dataRow.tipo_geocerca_fin,
        nombre_tipo_geocerca_inicio:dataRow.tipo_geocerca_inicio,
        editing:true
      })

  }

  return (
    <>
      <Grid container justifyContent='space-between'>
        <Grid xs={6} item>
          <FormControl sx={{ m: 1, minWidth: 260 }}>
            <InputLabel id="demo-simple-select-helper-label">{t("tipo_flota")}</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={tipoFlota}
              label={t("tipo_flota")}
              onChange={onChangeFlota}
            >
              <MenuItem value={'todos'}>{t('todos_flotas')}</MenuItem>
              {
                arrayResultado.map((f, index) =>{
                  return (
                    <MenuItem key={index} value={f.nombre_tipo_maquina}>{t(`flota_${f.nombre_tipo_maquina.toLowerCase()}`)}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>                      
        </Grid>

        <Grid xs={6} container item direction='row' wrap='nowrap' alignItems='center' justifyContent='flex-end'>
          <Box sx={{ minWidth: '240px' ,marginLeft:20}}>
                <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    className={classes.root}
                    disabled={FormActions.isEditting}
                    onClick={() =>{
                      onHandleEditNewCiclo()
                    }
                    }
                    >
                    <Grid container alignItems="center" spacing={7}>
                        <Grid item>
                            <BorderColorIcon style={{ color: 'white', display:'block', margin:'auto' }} />
                        </Grid>
                        <Grid item>
                            {t("editar")}
                        </Grid>
                    </Grid>
                </Button>
            </Box>
            <Box sx={{ minWidth: '240px' ,marginLeft:20}}>
                <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    className={classes.root}
                    disabled={FormActions.isAdding}
                    onClick={() =>{
                      onHandleAddNewCiclo();
                    }
                    }
                    >
                    <Grid container alignItems="center" spacing={4}>
                        <Grid item>
                            <ControlPointIcon style={{ color: 'white', display:'block', margin:'auto' }} />
                        </Grid>
                        <Grid item>
                            {t("agregar")}
                        </Grid>
                    </Grid>
                </Button>
            </Box>
        </Grid>
      </Grid>
    
      <TablaCiclosGps 
        loading={loadingReglasVueltasGps}
        data={filteredData.length > 0 ? filteredData : dataReglasVueltasGps }
        isEditing={FormActions.isEditting}
        onHandleSetFormikEditValues={onHandleSetFormikEditValues}
        onHandleEditNewCicloRowSelected={onHandleEditNewCicloRowSelected}
        refForm={refForm}
        id_area_operacion={id_area_operacion}
        nombreMina={nombreMina}
        pathMina={(location.pathname.split("/"))[2]}
      />
      {
        (FormActions.isAdding || (FormActions.isEditting && FormActions.isEdittingRowSelected)) &&
        <CiclosGPSForm 
        data={filteredData.length > 0 ? filteredData : dataReglasVueltasGps }
        id_area_operacion={id_area_operacion}
        isEditing={FormActions.isEditting}
        onHandleCloseForm={onHandleCancelOperations}
        nombreMina={nombreMina}
        pathMina={(location.pathname.split("/"))[2]}
        formDataEdit={dataFormEditVicon}
        refForm={refForm}
        />
      }
    </>
  )
}
