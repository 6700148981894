import React, { useEffect, useState} from "react";
import {Card, CardContent, CardMedia} from "@mui/material";
import NavBar from "../../components/organisms/NavBar.js";
import { Breadcrumbs, Container, Link, Button } from "@material-ui/core";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";

import { useLocation } from "react-router-dom";

import ImagenEquipo from "../Produccion/maquina.png";
import { makeStyles } from "@material-ui/core/styles";
import BarTimelineChartVacio from "../Produccion/BarTimelineChartVacio";
import MotionChart from "./MotionChart";
import imgCamion from "../Produccion/camion.png";
import imgCamionDumper from "../Produccion/camion-dumper.png";
import SectionHeader from "../../components/molecules/SectionHeader.js";
import IconoProdTotal from '../../assets/icons/icono-prod-total.png';
import IconoKpi from '../../assets/icons/icono-kpi.png';
import IconoResumenDiario from '../../assets/icons/icono-resumen-diario.png';
import IconoDetalle from '../../assets/icons/icono-detalle.png';
import ProdKpiCard from "../../components/organisms/ProdKpiCard.js";
import IconoVueltas from '../../assets/icons/icono-vueltas.png';
import IconoTonelaje from '../../assets/icons/icono-tonelaje.png';
import IconoRendimiento from '../../assets/icons/icono-rendimiento.png';
import { filtrarPorTurno, sortByDate } from "../../commons/Filtros.js";

import { getVueltasMaquinaRango, getMovimientosPorMaquina } from "../../services/diario";
import { getInfoGeneralMina, getTiemposAsarco } from "../../services/general";
import getEncuentrosMovMaquina from "./getEncuentrosMovMaquina";
import HorizontalTimeChart from "../Operaciones/HorizontalTimeChart";
import { formatoDecimales } from "../../commons/Formatos";
import { HtmlTooltip } from "../../components/atoms/StyledComponents.js";
import '../../translator';
import { useTranslation } from 'react-i18next';
import TablaVueltasPortales from "../OperacionesMaquina/Tablav5";

import { restarDias, sumarDias } from "../../commons/FormatearFecha";

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { format } from "date-fns";
import { getResumenMensualMaquina } from "../../services/produccion";
import PantallaCarga from "../../components/molecules/PantallaCarga.js";
import { usePageTimingContext } from "../../context/PageTimingContext.js";

const useStyles = makeStyles({
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  buttonUnselected: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  prodDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  },
  prodValue:{
    fontSize: "20px",
    fontWeight:"bold"
  },
  bordermd:{
    '@media (min-width:1200px)': {
      borderRight:"2px solid #d7d7d7"
    }
  },
  marginmd:{
    '@media (min-width:1200px)': {
      marginLeft:"10px"
    }
  }
});

const FuncionamientoDiario = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { sendTime } = usePageTimingContext();

  const [seriesMovilizacionMaquina, setSeriesMovilizacionMaquina] = useState([]);
  const [loading, setLoading] = useState(true);

  const [fechaActual, setFechaActual] = useState( new Date(JSON.parse(localStorage.getItem("datosDia")).fecha));
  const [rutaMina, setRutaMina] = useState("");
  const [idMina, setIdMina] = useState(0);
  const [nombreMina, setNombreMina] = useState("");
  const [imagenCamion, setImagenCamion] = useState(imgCamion);
  const [listaTurnos, setListaTurnos] = useState([]);
  const turnoTodos = {
    nombre: "turnotodos",
    hora_fin: "23:59:59",
    hora_inicio: "00:00:00",
    id: 0,
    id_mina: 0,
    id_tipo_turno: 0,
    orden: 0
  }
  const [turno, setTurno] = useState(turnoTodos);
  const datosMaqVacios = {
    nombre: "",
    modelo: "",
    tipo: "",
    contadorVueltas: 0,
    contadorHoras: 0,
    vueltas: [],
    horometros: [],
    fecha: "2020-05-05"
  }
  const [datosMaquina, setDatosMaquina] = useState(datosMaqVacios);

  const [seriesMovilizacionPorTurno, setSeriesMovilizacionPorTurno] = useState([]);
  const [tiemposAsarco, setTiemposAsarco] = useState({});
  const [tiemposAsarcoPorTurno, setTiemposAsarcoPorTurno] = useState({
    "nominal": 0,
    "disponible": 0,
    "indisponible": 0,
    "operativo": 0,
    "reserva": 0,
    "efectivo": 0,
    "perdida_operacional":0,
    "ralenti":0
  });
  const [kpiProd, setKpiProd] = useState({vueltas:{},tonelaje:{},rendimiento:{},horas:{}});
  const [portalesRows,setPortalesRows] = useState([]);
  const [configColumnas, setConfigColumnas] = useState([]);
  const [configHeadCols, setConfigHeadCols] = useState({
    vueltas: [{ field: 'vueltasTotal' }],
    tonelaje: [{ field: 'tonelajeTotal' }],
    tiempo: [{ field: 'tiempoTotal' }],
    rendimiento: [{ field: 'rendimientoTotal'}],
    cargadorDumper:[]
  });

  const [estadosDataSeries, setEstadosDataSeries] = useState([]);
  const [limites, setLimites] = useState({min: undefined, max: undefined});
  const classes = useStyles();

  const generaColumnas = (turnos) => {

    const cols = [
      /* {
        field: "id",
        align: "center",
        headerAlign: 'center',
        headerName: t("nombre"),
        width: 100,
        headerClassName:"cell-font",
        hide: true
      },
      {
        field: "nombre",
        align: "center",
        headerAlign: 'center',
        headerName: t("nombre"),
        width: 100,
        headerClassName:"border-right-header cell-font",
      } */
    ]

    const baseColumna = {
      field: "",
      headerName: "",
      headerClassName:"cell-font",
      align: "center",
      headerAlign: 'center',
      valueFormatter: params => params?.value.toFixed(0),       
      flex:1
    }

    const tipos = [ 
      {des:'vueltas',cifras:false},
      {des:'tonelaje',cifras:false},
      {des:'tiempo',cifras:true},
      {des:'rendimiento',cifras:true}
    ]

    const headCols = {
      vueltas: [{ field: 'vueltasTotal' }],
      tonelaje: [{ field: 'tonelajeTotal' }],
      tiempo: [{ field: 'tiempoTotal' }],
      rendimiento: [{ field: 'rendimientoTotal'}],
      cargadorDumper:[]
    };
    
    tipos.forEach( tipo => {
      turnos.forEach( turno => {
        const fields = [...headCols[tipo.des]]
        fields.push({field:`${tipo.des}Turno${turno.nombre}`})
        headCols[tipo.des] = fields
        cols.push({
          ...baseColumna,
          field: `${tipo.des}Turno${turno.nombre}`,
          headerName: `turno_${turno.nombre.toLowerCase()}`,
          valueFormatter: params => tipo.cifras?(Math.round((params?.value)*10)/10).toLocaleString('de-DE'):(Math.round((params?.value))).toLocaleString('de-DE'),
        })
      })
      cols.push({
        ...baseColumna,
        field: `${tipo.des}Total`,
        headerName: "Total",
        headerClassName:tipo.des!=="rendimiento"?"border-right-header cell-font":"cell-font",
        cellClassName: "cell-total",
        valueFormatter: params => tipo.cifras?(Math.round((params?.value)*10)/10).toLocaleString('de-DE'):(Math.round((params?.value))).toLocaleString('de-DE'),
      })
    })
    setConfigHeadCols(headCols)
    setConfigColumnas(cols)

  }

  const procesarVueltasMaquina = (datosDelDia, turnos, portales) => {

    const dataRows = {
      vueltasTotal: 0,
      tonelajeTotal: 0,
      tiempoTotal: 0,
      rendimientoTotal: 0
    }

    turnos.forEach( t => {
      dataRows[`vueltasTurno${t.nombre}`] = 0
      dataRows[`tonelajeTurno${t.nombre}`] = 0
      dataRows[`tiempoTurno${t.nombre}`] = 0
      dataRows[`rendimientoTurno${t.nombre}`] = 0
    })

    const dataRowsWithKeys= portales.reduce((obj, item) => ((obj[item] = {id:`id${item}`,nombre:item,...dataRows}, obj)),{});

    const idMina = localStorage.getItem("idMina");
    
    getVueltasMaquinaRango(idMina,datosDelDia.idMaquina,datosDelDia.fecha,datosDelDia.fecha).then(({success, data}) => {
      if(success){
        /* RELLENAR FILAS TABLA */
        data.forEach( v => {
          // Turnos
          dataRowsWithKeys[v.ubicacion][`vueltasTurno${v.turno}`] += 1
          dataRowsWithKeys[v.ubicacion][`vueltasTotal`] += 1
          // Toneladas
          dataRowsWithKeys[v.ubicacion][`tonelajeTurno${v.turno}`] += Number(datosDelDia.toneladas)
          dataRowsWithKeys[v.ubicacion][`tonelajeTotal`] += Number(datosDelDia.toneladas)
          // Tiempo efectivo
          dataRowsWithKeys[v.ubicacion][`tiempoTurno${v.turno}`] += v.duracion_minutos/60
          dataRowsWithKeys[v.ubicacion][`tiempoTotal`] += v.duracion_minutos/60
        })
        // Rendimiento
        portales.forEach( p => {
          const auxTon = dataRowsWithKeys[p][`tonelajeTotal`]
          const auxTime = ((dataRowsWithKeys[p][`tiempoTotal`]!==0)?dataRowsWithKeys[p][`tiempoTotal`]:1)
          dataRowsWithKeys[p][`rendimientoTotal`] = auxTon / auxTime
          turnos.forEach( t => {
            dataRowsWithKeys[p][`rendimientoTurno${t.nombre}`] = 
              dataRowsWithKeys[p][`tonelajeTurno${t.nombre}`] / 
              ((dataRowsWithKeys[p][`tiempoTurno${t.nombre}`]!==0)?dataRowsWithKeys[p][`tiempoTurno${t.nombre}`]:1)
          })
        })

        setPortalesRows(Object.values(dataRowsWithKeys))

        /* FIN RELLENAR FILAS TABLA */

        /* RELLENAR KPIS */

        // Crea objeto con nombre de cada turno como key y valor 0
        let objTurnos = turnos.reduce((obj, item) => ((obj[item.nombre] = 0, obj)),{})  
        // Agrega a objeto el turnotodos
        objTurnos["turnotodos"] = 0;
        // Crea objeto con kpi como key y como valor los turnos
        let objKpis = {vueltas:{...objTurnos},tonelaje:{...objTurnos},rendimiento:{...objTurnos}, horas:{...objTurnos}}
        // Para acumular el tiempo efectivo de todos los turnos
        data.forEach((vuelta) => {
          if(vuelta.turno){
            objKpis["vueltas"][vuelta.turno] += 1
            objKpis["tonelaje"][vuelta.turno] += datosDelDia.toneladas
            objKpis["horas"][vuelta.turno] += vuelta.duracion_minutos/60
          }
          objKpis["vueltas"]["turnotodos"] += 1
          objKpis["tonelaje"]["turnotodos"] += datosDelDia.toneladas
          objKpis["horas"]["turnotodos"] += vuelta.duracion_minutos/60
        })
        objKpis["rendimiento"]["turnotodos"] += objKpis["tonelaje"]["turnotodos"] / objKpis["horas"]["turnotodos"];
        turnos.forEach( t => {
          objKpis["rendimiento"][t.nombre] += (objKpis["tonelaje"][t.nombre] / objKpis["horas"][t.nombre]) || 0;
        })
        
        setKpiProd(objKpis)
        /* FIN RELLENAR KPIS */

      }
    })
  } 

  
  const getEstadosTotalDia = (estados) => {
    let totales = []
    Object.values(estados).forEach( valueTurno => {
      totales = [...totales, ...valueTurno]
    })
    return totales;
  } 


  const getDataSeriesEstados = (estados, lim) => {

    const datosEstados = [...estados]

    const dataOperativos = [];
    const dataMantencion = [];
    const dataFueradeServ = [];
    const dataSinInfo = [];
    const dataRalenti = [];
    const dataJustificada =[];

    // Array para guardar los ultimos estados de cada maquina
    // Se itera por cada maquina
  
      // Ordena valores por fecha
      const sortedValues = sortByDate(datosEstados,"inicio");

      // Horarios de turno con su fecha tipo Date
      //const minmax = getMinMax(seriesTurno);
      const fechaInicioTurno = lim.min
      const fechaFinTurno = lim.max
      
      // Ultima hora para analizar estados sin info
      let ultimaHoraSinInfo = lim.min
      sortedValues.forEach(estado => {
        const data = {
          x: "horometro",
          y:[
            (new Date(estado.inicio)).getTime(),
            (new Date(estado.fin)).getTime()
          ]
        }
        if(!estado.tipo_justificacion){
        switch(estado.movimiento){
          case "operativo":
            dataOperativos.push(data);
            break;
          case "mantencion":
            dataMantencion.push(data);
            break;
          case "fuera de servicio":
            dataFueradeServ.push(data);
            break;
          case "ralenti":
            dataRalenti.push(data);
          default:
            break
        }
      }else if(estado?.tipo_justificacion){
        (estado.tipo_justificacion.toLowerCase() == "otro") 
         ? dataJustificada.push({...data,tipo_justificacion:estado?.observacion}) 
         : dataJustificada.push({...data,tipo_justificacion:estado?.tipo_justificacion});
     }

         /* 
         Si hay una diferencia de tiempo entre la hora final del 
         estado anterior y la inicial del actual, agrega estado sin info 
         */
        if(((new Date(estado.inicio)).getTime() - ultimaHoraSinInfo.getTime())/1000>1){
          dataSinInfo.push({
            x: "horometro",
            y:[
              ultimaHoraSinInfo.getTime(),
              (new Date(estado.inicio)).getTime(),
            ]
          })
        }

        ultimaHoraSinInfo = new Date(estado.fin)

      })

      /* 
      Si la maquina no tiene estados, agrega estado sininfo con el largo del inicio y fin de turno
      Si tiene datos, comprueba si hay un valle de tiempo entre el fin de ultimo estado y el fin del turno y agrega un estado sininfo
      */
      if(sortedValues.length===0){
        dataSinInfo.push({
          x: "horometro",
          y:[
            fechaInicioTurno.getTime(),
            fechaFinTurno.getTime()
          ]
        })
      }
      else{
        const finEstadoFinal = new Date(sortedValues[sortedValues.length-1].fin)
          if((fechaFinTurno.getTime() - finEstadoFinal.getTime())/1000>1){
            dataSinInfo.push({
            x: "horometro",
            y:[
              finEstadoFinal.getTime(),
              fechaFinTurno.getTime(),
            ]
          })
        }
      }

    return([
      {
        name: 'Operativo',
        color: "#01b7c4",
        data: dataOperativos
      },
      {
        name: 'En Mantención',
        color: "#ffbf00",
        data: dataMantencion
      },
      {
        name: 'Reserva',
        color: "#C5283D",
        data: dataFueradeServ
      },
      {
        name: 'Sin Información',
        color: "#d6dce4",
        data: dataSinInfo
      },
      {
        name:t("ralenti"),
        color:'#E9724C',
        data:dataRalenti
      },
      {
        name:'justificado',
        color:'#28B463',
        data:dataJustificada
      }
    ])

  } 

  const procesarMovimientosMaquina = (idMaq, fechaMaq, lim) => {
    const idMina = localStorage.getItem("idMina");
    const resEstados = getMovimientosPorMaquina(idMina, idMaq, fechaMaq, fechaMaq);
    resEstados.then(({success,data}) => {
      if(success && data[fechaMaq]){
        const estadosTotales = getEstadosTotalDia(data[fechaMaq]);
        setEstadosDataSeries(getDataSeriesEstados(estadosTotales, lim))
      }
    })
  }

  const getMinMaxTurnos = (turnos) => {
    const sortedTurnos =  turnos.sort(function(a, b) { 
      return a.orden - b.orden;
    });

    return {min: new Date(sortedTurnos[0].inicio), max: new Date(sortedTurnos[turnos.length-1].fin)}
  }

  const procesarAsarco = (tiempos, turnoSelec) => {
    // solucionar cuando llega objeto vacio
    const auxAsarco = {
      nominal: 0,
      disponible: 0,
      indisponible: 0,
      operativo: 0,
      reserva: 0,
      efectivo: 0,
      perdida_operacional:0
    }

    if(Object.values(tiempos).length>0){
      if(turnoSelec === turnoTodos){
        Object.values(tiempos).forEach( t => {
          auxAsarco["nominal"]              += t["nominal"]?t["nominal"]:0
          auxAsarco["disponible"]           += t["disponible"]?t["disponible"]:0
          auxAsarco["indisponible"]         += t["indisponible"]?t["indisponible"]:0
          auxAsarco["operativo"]            += t["operativo"]?t["operativo"]:0
          auxAsarco["reserva"]              += t["reserva"]?t["reserva"]:0
          auxAsarco["efectivo"]             += t["efectivo"]?t["efectivo"]:0
          auxAsarco["perdida_operacional"]  += t["perdida_operacional"]?t["perdida_operacional"]:0
        })
      }
      else{
        const asarcoPorTurno = tiempos[turnoSelec.nombre]
        auxAsarco["nominal"]              += asarcoPorTurno["nominal"]?asarcoPorTurno["nominal"]:0
        auxAsarco["disponible"]           += asarcoPorTurno["disponible"]?asarcoPorTurno["disponible"]:0
        auxAsarco["indisponible"]         += asarcoPorTurno["indisponible"]?asarcoPorTurno["indisponible"]:0
        auxAsarco["operativo"]            += asarcoPorTurno["operativo"]?asarcoPorTurno["operativo"]:0
        auxAsarco["reserva"]              += asarcoPorTurno["reserva"]?asarcoPorTurno["reserva"]:0
        auxAsarco["efectivo"]             += asarcoPorTurno["efectivo"]?asarcoPorTurno["efectivo"]:0
        auxAsarco["perdida_operacional"]  += asarcoPorTurno["perdida_operacional"]?asarcoPorTurno["perdida_operacional"]:0
      }
      setTiemposAsarcoPorTurno(auxAsarco)
    }
  } 

  const getAsarco = (idMina, idMaq, inicio, fin, t) => {
    const resAsarco = getTiemposAsarco(idMina, idMaq/* 12 */, inicio, fin)
    resAsarco.then(({success,data}) => { 
      if(success){
        setTiemposAsarco(data[inicio])
        procesarAsarco(data[inicio], t)
      } 
    })
  }

  useEffect(()=>{

    const nombreMina = localStorage.getItem("nombreMina");
    const idMina = localStorage.getItem("idMina");
    const idMaquina = localStorage.getItem("idMaquina")
    const datosDia = JSON.parse(localStorage.getItem("datosDia"));
    const rutaMina = localStorage.getItem("rutaMina");
    setRutaMina(rutaMina);
    setDatosMaquina(datosDia);
    setNombreMina(nombreMina);
    setIdMina(idMina);
    getInfoGeneralMina(idMina,datosDia.fecha)
      .then(({success, data}) => {
        if(success){
          setListaTurnos(data.turnos);
          setLimites(getMinMaxTurnos(data.turnos))
          generaColumnas(data.turnos)
          procesarVueltasMaquina(datosDia, data.turnos, data.portales)
          procesarMovimientosMaquina(idMaquina, datosDia.fecha, getMinMaxTurnos(data.turnos))
          getAsarco(idMina, idMaquina, datosDia.fecha, datosDia.fecha, turnoTodos)
        }
      })
  },[]);


  useEffect(() => {
    if(idMina === "4"){
      setImagenCamion(imgCamionDumper);
    }

  }, [idMina]);

  const onChangeTurno = (t) => {
    setTurno(t)
    setLimites((t===turnoTodos)?getMinMaxTurnos(listaTurnos):{min: new Date(t.inicio), max: new Date(t.fin)})
    setSeriesMovilizacionPorTurno(filtrarSeriesMovilizacionPorTurno(seriesMovilizacionMaquina,t));
    procesarAsarco(tiemposAsarco, t)
  }

  const filtrarSeriesMovilizacionPorTurno = (movilizaciones,t) => {
    var series;
    if(t === turnoTodos){
      series = [];
      for(const [nombre_turno, movilizaciones_turno] of Object.entries(movilizaciones)){
        series = series.concat(movilizaciones_turno);
      }
    }
    else{
      series = movilizaciones[t.nombre];
    }
    return series?series:[];
  }



  const updateDataMovilizacionMaquina = (idMaquina,fecha) => {
    setLoading(true);
    if(idMaquina){
      getEncuentrosMovMaquina(idMaquina,fecha)
      .then(({success, data}) => {
        if(success){
          setSeriesMovilizacionMaquina(data["beacons"]);
          setSeriesMovilizacionPorTurno(filtrarSeriesMovilizacionPorTurno(data["beacons"], turnoTodos));
        }
        else{
          console.log("Ocurrio un error");
        }
        setLoading(false)
      })  
    }
    else{
      console.log("No se ha seleccionado ninguna máquina");
    }
  };


  const getMinMaxGrafico = () => {
    let fechaInicio = new Date(`${datosMaquina.fecha} ${turno.hora_inicio}`);
    let fechaFin = new Date(`${datosMaquina.fecha} ${turno.hora_fin}`);
    switch(Number(turno.id_tipo_turno)){
      case 1:
        // El turno se encuentro entre dos dias, partiendo por la madrugada del dia anterior
        fechaInicio.setDate(fechaInicio.getDate() - 1);
        return ({
          min: fechaInicio,
          max: fechaFin
        })
      case 2:
        // El turno es dentro de horario de un mismo dia
        return ({
          min: fechaInicio,
          max: fechaFin
        })
        // Case 3 representaria turno entre dos dias que termina en el dia siguiente
      case 3:
        fechaFin.setDate(fechaFin.getDate() + 1);
        return ({
          min: fechaInicio,
          max: fechaFin
        })
      default:
        // El turno tiene id 0 o distinto, por lo tanto, se deben mostrar todos los datos
        listaTurnos.forEach(itemTurno => {
          switch(itemTurno.orden){
            case 0:        
              // Primer turno      
              fechaInicio = new Date(`${datosMaquina.fecha} ${itemTurno.hora_inicio}`);
              if(Number(itemTurno.id_tipo_turno)===1){
                fechaInicio.setDate(fechaInicio.getDate() - 1);
              }
            case (Number(listaTurnos.length)-1):
              // Ultimo turno
              fechaFin = new Date(`${datosMaquina.fecha} ${itemTurno.hora_fin}`);
              if(Number(itemTurno.id_tipo_turno)===3){
                fechaFin.setDate(fechaFin.getDate() + 1);
              }
            default:
              // Turnos intermedios no se consideran
              break;
          }
        })

        return({
          min: fechaInicio,
          max: fechaFin
        })
    }
  };

  const onChangeFecha = (fecha) => {
    setLoading(true);
    const idMina = localStorage.getItem("idMina");
    const idMaquina = localStorage.getItem("idMaquina")
    const month = fecha.getMonth() + 1;
    const year = fecha.getFullYear();
    let resourceData = []


    const año = fecha.getFullYear();
   const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
   const diaAux = fecha.getDate()+1;
   const dia =diaAux.toString().padStart(2, "0");

const fechaFormateada = `${año}-${mes}-${dia}`;

    getResumenMensualMaquina(idMina,idMaquina,month,year)
     .then(({success,data}) =>{
      if(data){
        
        for (let dia in data.vueltas) {
          let vueltas = 0;
          let tons = 0;
          let minutos = 0;
          Object.values(data.vueltas[dia]).forEach((valVuelta) => {
            vueltas+=valVuelta["total_vueltas"]
            tons+=valVuelta["total_toneladas"]
            minutos+=valVuelta["total_minutos_vueltas"]
          })
          if( Date.parse(dia) <= (new Date()) ){
            let resourceDataaux = {
              idMaquina: idMaquina,
              nombre: data.nombre,
              modelo: data.modelo,
              tipo: data.tipo,
              contadorVueltas: vueltas,
              contadorTonelaje: tons,//tonelajes,
              contadorHoras: minutos,
              vueltas: data.vueltas[dia],
              //horometros: maquina.horometros[dia],
              fecha: dia,
              toneladas: data.toneladas
            };
            
            resourceData.push(resourceDataaux)
          }
        }
        let result = resourceData.filter(x => x.fecha == fechaFormateada)[0];
        localStorage.setItem("datosDia", JSON.stringify(result));
       setFechaActual(fecha);
        getInfoGeneralMina(idMina,result.fecha)
        .then(({success, data}) => {
          if(success){
            setDatosMaquina(result);
            setListaTurnos(data.turnos);
             setLimites(getMinMaxTurnos(data.turnos))
             generaColumnas(data.turnos)
            procesarVueltasMaquina(result, data.turnos, data.portales)
            procesarMovimientosMaquina(idMaquina, result.fecha, getMinMaxTurnos(data.turnos))
            getAsarco(idMina, idMaquina, result.fecha, result.fecha, turnoTodos)
          }
        })
        .catch((err) =>{
          console.log(err);
          setLoading(false);
        })

      }
     }).catch((err) => {
      console.log(err)
      setLoading(false);
     })

  }

  const minToHours = (minutes) => {
    return Math.round((minutes/60) * 100) / 100/* (minutes/60).toFixed(2); */
  }

  useEffect(()=>{
    if(datosMaquina.nombre !== ""){
      updateDataMovilizacionMaquina(datosMaquina.idMaquina, datosMaquina.fecha);
    }
  },[datosMaquina]);

  useEffect(() => {
    return () => {
      sendTime(location.pathname)
    }
  }, [])


  if(loading){
    return (
      <PantallaCarga loading={loading}/>
    )
  }
  return(
    <>
      <NavBar />
      <Container>
        <br/>
        <Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px"}}
            color="inherit"
            href="/"
          >
            Mine-Watch
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px"}}
            color="inherit"
            href={`produccion/${rutaMina}`}
          >
            {/* props mina */}
            {"Mina " + nombreMina}
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px"}}
            color="inherit"
            href="/maquina"
          >
            {datosMaquina.nombre}
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px", color:"#00b7c4"}}
            color="inherit"
          >
            {t("funcionamiento_diario")}
          </Link>
        </Breadcrumbs>
        <br/>
        <br/>
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          > 

              <Grid item lg={5}>
                <Grid container alignItems="center">
                  <Grid item>
                  <CardMedia
                      component="img"
                      image={datosMaquina.tipo==="Camion"?imagenCamion:datosMaquina.tipo==="LHD"?ImagenEquipo:""}
                      alt="Paella dish"
                      style={{ width: datosMaquina.tipo==="Camion"?"80px":"120px", marginRight: "20px" }}
                    />
                  </Grid>

                  <Grid item>
                  <Grid 
                  container
                  direction="column"
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "#00B7C4"
                      }}
                    >
                      {datosMaquina.nombre}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14.4px",
                        display:"flex",
                        alignItems:"end",
                        marginTop:'10px',
                        marginBottom:'10px'
                      }}
                    >
                      <ArrowBackIosNewRoundedIcon onClick={() => onChangeFecha(restarDias(fechaActual,1))} sx={{color:'#fafafa',backgroundColor:'rgb(0, 183, 196)',cursor:'pointer',borderRadius:'50px', padding:'3px',marginRight:"10px",':hover':{backgroundColor:'#2f4554'}}}/>
                      {
                      (i18n.language==='en')?
                        ("Data of "+ String((new Date(datosMaquina.fecha+" 23:59:59")).toLocaleString('en-US', { month: 'long', year:'numeric', day: 'numeric' })))
                      :
                        ("Mostrando "+ String((new Date(datosMaquina.fecha+" 23:59:59")).getDate())  + " de " +String((new Date(datosMaquina.fecha+" 23:59:59")).toLocaleString('default', { month: 'long' })) + " del " + String((new Date(datosMaquina.fecha+" 23:59:59")).getFullYear()))
                      }
                      {
                        (datosMaquina.fecha !== format((new Date()), "yyyy-MM-dd")) &&
                        <ArrowForwardIosRoundedIcon onClick={() => onChangeFecha(sumarDias(fechaActual,1))} sx={{color:'#fafafa',backgroundColor:'rgb(0, 183, 196)',cursor:'pointer',borderRadius:'50px', padding:'3px',marginLeft:"10px",':hover':{backgroundColor:'#2f4554'}}}/>
                      }
                    </Typography>
                  </Grid>
                </Grid>
                  </Grid>
                </Grid>


              
              </Grid>
          <Grid item lg = {7}>
            <Grid container justifyContent='flex-end' alignItems="center">
              <Button
                  onClick={()=>onChangeTurno(turnoTodos)}
                  className={turno.id===0?classes.buttonSelected:classes.buttonUnselected}
              >
                {t("todos")}
              </Button>

              {
                listaTurnos.map((itemTurno,index) => {
                  return(
                      <Button
                          key={index+1}
                          onClick={()=>onChangeTurno({id: index+1, ...itemTurno })}
                          className={(turno.nombre===itemTurno.nombre)?classes.buttonSelected:classes.buttonUnselected}
                      >
                        {t(`turno_${itemTurno.nombre.toLowerCase()}`)}
                      </Button>
                  )
                })
              }
            </Grid>
          </Grid>

        </Grid>
        <br/>
        <Card sx={{padding:"30px 20px 20px 20px"}}>
          <CardContent>
            <SectionHeader
              title={t("kpis_de_prod_diaria")}
              subtitle={""}
              icon={<img src={IconoKpi} width="35px" alt=" Icono Resumen Diario"/>}
            />
            <br/>
            <br/>
            
            <Grid container sx={{backgroundColor:"#2f4554", borderRadius:"2px"}}>
              <Grid item lg={4} md={12} xs={12} sx={{borderRight:"1px solid #465f70"}}>
                <ProdKpiCard 
                  titulo={t("vueltas")}
                  subtitulo={`(${t("cantidad")})`} 
                  dataTurnos={kpiProd.vueltas}
                  isSelect = {true}
                  icono={<img src={IconoVueltas} width="18px" alt=" icono vueltas"/>}
                  turno={turno.nombre}
                />               
              </Grid>
              <Grid item lg={4} md={12} xs={12} sx={{borderRight:"1px solid #465f70"}}>
                <ProdKpiCard 
                  titulo={t("tonelaje")}
                  subtitulo="(T)" 
                  dataTurnos={kpiProd.tonelaje}
                  isSelect = {true}
                  icono={<img src={IconoTonelaje} width="18px" alt=" icono vueltas"/>}
                  turno={turno.nombre}
                />    
              </Grid>
              <Grid item lg={4} md={12} xs={12}>
                <ProdKpiCard 
                  titulo={t("rendimiento")}
                  subtitulo="(T/h)" 
                  dataTurnos={kpiProd.rendimiento}
                  isSelect = {true}
                  icono={<img src={IconoRendimiento} width="23px" alt=" icono vueltas"/>}
                  turno={turno.nombre}
                />    
              </Grid>
            </Grid>

            <br/>
            <br/>
            <SectionHeader
              title={t("tiempo_asarco")}
              subtitle={""}
              icon={<img src={IconoProdTotal} width="35px" alt=" Icono Resumen Diario"/>}
            />
            <br/>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              columns={7}
              sx={{padding:"0px 30px 0px 0px"}}
            >
              <Grid item lg={1} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
                <HtmlTooltip
                  enterDelay={0}
                  title={
                    <Typography color="inherit" fontSize="12px" alignItems="center" textAlign="center">
                      {t("horas_del_dia")}
                    </Typography>
                  }
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{cursor:"help"}}
                  >
                    <div className={classes.prodValue} style={{color:"#2f4554"}}>
                      {formatoDecimales(tiemposAsarcoPorTurno.nominal)}
                    </div>
                    <div className={classes.prodDes}>
                      {t("tiempo_nominal")}
                    </div>
                  </Grid>
                </HtmlTooltip>
              </Grid>
              <Grid item lg={1} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
                <HtmlTooltip
                  enterDelay={0}
                  title={
                    <Typography color="inherit" fontSize="12px" alignItems="center" textAlign="center">
                      {`${t("tiempo_nominal")} - ${t("tiempo_indisponible")}`}
                    </Typography>
                  }
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{cursor:"help"}}
                  >
                    <div className={classes.prodValue} style={{color:"#01b7c4"}}>
                      {formatoDecimales(tiemposAsarcoPorTurno.disponible)}
                    </div>
                    <div className={classes.prodDes}>
                      {t("tiempo_disponible")}
                    </div>
                  </Grid>
                </HtmlTooltip>
              </Grid>
              <Grid item lg={1} md={2} xs={2} className={classes.bordermd}>
                <HtmlTooltip
                  enterDelay={0}
                  title={
                    <Typography color="inherit" fontSize="12px" alignItems="center" textAlign="center">
                      {t("tiempo_total_en_taller")}
                    </Typography>
                  }
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{cursor:"help"}}
                  >
                    <div className={classes.prodValue} style={{color:"#ff6600"}}>
                      {formatoDecimales(tiemposAsarcoPorTurno.indisponible)}
                    </div>
                    <div className={classes.prodDes}>
                      {t("tiempo_indisponible")}
                    </div>
                  </Grid>
                </HtmlTooltip>
              </Grid>
              <Grid item lg={1} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
                <HtmlTooltip
                  enterDelay={0}
                  title={
                    <Typography color="inherit" fontSize="12px" alignItems="center" textAlign="center">
                      {"Horómetro - Tiempo encendido en taller"}
                    </Typography>
                  }
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{cursor:"help"}}
                  >
                    <div className={classes.prodValue} style={{color:"#01b7c4"}}>
                      {formatoDecimales(tiemposAsarcoPorTurno.operativo)}
                    </div>
                    <div className={classes.prodDes}>
                      {t("tiempo_operativo")}
                    </div>
                  </Grid>
                </HtmlTooltip>
              </Grid>
              <Grid item lg={1} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
                <HtmlTooltip
                  enterDelay={0}
                  title={
                    <Typography color="inherit" fontSize="12px" alignItems="center" textAlign="center">
                      {`${t("tiempo_disponible")} - ${t("tiempo_operativo")}`}
                    </Typography>
                  }
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{cursor:"help"}}
                  >
                    <div className={classes.prodValue} style={{color:"#C5283D"}}>
                      {formatoDecimales(tiemposAsarcoPorTurno.reserva)}
                    </div>
                    <div className={classes.prodDes}>
                      {t("tiempo_reserva")}
                    </div>
                  </Grid>
                </HtmlTooltip>
              </Grid>
              <Grid item lg={1} md={2} xs={2} className={classes.bordermd}>       
                <HtmlTooltip
                  enterDelay={0}
                  title={
                    <Typography color="inherit" fontSize="12px" alignItems="center" textAlign="center">
                      {t("tiempo_total_de_vueltas")}
                    </Typography>
                  }
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{cursor:"help"}}
                  >
                    <div className={classes.prodValue} style={{color:"#01b7c4"}}>
                      {formatoDecimales(tiemposAsarcoPorTurno.efectivo)}
                    </div>
                    <div className={classes.prodDes}>
                      {t("tiempo_efectivo")}
                    </div>
                  </Grid>
                </HtmlTooltip>   
              </Grid>
              <Grid item lg={1} md={2} xs={2}>
                <HtmlTooltip
                  enterDelay={0}
                  title={
                    <Typography color="inherit" fontSize="12px" alignItems="center" textAlign="center">
                      {`${t("tiempo_operativo")} - ${t("tiempo_efectivo")}`}
                    </Typography>
                  }
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={{cursor:"help"}}
                    className={classes.marginmd}
                  >
                    <div className={classes.prodValue} style={{color:"#ff6600"}}>
                      {formatoDecimales(tiemposAsarcoPorTurno.perdida_operacional)}
                    </div>                
                    <div className={classes.prodDes}>
                      {t("tiempo_perdida_operacional")}
                    </div>
                  </Grid>
                </HtmlTooltip>
              </Grid>
            </Grid>

            <br/>
            <br/>

            <SectionHeader
              title={t("movimiento_y_horometro")}
              subtitle={""}
              icon={<img src={IconoResumenDiario} width="35px" alt=" Icono Resumen Diario"/>}
            />


            <div style={{width:"100%"}}>
              {
                seriesMovilizacionPorTurno.length>0?
                  <MotionChart
                  series = {seriesMovilizacionPorTurno}
                  limites={getMinMaxGrafico()}
                  fecha={datosMaquina.fecha}
                />
                :
                  <BarTimelineChartVacio
                    limites={limites}
                    labels={['Carga', 'Descarga']}
                    height="300"
                    loading={loading}
                
                  />
              } 

          <br/>
          <br/>

          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
              <Grid container justifyContent="center" alignItems="center" >
                <span 
                  style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#01b7c4",
                    borderRadius:"50%",
                    display: "inline-block"
                  }}
                />
                <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                  {t("operativo")}
                </span>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
              <Grid container justifyContent="center" alignItems="center" >
                <span 
                  style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#E9724C",
                    borderRadius:"50%",
                    display: "inline-block"
                  }}
                />
                <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                  {t("ralenti")}
                </span>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
            <Grid container justifyContent="center" alignItems="center" >
              <span 
                style={{
                  margin:"0px 5px 0px 0px",
                  height:"13px",
                  width:"13px",
                  backgroundColor: "#ffbf00",
                  borderRadius:"50%",
                  display: "inline-block"
                }}
              />
              <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                {t("en_mantencion")}
              </span>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
            <Grid container justifyContent="center" alignItems="center" >

              <span 
                style={{
                  margin:"0px 5px 0px 0px",
                  height:"13px",
                  width:"13px",
                  backgroundColor: "#C5283D",
                  borderRadius:"50%",
                  display: "inline-block"
                }}
              />
              <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                {t("fuera_de_servicio")}
              </span>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
              <Grid container justifyContent="center" alignItems="center" >
                <span 
                  style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#28B463",
                    borderRadius:"50%",
                    display: "inline-block"
                  }}
                />
                <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                  {t("Justificado")}
                </span>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important"}}>
            <Grid container justifyContent="center" alignItems="center" >

              <span 
                style={{
                  margin:"0px 5px 0px 0px",
                  height:"13px",
                  width:"13px",
                  backgroundColor: "#d6dce4" ,
                  borderRadius:"50%",
                  display: "inline-block"
                }}
              />
              <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                {t("sin_info")}
              </span>
              </Grid>
            </Grid>
          </Grid>

          {
            estadosDataSeries.length>0 ?
              <HorizontalTimeChart
                series = {estadosDataSeries} 
                turno={limites}
                numCategorias={1}
              />
            :
              <BarTimelineChartVacio
                limites={getMinMaxGrafico()}
                labels={[t("horometro")]}
                height="130"
                loading={loading}
              />
          }

            </div>

            <br/>
            <br/>
            <SectionHeader
              title={t("detalle_por_portal")}
              subtitle={""}
              icon={<img src={IconoDetalle} width="35px" alt=" Icono Resumen Diario"/>}
            />
            <br/>
            {/* <TablaFuncionamiento  data={vueltasPorTurno} columnsType={tipoInfoVuelta} /> */}
            <TablaVueltasPortales data={portalesRows} cols={configColumnas} headCols={configHeadCols}/>
          </CardContent>
        </Card>
        <br/>
      </Container>
    </>
  ); 
};

export default FuncionamientoDiario;