import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@mui/material/Divider";

//import HomeIcon from '@mui/icons-material/Home';
//import WhatshotIcon from '@mui/icons-material/Whatshot';
//import GrainIcon from '@mui/icons-material/Grain';

var moment = require("moment-timezone");

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "id_vibot",
    headerName: "ID Vibot",
    width: 150,
  },
  {
    field: "beacon_mac",
    headerName: "Beacon Mac",
    width: 150,
  },
  {
    field: "beacon_major",
    headerName: "Beacon Major",
    type: "number",
    width: 170,
  },
  {
    field: "beacon_minor",
    headerName: "Beacon Minor",
    type: "number",
    width: 170,
  },
  {
    field: "fecha_final",
    headerName: "Fecha Final",
    width: 170,
    valueFormatter: ({ value }) =>
      moment(value).utc().format("DD/MM/YYYY"),
  },
  {
    field: "fecha_inicial",
    headerName: "Fecha Inicial",
    width: 170,
    valueFormatter: ({ value }) =>
      moment(value).utc().format("DD/MM/YYYY"),
  },
  {
    field: "hora_final",
    headerName: "Hora Final",
    width: 170,
    valueFormatter: ({ value }) =>{
     return value},
  },
  {
    field: "hora_inicial",
    headerName: "Hora Inicial",
    width: 170,
    valueFormatter: ({ value }) =>
      value,
  },
  {
    field: "rssi_max",
    headerName: "rssi_max",
    type: "number",
    width: 170,
  },
  {
    field: "rssi_min",
    headerName: "rssi_min",
    type: "number",
    width: 170,
  },
  {
    field: "rssi_prom",
    headerName: "rssi_prom",
    type: "number",
    width: 170,
  },
];

const TableCCU = (props) => {
  const { loading, rows } = props;

  return !loading ? (
    <>
      <CssBaseline />
      <Container>
        <Grid container spacing={0} columns={10}>
          <br/>
          <Grid item xs={12}>
            <Typography
              style={{ color: "#323b41", fontWeight: "bold", fontSize: "18px" }}
            >
              ENCUENTROS
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                style={{fontSize:"14.4px"}}
                color="inherit"
                href="/inicio"
              >
                Inicio
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                style={{fontSize:"14.4px"}}
                color="inherit"
                href="/encuentros"
              >
                Encuentros
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <br/>
        <Divider/>
        <br/>
        <div style={{ height: 550, width: "100%", marginTop: "20px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={40}
            rowsPerPageOptions={[40]}
            density={"compact"}
            //checkboxSelection
            disableSelectionOnClick
          />
        </div>
      </Container>
    </>
  ) : (
    "Loading..."
  );
};
export default TableCCU;
