import React, { useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import Pool from "../UserPool";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const ForgotPassword = () => {
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alert, setAlert] = React.useState({open: false, message: "", severity: "success"});

  const handleOpenAlert = (mensaje, tipo) => {
    setAlert({open: true, message: mensaje, severity: tipo});
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({open: false, message: alert.message, severity: alert.severity});
  };

  const history = useHistory();

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool,
    });
  };

  const sendCode = (event) => {
    event.preventDefault();

    getUser().forgotPassword({
      onSuccess: (data) => {
        console.log("onSuccess:", data);
      },
      onFailure: (err) => {
        console.error("onFailure:", err);
      },
      inputVerificationCode: (data) => {
        setStage(2);
      },
    });
  };

  const resetPassword = (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      handleOpenAlert("La contraseña no es la misma", "error");
      console.error("Passwords are not the same");
      return;
    }

    getUser().confirmPassword(code, password, {
      onSuccess: (data) => {
        handleOpenAlert("Contraseña recuperada exitosamente", "success");

        setTimeout(() => {
          history.push("/");
        }, 2000);       
      },
      onFailure: (err) => {
        console.error("onFailure:", err);
      },
    });
  };

  const paperStyle = {
    padding: 20,
    height: "42vh",
    width: 280,
    margin: "20px auto",
  };

  return (
    <div>
      {stage === 1 && (
        <form onSubmit={sendCode}>
          <Grid container>
            <Paper elevation={10} style={paperStyle}>
              <Typography
                style={{
                  fontWeight: "bold",
                  marginTop: "15px",
                }}
              >
                Recupera tu contraseña
              </Typography>
              <TextField
                style={{ marginBottom: "20px", marginTop: "20px" }}
                id="outlined-basic"
                label="EMAIL"
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <Button
                variant="contained"
                style={{ color: "green" }}
                type="submit"
              >
                Enviar
              </Button>
            </Paper>
          </Grid>
        </form>
      )}

      {stage === 2 && (
        <form onSubmit={resetPassword}>
          <Grid container>
            <Paper elevation={10} style={paperStyle}>
              <TextField
                style={{ marginBottom: "10px", marginTop: "10px" }}
                id="outlined-basic"
                label="TU CÓDIGO"
                placeholder="TU CÓDIGO"
                variant="outlined"
                value={code}
                onChange={(event) => setCode(event.target.value)}
              />
              <TextField
                style={{ marginBottom: "10px", marginTop: "10px" }}
                id="outlined-basic"
                label="INGRESA CONTRASEÑA"
                placeholder="INGRESA CONTRASEÑA"
                variant="outlined"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />

              <TextField
                style={{ marginBottom: "10px", marginTop: "10px" }}
                id="outlined-basic"
                label="REPITE CONTRASEÑA"
                placeholder="REPITE CONTRASEÑA"
                variant="outlined"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />

              <Button
                variant="contained"
                style={{ color: "green" }}
                type="submit"
              >
                Cambiar contraseña
              </Button>
            </Paper>
          </Grid>
        </form>
        
      )}
      <Snackbar 
        open={alert.open} 
        autoHideDuration={2000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant = "filled" onClose={handleCloseAlert} severity= {alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ForgotPassword;
