import {React, useContext} from "react";
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import { translateMachineName } from "../../commons/utiles";
import { GeneralMinaContext } from "../../context/GeneralMina";

const CategoricalBarChart = ({dataSeries, loading, titulo}) => {
  const { t, i18n } = useTranslation();
  const {onHandleGetGeneralData} = useContext(GeneralMinaContext);
  const options = {
    debug: true,
    noData: {
      text: loading?"Cargando ..." : "No hay datos",
      offsetX: 70,
      offsetY: -20,
      style: {
        color: "#2f4554"/* "#00b7c4" */,
        fontSize: '14.4px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      }
    },
    loading:loading,
    grid: {
      position: 'back'
    },
    legend:{
      hide:true,
      position: "bottom",
      horizontalAlign: "right",
      offsetY: 10
    },
    colors: ["#2f4554"],
    chart: {
      toolbar:{show:false},
      defaultLocale: i18n?.language ? i18n.language : "en",
      locales: [{
        name: 'en',
        options: {
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          days: ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
          shortDays: ['Dom','Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          toolbar: {
            download: 'Descargar SVG',
            selection: 'Selección',
            selectionZoom: 'Selección Zoom',
            zoomIn: 'Zoom',
            zoomOut: 'Alejar',
            pan: 'Panning',
            reset: 'Reset Zoom',
          }
        }
      },
      {
        name: 'es',
        options: {
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          days: ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
          shortDays: ['Dom','Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          toolbar: {
            download: 'Descargar SVG',
            selection: 'Selección',
            selectionZoom: 'Selección Zoom',
            zoomIn: 'Zoom',
            zoomOut: 'Alejar',
            pan: 'Panning',
            reset: 'Reset Zoom',
          }
        }
      }
    ],
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        colors: {
          backgroundBarColors: dataSeries?.categorias.map(() => "#f5f5f5")//["#f5f5f5"],                               
        },
      },
    },
    dataLabels: {
      enabled: false,
      style:{
        colors: ['#f5f5f5']
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: dataSeries?.categorias,
      labels:{
        style: {
          fontWeight: "bold",
      },
      formatter:function(val){
        if(typeof(val)=== "string"){
          return translateMachineName(val,t,onHandleGetGeneralData()?.idMina)
        }else{
          return val;
        }
      }
      }
    },
    yaxis: [
      {
        labels: {
          formatter: function(val) {
            return `${Math.floor(val)}`
          }
        },
        min: 0,
        max: Math.max(...dataSeries?.vueltas)+2,
      }
    ],
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val}`
        }
      }
    }
  };
  if(!dataSeries){
    return <></>
  }
  
  return (
    <div id="chartbar">
      <Chart 
        height={"250px"} 
        width={"100%"} 
        options={options} 
        series={
          [
            {
              name: titulo,
              data: dataSeries?.vueltas
            }
          ]
        } 
        type="bar" 
      />
    </div>
  );
  
}

export default CategoricalBarChart;