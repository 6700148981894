import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import {
  Grid,
  Box,
  Paper,
  TablePagination,
  makeStyles,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TablaDeCarga } from '../../../components/organisms/TablaDeCarga';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { PopUpDeleteCicloGps } from './PopUpDeleteCicloGps';
import { PopUpSuccessDelete } from '../vicons/PopUpSuccessDelete';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#2f4554",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

export const TablaCiclosGps = ({loading=false, data=[], isEditing, onHandleSetFormikEditValues,onHandleEditNewCicloRowSelected,refForm, id_area_operacion, nombreMina, pathMina, onHandleCancelOperations}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showPopUpDeleteSuccess, setShowPopUpDeleteSuccess] = useState(false);
    const [cicloDeleteData, setCicloDeleteData] = useState();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

      const onHandleRefFormClick = () =>{
        refForm.current?.scrollIntoView({ behavior: 'smooth' });
      }

      const onHandleShowPopUpDelete = (show,ciclo) =>{
        setShowPopUpDelete(show)
        setCicloDeleteData(ciclo)
      }

      const onHandleShowPopUpSuccess = (show) =>{
        setShowPopUpDeleteSuccess(show);
      }
      
    if(loading){
        return (
            <>
                <TablaDeCarga columnas={["Maquina", "Tipo geocerca inicio", "Tipo geocerca fin"]}/>
            </>
        )
    }

    return (
      <>
        <Grid container style={{marginBottom:"20px"}}>

            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">{t("tipo_maquina")}</StyledTableCell>
                            <StyledTableCell align="center">{t("tipo_geocerca_inicio")}</StyledTableCell>
                            <StyledTableCell align="center">{t("tipo_geocerca_fin")}</StyledTableCell>
                            {isEditing && (
                              <StyledTableCell align="center">{""}</StyledTableCell>
                            )}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                       {
                        data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(el =>(
                          <StyledTableRow key={el?.id}>
                              <StyledTableCell align="center" component="th" scope="row">
                                  {t(el.nombre_tipo_maquina)}
                              </StyledTableCell>
                              <StyledTableCell align="center" component="th" scope="row">
                                  {el.tipo_geocerca_inicio}
                              </StyledTableCell>
                              <StyledTableCell align="center" component="th" scope="row">
                                  {el.tipo_geocerca_fin}
                              </StyledTableCell>
                              {isEditing && (
                                <StyledTableCell align="center">
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-evenly"
                                  >
                                    <Box
                                      onClick={() => {
                                        onHandleSetFormikEditValues(el)
                                        onHandleEditNewCicloRowSelected()
                                        onHandleRefFormClick()
                                      }}
                                      sx={{
                                        "&:hover": {
                                          backgroundColor: "rgb(0, 183, 196)",
                                          borderRadius: "50%",
                                          padding: "7px",
                                        },
                                        padding: "7px",
                                      }}
                                    >
                                      <Tooltip title={t("editar")}>
                                        <BorderColorIcon
                                          style={{
                                            color: "#2f4554",
                                            margin: "auto",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Tooltip>
                                    </Box>
                                    <Box
                                      sx={{
                                        "&:hover": {
                                          backgroundColor: "rgb(0, 183, 196)",
                                          borderRadius: "50%",
                                          padding: "7px",
                                        },
                                        padding: "7px",
                                      }}
                                    >
                                      <Tooltip title={t("eliminar")}
                                        onClick={()=> {
                                          onHandleShowPopUpDelete(true, el)
                                        }}
                                      >
                                        <DeleteOutlineOutlinedIcon
                                          style={{
                                            color: "#2f4554",
                                            margin: "auto",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Tooltip>
                                    </Box>
                                  </Grid>
                                </StyledTableCell>
                              )}
                              </StyledTableRow>
                            ))
                          } 
                    </TableBody>
                </Table>
                <TablePagination
                 rowsPerPage={rowsPerPage}
                 component={"div"}
                 count={data.length}
                 rowsPerPageOptions={[10,25,35]}
                 page={page}
                 onPageChange={handleChangePage}
                 onRowsPerPageChange={handleChangeRowsPerPage}
                 labelRowsPerPage={t("filas_por_pagina")}
                />
            </TableContainer>
        </Grid>
        <PopUpDeleteCicloGps 
        ciclo={cicloDeleteData} 
        id_area_operacion={id_area_operacion}
        nombre_mina={nombreMina} 
        onHandleShowPopUp={onHandleShowPopUpDelete} 
        show={showPopUpDelete}
        showPopUpSuccess={onHandleShowPopUpSuccess}
        pathMina={pathMina}
        />
         <PopUpSuccessDelete
            onHandleShowPopUp={onHandleShowPopUpSuccess}
            show={showPopUpDeleteSuccess}
          />
      </>
    )
}
