import React, {useState, useEffect, useContext} from "react";
import Chart from "react-apexcharts";
import "./StackedChartStyles.css";
import { format } from "date-fns";
import { separarPorMiles } from "../../commons/Formatos";
import '../../translator';
import { useTranslation } from 'react-i18next';
import { translateMachineName } from "../../commons/utiles";
import { GeneralMinaContext } from "../../context/GeneralMina";

const StackedChart = ({data}) => {
  const { t, i18n } = useTranslation();
  const {onHandleGetGeneralData} = useContext(GeneralMinaContext);

  // tambien agregar mas colores
  const [labels, setLabels] = useState([]);
  const [multiSeries, setMultiSeries] = useState([]);
  const [yaxisSeries, setYaxisSeries] = useState([]);
  const [seriesWidth, setSeriesWidth] = useState([]);
  const [seriesFill, setSeriesFill] = useState([]);

  const redondearEntero = (n) => {
    let escala= 1000
    if(n<100){
      escala=10
    }
    else if(n<1000){
      escala=100
    }
    else if(n<10000){
      escala=1000
    }
    else if(n<1000000){
      escala=10000
    }
    else{
      
    }
    return n - n%escala + escala;
  } 

  const getSeriesColor = (i) => {
    const colors = [
      '#2F4453',
      '#01B7C3',
      '#187FC5',  
      '#3DD7EF',
      '#2F4453',
      '#01B7C3',
      '#187FC5',  
      '#3DD7EF',
      '#2F4453',
      '#01B7C3',
      '#187FC5',  
      '#3DD7EF'
    ]

    return colors[i]
    /* [ '#ff6600','#2f4554', '#00b7c4'] */
  }

  useEffect(()=>{
      const dataEntries = Object.entries(data);
      if(dataEntries.length>0){
        // labels => fechas
        const labels = Object.keys(dataEntries[0][1].vueltas);
  
        let acumulaToneladas = 0;
        let fechasLabels = []; 
        let tonDiarioPorMaquina={}
        let dataTonAcumulado=[]
        let dataTonMetaSimulada=[]
        let max = 30;
        labels.forEach( fecha => {
          let sumaToneladas = 0;
          for(let j=0;j<dataEntries.length;j++){
            if(!fechasLabels.includes(fecha)){
              fechasLabels.push(fecha);
            }

            let tonActual = 0;
            Object.values(dataEntries[j][1]["vueltas"][fecha]).forEach((valVuelta) => {
              tonActual+=valVuelta["total_toneladas"]
              sumaToneladas+=valVuelta["total_toneladas"]
            })

            if(tonDiarioPorMaquina[dataEntries[j][1].nombre]){
              tonDiarioPorMaquina[dataEntries[j][1].nombre].push(tonActual)
            }
            else{
              tonDiarioPorMaquina[dataEntries[j][1].nombre] = [tonActual]
            }
          } 
          if(sumaToneladas>max){max=sumaToneladas};

          acumulaToneladas +=sumaToneladas
          dataTonAcumulado.push(acumulaToneladas);
          dataTonMetaSimulada.push(acumulaToneladas+10000);

        });

        let serFill = [1,0]
        let serWidth = [2,2]
        let nSeries = -1
        const series = Object.entries(tonDiarioPorMaquina).map(([key,data])=> {
          serWidth.unshift(1)
          serFill.unshift(1)
          nSeries+=1
          return {
            name: translateMachineName(key, t,onHandleGetGeneralData().idMina),
            type: "bar",
            data: data,
            color: getSeriesColor(nSeries)   
          }
        });
      

        const seriesCompletas = series.concat([
          {
            name: "tonelaje_acumulado"/* 'Tonelaje Acumulado' */,
            type: 'line',
            data: dataTonAcumulado,
            color: '#ff6600'
          },
          {
            name: 'meta_acumulada',
            type: 'area',
            data: dataTonMetaSimulada,
            color: '#FFBE00'
          },
        ]);

        const yaxisSeries = []; 
        let showAxis = true;
        Object.keys(tonDiarioPorMaquina).forEach(key => {
          if(showAxis){
            yaxisSeries.push({
              max:redondearEntero(max),
              axisTicks: {show: true},
              axisBorder: {show: true},
              tickAmount: 5,
              labels: {
                formatter: (value) => { return separarPorMiles(value)}
              },           
            })
          }
          else{
            yaxisSeries.push({
              max:max,
              show: false,
              labels: {
                formatter: (value) => { return separarPorMiles(value)}
              },   
            })
          }
          showAxis = false
        })

        // agrega maximo eje opuesto
        const len = dataTonAcumulado.length;
        let maxOpposite = 10000
        if(dataTonAcumulado[len-1]>dataTonMetaSimulada[len-1]){
          maxOpposite += dataTonAcumulado[len-1]
        }
        else{
          maxOpposite += dataTonMetaSimulada[len-1]
        }

        yaxisSeries.push(
          {
            min:0,
            max: redondearEntero(maxOpposite),
            seriesName: "tonelaje_acumulado"/* 'Tonelaje Acumulado' */,
            opposite: true,
            axisTicks: {show: true},
            axisBorder: {show: true},
            tickAmount: 5,
            labels: {
              style: {colors: ['#ff6600']},
              formatter: (value) => { return separarPorMiles(value)}
            }
          }
        )

        yaxisSeries.push({
          min:0,
          max: redondearEntero(maxOpposite),
          seriesName:'meta_acumulada',
          opposite: true,
          show: false,
          axisTicks: {show: false},
          axisBorder: {show: false},
          tickAmount: 5,
        })

        setSeriesFill(serFill)
        setSeriesWidth(serWidth)
        setMultiSeries(seriesCompletas);
        setLabels(fechasLabels.map(f => `${f} 00:00:00`));
        setYaxisSeries(yaxisSeries)
      }
      else{
        setLabels([]);
        setMultiSeries([]);
        setYaxisSeries([]);
      }
  },[data,i18n?.language]);

  var options = {
    chart: {
      height: 350,
      type: "line",
      stacked: true,
      toolbar:{
        show:false
      },
      defaultLocale: i18n?.language ? i18n.language : "en",
      locales: [{
        name: 'en',
        options: {
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          days: ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
          shortDays: ['Dom','Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          toolbar: {
            download: 'Descargar SVG',
            selection: 'Selección',
            selectionZoom: 'Selección Zoom',
            zoomIn: 'Zoom',
            zoomOut: 'Alejar',
            pan: 'Panning',
            reset: 'Reset Zoom',
          }
        }
      },
      {
        name: 'es',
        options: {
          months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          days: ['Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'],
          shortDays: ['Dom','Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
          toolbar: {
            download: 'Descargar SVG',
            selection: 'Selección',
            selectionZoom: 'Selección Zoom',
            zoomIn: 'Zoom',
            zoomOut: 'Alejar',
            pan: 'Panning',
            reset: 'Reset Zoom',
          }
        }
      }],
    },
    fill: {
      opacity: seriesFill,
    },
    noData: {
      text: "Cargando ...",
      offsetX: 70,
      offsetY: -20,
      style: {
        color: "#000000"/* "#00b7c4" */,
        fontSize: '14.4px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      }
    },
    dataLabels: {
      enabled: false
    },
    /* colors: [ '#ff6600','#2f4554', '#00b7c4'], */

    stroke: {
      width: seriesWidth,
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: "30%"
      }
    },
    markers: {
      size: 0
    },
    xaxis: {
      categories: labels,
      labels: {
        formatter: function (val) {
          if(val){
            return format((new Date(val)), "dd");
          }

        },
        datetimeUTC: false,
      },
    },
    yaxis: yaxisSeries,
    tooltip: {
      /* shared: true,
      intersect: false, */
      x: {
        show: false
      },
      y: {
        formatter: (val)=>{return `${separarPorMiles(val)} T`},
        title: {
          formatter: (seriesName) => {
            if(["tonelaje_acumulado","meta_acumulada"].includes(seriesName) || (seriesName.split("_")[0] === "turno")){ 
              return t(seriesName)
            };            
            return seriesName;
          }
        },
      }
    },
    legend:{
      show:true, 
      itemMargin:{
        horizontal: 25,
        vertical: 13
      },
      markers: {
        offsetX: -10
      },
      formatter: function(seriesName, opts) {
        if(["tonelaje_acumulado","meta_acumulada"].includes(seriesName) || (seriesName.split("_")[0] === "turno")){ 
          return t(seriesName)
        };
        return seriesName;
    }
    },

  };
  

  return(
    <Chart
      options={options}
      series={multiSeries}
      type="line"
      height={350}
    />
  )
}

export default StackedChart;