import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, esES, GridOverlay } from "@material-ui/data-grid"
import "moment/locale/es";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { styled } from '@mui/material/styles';
import { separarPorMiles } from "../../../commons/Formatos";
import { format } from "date-fns";
import { es, enUS } from "date-fns/locale";
import '../../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from "../../../commons/Idiomas";
import { translateMachineName } from "../../../commons/utiles";

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: 'column',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <div style={{backgroundColor:"#00b7c4", color:"white", fontSize:"14.4px",padding:"10px 15px", borderRadius:"3px"}}>No hay datos</div>
    </StyledGridOverlay>
  );
}

var _ = require("lodash");

const useStyles2 = makeStyles((theme) => ({
  root: {
    padding: 0,
    "& .MuiDataGrid-colCell.MuiDataGrid-colCellSortable": {
      paddingLeft: "5px"
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none"
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      fontWeight: "normal !important"
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.type === "light" ? "#2f4554" : "#1d1d1d",
      color: "white",
    },
    "& .MuiDataGrid-row.Mui-selected":{
      backgroundColor:"#a9e1e5 !important"
    },
    "& .MuiDataGrid-cell:focus":{
      outline: " solid black 0px"
    },
    "& .MuiDataGrid-renderingZone": {
      "& .MuiDataGrid-row": {
        "&:nth-of-type(2n)": { 
          backgroundColor: "rgba(47, 69, 84, .07)"/* "rgba(169, 225, 229, .3)"  */
        },
        "&:hover": {backgroundColor: "rgb(0, 183, 196, 0.15)"}
      }
    }
  }
}));

export default function TablaMaquinas({data,maquinas,cargando,fecha,idMina}) {
  let history = useHistory();
  const classes = useStyles2();
  const { t, i18n } = useTranslation();

  const [rowSelected, setRowSelected] = useState({});
  const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date());

  const columns = [
    { field: "id", headerName: "ID", width: 0, hide: true },
    {
      field: "numeroVuelta",
      headerName: "N°Vuelta",
      align: "center",
      type: "number",
      width: 120,
      hide: true
    },
    {
      field: "nombre",
      headerName: t("equipo"),
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 120,
      flex: 0.3,
      renderCell:(params) =>{
        if(typeof(params.value) == "string"){
          return translateMachineName(params.value, t, idMina)
        }
        return params.value;
      }
    },
    {
      field: "ultimaActualizacion",
      headerName: t("ultima_actualizacion"),
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 220,
      flex: 0.5,
      renderCell: (params) => {
        if(params.value){
          const idioma = getLocaleLanguage(i18n.language)
          const fechaRow = new Date(params.value);
          let diaSemana = format(fechaRow, 'EEEE', { locale: idioma });
          return (
            `${diaSemana} ${format(fechaRow, 'dd LLLL', { locale: idioma })}, ${format(fechaRow, 'HH:mm', { locale: idioma }) }`
            //`${diaSemana.replace(/^./, diaSemana[0].toUpperCase())} ${format(fechaRow, 'dd LLLL')} de ${format(fechaRow, 'LLLL', { locale: enUS })}, ${format(fechaRow, 'HH:mm')} hrs`
          );
        }
        else{
          return "-"
        }

      }
    },
    {
      field: "ultimaUbicacion",
      headerName: t("ultima_ubicacion"),
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 130,
      flex: 0.4
    },
    {
      field: "vueltas",
      headerName: t("numero_de_vueltas"),
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 120,
      flex:0.4,
      renderCell: (params) => {
        return separarPorMiles(params.value);
      }
    },
    {
      field: "tonelaje",
      headerName: `${t("toneladas")} (T)`,
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 120,
      flex:0.3,
    },    
    {
      field: "horometro",
      headerName: `${t("tiempo_efectivo")} (H)`,
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 130,
      flex:0.4
    },
  ];

  const onClickRow = (row) => {
    setRowSelected(row.row);
    history.push("/maquina");
    /* setRedirect(true); */
    localStorage.setItem("idMaquina",row.row.id);
    localStorage.setItem("fechaMaquina",fechaSeleccionada);
  };

  useEffect(()=>{
    setFechaSeleccionada(fecha);
  },[fecha]);

  return (
    <>
      <DataGrid
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        localeText={{...esES.props.MuiDataGrid.localeText, noRowsLabel: 'No hay datos'}}
        autoHeight
        hideFooter
        isRowSelectable={true}
        onRowClick={(data)=>{onClickRow(data)}}
        className={classes.root}
        density={"compact"}
        rows={data}
        columns={columns}
        loading={cargando}
        disableColumnMenu={true}
        hideFooterPagination={true}
      />
</>
  );
}
