import React from 'react'
import {
    Grid,
    Typography
  } from "@mui/material";

export const SectionHeaderNoImage = ({title,subtitle}) => {
  return (
    <>
      <Grid container alignItems="center">
          <Grid container>
            <Grid item lg={12} md={12} xs={12}>
              <Typography
                style={{fontWeight: "500",fontSize: "15px",color: "#2f4554"}}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Typography
                style={{fontStyle: "italic",fontSize: "12px",color: "#717171"}}
              >
                {subtitle}
              </Typography>
            </Grid>
          </Grid>
      </Grid>
    </>
  )
}
