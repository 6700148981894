import { useState, useEffect, useRef } from 'react';
import { getMaquinasByIdAreaOperacion } from '../services/justificacion';

export const useGetMaquinasByIdMina = (idAreaOperacion) => {

    const [maquinas, setMaquinas] = useState([]);
    const [loadingMaquinas, setLoadingMaquinas] = useState(false);
    const prevIdAreaOperacionRef = useRef(idAreaOperacion);

    useEffect(() => {
        if(idAreaOperacion != 0 && idAreaOperacion !== prevIdAreaOperacionRef.current){

            setLoadingMaquinas(true);
            getMaquinasByIdAreaOperacion(idAreaOperacion)
            .then((resp) =>{
                setMaquinas(resp.data);
                setLoadingMaquinas(false);
            })
            .catch(err => console.log(err))
            .finally(() => setLoadingMaquinas(false));

            prevIdAreaOperacionRef.current = idAreaOperacion;
        }

    }, [idAreaOperacion])

    return { maquinas, loadingMaquinas}
    
}
