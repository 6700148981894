export const getActividadUsuario = async (id_area_operacion,fecha_inicio,fecha_termino,fecha,group_by_fecha,group_by_path_name,group_by_usuario) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_area_operacion,
        fecha_inicio,
        fecha_termino,
        fecha,
        group_by_fecha,
        group_by_path_name,
        group_by_usuario
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/get-actividad-usuario",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }