import { useEffect, useState } from "react";
import { getMaquinasJustificacion } from "../services/justificacion";

export const useMaquinasJustificacion = (fecha,id_area_operacion,recargarTabla) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [maquinaSelected, setMaquinaSelected] = useState(0);
    const [alarmaSelected, setAlarmaSelected] = useState(0);

    useEffect(() => {
        if(!fecha){
            return;
        }
        setLoading(true);
        getMaquinasJustificacion(fecha,id_area_operacion).then(maquinas => {
            setData(maquinas.data);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            console.log(err);
        });
    }, [fecha,recargarTabla])


    const filterMaquinasById = () => {
        if (maquinaSelected === 0 && alarmaSelected ===0) {
        return data;
        } else if(maquinaSelected !== 0 && alarmaSelected !== 0) {
        return data.filter((maquina) => maquina.id_maquina === maquinaSelected && maquina.id_tipo_movimiento === alarmaSelected);
        }
        else if(maquinaSelected !== 0 && alarmaSelected == 0){
            return data.filter((maquina) => maquina.id_maquina === maquinaSelected); 
        }
        else if(maquinaSelected == 0 && alarmaSelected !== 0){
            return data.filter((maquina) => maquina.id_tipo_movimiento === alarmaSelected); 
        }
    };
    
    

    return {data:filterMaquinasById(),setData, loading,setMaquinaSelected, maquinaSelected,alarmaSelected, setAlarmaSelected}
}
