import React from 'react'
import { TablaTipoGeocercaSolicitud } from './TablaTipoGeocercaSolicitud';

export const SolicitudesTipoGeocerca = ({idMina, nombreMina}) => {
  return (
    <div>
        <TablaTipoGeocercaSolicitud id_area_operacion={idMina} nombreMina={nombreMina}/>
    </div>
  )
}
