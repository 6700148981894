import React from 'react'
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    buttonSelected: {
      backgroundColor: "#2f4554",
      color: "white",
      padding: "5px 30px",
      fontSize: "14.4px",
      textTransform: "none",
      borderRadius: "4px",
      margin: "3px",
      height: "38px",
      width:"160px",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "#00B7C4"
      }
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642",
      },
      "&:disabled": {
        backgroundColor: "gray",
      },
    },
    buttonUnselected: {
      backgroundColor: "#f4f4f4",
      padding: "5px 30px",
      fontSize: "14.4px",
      textTransform: "none",
      borderRadius: "4px",
      margin: "3px",
      height: "38px",
      width:"160px",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "#00B7C4"
      }
    },
    prodDes:{
      color: "#2f4554",
      fontSize: "12px !important",
    },
    prodValue:{
      fontSize: "20px",
      fontWeight:"bold"
    },
    bordermd:{
      '@media (min-width:1200px)': {
        borderRight:"2px solid #d7d7d7"
      }
    },
    marginmd:{
      '@media (min-width:1200px)': {
        marginLeft:"10px"
      }
    },
    datepicker: {
      width: "190px",
      backgroundColor:"white",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00B7C4"
      },
      "& .MuiInputBase-root": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        "& .MuiButtonBase-root": {
  
          borderColor: "error.main"
  
        },
        "& .MuiInputBase-input": {
          backgroundColor: "#ffffff",
          borderColor: "error.main",
          /* fontSize:"14.4px" */
        }
      },
  
      '& .MuiOutlinedInput-root': { 
        '&:hover fieldset': {
            borderColor: "#00B7C4",
        }
      },
    }
  });
  

export const LeyendasMovimientos = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <Grid item lg={2} md={2} xs={2} sx={{ padding: "3px 0px !important", borderRight: "2px solid #d7d7d7" }}>
                <Grid container justifyContent="center" alignItems="center" >
                    <span
                        style={{
                            margin: "0px 5px 0px 0px",
                            height: "13px",
                            width: "13px",
                            backgroundColor: "#01b7c4",
                            borderRadius: "50%",
                            display: "inline-block"
                        }}
                    />
                    <span className={classes.prodDes} style={{ marginLeft: "3px" }}>
                        {t("operativo")}
                    </span>
                </Grid>
            </Grid>

            <Grid item lg={2} md={2} xs={2} sx={{ padding: "3px 0px !important", borderRight: "2px solid #d7d7d7" }}>
                <Grid container justifyContent="center" alignItems="center" >
                    <span
                        style={{
                            margin: "0px 5px 0px 0px",
                            height: "13px",
                            width: "13px",
                            backgroundColor: "#E9724C",
                            borderRadius: "50%",
                            display: "inline-block"
                        }}
                    />
                    <span className={classes.prodDes} style={{ marginLeft: "3px" }}>
                        {t("ralenti")}
                    </span>
                </Grid>
            </Grid>

            <Grid item lg={2} md={2} xs={2} sx={{ padding: "3px 0px !important", borderRight: "2px solid #d7d7d7" }}>
                <Grid container justifyContent="center" alignItems="center" >

                    <span
                        style={{
                            margin: "0px 5px 0px 0px",
                            height: "13px",
                            width: "13px",
                            backgroundColor: "#ffbf00",
                            borderRadius: "50%",
                            display: "inline-block"
                        }}
                    />
                    <span className={classes.prodDes} style={{ marginLeft: "3px" }}>
                        {t("en_mantencion")}
                    </span>
                </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={2} sx={{ padding: "3px 0px !important", borderRight: "2px solid #d7d7d7" }}>
                <Grid container justifyContent="center" alignItems="center" >

                    <span
                        style={{
                            margin: "0px 5px 0px 0px",
                            height: "13px",
                            width: "13px",
                            backgroundColor: "#C5283D",
                            borderRadius: "50%",
                            display: "inline-block"
                        }}
                    />
                    <span className={classes.prodDes} style={{ marginLeft: "3px" }}>
                        {t("fuera_de_servicio")}
                    </span>
                </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={2} sx={{ padding: "3px 0px !important", borderRight: "2px solid #d7d7d7" }}>
                <Grid container justifyContent="center" alignItems="center" >
                    <span
                        style={{
                            margin: "0px 5px 0px 0px",
                            height: "13px",
                            width: "13px",
                            backgroundColor: "#28B463",
                            borderRadius: "50%",
                            display: "inline-block"
                        }}
                    />
                    <span className={classes.prodDes} style={{ marginLeft: "3px" }}>
                        {t("justificadoSingular")}
                    </span>
                </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={2} sx={{ padding: "3px 0px !important" }}>
                <Grid container justifyContent="center" alignItems="center" >

                    <span
                        style={{
                            margin: "0px 5px 0px 0px",
                            height: "13px",
                            width: "13px",
                            backgroundColor: "#d6dce4",
                            borderRadius: "50%",
                            display: "inline-block"
                        }}
                    />
                    <span className={classes.prodDes} style={{ marginLeft: "3px" }}>
                        {t("sin_info")}
                    </span>
                </Grid>
            </Grid>
        </Grid>
    )
}

