import React, {useState} from 'react';
import {Button} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import {MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { es } from "date-fns/locale";
import { makeStyles } from "@material-ui/core/styles";
import PlanillaIcon from '@mui/icons-material/ContentPaste';

//import LandslideIcon from '@mui/icons-material/Landslide';

const useStyles = makeStyles({
  icon:{
    fontSize: "40px",
    marginRight:"10px",
    /* color: "#2f4554" */
  },
  titulo:{
    fontWeight: "bold",
    fontSize: "14.4px",
  },
  texto:{
    fontSize: "14.4px"
  },
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        fontSize:"14.4px"
      }
    },

    '& .MuiOutlinedInput-root': { 
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
  },

  }
});

export default function ModalDescarga({open, onClickClose}) {

  const classes = useStyles();

  const handleClose = () => {
    onClickClose(false);
  };

  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent sx = {{padding:"0"}}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{padding:"13px 20px"}}
          >
            <Grid item lg={9}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <PlanillaIcon className={classes.icon}/>
                <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                  Planilla de Control Diario
                </Typography>
              </Grid>
            </Grid>
            <div onClick={handleClose} className={classes.texto} style={{fontStyle:"italic", cursor:"pointer"}}>
              Cerrar
            </div>
          </Grid>

          <Divider/>
          
          <br/>

          <Grid 
            container 
            alignItems='center' 
            justifyContent='space-between'
            sx={{padding:"13px 20px"}}
          >
            <Grid item>
              <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                Fecha Inicio
              </Typography>
              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <DatePicker
                  /* disableToolbar */
                  className={classes.datepicker}
                  format="dd/MM/yyyy"
                  cancelLabel="Cancelar"
                  size="small"
                  /* variant="inline" */
                  inputVariant="outlined"
                  /* label="Fecha inicio" */
                  value={fechaInicio}
                  onChange={setFechaInicio}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                Fecha Fin
              </Typography>
              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <DatePicker
                  /* disableToolbar */
                  className={classes.datepicker}
                  format="dd/MM/yyyy"
                  cancelLabel="Cancelar"
                  size="small"
                  /* variant="inline" */
                  inputVariant="outlined"
                  /* label="Fecha inicio" */
                  value={fechaFin}
                  onChange={setFechaFin}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>

        <br/>
        <br/>

        <DialogActions>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{padding:"5px 20px"}}
          >
            <Button onClick={handleClose} className={classes.buttonSelected}>Descargar</Button>
          </Grid>
        </DialogActions>
        <br/>
      </Dialog>
    </div>
  );
}