import { Box, Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, Link, MenuItem, TextField, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react'
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { SectionHeaderNoImage } from '../../../components/molecules/SectionHeaderNoImage';

import iconoEditar from '../../../assets/icons/icono-editar.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SectionHeader from '../../../components/molecules/SectionHeader';
import { useGetSectorYAreaoperacionVicon } from '../../../hooks/useGetSectorYAreaoperacionVicon';
import { useGetMinorsViconsByIdOperacion } from '../../../hooks/useGetMinorsViconsByIdOperacion';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { insertarVersionamientoVicon } from '../../../services/administracion';
import { decimalToHexadecimal } from '../../../commons/utiles';
import Pool from '../../../authentication/../UserPool'
import { PopUpDelete } from '../vicons/PopUpDelete';
import { PopUpSuccess } from '../../../components/organisms/PopUpSuccess';
import { PopUpConfirmacionGenerico } from '../../../components/organisms/PopUpConfirmacionGenerico';
import { AlertaGenerica } from '../../../components/molecules/AlertaGenerica';


const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

  const initialStatePoUps = ({
    showPopUpSuccess:false,
    showPopUpDelete:false,
    showPopUpConfirmEdit:false
  });

export const ViconForm = ({onHandleViewViconForm, editing,idMina, vicons,id_area_operacion, nombreMina, formData, refForm, pathMina,onHandleEditVicons}) => {
    const prioridadOperacion = Array.from({ length: 50 }, (_, index) => index + 1);
    const [estadoPopUps,setEstadoPopUps] = useState(initialStatePoUps);
    const [loadingVista, setLoadingVista] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        message: "",
        severity: "success",
      });
    const onHandleShowPopUpSuccess = (show) =>{
        setEstadoPopUps((prevState) => ({...prevState,showPopUpSuccess:show}))
      }
      const onHandleShowPopUpDelete = (show) =>{
        setEstadoPopUps((prevState) => ({...prevState,showPopUpDelete:show}))
      }

      const onHandleShowPopUpConfirmEdit = (show) =>{
          setEstadoPopUps((prevState) =>({...prevState,showPopUpConfirmEdit:show}))
          setAllFieldsTouched();
      }

    const initialStateForm = {
        id:0,
        id_area_operacion: id_area_operacion,
        nombre_mina: nombreMina,
        major: 1,
        minor:0,
        id_tipo_beacon:0,
        area_asignada:"",
        id_grupo:0,
        nombre_sector_operacion:"",
        orden:0,
        descripcion:"",
        mac:"",
        editing:false,
        solicitante:"",
        pathMina:pathMina,
        es_frente:false,
        entrada:false
      }

    const formik = useFormik({
    initialValues: initialStateForm,
    validationSchema: Yup.object({
        id_tipo_beacon:Yup.number().test(
            "seleccionar_area",
            "El area es obligatoria",
            function (value) {
              return value !== undefined && value !== 0;
            }
          ),
        id_grupo:Yup.number().test(
            "seleccionar_sector_operacion",
            "El sector de operación es obligatorio",
            function (value) {
              return value !== undefined && value !== 0;
            }
          ),
        minor:Yup.number().required("El minor es obligatorio"),
        descripcion:Yup.string().required("El nombre del vicon es obligatorio"),
        orden:Yup.number().test(
            "seleccionar_orden",
            "La prioridad el beacon es obligatoria",
            function (value) {
              return value !== undefined && value !== 0;
            }
          ),
        minor:Yup.number().test(
            "Escribir minor",
            "El minor del vicon es obligatorio",
            function(value){
                return value !== undefined && value !== 0;
            }
        )
        
    }),
    onSubmit: async (values) => {
        try {
            await insertarVersionamientoVicon({
                descripcion:values.descripcion,
                id_area_operacion:values.id_area_operacion,
                id_grupo:values.id_grupo,
                id_tipo_beacon:values.id_tipo_beacon,
                id_tipo_operacion:editing ? 2 : 1,
                major:values.major,
                minor:values.minor,
                orden:values.orden,
                solicitante: await Pool.getCurrentUser().username,
                nombre_mina:values.nombre_mina,
                nombre_sector_operacion:values.nombre_sector_operacion,
                area_asignada:values.area_asignada,
                pathMina:values.pathMina,
                es_frente:values.es_frente,
                entrada:values.entrada

            })
        } catch (error) {
            console.log(error)
        }
    },
    });

    const { t } = useTranslation();
    const classes = useStyles();
    const { areaAsignada, sectorDeOperacion } = useGetSectorYAreaoperacionVicon(idMina);

    const onHandleSetFormikValues = (formData) =>{
        const {id,descripcion,mac,major,minor,orden,id_area_asignada,id_sector_operacion,nombre_sector_operacion,area_asignada,es_frente, entrada} = formData;
        formik.setFieldValue("id",id ? id : initialStateForm.id);
        formik.setFieldValue("mac", mac ? mac : "Sin información")
        formik.setFieldValue("descripcion",descripcion ? descripcion : initialStateForm.descripcion);
        formik.setFieldValue("major",major ? major : initialStateForm.major);
        formik.setFieldValue("minor",minor ? minor : initialStateForm.minor);
        formik.setFieldValue("id_tipo_beacon",id_area_asignada ? id_area_asignada: initialStateForm.id_tipo_beacon);
        formik.setFieldValue("id_grupo",id_sector_operacion ? id_sector_operacion : initialStateForm.id_grupo);
        formik.setFieldValue("orden",orden ? orden : initialStateForm.orden);
        formik.setFieldValue("nombre_sector_operacion",nombre_sector_operacion ? nombre_sector_operacion : initialStateForm.nombre_sector_operacion)
        formik.setFieldValue("area_asignada",area_asignada ? area_asignada : initialStateForm.area_asignada)
        formik.setFieldValue("solicitante", Pool.getCurrentUser().username)
        formik.setFieldValue("es_frente",es_frente ? false : true);
        formik.setFieldValue("entrada",entrada ? false : true);
        setLoadingVista(false)
      }

      const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setAlert({ open: false, message: alert.message, severity: alert.severity });
      };

      const onHandleRefFormClick = () =>{
        refForm.current?.scrollIntoView({ behavior: 'smooth' });
      }
    

      const handleErrorForm = () => {

        if (!formik.isValid) {
          let msg = "";
          const claves = Object.keys(formik.errors);
          for (const clave of claves) {
            const valor = "* " + formik.errors[clave] + "?";
            msg += valor;
          }
          setAlert({
            open: true,
            message: msg,
            severity: "warning",
          });
          return true;
        }else{
            return false
        }
      };

    const setAllFieldsTouched = () => {
        Object.keys(formik.values).forEach(fieldName => {
          formik.setFieldTouched(fieldName, true);
        });
      };

      useEffect(() => {
        setLoadingVista(true)
      if(editing){
        onHandleSetFormikValues(formData);
      }else{
        setLoadingVista(false)
      }
      onHandleRefFormClick()
    }, [formData?.id])
    
    // useEffect(() => {

    //     setAllFieldsTouched();
    // }, [formik?.values?.id])
    if(loadingVista || formik.isSubmitting){
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        )
    }
  return (
    <div ref={refForm}>
        <Grid style={{padding:'48px', backgroundColor:'#f3f4f7', border:'3px solid rgb(0, 183, 196)'}} container>
            <Grid container style={{marginBottom:'30px'}}>
                {
                    editing ?
                    (
                        <SectionHeader icon={<img src={iconoEditar} style={{maxWidth:'32px'}}/>} title={`${t('editar')} VICON`} subtitle={t('editar_vicon_subtitle')}/>
                    ) :
                    (
                        <SectionHeader icon={<AddCircleOutlineIcon fontSize='large'/>} title={`${t('agregar_nuevo_vicon')}`} subtitle={t('agregar_nuevo_vicon_subtitulo')} />
                    )
                }
            </Grid>
            <Grid container spacing={5} style={{marginBottom:'20px'}}>
                <Grid item container xs={6}>
                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center'>
                        <Grid item xs={6}>
                            <InputLabel >
                            <SectionHeaderNoImage title={t('minaSingular')}/>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="margin-none"
                                name="nombre_mina"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ marginTop: "15px" }}
                                fullWidth
                                variant='outlined'
                                hiddenLabel
                                size='small'
                                margin='none'
                                disabled
                                value={formik.values.nombre_mina}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center'>
                        <Grid item xs={6}>
                            <InputLabel >
                                <SectionHeaderNoImage title={t('sector_operacion')}/>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6} style={{maxHeight:'40px',marginTop:'13px', width:'100%'}}>
                            <Select
                            name="id_grupo"
                            onBlur={formik.handleBlur}
                            defaultValue={0}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.id_grupo}
                            onChange={(e) =>{
                                const selectedOption = sectorDeOperacion.find((el) => el.id === e.target.value);
                                formik.setFieldValue("id_grupo", e.target.value)
                                formik.setFieldValue("nombre_sector_operacion", selectedOption?.nombre || '')
                            }
                            }
                            style={{maxHeight:'40px', width:'100%'}}
                            >
                            <MenuItem key={0} value={0}>
                                {t("seleccione_opcion")}
                            </MenuItem>
                            {
                                sectorDeOperacion.map((el,index) => (
                                    <MenuItem value={el.id} key={el.id+"-"+index}>{el.nombre}</MenuItem>
                                ))
                            }   
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center'>
                        <Grid item xs={6}>
                            <InputLabel >
                                <SectionHeaderNoImage title={t('area_asignada')} subtitle={t('area_asignada_subtitle')}/>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6} style={{maxHeight:'40px',marginTop:'13px', width:'100%'}}>
                            <Select
                            name="id_tipo_beacon"
                            onBlur={formik.handleBlur}
                            defaultValue={0}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.id_tipo_beacon}
                            onChange={(e) =>{
                                const selectedOption = areaAsignada.find((el) => el.id === e.target.value);
                                formik.setFieldValue("id_tipo_beacon", e.target.value)
                                formik.setFieldValue("area_asignada", selectedOption?.descripcion || '')
                            }
                            }
                            style={{maxHeight:'40px', width:'100%'}}
                            >
                            <MenuItem key={0} value={0}>
                                {t("seleccione_opcion")}
                            </MenuItem>
                            {
                                areaAsignada.map((el,index) => (
                                    <MenuItem value={el.id} key={el.id+"-"+index}>{el.descripcion}</MenuItem>
                                ))
                            }   
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center' style={{marginTop:'5px'}}>
                        <Grid item xs={6}>
                            <InputLabel >
                                <SectionHeaderNoImage title={t("ubicado_en_frente")}/>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6} style={{maxHeight:'40px',marginTop:'13px', width:'100%'}}>
                            <Checkbox 
                              checked = {formik.values.es_frente  ? true : false}
                              style={{color : "#2f4554", width:'0px', height:'0px'}} 
                              onChange={(e, checked) =>{
                                if(checked){
                                    formik.setFieldValue('es_frente',true)
                                }else{
                                    formik.setFieldValue('es_frente',false)
                                }
                              }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {/* SEGUNDA COLUMNA */}
                <Grid item container xs={6}>
                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center'>
                        <Grid item xs={6}>
                            <InputLabel >
                                <SectionHeaderNoImage title={'MINOR VICON'} subtitle={t('id_vicon_subtitle')}/>
                            </InputLabel>
                        </Grid>
                        {
                        <Grid item xs={6} style={{maxHeight:'40px',marginTop:'13px', width:'100%'}}>
                            <TextField
                                disabled={editing ? true :false}
                                id="margin-none"
                                name="minor"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) =>formik.setFieldValue("minor",e.target.value.toString())}
                                value={formik.values.minor}
                                style={{ }}
                                fullWidth
                                variant='outlined'
                                hiddenLabel
                                size='small'
                                margin='none'
                            />
                        </Grid> 
                        }
                    </Grid>

                    <Grid container item xs={12} spacing={1} wrap='nowrap' alignItems='center'>
                        <Grid item xs={6}>
                            <InputLabel >
                                <SectionHeaderNoImage title={t('nombre_referencia_vicon')} subtitle={t('validacion_10_caracteres')}/>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="margin-none"
                                name="descripcion"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) =>formik.setFieldValue("descripcion",e.target.value.toString())}
                                value={formik.values.descripcion}
                                style={{ marginTop: "15px" }}
                                fullWidth
                                variant='outlined'
                                hiddenLabel
                                size='small'
                                margin='none'
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center'>
                        <Grid item xs={6}>
                            <InputLabel >
                                <SectionHeaderNoImage title={t('prioridad_operacion')} subtitle={t('prioridad_operacion_subtitle')}/>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6} style={{maxHeight:'40px',marginTop:'13px', width:'100%'}}>
                            <Select
                            name="orden"
                            onBlur={formik.handleBlur}
                            defaultValue={0}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.orden}
                            onChange={(e) =>
                                formik.setFieldValue("orden", e.target.value)
                            }
                            style={{maxHeight:'40px', width:'100%'}}
                            >
                            <MenuItem key={999} value={0}>
                                {t("seleccione_opcion")}
                            </MenuItem>
                            {
                                prioridadOperacion.map((index) => (
                                    <MenuItem value={index} key={index}>{index}</MenuItem>
                                ))
                            }
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center' style={{marginTop:'5px',}}>
                        <Grid item xs={6}>
                            <InputLabel >
                                <SectionHeaderNoImage title={t("ubicado_en_entrada_mina")}/>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6} style={{maxHeight:'40px', marginTop:'13px', width:'100%'}}>
                            <Checkbox 
                              checked = {formik.values.entrada ? true : false}
                              style={{color : "#2f4554", width:'0px', height:'0px'}} 
                              onChange={(e, checked) =>{
                                if(checked){
                                    formik.setFieldValue('entrada',true)
                                }else{
                                    formik.setFieldValue('entrada',false)
                                }
                              }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>

            {/* FOOTER */}
            <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={1} style={{maxHeight:'41px'}}>
                <Grid item xs={9} container wrap='nowrap' justifyContent='flex-start' alignItems='flex-start'>
                    <Box sx={{backgroundColor:'rgb(0, 183, 196)'}}>
                            <InfoOutlinedIcon  fontSize='large' sx={{color:'white',padding:'5px'}}/>
                    </Box>
                    <Box sx={{color:'#666666' ,padding:'10.5px 25px', backgroundColor:'#eaedf1'}}>{t('recuerdo_aprobacion')}</Box>
                </Grid>
                <Grid item xs={3}>
                    {
                        editing ?
                        <Box>
                            <Button
                                fullWidth
                                type="button"
                                variant="contained"
                                className={classes.root}
                                style={{textAlign:'center', marginBottom:'15px'}}
                                onClick={() => onHandleShowPopUpConfirmEdit(true)}
                                >
                                {t("guardar_cambios")}
                            </Button>
                        </Box> :

                        <Box>
                        <Button
                            fullWidth
                            type="button"
                            variant="contained"
                            className={classes.root}
                            style={{textAlign:'center', marginBottom:'15px'}}
                            onClick={() => {
                                onHandleShowPopUpConfirmEdit(true)
                            }}
                            >
                            {t("agregarr")}
                        </Button>
                    </Box>

                    }
                    <Box sx={{display:'flex',justifyContent:'center'}}>
                    <Link 
                    style={{cursor:'pointer'}} 
                    onClick={()=>{
                        onHandleViewViconForm(false,false)
                        }} 
                    underline='always' 
                    color='inherit'>{t('cancelar')}</Link>
                    </Box>
                </Grid>
                </Grid>
        </Grid>

        {/* PopUp confirmEdit */}
        <PopUpConfirmacionGenerico
            editing={editing}
            formik={formik}
            onHandleShowPopUp={onHandleShowPopUpConfirmEdit}
            show={estadoPopUps.showPopUpConfirmEdit}
            onHandleShowPopUpSuccess={onHandleShowPopUpSuccess}
            handleErrorForm={handleErrorForm}
            dataColumnas={{
                columna1:[
                {
                    label:"Mina",
                    value:formik.values.nombre_mina
                },
                {
                    label:'Sector de operacion',
                    value:formik.values.nombre_sector_operacion
                },
                {
                    label:'Area asignada',
                    value:formik.values.area_asignada
                }
            ],
                columna2:[
                {
                    label:"Minor",
                    value:formik.values.minor
                },
                {
                    label:"Nombre Vicon",
                    value:formik.values.descripcion
                },
                {
                    label:"Prioridad en operacion",
                    value:formik.values.orden
                }
            ]
            }}
        />
        
        {/* PopUp Success */}
        <PopUpSuccess onHandleShowPopUp={onHandleShowPopUpSuccess} show={estadoPopUps.showPopUpSuccess} onHandleViewform={onHandleViewViconForm} onHandleEditVicons={onHandleEditVicons} />


        {/* ALERTA DE VALIDACIONES */}
        <AlertaGenerica alert={alert} handleCloseAlert={handleCloseAlert} duration={5000} />
    </div>
  )
}
