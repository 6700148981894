import { makeStyles } from "@material-ui/core/styles";
import { DataGrid, esES, GridOverlay } from "@material-ui/data-grid"
import "moment/locale/es";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { styled } from '@mui/material/styles';
import { separarPorMiles } from "../../../commons/Formatos";
import { format } from "date-fns";
import { es, enUS } from "date-fns/locale";
import '../../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from "../../../commons/Idiomas";
import { formatearFechaISO, formatearFechaISOEnPalabras } from "../../../commons/FormatearFecha";

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: 'column',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <div style={{backgroundColor:"#00b7c4", color:"white", fontSize:"14.4px",padding:"10px 15px", borderRadius:"3px"}}>No hay datos</div>
    </StyledGridOverlay>
  );
}

var _ = require("lodash");

const useStyles2 = makeStyles((theme) => ({
  root: {
    padding: 0,
    "& .MuiDataGrid-colCell.MuiDataGrid-colCellSortable": {
      paddingLeft: "5px"
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none"
    },
    "& .MuiDataGrid-columnHeaderTitle":{
      fontWeight: "normal !important"
    },
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.type === "light" ? "#2f4554" : "#1d1d1d",
      color: "white",
    },
    "& .MuiDataGrid-row.Mui-selected":{
      backgroundColor:"#a9e1e5 !important"
    },
    "& .MuiDataGrid-cell:focus":{
      outline: " solid black 0px"
    },
    "& .MuiDataGrid-renderingZone": {
      "& .MuiDataGrid-row": {
        "&:nth-of-type(2n)": { 
          backgroundColor: "rgba(47, 69, 84, .07)"/* "rgba(169, 225, 229, .3)"  */
        },
        "&:hover": {backgroundColor: "rgb(0, 183, 196, 0.15)"}
      }
    }
  }
}));

export default function TablaVueltasGPS({data=[], cargando}) {
  let history = useHistory();
  const classes = useStyles2();
  const { t, i18n } = useTranslation();


  const columns = [
    { field: "id", headerName: "ID", width: 0, hide: true },
    { field: "id_vibot", headerName: "ID Vibot", width: 0, hide: true },
    { field: "maquina",headerName: "Maquina",width: 0, hide: true },
    {
        field: "fecha_inicio",
        headerName: "Fecha inicio",
        headerAlign: "center",
        align: "center",
        type: "number",
        minWidth: 100,
        flex: 0.5,
        renderCell: (params) => {
          if(params?.value && data.length > 0){
             //return formatearFechaISO(params.value)
            return formatearFechaISOEnPalabras(params.value)
          }
          else{
            return "-"
          }
  
        }
      },
    {
        field: "fecha_fin",
        headerName: "Fecha fin",
        headerAlign: "center",
        align: "center",
        type: "number",
        minWidth: 100,
        flex: 0.5,
        renderCell: (params) => {
          if(params?.value && data.length > 0){
             //return formatearFechaISO(params.value)
            return formatearFechaISOEnPalabras(params.value)
          }
          else{
            return "-"
          }
  
        }
      },
    {
      field: "geocerca_inicio",
      headerName: "Geocerca inicio",
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 105,
      flex: 0.4
    },
    {
      field: "geocerca_fin",
      headerName: "Gecerca fin",
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 105,
      flex:0.4
    },
    {
      field: "distancia_recorrida",
      headerName: `Distancia (km)`,
      headerAlign: "center",
      align: "center",
      type: "number",
      minWidth: 130,
      flex:0.4,
    },
    { field: "turno", headerName: "turno",width: 0, hide:true}
  ];

  return (
    <>
      <DataGrid
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        pageSize={15}
        pagination
        localeText={{...esES.props.MuiDataGrid.localeText, noRowsLabel: 'No hay datos'}}
        autoHeight
        isRowSelectable={false}
        className={classes.root}
        density={"compact"}
        rows={data}
        columns={columns}
        loading={cargando}
        disableColumnMenu={true}
      />
</>
  );
}
