function descendingComparator(a, b, orderBy, nullLast) {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (nullLast) {
    if (valueA === null && valueB !== null) return 1;
    if (valueB === null && valueA !== null) return -1;
  }

  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;
  return 0;
}

function ascendingComparator(a, b, orderBy, nullLast) {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (nullLast) {
    if (valueA === null && valueB !== null) return 1;
    if (valueB === null && valueA !== null) return -1;
  }

  if (valueA < valueB) return -1;
  if (valueA > valueB) return 1;
  return 0;
}

export function getComparator(order, orderBy, nullLastIds = []) {
  const nullLast = nullLastIds.includes(orderBy);
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, nullLast)
    : (a, b) => ascendingComparator(a, b, orderBy, nullLast);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const nullLastIds = ["tipo_ultima_ubicacion", "hora_ultima_ubicacion"]