import React from 'react'

import { GenericPopUp } from '../../../components/molecules/GenericPopUp';
import { Box, Button, Checkbox, Grid, InputLabel, Link, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next';

import HelpIcon from '@mui/icons-material/Help';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

export const PopUpConfirmarSolicitud = ({formik, show, onHandleShowPopUp,onHandleShowPopUpSuccess,handleErrorForm,text=""}) => {

  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
        <GenericPopUp maxwidth='md' show={show} handleShowPopUp={onHandleShowPopUp} showCancel={false} Children={
            <Grid container justifyContent='center' alignItems='center' direction='column' style={{paddingBottom:'50px'}}>
                <Grid container item justifyContent='center' alignItems='center' direction='column'>
                    <Grid style={{margin:'5px 5px'}}>
                      <HelpCenterIcon sx={{width:'42px',height:'42px'}}/>
                    </Grid>
                    <Box sx={{color:'#2f4554', fontWeight:'500' ,padding:'20px',textAlign:'center'}}>
                      <Typography
                          style={{fontWeight: "bolder",fontSize: "15px",color: "#2f4554", textAlign:'center'}}
                      >
                          {formik.values?.tipo_operacion == 1 ? "¿Está seguro/a de aprobar el ingreso del presente VICON?" :
                          formik.values?.tipo_operacion == 2 ? "¿Está seguro/a de aprobar la presente modificación?" : 
                          formik.values?.tipo_operacion == 3 ? "¿Está seguro/a de desactivar el presente registro?" : 
                          "¿Está seguro/a de cancelar la presente solicitud?" }
                      </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{color:'#2f4554', fontWeight:'500' ,padding:'45.5px 45px', backgroundColor:'#f3f4f7',textAlign:'center'}}>
                        <Grid container item xs={12} spacing={1}>

                            <Grid container item xs={6} spacing={1}>
                                <Grid container item xs={12} justifyContent='flex-start' alignItems='flex-start'>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Solicitante</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{formik.values.solicitante}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Sector de operación</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{formik.values.nombre_beacon_grupo}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Área asignada</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{formik.values.descripcion_tipo_beacon}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                                {
                                    formik.values?.tipo_operacion != 3 && formik.values?.tipo_operacion != 4 &&(
                                    <Grid container item xs={12}>
                                        <Grid item xs={6}>
                                            <InputLabel >
                                                <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Es frente</Typography>
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'left' }}>
                                        <Checkbox 
                                            checked = {formik.values.es_frente  ? true : false}
                                            style={{color : "#2f4554", width:'0px', height:'0px'}} 
                                            onChange={(e, checked) =>{
                                                if(checked){
                                                    formik.setFieldValue('es_frente',true)
                                                }else{
                                                    formik.setFieldValue('es_frente',false)
                                                }
                                            }}
                                            />                                    
                                        </Grid>
                                    </Grid>
                                    )
                                }
                            </Grid>
                            
                            <Grid container item xs={6} spacing={1}>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Minor</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{formik.values.minor}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Nombre Vicon</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{formik.values.descripcion}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Prioridad en operación</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{formik.values.orden}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                                {
                                    formik.values?.tipo_operacion != 3 && formik.values?.tipo_operacion != 4 &&(
                                    <Grid container item xs={12}>
                                        <Grid item xs={6}>
                                            <InputLabel >
                                                <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Es entrada</Typography>
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={6} style={{ textAlign: 'left' }}>
                                        <Checkbox 
                                            checked = {formik.values.entrada  ? true : false}
                                            style={{color : "#2f4554", width:'0px', height:'0px'}} 
                                            onChange={(e, checked) =>{
                                                if(checked){
                                                    formik.setFieldValue('entrada',true)
                                                }else{
                                                    formik.setFieldValue('entrada',false)
                                                }
                                            }}
                                            />                                    
                                        </Grid>
                                    </Grid>   
                                    )
                                }
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
                <Grid container direction='row' justifyContent='center' alignItems='center' spacing={5}>
                    <Grid item>
                        <Button
                                type="button"
                                variant="contained"
                                className={classes.root}
                                style={{textAlign:'center', marginTop:'35px', padding:'8px 20px'}}
                                onClick={() =>{
                                if( handleErrorForm() == true){
                                    handleErrorForm()
                                    }else{
                                    formik.submitForm();
                                    onHandleShowPopUp(false);
                                    onHandleShowPopUpSuccess(true);
                                }
                                }
                                }
                                >
                                {t("confirmar_cambios")}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Box sx={{display:'flex',justifyContent:'center', marginTop:'30px'}}>
                            <Link 
                            style={{cursor:'pointer'}} 
                            onClick={()=>{
                                onHandleShowPopUp(false);
                            }} 
                            underline='always' 
                            color='inherit'>{t('cancelar')}</Link>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        }/>
    </>
  )
}
