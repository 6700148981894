import React from "react";
import { ImageIcon, ImagePlusIcon, LoaderIcon, TrashIcon } from "./IconsPopover";
import { CustomButton, PreviewImageButton } from "./ButtonsPopover";
import { base64ToUrl } from "../../../commons/utiles";

export function ShiftImageSection({
  hasScreenshots,
  tab,
  turno,
  loading,
  handleTakeScreenshot,
  handleRemoveScreenshot,
  screenshots
}) {
  const url = screenshots[tab][turno][0] ? base64ToUrl(screenshots[tab][turno][0]) : null;

  return (
    <section
      style={{
        display: "flex",
        gap: "5px",
        flexDirection: "column",
        width: "198px"
      }}>
      <h4>Turno {turno}</h4>
      {!hasScreenshots(tab, turno) ? (
        <section
          style={{
            cursor: loading[tab][turno] ? "default" : "pointer",
            border: "1px solid #a5a5a5",
            borderRadius: "4px",
            padding: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          onClick={() =>
            handleTakeScreenshot("control-de-frentes-container", tab, turno)
          }>
          {loading[tab][turno] ? <LoaderIcon /> : <ImagePlusIcon />}
        </section>
      ) : (
        <section
          style={{
            cursor: "default",
            border: "1px solid #a5a5a5",
            borderRadius: "4px",
            padding: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
          <ImageIcon />
        </section>
      )}
      <section style={{ display: "flex", columnGap: "5px" }}>
        <PreviewImageButton url={url} />
        <CustomButton
          icon={<TrashIcon />}
          text="Eliminar"
          disabled={hasScreenshots(tab, turno)}
          onClick={() => handleRemoveScreenshot(tab, turno)}
        />
      </section>
    </section>
  );
}
