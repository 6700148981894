import React, { useState, useContext } from "react";
import { AccountContext } from "./Account";
import { Grid, TextField, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  datepicker: {
    width: "300px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },

    "& .MuiInputBase-root": {

      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main"
      }
    },

    '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
      /* '& fieldset': {            // - The <fieldset> inside the Input-root
          borderColor: 'pink',   // - Set the Input border
      }, */
      '&:hover fieldset': {
          borderColor: "#00B7C4", // - Set the Input border when parent has :hover
      }/* ,
      '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
          borderColor: 'green',
      }, */
  },

  }
});

export default () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const classes = useStyles();

  const { getSession } = useContext(AccountContext);
  const btnstyle = { marginTop:"25px", backgroundColor: "#2f4554", textTransform: "capitalize" };

  //console.log(getSession)

  const onSubmit = (event) => {
    event.preventDefault();

    getSession().then(({ user }) => {
      user.changePassword(password, newPassword, (err, result) => {
        if (err) {
          console.error(err);
        } else {
          console.log(result);
        }
      });
    });
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <TextField
              className={classes.datepicker}
              size="small"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Clave actual"
              type="password"
              required
            />
          </Grid>          
          <Grid item>
            <TextField
              className={classes.datepicker}
              size="small"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              placeholder="Nueva clave"
              type="password"
              required
            />
          </Grid>  
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              style={btnstyle}
              /* fullWidth */
              onClick={onSubmit}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>

        {/*         <label>Current password</label>
        <input
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <label>New password</label>
        <input
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />

        <button type="submit">Change password</button> */}
      </form>
    </div>
  );
};
