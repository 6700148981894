import { Box, Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, Link, MenuItem, TextField, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react'
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { SectionHeaderNoImage } from '../../../components/molecules/SectionHeaderNoImage';

import iconoEditar from '../../../assets/icons/icono-editar.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SectionHeader from '../../../components/molecules/SectionHeader';
import { useGetSectorYAreaoperacionVicon } from '../../../hooks/useGetSectorYAreaoperacionVicon';
import { useGetMinorsViconsByIdOperacion } from '../../../hooks/useGetMinorsViconsByIdOperacion';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { insertReglaVueltaGpsControlVersion, insertarVersionamientoVicon } from '../../../services/administracion';
import { decimalToHexadecimal } from '../../../commons/utiles';
import { PopUpDelete } from '../vicons/PopUpDelete';
import { PopUpSuccess } from '../../../components/organisms/PopUpSuccess';
import Pool from '../../../authentication/../UserPool';
import { useGetTiposDeGeocerca } from '../../../hooks/administracion/gps/useGetTiposDeGeocerca';
import { useGetAllTiposDeMaquinas } from '../../../hooks/general/useGetAllTiposDeMaquinas';
import { PopUpConfirmacionGenerico } from '../../../components/organisms/PopUpConfirmacionGenerico';
import { AlertaGenerica } from '../../../components/molecules/AlertaGenerica';


const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

  const initialStatePoUps = ({
    showPopUpSuccess:false,
    showPopUpDelete:false,
    showPopUpConfirmEdit:false
  });

export const CiclosGPSForm = ({data, id_area_operacion, isEditing, onHandleCloseForm, nombreMina, pathMina, formDataEdit, refForm}) => {
    const {loadingTiposGeocerca,tiposDeGeocerca} = useGetTiposDeGeocerca(id_area_operacion);
    const {loadingAllTiposDeMaquinas, dataAllTiposDeMaquinas} = useGetAllTiposDeMaquinas();
    const prioridadOperacion = Array.from({ length: 50 }, (_, index) => index + 1);
    const [estadoPopUps,setEstadoPopUps] = useState(initialStatePoUps);
    const [loadingVista, setLoadingVista] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        message: "",
        severity: "success",
      });
    const onHandleShowPopUpSuccess = (show) =>{
        setEstadoPopUps((prevState) => ({...prevState,showPopUpSuccess:show}))
      }
      const onHandleShowPopUpDelete = (show) =>{
        setEstadoPopUps((prevState) => ({...prevState,showPopUpDelete:show}))
      }

      const onHandleShowPopUpConfirmEdit = (show) =>{
          setEstadoPopUps((prevState) =>({...prevState,showPopUpConfirmEdit:show}))
          setAllFieldsTouched();
      }

      const setAllFieldsTouched = () => {
        Object.keys(formik.values).forEach(fieldName => {
          formik.setFieldTouched(fieldName, true);
        });
      };
      const onHandleRefFormClick = () =>{
        refForm.current?.scrollIntoView({ behavior: 'smooth' });
      }

    const initialStateForm = {
        id:0,
        id_area_operacion: id_area_operacion,
        nombre_mina: nombreMina,
        id_tipo_maquina:0,
        nombre_tipo_maquina:'',
        id_tipo_geocerca_inicio:0,
        nombre_tipo_geocerca_inicio:'',
        id_tipo_geocerca_fin:0,
        nombre_tipo_geocerca_fin:'',
        editing:false,
        solicitante:Pool.getCurrentUser().username,
        pathMina:pathMina
      }
      const validationSchema = Yup.object().shape({
        id_tipo_maquina:Yup.number()
          .test(
            "seleccionar_tipo_maquina",
            "El tipo de maquina es obligatorio",
            value => value !== undefined && value !== 0
          ),
        id_tipo_geocerca_inicio: Yup.number()
          .test(
            "seleccionar_tipo_geocerca_inicio",
            "El tipo de geocerca de inicio es obligatorio",
            value => value !== undefined && value !== 0
          ),
        id_tipo_geocerca_fin: Yup.number()
          .required('El tipo de geocerca de fin es obligatorio')
          .notOneOf([0], 'El tipo de geocerca de fin es obligatorio')
          .test(
            "verificar_tipo_geocerca_fin",
            `El ciclo con inicio, fin y tipo de maquina especificados ya se encuentra registrado`,
            function (value) {
              const { id_tipo_geocerca_inicio, id_tipo_maquina  } = this.parent;
              return !data.some(obj => obj.id_tipo_geocerca_fin === value && obj.id_tipo_geocerca_inicio == id_tipo_geocerca_inicio && obj.id_tipo_maquina ==id_tipo_maquina);
            }
          ),
      });
      
    const formik = useFormik({
    initialValues: initialStateForm,
    validationSchema:validationSchema,
    onSubmit: async (values) => {
        try {
        const {
          id,
          id_area_operacion,
          nombre_mina,
          id_tipo_maquina,
          nombre_tipo_maquina,
          id_tipo_geocerca_inicio,
          nombre_tipo_geocerca_inicio,
          id_tipo_geocerca_fin,
          nombre_tipo_geocerca_fin,
          solicitante,
          pathMina,
          editing
        } = values;

        await insertReglaVueltaGpsControlVersion(id,id_area_operacion,id_tipo_maquina,id_tipo_geocerca_inicio,id_tipo_geocerca_fin,solicitante,nombre_tipo_geocerca_inicio,nombre_tipo_geocerca_fin,editing,pathMina,nombre_tipo_maquina,nombre_mina);        
        } catch (error) {
            console.log(error)
        }
    },
    });

    const { t } = useTranslation();
    const classes = useStyles();

    const onHandleSetFormikValues = (formDataEdit) =>{
      const {id,id_tipo_geocerca_inicio,id_tipo_geocerca_fin,id_tipo_maquina,nombre_tipo_maquina,nombre_tipo_geocerca_fin,nombre_tipo_geocerca_inicio,editing} = formDataEdit;
      formik.setFieldValue("id",id ? id : initialStateForm.id);
      formik.setFieldValue("id_tipo_geocerca_inicio", id_tipo_geocerca_inicio ? id_tipo_geocerca_inicio : initialStateForm.id_tipo_geocerca_inicio)
      formik.setFieldValue("id_tipo_geocerca_fin",id_tipo_geocerca_fin ? id_tipo_geocerca_fin : initialStateForm.id_tipo_geocerca_fin);
      formik.setFieldValue("id_tipo_maquina",id_tipo_maquina ? id_tipo_maquina : initialStateForm.id_tipo_maquina);
      formik.setFieldValue("nombre_tipo_maquina",nombre_tipo_maquina ? nombre_tipo_maquina : initialStateForm.nombre_tipo_maquina);
      formik.setFieldValue("nombre_tipo_geocerca_fin",nombre_tipo_geocerca_fin ? nombre_tipo_geocerca_fin: initialStateForm.nombre_tipo_geocerca_fin);
      formik.setFieldValue("nombre_tipo_geocerca_inicio",nombre_tipo_geocerca_inicio ? nombre_tipo_geocerca_inicio : initialStateForm.nombre_tipo_geocerca_inicio);
      formik.setFieldValue("editing",editing ? editing : initialStateForm.editing);
      formik.setFieldValue("solicitante", Pool.getCurrentUser().username)
      formik.setFieldValue("id_tipo_operacion",2)
      setLoadingVista(false)
    }

      const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setAlert({ open: false, message: alert.message, severity: alert.severity });
      };

    useEffect(() => {
      setLoadingVista(true)
      formik.setValues({...formik.values})
      onHandleSetFormikValues(formDataEdit);
      setLoadingVista(false);
    }, [formDataEdit?.id])

    useEffect(() => {
      if(!loadingTiposGeocerca && !loadingVista){
        onHandleRefFormClick();
      }
    }, [loadingTiposGeocerca,loadingVista])
    
    

      const handleErrorForm = () => {

        if (!formik.isValid) {
          let msg = "";
          const claves = Object.keys(formik.errors);
          for (const clave of claves) {
            const valor = "* " + formik.errors[clave] + "?";
            msg += valor;
          }
          setAlert({
            open: true,
            message: msg,
            severity: "warning",
          });
          return true;
        }else{
            return false
        }
      };

      if(loadingTiposGeocerca || formik.isSubmitting || loadingVista){
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        )
    }

  return (
    <div ref={refForm} >
        <Grid style={{padding:'48px', backgroundColor:'#f3f4f7', border:'3px solid rgb(0, 183, 196)'}} container>
            <Grid container style={{marginBottom:'30px'}}>
                {
                    isEditing ?
                    (
                        <SectionHeader icon={<img src={iconoEditar} style={{maxWidth:'32px'}}/>} title={`${t('editar_ciclo')}`}/>
                    ) :
                    (
                        <SectionHeader icon={<AddCircleOutlineIcon fontSize='large'/>} title={`${t('agregar_nuevo_ciclo')}`} />
                    )
                }
            </Grid>
            <Grid container spacing={5} style={{marginBottom:'20px'}}>
                <Grid item container xs={6}>
                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center'>
                        <Grid item xs={6}>
                            <InputLabel >
                            <SectionHeaderNoImage title={t('minaSingular')}/>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="margin-none"
                                name="nombre_mina"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ marginTop: "15px" }}
                                fullWidth
                                variant='outlined'
                                hiddenLabel
                                size='small'
                                margin='none'
                                disabled
                                value={formik.values.nombre_mina}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center'>
                        <Grid item xs={6}>
                            <InputLabel >
                                <SectionHeaderNoImage title={t('inicio_ciclo')} subtitle={t("inicio_ciclo_subtitulo")}/>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6} style={{maxHeight:'40px',marginTop:'13px', width:'100%'}}>
                            <Select
                            name="id_tipo_geocerca_inicio"
                            onBlur={formik.handleBlur}
                            defaultValue={0}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.id_tipo_geocerca_inicio}
                            onChange={(e) =>{
                                const selectedOption = tiposDeGeocerca?.find((el) => el.id === e.target.value);
                                formik.setFieldValue("id_tipo_geocerca_inicio", e.target.value)
                                formik.setFieldValue("nombre_tipo_geocerca_inicio", selectedOption?.nombre || '')
                            }
                            }
                            style={{maxHeight:'40px', width:'100%'}}
                            >
                            <MenuItem key={0} value={0}>
                                {t("seleccione_opcion")}
                            </MenuItem>
                            {
                                tiposDeGeocerca?.map((el,index) => (
                                    <MenuItem value={el.id} key={el.id+"-"+index}>{el.nombre}</MenuItem>
                                ))
                            }   
                            </Select>
                        </Grid>
                    </Grid>

                </Grid>
                {/* SEGUNDA COLUMNA */}
                <Grid item container xs={6}>

                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center'>
                        <Grid item xs={6}>
                            <InputLabel >
                                <SectionHeaderNoImage title={t('tipo_maquina')} />
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6} style={{maxHeight:'40px',marginTop:'13px', width:'100%'}}>
                            <Select
                            name="orden"
                            onBlur={formik.handleBlur}
                            defaultValue={0}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.id_tipo_maquina}
                            onChange={(e) =>{
                                const selectedOption = dataAllTiposDeMaquinas?.find((el) => el.id === e.target.value);
                                formik.setFieldValue("id_tipo_maquina", e.target.value)
                                formik.setFieldValue("nombre_tipo_maquina", selectedOption?.nombre || '')
                            }
                            }
                            style={{maxHeight:'40px', width:'100%'}}
                            >
                            <MenuItem key={999} value={0}>
                                {t("seleccione_opcion")}
                            </MenuItem>
                            {
                                dataAllTiposDeMaquinas?.map((el) => (
                                    <MenuItem value={el.id} key={el.id}>{el.nombre}</MenuItem>
                                ))
                            }
                            </Select>
                        </Grid>
                    </Grid>

                    
                    <Grid item xs={12} container spacing={1} wrap='nowrap' alignItems='center'>
                        <Grid item xs={6}>
                            <InputLabel >
                                <SectionHeaderNoImage title={t('fin_ciclo')} subtitle={t("fin_ciclo_subtitulo")}/>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6} style={{maxHeight:'40px',marginTop:'13px', width:'100%'}}>
                            <Select
                            name="id_tipo_geocerca_fin"
                            onBlur={formik.handleBlur}
                            defaultValue={0}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.id_tipo_geocerca_fin}
                            onChange={(e) =>{
                                const selectedOption = tiposDeGeocerca?.find((el) => el.id === e.target.value);
                                formik.setFieldValue("id_tipo_geocerca_fin", e.target.value)
                                formik.setFieldValue("nombre_tipo_geocerca_fin", selectedOption?.nombre || '')
                            }
                            }
                            style={{maxHeight:'40px', width:'100%'}}
                            >
                            <MenuItem key={0} value={0}>
                                {t("seleccione_opcion")}
                            </MenuItem>
                            {
                                tiposDeGeocerca?.map((el,index) => (
                                    <MenuItem value={el.id} key={el.id+"-"+index}>{el.nombre}</MenuItem>
                                ))
                            }   
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>

            {/* FOOTER */}
            <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={1} style={{maxHeight:'41px'}}>
                <Grid item xs={9} container wrap='nowrap' justifyContent='flex-start' alignItems='flex-start'>
                    <Box sx={{backgroundColor:'rgb(0, 183, 196)'}}>
                            <InfoOutlinedIcon  fontSize='large' sx={{color:'white',padding:'5px'}}/>
                    </Box>
                    <Box sx={{color:'#666666' ,padding:'10.5px 25px', backgroundColor:'#eaedf1'}}>{t('recuerdo_aprobacion')}</Box>
                </Grid>
                <Grid item xs={3}>
                    <Box>
                        <Button
                            fullWidth
                            type="button"
                            variant="contained"
                            className={classes.root}
                            style={{textAlign:'center', marginBottom:'15px'}}
                            onClick={() => onHandleShowPopUpConfirmEdit(true)}
                            >
                            {isEditing ? t("guardar_cambios"): t("agregarr")}
                        </Button>
                    </Box>
                    <Box sx={{display:'flex',justifyContent:'center'}}>
                    <Link 
                    style={{cursor:'pointer'}} 
                    onClick={()=>{
                        onHandleCloseForm()
                        }} 
                    underline='always' 
                    color='inherit'>{t('cancelar')}</Link>
                    </Box>
                </Grid>
                </Grid>
        </Grid>

        {/* PopUp confirmEdit */}
        <PopUpConfirmacionGenerico
            editing={isEditing}
            formik={formik}
            onHandleShowPopUp={onHandleShowPopUpConfirmEdit}
            show={estadoPopUps.showPopUpConfirmEdit}
            onHandleShowPopUpSuccess={onHandleShowPopUpSuccess}
            handleErrorForm={handleErrorForm}
            dataColumnas={{
                columna1:[
                {
                    label:t("mina"),
                    value:formik.values.nombre_mina
                },
                {
                    label:t("tipo_geocerca_inicio"),
                    value:formik.values.nombre_tipo_geocerca_inicio
                }
            ],
                columna2:[
                {
                    label:t("tipo_maquina"),
                    value:formik.values.nombre_tipo_maquina
                },
                {
                    label:t("tipo_geocerca_fin"),
                    value:formik.values.nombre_tipo_geocerca_fin
                }
            ]
            }}
        />
        
        {/* PopUp Success */}
        <PopUpSuccess onHandleShowPopUp={onHandleShowPopUpSuccess} show={estadoPopUps.showPopUpSuccess} onHandleViewform={onHandleCloseForm} onHandleEditVicons={onHandleCloseForm} />


        {/* ALERTA DE VALIDACIONES */}
        <AlertaGenerica alert={alert} handleCloseAlert={handleCloseAlert} duration={5000} />
    </div>
  )
}
