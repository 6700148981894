import "date-fns";
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import "./styles.css";
import moment from "moment";
import makeStyles from "@material-ui/styles/makeStyles";
import {formatearFecha} from "../../../commons/FormatearFecha";

const localeMap = {
  es: esLocale,
};

const useStyles = makeStyles({
  root: {

    /* "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    }, */
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },

    "& .MuiInputBase-root": {
      padding: 0, 
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 10,
        paddingRight:10,
        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        //padding: 10,
        paddingLeft: 10,
        paddingRight:10,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: "#ffffff",
        borderColor: "error.main"
      }
    }
  }
});

export default function MaterialUIPickers(props) {
  const [selectedDate, setSelectedDate] = React.useState(
    moment(props.fecha)
      .add(1, "days")
      .utc()
      .format("YYYY-MM-DD")
  );

  const [locale, setLocale] = useState("es");
  const classes = useStyles();
  const handleDateChange = (date) => {
    setSelectedDate(date);
    
    const newDate = formatearFecha(date);
      /* date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate(); */
    props.setfecha(newDate);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <Grid container justifyContent="space-around">
        <KeyboardDatePicker
          className={classes.root}
          margin="small"
          id="date-picker-dialog"
          //label={props.leyenda}
          inputVariant="outlined"
          format="yyyy-MM-dd"
          value={selectedDate}
          onChange={handleDateChange}
          cancelLabel="Cancelar"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
