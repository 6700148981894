import { useEffect, useState } from "react";
import { getAllAreaOperacion } from "../../services/general";

export const useGetallAreaOperacion = (activo, formatToSelectInput=false) => {
  const [allAreaOperacion, setAllAreaOperacion] = useState([]);
  const [loadingAreaOperacion, setloadingAreaOperacion] = useState(false);
    
  useEffect(() => {
        getAllAreaOperacion(activo)
          .then((res) => {
            setloadingAreaOperacion(true);
              if(formatToSelectInput){
                  setAllAreaOperacion(formatGetAllAreaOperacion(res.data));
                  setloadingAreaOperacion(false);
            }else{
                setAllAreaOperacion(res.data);
                setloadingAreaOperacion(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setloadingAreaOperacion(false);
          });
    }, []);

function formatGetAllAreaOperacion(data=[]){
    if(data.length > 0){
        let resp = data.map((dato) =>{
            return  {id:dato.id, nombre:dato.nombre,value:dato.id}
        })
        return resp;
    }
    return [];
}


  return { loadingAreaOperacion, allAreaOperacion };
};
