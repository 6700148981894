import React, { useEffect, useState} from "react";
import {Card, CardContent, CardMedia} from "@mui/material";
import NavBar from "../../components/organisms/NavBar.js";
import { Container, Typography, Link, Button, CircularProgress} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import BarTimelineChartVacio from "../Produccion/BarTimelineChartVacio";
import MotionChart from "./MotionChart";
import imgCamion from "../Produccion/camion.png";
import imgCamionDumper from "../Produccion/camion-dumper.png";
import ImagenJumbo from "../../assets/images/jumbo.png";
import SectionHeader from "../../components/molecules/SectionHeader.js";
import IconoKpi from '../../assets/icons/icono-kpi.png';
import IconoResumenDiario from '../../assets/icons/icono-resumen-diario.png';
import IconoDetalle from '../../assets/icons/icono-detalle.png';
import { sortByDate} from '../../commons/Filtros.js'
import TablaV5 from "./Tablav5";

import { getVueltasMaquinaRango, getEncuentrosMovMaquina, getMovimientosPorMaquina } from "../../services/diario";
import { getInfoGeneralMina, getTiemposAsarco } from "../../services/general";
import HorizontalTimeChart from "../Operaciones/HorizontalTimeChart";
import { Redirect, useLocation } from "react-router";
import { format } from "date-fns";

import {MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import '../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from "../../commons/Idiomas";
import { restarDias, sumarDias } from "../../commons/FormatearFecha";

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { getInfoJumbo } from "../../services/operaciones";

import ResumenDiarioCard1 from "../Operaciones/JumboView/ResumenCard1";

import { getUltimaUbicacionJustificacion } from "../../services/justificacion";
import { PopupRegistroJustificacion } from "../Justificacion/PopupRegistroJustificacion";
import { useGetGruposMotivosJustificaciones } from "../../hooks/useGetGruposMotivosJustificaciones";
import { MapaGPS } from "../MapaGPS";
import { useGetEncuentroGPS } from "../../hooks/useGetEncuentroGPS";
import { GeneralMinaContext } from "../../context/GeneralMina";
import { useContext } from "react";
import { TiempoAsarcoGenerico } from "../../components/organisms/TiempoAsarcoGenerico.js";
import { MultipleSimpleIndicator } from "../../components/organisms/MultipleSimpleIndicator.js";
import { useGetInfoVueltasGPS } from "../../hooks/operacionesMaquinas/Rajo/useGetInfoVueltasGPS.js";
import { useGetMovimientosGPS } from "../../hooks/useGetMovimientosGPS.js";
import { ConvertMinutosToHoras } from "../../commons/FormatearTiempo.js";
import { LeyendasMovimientos } from "../../components/molecules/LeyendasMovimientos.js";
import { BreadCrumbsGenerico } from "../../components/molecules/BreadCrumbsGenerico.js";
import { usePageTimingContext } from "../../context/PageTimingContext.js";
import { AlertaGenerica } from "../../components/molecules/AlertaGenerica.js";

const useStyles = makeStyles({
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"160px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  root: {
    backgroundColor: "#2f4554",
    margin: "8px 0",
    color: "white",
    "&:hover": {
      backgroundColor: "#253642",
    },
    "&:disabled": {
      backgroundColor: "gray",
    },
  },
  buttonUnselected: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"160px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  prodDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  },
  prodValue:{
    fontSize: "20px",
    fontWeight:"bold"
  },
  bordermd:{
    '@media (min-width:1200px)': {
      borderRight:"2px solid #d7d7d7"
    }
  },
  marginmd:{
    '@media (min-width:1200px)': {
      marginLeft:"10px"
    }
  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        /* fontSize:"14.4px" */
      }
    },

    '& .MuiOutlinedInput-root': { 
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
    },
  }
});

export const OperacionesMaquinaJumbos = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { sendTime } = usePageTimingContext();
  const localDatosMaquina = localStorage.getItem("dataOpMaq");
  const localDatosMina = localStorage.getItem("dataOpMina");
  const localFecha = localStorage.getItem("fechaObjetivo");

  const turnoTodos = {
    nombre: "turnotodos",
    hora_fin: "23:59:59",
    hora_inicio: "00:00:00",
    id: 0,
    id_mina: 0,
    id_tipo_turno: 0,
    orden: 0
  }
  const datosMaqVacios = {
    nombre: "",
    modelo: "",
    tipo: "",
    contadorVueltas: 0,
    contadorHoras: 0,
    vueltas: [],
    horometros: [],
    fecha: "2020-05-05"
  }
  const [tiemposAsarcoPorTurno, setTiemposAsarcoPorTurno] = useState({
    "nominal": 0,
    "disponible": 0,
    "indisponible": 0,
    "operativo": 0,
    "reserva": 0,
    "efectivo": 0,
    "perdida_operacional":0,
    "ralenti":0
  });
  const [tiemposAsarco, setTiemposAsarco] = useState({});
  const [fechaActual, setFechaActual] = useState( new Date(localFecha) || new Date());
  const [seriesMovilizacionMaquina, setSeriesMovilizacionMaquina] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMovimientos, setLoadingMovimientos] = useState(true);
  const [datosResumenDiario, setDatosResumenDiario] = useState({});
  const [datosProdTotal, setDatosProdTotal] = useState({});
  const [prodBrazosTotal, setProdBrazosTotal] = useState({horas:0,metros:0});
  const [estados, setEstados] = useState({});
  const [horometroJumbo, setHorometroJumbo] = useState();
  const [loadHorizontalChart, setLoadHorizontalChart] = useState(true)
  const [reloadCharts, setReloadCharts] = useState(0);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [loadingPopUp, setLoadingPopUp] = useState(false);
  const [rutaMina, setRutaMina] = useState(JSON.parse(localStorage.getItem("dataOpMina")).rutaMina);
  const [idMina, setIdMina] = useState(0);
  const [nombreMina, setNombreMina] = useState("");
  const [imagenCamion, setImagenCamion] = useState(imgCamion);
  const [listaTurnos, setListaTurnos] = useState([]);
  const [controllerActivo, setControllerActivo] = useState(null);
  const [turno, setTurno] = useState(turnoTodos);
  const [datosMaquina, setDatosMaquina] = useState(datosMaqVacios);
  const [TirosYvoladuras, setTirosYvoladuras] = useState({Dia:{voladuras:0,tiros:0},Noche:{voladuras:0,tiros:0},turnotodos:{voladuras:0,tiros:0}})
  const [seriesMovilizacionPorTurno, setSeriesMovilizacionPorTurno] = useState([]);
  const [kpiProd, setKpiProd] = useState({vueltas:{},tonelaje:{},rendimiento:{},horas:{}});
  const [portalesRows,setPortalesRows] = useState([]);
  const [configColumnas, setConfigColumnas] = useState([]);
  const [seriesEstados, setSeriesEstados] = useState([]);
  const {gruposJustificaciones,loadingGrupoJustificaciones} = useGetGruposMotivosJustificaciones(JSON.parse(localDatosMina).idMina);
  const { dataVueltasGPS,loadingInfoVueltasGPS } = useGetInfoVueltasGPS(format(fechaActual, "yyyy-MM-dd"),JSON.parse(localDatosMaquina).id);
  const {loadingEncuentrosGPS, EncuentrosGPS } = useGetEncuentroGPS(JSON.parse(localDatosMina).idMina,JSON.parse(localDatosMaquina).id,format(fechaActual, "yyyy-MM-dd"));
  const { loadingMovimientosGPS, movimientosGPS } = useGetMovimientosGPS(JSON.parse(localDatosMina).idMina, JSON.parse(localDatosMaquina).id,format(fechaActual, "yyyy-MM-dd"));
  const [loadingKpiProduccionDiaria, setLoadingKpiProduccionDiaria] = useState(false);
  const {onHandleGetGeneralData, onHandleSetDataGeneral} = useContext(GeneralMinaContext);

  const procesarAsarco = (tiempos, turnoSelec) => {
    // solucionar cuando llega objeto vacio
    const auxAsarco = {
      nominal: 0,
      disponible: 0,
      indisponible: 0,
      operativo: 0,
      reserva: 0,
      efectivo: 0,
      perdida_operacional:0,
      ralenti:0
    }

    if(Object.values(tiempos).length>0){
      if(turnoSelec === turnoTodos){
        Object.values(tiempos).forEach( t => {
          auxAsarco["nominal"]              += t["nominal"]?t["nominal"]:0
          auxAsarco["disponible"]           += t["disponible"]?t["disponible"]:0
          auxAsarco["indisponible"]         += t["indisponible"]?t["indisponible"]:0
          auxAsarco["operativo"]            += t["operativo"]?t["operativo"]:0
          auxAsarco["reserva"]              += t["reserva"]?t["reserva"]:0
          auxAsarco["efectivo"]             += t["efectivo"]?t["efectivo"]:0
          auxAsarco["perdida_operacional"]  += t["perdida_operacional"]?t["perdida_operacional"]:0
          auxAsarco["ralenti"]              +=  t["ralenti"]?t["ralenti"]:0
        })
      }
      else{
        const asarcoPorTurno = tiempos[turnoSelec.nombre]
        auxAsarco["nominal"]              += asarcoPorTurno["nominal"]?asarcoPorTurno["nominal"]:0
        auxAsarco["disponible"]           += asarcoPorTurno["disponible"]?asarcoPorTurno["disponible"]:0
        auxAsarco["indisponible"]         += asarcoPorTurno["indisponible"]?asarcoPorTurno["indisponible"]:0
        auxAsarco["operativo"]            += asarcoPorTurno["operativo"]?asarcoPorTurno["operativo"]:0
        auxAsarco["reserva"]              += asarcoPorTurno["reserva"]?asarcoPorTurno["reserva"]:0
        auxAsarco["efectivo"]             += asarcoPorTurno["efectivo"]?asarcoPorTurno["efectivo"]:0
        auxAsarco["perdida_operacional"]  += asarcoPorTurno["perdida_operacional"]?asarcoPorTurno["perdida_operacional"]:0
        auxAsarco["ralenti"]              += asarcoPorTurno["ralenti"]?asarcoPorTurno["ralenti"]:0
      }
      setTiemposAsarcoPorTurno(auxAsarco)
    }
  } 

  const getAsarco = (idMina, idMaq, inicio, fin, t) => {
    const resAsarco = getTiemposAsarco(idMina, idMaq/* 12 */, inicio, fin)
    resAsarco.then(({success,data}) => { 
      if(success){
        setTiemposAsarco(data[inicio]||{})
        procesarAsarco(data[inicio]||{}, t)
      } 
    })
  }

  const defaultConfigHeadCols = {
    vueltas: [{ field: 'vueltasTotal' }],
    tonelaje: [{ field: 'tonelajeTotal' }],
    tiempo: [{ field: 'tiempoTotal' }],
    rendimiento: [{ field: 'rendimientoTotal'}],
    cargadorDumper:[]
  }
  const [configHeadCols, setConfigHeadCols] = useState(defaultConfigHeadCols);
  const [limites, setLimites] = useState({min: undefined, max: undefined});
  const classes = useStyles();

  const generaColumnas = (turnos) => {
    const cols = [
    ]

    const baseColumna = {
      field: "",
      headerName: "",
      headerClassName:"cell-font",
      align: "center",
      headerAlign: 'center',
      valueFormatter: params => params?.value.toFixed(0),       
      flex:1
    }

    const tipos = [ 
      {des:'vueltas',cifras:false},
      {des:'tonelaje',cifras:false},
      {des:'tiempo',cifras:true},
      {des:'rendimiento',cifras:true}
    ]

    const headCols = {...defaultConfigHeadCols}
    
    tipos.forEach( tipo => {
      turnos.forEach( turno => {
        const fields = [...headCols[tipo.des]]
        fields.push({field:`${tipo.des}Turno${turno.nombre}`})
        headCols[tipo.des] = fields
        cols.push({
          ...baseColumna,
          field: `${tipo.des}Turno${turno.nombre}`,
          headerName: `turno_${turno.nombre.toLowerCase()}`,
          valueFormatter: params => tipo.cifras?(Math.round((params?.value)*10)/10).toLocaleString('de-DE'):(Math.round((params?.value))).toLocaleString('de-DE'),
        })
      })
      cols.push({
        ...baseColumna,
        field: `${tipo.des}Total`,
        headerName: "Total",
        headerClassName:tipo.des!=="rendimiento"?"border-right-header cell-font":"cell-font",
        cellClassName: "cell-total",
        valueFormatter: params => tipo.cifras?(Math.round((params?.value)*10)/10).toLocaleString('de-DE'):(Math.round((params?.value))).toLocaleString('de-DE'),
      })
    })

    setConfigHeadCols(headCols)
    setConfigColumnas(cols)

  }

  const [stateInicialFormJustificaciones, setstateInicialFormJustificaciones] = useState({
    fecha_inicio:'',
    fecha_termino:'',
    id_movimiento:0,
    id_registro_movimiento:0,
    id_maquina:JSON.parse(localDatosMaquina).id,
    nombre_motivo:'',
    ubicacion:''
   })


  const handleClickOpen = (id_movimiento, fecha_inicio, fecha_termino, id_t_registro_movimiento) => {
    setOpen(true);
    setLoadingPopUp(true);

    setstateInicialFormJustificaciones(prevState =>{
      return {
        ...prevState,
        fecha_inicio,
        fecha_termino,
        id_movimiento,
        id_t_registro_movimiento,
        id_maquina:JSON.parse(localDatosMaquina).id
      }
    });

    getUltimaUbicacionJustificacion(idMina, id_t_registro_movimiento)
    .then((resp) => {
      setstateInicialFormJustificaciones(prevState =>{
        return {
          ...prevState,
          ubicacion: resp.data.nombre
        }
      })
      setLoadingPopUp(false);
    })
    .catch((err) => {
      console.log(err);
      setLoadingPopUp(false);
    });

  };

  const procesarVueltasMaquina = (datosDelDia, turnos, portales, fecha,idMina) => {

    const dataRows = {
      vueltasTotal: 0,
      tonelajeTotal: 0,
      tiempoTotal: 0,
      rendimientoTotal: 0
    }

    turnos.forEach( t => {
      dataRows[`vueltasTurno${t.nombre}`] = 0
      dataRows[`tonelajeTurno${t.nombre}`] = 0
      dataRows[`tiempoTurno${t.nombre}`] = 0
      dataRows[`rendimientoTurno${t.nombre}`] = 0
    })

    const dataRowsWithKeys= portales.reduce((obj, item) => ((obj[item] = {id:`id${item}`,nombre:item,...dataRows}, obj)),{});

    getVueltasMaquinaRango(idMina,datosDelDia.id,format(fecha, "yyyy-MM-dd"),format(fecha, "yyyy-MM-dd")).then(({success, data}) => {
  
      if(success){
        /* RELLENAR FILAS TABLA */
        data.forEach( v => {
          // Turnos
          dataRowsWithKeys[v.ubicacion][`vueltasTurno${v.turno}`] += 1
          dataRowsWithKeys[v.ubicacion][`vueltasTotal`] += 1
          // Toneladas
          dataRowsWithKeys[v.ubicacion][`tonelajeTurno${v.turno}`] += Number(datosDelDia.toneladas)
          dataRowsWithKeys[v.ubicacion][`tonelajeTotal`] += Number(datosDelDia.toneladas)
          // Tiempo efectivo
          dataRowsWithKeys[v.ubicacion][`tiempoTurno${v.turno}`] += v.duracion_minutos/60
          dataRowsWithKeys[v.ubicacion][`tiempoTotal`] += v.duracion_minutos/60
        })
        // Rendimiento
        portales.forEach( p => {
          const auxTon = dataRowsWithKeys[p][`tonelajeTotal`]
          const auxTime = ((dataRowsWithKeys[p][`tiempoTotal`]!==0)?dataRowsWithKeys[p][`tiempoTotal`]:1)
          dataRowsWithKeys[p][`rendimientoTotal`] = auxTon / auxTime
          turnos.forEach( t => {
            dataRowsWithKeys[p][`rendimientoTurno${t.nombre}`] = 
              dataRowsWithKeys[p][`tonelajeTurno${t.nombre}`] / 
              ((dataRowsWithKeys[p][`tiempoTurno${t.nombre}`]!==0)?dataRowsWithKeys[p][`tiempoTurno${t.nombre}`]:1)
          })
        })

        setPortalesRows(Object.values(dataRowsWithKeys))

        /* FIN RELLENAR FILAS TABLA */

        /* RELLENAR KPIS */

        // Crea objeto con nombre de cada turno como key y valor 0
        let objTurnos = turnos.reduce((obj, item) => ((obj[item.nombre] = 0, obj)),{})  
        // Agrega a objeto el turnotodos
        objTurnos["turnotodos"] = 0;
        // Crea objeto con kpi como key y como valor los turnos
        let objKpis = {vueltas:{...objTurnos},tonelaje:{...objTurnos},rendimiento:{...objTurnos}, horas:{...objTurnos}}
        // Para acumular el tiempo efectivo de todos los turnos
        data.forEach((vuelta) => {
          if(vuelta.turno){
            objKpis["vueltas"][vuelta.turno] += 1
            objKpis["tonelaje"][vuelta.turno] += datosDelDia.toneladas
            objKpis["horas"][vuelta.turno] += vuelta.duracion_minutos/60
          }
          objKpis["vueltas"]["turnotodos"] += 1
          objKpis["tonelaje"]["turnotodos"] += datosDelDia.toneladas
          objKpis["horas"]["turnotodos"] += vuelta.duracion_minutos/60
        })
        objKpis["rendimiento"]["turnotodos"] += objKpis["tonelaje"]["turnotodos"] / objKpis["horas"]["turnotodos"];
        turnos.forEach( t => {
          objKpis["rendimiento"][t.nombre] += (objKpis["tonelaje"][t.nombre] / objKpis["horas"][t.nombre]) || 0;
        })
        
        setKpiProd(objKpis)
        /* FIN RELLENAR KPIS */

      }
    })
  } 


  const getDataSeriesEstados = (datosEstados) => {
    const dataOperativos = [];
    const dataMantencion = [];
    const dataFueradeServ = [];
    const dataRalenti = [];
    const dataSinInfo = [];
    const dataJustificada =[];

    // Se itera por cada maquina
    Object.entries(datosEstados).forEach(([key,value]) => {
      // Ordena valores por fecha
      const sortedValues  = sortByDate(value.horometro,"fin");

      // Horarios de turno con su fecha tipo Date
      const minmax = JSON.parse(localStorage.getItem('estadosJumbo')).minMax;
      const fechaInicioTurno = new Date(minmax.min)
      const fechaFinTurno = new Date(minmax.max)

      // Ultima hora para analizar estados sin info
      let ultimaHoraSinInfo = new Date(minmax.min);
      sortedValues.forEach(estado => {
        const data = {
          x: key,
          y:[
            (new Date(estado.inicio)).getTime(),
            (new Date(estado.fin)).getTime()
          ],
          id_t_registro_movimiento:estado?.id_t_registro_movimiento || undefined,
          id_tipo_movimiento:estado?.id_tipo_movimiento || undefined
        }
      if(!estado?.tipo_justificacion){
        //EXCEPCION MEDIALUNA
        if(JSON.parse(localDatosMina).idMina !=21){
          switch(estado.tipo){
            case "operativo":
              dataOperativos.push(data);
              break;
            case "mantencion":
              dataMantencion.push(data);
              break;
            case "ralenti":
              dataRalenti.push(data);
              break;
            case "fuera de servicio":
              dataFueradeServ.push(data);
              break;
            default:
              break
          }
        }else{
          switch(estado.tipo){
            case "operativo":
              dataOperativos.push(data);
              break;
            case "mantencion":
              dataMantencion.push(data);
              break;
            case "ralenti":
              dataOperativos.push(data);
              break;
            case "fuera de servicio":
              dataFueradeServ.push(data);
              break;
            default:
              break
          }
        }
        }else if(estado?.tipo_justificacion){
          (estado.tipo_justificacion.toLowerCase() == "otro") 
           ? dataJustificada.push({...data,tipo_justificacion:estado?.observacion}) 
           : dataJustificada.push({...data,tipo_justificacion:estado?.tipo_justificacion});
       }

        /*
        Si hay una diferencia de tiempo entre la hora final del 
        estado anterior y la inicial del actual, agrega estado sin info 
        */
        if(((new Date(estado.inicio)).getTime() - ultimaHoraSinInfo.getTime())/1000>1){
          dataSinInfo.push({
            x: key,
            y:[
              ultimaHoraSinInfo.getTime(),
              (new Date(estado.inicio)).getTime(),
            ]
          })
        }

        ultimaHoraSinInfo = new Date(estado.fin)

      })

      /* 
      Si la maquina no tiene estados, agrega estado sininfo con el largo del inicio y fin de turno
      Si tiene datos, comprueba si hay un valle de tiempo entre el fin de ultimo estado y el fin del turno y agrega un estado sininfo
      */
      if(sortedValues.length===0){
        dataSinInfo.push({
          x: key,
          y:[
            fechaInicioTurno.getTime(),
            fechaFinTurno.getTime()
          ]
        })
      }
      else{
        const finEstadoFinal = new Date(sortedValues[sortedValues.length-1].fin)
        if((fechaFinTurno.getTime() - finEstadoFinal.getTime())/1000>1){
          dataSinInfo.push({
            x: key,
            y:[
              finEstadoFinal.getTime(),
              fechaFinTurno.getTime(),
            ]
          })
        }
      }
    });


    return([
      {
        name: 'operativo',
        color: "#01b7c4",
        data: dataOperativos
      },
      {
        name: 'en_mantencion',
        color: "#ffbf00",
        data: dataMantencion
      },
      {
        name: 'fuera_de_servicio',
        color: "#c5283d",
        data: dataFueradeServ
      },
      {
        name: 'sin_info',
        color:"#d6dce4",
        data: dataSinInfo
      },
      {
        name:'ralenti',
        color: "#e9724c",
        data:dataRalenti
      },
      {
        name:'justificado',
        color:'#28B463',
        data:dataJustificada
      }
    ])

  }

  

  const procesarEstado = (datosEstados,minMax) => {
    let tipoTurno = JSON.parse(localStorage.getItem('estadosJumbo')).tipoTurno
     setHorometroJumbo({...datosEstados,minMax:minMax,tipoTurno:tipoTurno});
     return {...datosEstados,minMax:minMax,tipoTurno:tipoTurno};
  }

  const  procesarMovimientosMaquina = (idMina,minMax,fecha,controller,idMaquina) => {
    // const resEstadosPromise = getMovimientosDia(idMina,fecha,controller);
    const resEstadosPromise = getMovimientosPorMaquina(idMina, idMaquina, fecha, fecha);
    const restInfoJumboPromise = getInfoJumbo(idMina, JSON.parse(localDatosMaquina).id, fecha, controller)
    setLoadingKpiProduccionDiaria(true);
    
    Promise.all([resEstadosPromise, restInfoJumboPromise])
    .then(([resEstados, restInfoJumbo]) => {
      if (resEstados.success && restInfoJumbo.success && restInfoJumbo.data && resEstados.data[fecha]) {
        localStorage.setItem('estadosJumbo',  JSON.stringify(procesarEstado(resEstados.data, minMax)));
        getInfo(idMina, procesarEstado(resEstados.data[fecha], minMax), restInfoJumbo.data); // Pasa ambos resultados a getInfo
        // setLoadHorizontalChart(false);
      } else {
        setLoadingMovimientos(false);
      }
    })
    .catch(error => {
      console.error("Error al realizar las peticiones:", error);
      setLoadingMovimientos(false);
    });
    // controller.abort()
  }

  const getMinMaxTurnos = (turnos) => {
    const sortedTurnos =  turnos.sort(function(a, b) { 
      return a.orden - b.orden;
    });

    return {min: new Date(sortedTurnos[0].inicio), max: new Date(sortedTurnos[turnos.length-1].fin)}
  }

    const getInfo = async (idArea,horometroJumboAux2,restInfoJumbo) => {
    let nombreMaquina = JSON.parse(localStorage.getItem('dataOpMaq')).nombre
    let tipoTurno = JSON.parse(localStorage.getItem('estadosJumbo')).tipoTurno
      let contadorMetros = 0
      let contadorHoras = 0
      const objResumenDiario = {turnotodos:{metros:0,horas:0,horasMotor:0}};
      const objProduccion = {}
      const dataHorometro = {}
      dataHorometro["turnotodos"] = {}

      const newTirosYvoladuras = {
        turnotodos:{
            voladuras:0,
            tiros:0
        },
        Dia: {
          voladuras: 0,
          tiros: 0
        },
        Noche: {
          voladuras: 0,
          tiros: 0
        }
      };

      Object.keys(restInfoJumbo.Dia).forEach((brazosDia)=>{
        newTirosYvoladuras.Dia.voladuras += restInfoJumbo.Dia[brazosDia].voladuras;
        newTirosYvoladuras.Dia.tiros += restInfoJumbo.Dia[brazosDia].tiros;
        newTirosYvoladuras.turnotodos.voladuras += restInfoJumbo.Dia[brazosDia].voladuras;
        newTirosYvoladuras.turnotodos.tiros += restInfoJumbo.Dia[brazosDia].tiros;
    });

      Object.keys(restInfoJumbo.Noche).forEach((brazoNoche)=>{
        newTirosYvoladuras.Noche.voladuras += restInfoJumbo.Noche[brazoNoche].voladuras;
        newTirosYvoladuras.Noche.tiros += restInfoJumbo.Noche[brazoNoche].tiros;
        newTirosYvoladuras.turnotodos.voladuras += restInfoJumbo.Noche[brazoNoche].voladuras;
        newTirosYvoladuras.turnotodos.tiros += restInfoJumbo.Noche[brazoNoche].tiros;
    })
    setTirosYvoladuras(newTirosYvoladuras);


      Object.entries(restInfoJumbo).forEach(([keyTurno,valueTurno])=>{
        let brazoTipo = (JSON.parse(localStorage.getItem('dataOpMaq')).tipo == "Bolter"  &&  JSON.parse(localStorage.getItem("dataOpMina")).idMina == 16)? "_compresor" : "_percutor"
        objResumenDiario[keyTurno] = {metros:0,horas:0,horasMotor:0}
        dataHorometro[keyTurno] = {}
        //TEMPORAL OCULTAR PRIMER HOROMETRO EN BURITICA
        if(idArea){

          let nombreHorometro = JSON.parse(localStorage.getItem('dataOpMaq')).tipo == "Bolter" ? "horometro_bolter" : "horometro_jumbo"
          
          let horometroJumboAux = horometroJumboAux2
          //Procesa estados horometro api de movimientos
          dataHorometro[keyTurno][nombreHorometro] = {horometro: horometroJumboAux[keyTurno]}
          if(!(nombreHorometro in dataHorometro["turnotodos"])) dataHorometro["turnotodos"][nombreHorometro] = {horometro: []}
          dataHorometro["turnotodos"][nombreHorometro]["horometro"] = [...dataHorometro["turnotodos"][nombreHorometro]["horometro"], ...horometroJumboAux[keyTurno]]
        }
        
        Object.entries(valueTurno).forEach(([keyBrazo,valueBrazo])=>{

          const keyName = `${keyBrazo.replace(" ","").toLowerCase()}`
          
          if(!(`${keyName}_motor` in dataHorometro["turnotodos"])) dataHorometro["turnotodos"][`${keyName}_motor`]={horometro:[]};
          dataHorometro["turnotodos"][`${keyName}_motor`]["horometro"] = [...dataHorometro["turnotodos"][`${keyName}_motor`]["horometro"],...JSON.parse(JSON.stringify(restInfoJumbo))[keyTurno][`${keyBrazo}`]["horometro"]["motor"]]
          
          if(!(`${keyName}${brazoTipo}` in dataHorometro["turnotodos"])) dataHorometro["turnotodos"][`${keyName}${brazoTipo}`]={horometro:[]};
          dataHorometro["turnotodos"][`${keyName}${brazoTipo}`]["horometro"] = [...dataHorometro["turnotodos"][`${keyName}${brazoTipo}`]["horometro"],...JSON.parse(JSON.stringify(restInfoJumbo))[keyTurno][`${keyBrazo}`]["horometro"]["percutor"]]

          if(!(`${keyName}_motor` in dataHorometro[keyTurno])) dataHorometro[keyTurno][`${keyName}_motor`]={horometro:[]};
          dataHorometro[keyTurno][`${keyName}_motor`]["horometro"] = [...dataHorometro[keyTurno][`${keyName}_motor`]["horometro"],...JSON.parse(JSON.stringify(restInfoJumbo))[keyTurno][`${keyBrazo}`]["horometro"]["motor"]]
          if(!(`${keyName}${brazoTipo}` in dataHorometro[keyTurno])) dataHorometro[keyTurno][`${keyName}${brazoTipo}`]={horometro:[]};
          dataHorometro[keyTurno][`${keyName}${brazoTipo}`]["horometro"] = [...dataHorometro[keyTurno][`${keyName}${brazoTipo}`]["horometro"],...JSON.parse(JSON.stringify(restInfoJumbo))[keyTurno][`${keyBrazo}`]["horometro"]["percutor"]]


          // Datos resumen diario
          objResumenDiario[keyTurno]["metros"]          += valueBrazo["metros"]["percutor"]
          objResumenDiario["turnotodos"]["metros"]      += valueBrazo["metros"]["percutor"]
          objResumenDiario[keyTurno]["horas"]           += valueBrazo["horas"]["percutor"]
          objResumenDiario["turnotodos"]["horas"]       += valueBrazo["horas"]["percutor"]
          objResumenDiario[keyTurno]["horasMotor"]      += valueBrazo["horas"]["motor"]
          objResumenDiario["turnotodos"]["horasMotor"]  += valueBrazo["horas"]["motor"]

          // Horas y Metros totales
          contadorHoras+=valueBrazo["horas"]["percutor"]
          contadorMetros+=valueBrazo["metros"]["percutor"]

          // Datos produccion total
          if(!(keyBrazo in objProduccion)){
            objProduccion[keyBrazo]={turnotodos:{realizados:0,programados:300}}
          }
          if(!(keyTurno in objProduccion[keyBrazo])){
            objProduccion[keyBrazo][keyTurno]={realizados:0,programados:300}
          }
          objProduccion[keyBrazo][keyTurno]["realizados"]+=valueBrazo["metros"]["percutor"]
          objProduccion[keyBrazo]["turnotodos"]["realizados"]+=valueBrazo["metros"]["percutor"]


        })
      })  
      setDatosResumenDiario(objResumenDiario);
      setDatosProdTotal(objProduccion)

      setProdBrazosTotal({horas:contadorHoras,metros:contadorMetros})
      setEstados(dataHorometro);

      if(dataHorometro[tipoTurno]){
        setSeriesEstados(getDataSeriesEstados(dataHorometro[tipoTurno]))
        setLoadHorizontalChart(false);
      }

      setLoadingKpiProduccionDiaria(false)
      
  }

  useEffect(()=>{
    const localDatosMaquina = localStorage.getItem("dataOpMaq");
    const localDatosMina = localStorage.getItem("dataOpMina");
    const localFecha = localStorage.getItem("fechaObjetivo");
    
    let controller = new AbortController();
    if(localDatosMaquina){
      const objDatosMaquina = JSON.parse(localDatosMaquina);
      const objDatosMina = JSON.parse(localDatosMina);
      const nombreMina = objDatosMina.nombreMina;
      const idMina = objDatosMina.idMina;
      const idMaquina = objDatosMaquina.id;
      const datosDia = objDatosMaquina;
      const rutaMina = objDatosMina.rutaMina;
      const esMinaSubterrane = objDatosMina.esMinaSubterrane;
      onHandleSetDataGeneral(esMinaSubterrane,nombreMina,idMina);
      // setRutaMina(rutaMina);
      setDatosMaquina(datosDia);
      setNombreMina(nombreMina);
      setIdMina(idMina);
      getInfoGeneralMina(idMina,format(fechaActual, "yyyy-MM-dd"))
        .then(({success, data}) => {
          if(success){
            setListaTurnos(data.turnos);
            setLimites(getMinMaxTurnos(data.turnos))
            generaColumnas(data.turnos)
            procesarVueltasMaquina(datosDia, data.turnos, data.portales, fechaActual,idMina)
            procesarMovimientosMaquina(idMina,getMinMaxTurnos(data.turnos),format(fechaActual, "yyyy-MM-dd"),controller,idMaquina)
            getAsarco(idMina, idMaquina, format(fechaActual, "yyyy-MM-dd"), format(fechaActual, "yyyy-MM-dd"), turnoTodos)
          }
        })
    }
    return () => controller.abort();
  },[reloadCharts,  localStorage.getItem("dataOpMaq")]);

  useEffect(() => {
    
    if(idMina == "4"){
      setImagenCamion(imgCamionDumper);
    }

  }, [idMina]);

  const onChangeTurno = (t) => {
    setTurno(t)
    setLimites((t===turnoTodos)?getMinMaxTurnos(listaTurnos):{min: new Date(t.inicio), max: new Date(t.fin)})
    setSeriesMovilizacionPorTurno(filtrarSeriesMovilizacionPorTurno(seriesMovilizacionMaquina,t));
    procesarAsarco(tiemposAsarco, t)
  }

  const filtrarSeriesMovilizacionPorTurno = (movilizaciones,t) => {
    var series;
    if(t === turnoTodos){
      series = [];
      for(const [nombre_turno, movilizaciones_turno] of Object.entries(movilizaciones)){
        series = series.concat(movilizaciones_turno);
      }
    }
    else{
      series = movilizaciones[t.nombre];
    }
    return series?series:[];
  }

  const updateDataMovilizacionMaquina = (idMaquina,fecha) => {
    setLoadingMovimientos(true);
    if(idMaquina){
      getEncuentrosMovMaquina(idMaquina,fecha)
      .then(({success, data}) => {
        if(success){
          setSeriesMovilizacionMaquina(data["beacons"]);
          setSeriesMovilizacionPorTurno(filtrarSeriesMovilizacionPorTurno(data["beacons"], turnoTodos));
        }
        else{
          console.log("Ocurrio un error");
        }
        setLoadingMovimientos(false)
      })  
    }
    else{
      console.log("No se ha seleccionado ninguna máquina");
    }
  };

  const getMinMaxGrafico = (fechaActual) => {
    let fechaInicio = new Date(`${format(fechaActual, "yyyy-MM-dd")} ${turno.hora_inicio}`);
    let fechaFin = new Date(`${format(fechaActual, "yyyy-MM-dd")} ${turno.hora_fin}`);
    switch(Number(turno.id_tipo_turno)){
      case 1:
        // El turno se encuentro entre dos dias, partiendo por la madrugada del dia anterior
        fechaInicio.setDate(fechaInicio.getDate() - 1);
        return ({
          min: fechaInicio,
          max: fechaFin
        })
      case 2:
        // El turno es dentro de horario de un mismo dia
        return ({
          min: fechaInicio,
          max: fechaFin
        })
        // Case 3 representaria turno entre dos dias que termina en el dia siguiente
      case 3:
        fechaFin.setDate(fechaFin.getDate() + 1);
        return ({
          min: fechaInicio,
          max: fechaFin
        })
      default:
        // El turno tiene id 0 o distinto, por lo tanto, se deben mostrar todos los datos
        listaTurnos.forEach(itemTurno => {
          switch(itemTurno.orden){
            case 0:        
              // Primer turno      
              fechaInicio = new Date(`${format(fechaActual, "yyyy-MM-dd")} ${itemTurno.hora_inicio}`);
              if(Number(itemTurno.id_tipo_turno)===1){
                fechaInicio.setDate(fechaInicio.getDate() - 1);
              }
            case (Number(listaTurnos.length)-1):
              // Ultimo turno
              fechaFin = new Date(`${format(fechaActual, "yyyy-MM-dd")} ${itemTurno.hora_fin}`);
              if(Number(itemTurno.id_tipo_turno)===3){
                fechaFin.setDate(fechaFin.getDate() + 1);
              }
            default:
              // Turnos intermedios no se consideran
              break;
          }
        })

        return({
          min: fechaInicio,
          max: fechaFin
        })
    }
  };

  useEffect(()=>{
    if(datosMaquina.nombre !== ""){
      updateDataMovilizacionMaquina(datosMaquina.id, format(fechaActual, "yyyy-MM-dd"));
    }
  },[datosMaquina]);

  useEffect(() => {
    return () => {
      sendTime(location.pathname)
    }
  }, [])

  const onChangeFecha = (fecha) => {
    if(controllerActivo){
      controllerActivo.abort();
    }
    setTurno(turnoTodos);
    
    const localDatosMaquina = localStorage.getItem("dataOpMaq");
    const localDatosMina = localStorage.getItem("dataOpMina");
    const localFecha = localStorage.getItem("fechaObjetivo");
    setLoadingKpiProduccionDiaria(true)
    setFechaActual(fecha);
    setLoadingMovimientos(true);
    setLoadHorizontalChart(true);
    if(localDatosMaquina){
      let controller = new AbortController();
      setControllerActivo(controller);
      const objDatosMaquina = JSON.parse(localDatosMaquina);
      const objDatosMina = JSON.parse(localDatosMina);
      const idMina = objDatosMina.idMina;
      const idMaquina = objDatosMaquina.id;
      const datosDia = objDatosMaquina;
 
      getInfoGeneralMina(idMina,format(fecha, "yyyy-MM-dd"))
        .then(({success, data}) => {
          if(success){
            setListaTurnos(data.turnos);
            setLimites(getMinMaxTurnos(data.turnos))
            generaColumnas(data.turnos)
            procesarVueltasMaquina(datosDia, data.turnos, data.portales, fecha, idMina)
            procesarMovimientosMaquina(idMina,getMinMaxTurnos(data.turnos),format(fecha, "yyyy-MM-dd"),controller,idMaquina)
            updateDataMovilizacionMaquina(idMaquina, format(fecha, "yyyy-MM-dd"));
            getAsarco(idMina, idMaquina, format(fechaActual, "yyyy-MM-dd"), format(fechaActual, "yyyy-MM-dd"), turnoTodos)
          }
        })
    }
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ open: false, message: alert.message, severity: alert.severity });
  };
  return(
    <>{
      localDatosMaquina?
        <>
          <NavBar />
          <Container>

            {/* Formulario popup */}
            <PopupRegistroJustificacion
              FormJustificados={false}
              classes={classes}
              loadingPopUp={loadingPopUp}
              open={open}
              gruposJustificaciones={gruposJustificaciones}
              verCheckbox={true}
              stateInicialFormJustificaciones={stateInicialFormJustificaciones}
              setReloadCharts={setReloadCharts}
              setLoadHorizontalChart={setLoadHorizontalChart}
              setOpen={setOpen}
              setAlert={setAlert}
            />

            {/* Alerta de form */}
            <AlertaGenerica alert={alert} handleCloseAlert={handleCloseAlert} duration={5000} />

            <br/>

            <BreadCrumbsGenerico
            nombreMina={nombreMina}
            dataRuta={ [
              { id: 1, endpoint: "/", texto: `Mine-Watch` },
              { id: 2, texto: `${t('minaSingular')} ${nombreMina}` },
              { id: 3, endpoint:`/operaciones/${rutaMina}`, texto: t("operaciones") },
              { id: 4, texto: datosMaquina.nombre, destacar:true }
            ]}
            />

            <br/>
            <br/>

            <Grid container justifyContent="flex-end">
              <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.datepicker}
                  format="dd/MM/yyyy"
                  cancelLabel={t("cancelar")}
                  size="small"
                  inputVariant="outlined"
                  value={fechaActual}
                  onChange={onChangeFecha}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <br/>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            > 
              <Grid item lg={5}>
                <Grid container alignItems="center">
                  <Grid item>
                  <CardMedia
                      component="img"
                      image={datosMaquina.tipo==="Jumbo"?ImagenJumbo:datosMaquina.tipo==="Bolter"?ImagenJumbo:""}
                      alt="Imagen máquina"
                      style={{ width: datosMaquina.tipo==="Camion"?"80px":"120px", marginRight: "20px" }}
                    />
                  </Grid>

                  <Grid item>
                  <Grid 
                  container
                  direction="column"
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "#00B7C4"
                      }}
                    >
                      {datosMaquina.nombre}
                    </Typography>
                  </Grid>
                  <Grid item alignItems="center">
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14.4px",
                        display:"flex",
                        alignItems:"end"
                      }}
                    >
                      <ArrowBackIosNewRoundedIcon onClick={() => onChangeFecha(restarDias(fechaActual,1))} sx={{color:'#fafafa',backgroundColor:'rgb(0, 183, 196)',cursor:'pointer',borderRadius:'50px', padding:'3px',marginRight:"10px",':hover':{backgroundColor:'#2f4554'}}}/>
                      
                      {(i18n.language === 'es')?
                        (`Datos del día ${fechaActual.getDate()} de ${fechaActual.toLocaleString('es-ES', { month: 'long', year: 'numeric' })}`)
                      :
                        (`Data of ${fechaActual.toLocaleString('en-EN', { month: 'long', year:'numeric', day:'numeric' })}`)
                      }
                      {
                        (format(fechaActual, "yyyy-MM-dd") !== format((new Date()), "yyyy-MM-dd")) &&
                        <ArrowForwardIosRoundedIcon onClick={() => onChangeFecha(sumarDias(fechaActual,1))} sx={{color:'#fafafa',backgroundColor:'rgb(0, 183, 196)',cursor:'pointer',borderRadius:'50px', padding:'3px',marginLeft:"10px",':hover':{backgroundColor:'#2f4554'}}}/>
                      }
                    </Typography>
                  </Grid>
                </Grid>
                  </Grid>
                </Grid>


              
              </Grid>
              <Grid item lg = {7}>
                <Grid container justifyContent='flex-end' alignItems="center">
                  <Button
                      onClick={()=>onChangeTurno(turnoTodos)}
                      className={turno.id===0?classes.buttonSelected:classes.buttonUnselected}
                  >
                    {t("todos")}
                  </Button>

                  {
                    listaTurnos.map((itemTurno,index) => {
                      return(
                          <Button
                              key={index+1}
                              onClick={()=>onChangeTurno({id: index+1, ...itemTurno })}
                              className={(turno.nombre===itemTurno.nombre)?classes.buttonSelected:classes.buttonUnselected}
                          >
                            {t(`turno_${itemTurno.nombre.toLowerCase()}`)}
                          </Button>
                      )
                    })
                  }
                </Grid>
              </Grid>

            </Grid>
            <br/>
            <Card sx={{padding:"30px 20px 20px 20px"}}>
              <CardContent>
                <SectionHeader
                  title={t("kpis_de_prod_diaria")}
                  subtitle={""}
                  icon={<img src={IconoKpi} width="35px" alt=" Icono Resumen Diario"/>}
                />
                <br/>
                <br/>
                <Grid container spacing={1}>

                  {
                    loadingKpiProduccionDiaria ? 
                      <Grid container justifyContent="center" alignItems="center">          
                        <CircularProgress/>
                      </Grid> :
                      Object.entries(TirosYvoladuras).map(([key, value])=>{
                        return(
                          <Grid key={key} item lg={4} md={12} xs={12}>
                            <ResumenDiarioCard1
                              id={key}
                              titulo1={key==="turnotodos"?t("detalle_diario_brazos"):t("detalle_de")}
                              titulo2={key==="turnotodos"?"":t(`turno_${key.toLowerCase()}`)}
                              horas={value.voladuras}
                              horasMotor={value.tiros}
                              isSelect={turno.nombre===key}
                              onClick={()=>{}}
                              tipoFlota={JSON.parse(localDatosMaquina).tipo}
                              loading={loadingKpiProduccionDiaria}
                            />
                          </Grid>
                        )
                      })
                    }
                  </Grid>

                <br/>
                <br/>
                <TiempoAsarcoGenerico ocultarRalenti={true} tiemposAsarcoPorTurno={tiemposAsarcoPorTurno} />
                <br/>
                <br/>

                <SectionHeader
                  title={t("movimiento_y_horometro")}
                  subtitle={""}
                  icon={<img src={IconoResumenDiario} width="35px" alt=" Icono Resumen Diario"/>}
                />

                {
                  loadingMovimientos || loadingEncuentrosGPS?
                  
                  <Grid container justifyContent="center" alignItems="center">          
                    <CircularProgress/>
                  </Grid>
                  :(
                                        
                    seriesMovilizacionPorTurno.length > 0 || EncuentrosGPS[turno?.nombre]?.length > 0?
                    <MotionChart
                      series = {seriesMovilizacionPorTurno}
                      limites={getMinMaxGrafico(fechaActual)}
                      fecha={format(fechaActual, "yyyy-MM-dd")}
                      seriesGPS={EncuentrosGPS[turno.nombre]}
                    />
                  :
                    <BarTimelineChartVacio
                      limites={limites}
                      labels={[t('carga'), t('descarga')]}
                      height="300"
                      loading={!loading}
                  
                    />)
                }

              <div style={{width:"100%"}}>

              <br/>
              <br/>

              <LeyendasMovimientos/>

              {
                seriesEstados.length>0 && !loadHorizontalChart?
              
                  <HorizontalTimeChart
                    series = {seriesEstados} 
                    turno={limites}
                    numCategorias={4}
                    abrirModalJustificaciones={handleClickOpen}
                    MostrarLabelCompleto={false}
                  />
                :
                  <BarTimelineChartVacio
                    limites={getMinMaxGrafico(fechaActual)}
                    labels={[t("horometro")]}
                    height="130"
                    loading={loadHorizontalChart}
                  />
              }
                </div>
                {
                  datosMaquina && datosMaquina.es_subterranea !== undefined && !datosMaquina.es_subterranea && (
                    <>
                      <br/>
                      <br/>
                      <SectionHeader
                        title={t("Geolocalización")}
                        subtitle={""}
                        icon={<LocationSearchingIcon fontSize="large"/>}
                      />
                      <MultipleSimpleIndicator columnas={[{
                          titulo:"Cantidad de vueltas",
                          valor:dataVueltasGPS?.vueltas?.[turno?.nombre]?.length ? dataVueltasGPS?.vueltas?.[turno?.nombre]?.length : 0,
                          toolTip:undefined
                        },{
                          titulo:"Distancia promedio vuelta (km)",
                          valor:dataVueltasGPS?.distanciaPromedio?.[turno?.nombre] ? dataVueltasGPS?.distanciaPromedio?.[turno?.nombre]?.["promedio"] : 0,
                          toolTip:undefined
                        },{
                          titulo:"Tiempo promedio vuelta (hrs)",
                          valor:dataVueltasGPS?.tiempoPromedio?.[turno?.nombre] ? ConvertMinutosToHoras(dataVueltasGPS?.tiempoPromedio?.[turno?.nombre]?.["promedio"]) : 0,
                          toolTip:undefined
                        },{
                          titulo:"Velocidad promedio (km)",
                          valor:movimientosGPS?.speedPromedio?.[turno?.nombre]?.["promedio"] ? movimientosGPS?.speedPromedio?.[turno?.nombre]?.["promedio"] : 0,
                          toolTip:undefined
                        }]}/>
                        <br/>
                        <br/>
                        <MapaGPS 
                          id_area_operacion = {JSON.parse(localDatosMina).idMina} 
                          id_maquina = {JSON.parse(localDatosMaquina).id}
                          fecha={format(fechaActual, "yyyy-MM-dd")}
                          loadingMovimientosGPS={loadingMovimientosGPS}
                          movimientosGPS={movimientosGPS}
                        />
                    </>
                  )
                }
                <br/>
                <br/>
                <SectionHeader
                  title={t("detalle_por_portal")}
                  subtitle={""}
                  icon={<img src={IconoDetalle} width="35px" alt=" Icono Resumen Diario"/>}
                />
                <br/>
                {/* <TablaFuncionamiento  data={vueltasPorTurno} columnsType={tipoInfoVuelta} /> */}
                <TablaV5 data={portalesRows} cols={configColumnas} headCols={configHeadCols}/>
              </CardContent>
            </Card>
            <br/>
          </Container>
        </>
      :
        <Redirect to={"/"}/>
    }</>
  ); 
};
