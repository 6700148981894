import React from 'react'

import { useTranslation } from 'react-i18next';
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    buttonSelected: {
      backgroundColor: "#2f4554",
      color: "white",
      padding: "5px 30px",
      fontSize: "14.4px",
      textTransform: "none",
      borderRadius: "4px",
      margin: "3px",
      height: "38px",
      width:"160px",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "#00B7C4"
      }
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642",
      },
      "&:disabled": {
        backgroundColor: "gray",
      },
    },
    buttonUnselected: {
      backgroundColor: "#f4f4f4",
      padding: "5px 30px",
      fontSize: "14.4px",
      textTransform: "none",
      borderRadius: "4px",
      margin: "3px",
      height: "38px",
      width:"160px",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "#00B7C4"
      }
    },
    prodDes:{
      color: "#2f4554",
      fontSize: "12px !important",
    },
    prodValue:{
      fontSize: "20px",
      fontWeight:"bold"
    },
    bordermd:{
      ['@media (min-width:1200px)']: {
        borderRight:"2px solid #d7d7d7"
      }
    },
    marginmd:{
      ['@media (min-width:1200px)']: {
        marginLeft:"10px"
      }
    },
    datepicker: {
      width: "190px",
      backgroundColor:"white",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00B7C4"
      },
      "& .MuiInputBase-root": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        "& .MuiButtonBase-root": {
  
          borderColor: "error.main"
  
        },
        "& .MuiInputBase-input": {
          backgroundColor: "#ffffff",
          borderColor: "error.main",
          /* fontSize:"14.4px" */
        }
      },
  
      '& .MuiOutlinedInput-root': { 
        '&:hover fieldset': {
            borderColor: "#00B7C4",
        }
      },
    }
  });

export const RegistroIngreso = ({fecha_inicio, fecha_termino, title1,title2}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();

  return (
    <>
      <br/>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        columns={2}
        sx={{padding:"0px 30px 0px 0px"}}
      >
        <Grid item lg={1} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <div className={classes.prodValue} style={{color:"#2f4554"}}>
                {fecha_inicio}
              </div>
              <div className={classes.prodDes}>
                {title1}
              </div>
            </Grid>
        </Grid>                 

        <Grid item lg={1} md={2} xs={2}>       
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <div className={classes.prodValue} style={{color:"#01b7c4"}}>
                  {fecha_termino}
              </div>
              <div className={classes.prodDes}>
                {title2}
              </div>
            </Grid>
        </Grid>                
      </Grid>
    </>
  )
}
