import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Dropdownmaquina = () => {
    const [maquina, setMaquina] = React.useState('');

    const handleChange = (event) => {
      setMaquina(event.target.value);
    };
    return (
        <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Máquina</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={maquina}
            label="Máquina"
            onChange={handleChange}
          >
            <MenuItem value={10}>Maquina1</MenuItem>
            <MenuItem value={20}>Maquina2</MenuItem>
            <MenuItem value={30}>Maquina3</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
}

export default Dropdownmaquina;
