import React, { useState, useEffect, useContext } from "react";
import AWS from "aws-sdk";
import { DataGrid } from "@material-ui/data-grid";
import { Button, Container } from "@material-ui/core";
import NavBar from "./NavBar";
import { AccountContext } from "../../authentication/Account";
import SimpleBackdrop from "../molecules/BackDrop";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@mui/material/Divider";
import Login from "../../authentication/Login";
//import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const Tablausuarioempresa = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const { getSession } = useContext(AccountContext);
  const [role, setRole] = useState();
  const [numeroEmpresa, setNumeroEmpresa] = useState();

  useEffect(() => {
    getSession().then((session) => {
      const { idToken } = session;
      const { jwtToken, payload } = idToken;
      const { email, email_verified } = payload;
      try {
        const userType = session["custom:role"];
        setRole(userType);

        const userEmpresa = payload["custom:empresa"];
        if (userEmpresa == 2) {
          setNumeroEmpresa(2);
        }
      } catch (err) {
        console.log(err);
      }

      setLoggedIn(true);
    });
  }, [loggedIn, getSession, numeroEmpresa]);

  const getUsers = async () => {
    try {
      let allUsers = [];
      let more = true;
      let paginationToken = "";

      while (more) {
        let params = {
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Limit: 60
        };
        if (paginationToken !== "") {
          params.PaginationToken = paginationToken;
        }

        AWS.config.update({
          region: process.env.REACT_APP_USER_POOL_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_KEY
        });
        const cognito = new AWS.CognitoIdentityServiceProvider();
        const rawUsers = await cognito.listUsers(params).promise();
        allUsers = allUsers.concat(rawUsers.Users);

        if (rawUsers.PaginationToken) {
          paginationToken = rawUsers.PaginationToken;
        } else {
          more = false;
        }
      }

      let email_Usuarios;
      if (allUsers[0] !== undefined) {
        email_Usuarios = allUsers.map((x, key) => {
          const { Username, Attributes } = x;

          const contacts = new Map();

          const res = Attributes.map((x) => {
            if (x.Name == "phone_number") contacts.set("phone_number", x.Value);
            if (x.Name == "given_name") contacts.set("given_name", x.Value);
            if (x.Name == "middle_name") contacts.set("middle_name", x.Value);
            if (x.Name == "family_name") contacts.set("family_name", x.Value);
            if (x.Name == "custom:empresa") contacts.set("company", x.Value);
          });

          return {
            id: Username,
            id_borrar: Username,
            sub: Attributes[0].Value,
            phone_number: contacts.get("phone_number"),
            given_name: contacts.get("given_name"),
            middle_name: contacts.get("middle_name"),
            family_name: contacts.get("family_name"),
            company: contacts.get("company")
          };
        });
      }

      const new_email_Usuarios = email_Usuarios.filter((x1) => x1.company == 2);

      setUsers(new_email_Usuarios);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const deleteUser = async (user2delete) => {
    try {
      let more = true;
      let paginationToken = "";

      while (more) {
        let params = {
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Limit: 60
        };
        if (paginationToken !== "") {
          params.PaginationToken = paginationToken;
        }

        AWS.config.update({
          region: process.env.REACT_APP_USER_POOL_REGION,
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_KEY
        });
        const cognito = new AWS.CognitoIdentityServiceProvider();

        const resultDetelete = await cognito
          .adminDeleteUser({
            UserPoolId: process.env.REACT_APP_USER_POOL_ID,
            Username: user2delete
          })
          .promise();

        const { $response } = resultDetelete;
        const { httpResponse } = $response;
        const { statusCode } = httpResponse;

        if (Number(statusCode) === 200) {
          getUsers();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect((user2delete) => {
    deleteUser(user2delete);
  }, []);

  const handlePurge = (e) => {
    setRows();
  };

  const columns = [
    { field: "id", headerName: "Username", width: 200 },
    {
      field: "given_name",
      headerName: "Primer Nombre",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        return row.given_name;
      }
    },
    {
      field: "middle_name",
      headerName: "Segundo Nombre",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        return row.middle_name;
      }
    },
    {
      field: "family_name",
      headerName: "Apellidos",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        return row.family_name;
      }
    },
    {
      field: "phone_number",
      headerName: "Teléfono",
      width: 200,
      renderCell: (params) => {
        const { row } = params;
        return row.phone_number;
      }
    },
    {
      field: "id_borrar",
      headerName: "Acción",
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            style={{
              borderRadius: 18,
              backgroundColor: "#58b062",
              padding: "18px 36px"
            }}
            variant="contained"
            color="primary"
            onClick={handlePurge}
          >
            Borrar
          </Button>
        );
      }
    }
  ];

  const handleRowSelection = (e) => {
    const { id, row } = e;
    const { id_borrar, sub } = row;
    var result = window.confirm("Estas seguro que lo quieres borrar?");
    if (result) {
      deleteUser(id_borrar);
    }
  };

  let varAux = role !== undefined ? role.includes("admin") : false;

  return (
    <>
      {loggedIn && varAux ? (
        <div style={{ height: 550, width: "100%" }}>
          <NavBar />
          {loading && numeroEmpresa ? (
            <SimpleBackdrop />
          ) : (
            <>
              <Container>
              <Grid container spacing={0} columns={10}>
                <br/>
                <Grid item xs={12}>
                  <Typography
                    style={{ color: "#323b41", fontWeight: "bold", fontSize: "18px" }}
                  >
                    ADMINISTRAR USUARIOS
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      sx={{ display: "flex", alignItems: "center" }}
                      style={{fontSize:"14.4px"}}
                      color="inherit"
                      href="/inicio"
                    >
                      Inicio
                    </Link>
                    <Link
                      underline="hover"
                      sx={{ display: "flex", alignItems: "center" }}
                      style={{fontSize:"14.4px"}}
                      color="inherit"
                      href="/tablausuarioempresa"
                    >
                      Administrar usuarios
                    </Link>
                  </Breadcrumbs>
                </Grid>
              </Grid>
              <br/>
              <Divider/>
              <br/>

              <div style={{ height: 550, width: "100%", marginTop: "20px" }}>
                <DataGrid
                  rows={rows != undefined ? rows : users}
                  columns={columns}
                  pageSize={40}
                  rowsPerPageOptions={[40]}
                  density={"compact"}
                  checkboxSelection
                  //disableSelectionOnClick
                  onCellClick={handleRowSelection}
                />
              </div>

            </Container>
          </>
          )}
        </div>
      ) : (
        <Login/>
      )}{" "}
    </>
  );
};

export default Tablausuarioempresa;
