import React from "react";
import explosionIcon from "../../../assets/icons/explosion-icon.png";
import circleIcon from "../../../assets/icons/yellow-circle-icon.png";
import { activities, colors, parsedValues } from "../constants-frentes";

const empty = "transparent";
const planColor = colors.at(-1);

export function CellProgresoActividad({ item, activity, index }) {
  const currentActivity = item[activity];

  // 3 = 1, 2 = 0.5, 1.5 = 0.5, 1 = 0, 0 = 0
  const parsedActivity = parsedValues[currentActivity];
  const parsedActivityPlan = parsedValues[item[`plan_${activity}`]];

  const currentColor = colors[index];

  const barProgressColors = {
    "1-0": [currentColor, currentColor],
    "0-1": [planColor, planColor],
    "0.5-0.5": [currentColor, empty],
    "1-1": [currentColor, currentColor],
    "0.5-1": [currentColor, planColor],
    "0-0": [empty, empty],
    "0-0.5": [planColor, empty],
    "0.5-0": [currentColor, empty]
  };

  const [firstHalf, secondHalf] =
    barProgressColors[`${parsedActivity}-${parsedActivityPlan}`] || [empty, empty];

  const progressVoladura =
    parsedValues[item["voladura"]] && activity === "voladura";
  const lastAdvancedActivity = activities.reduceRight((acc, activity) => {
    if (parsedValues[item[activity]] > 0 && !acc) {
      acc = activity;
    }
    return acc;
  }, null);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: parsedActivity <= 0.5 ? "flex-start" : "center",
        alignItems: "center",
        width: "100%",
        minWidth: "90px",
        minHeight: "46px",
        marginInline: "1px 0",
        background: `linear-gradient(to right, ${firstHalf} 50%, ${secondHalf} 50%)`,
        borderRight: "1px solid #E9EBF8"
      }}>
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: parsedActivity <= 0.5 ? "50%" : "100%"
        }}>
        {lastAdvancedActivity === activity && !progressVoladura ? (
          <img 
            src={circleIcon}
            alt="circle-icon"
          />
        ) : progressVoladura && firstHalf !== empty && secondHalf !== empty ? (
          <img 
            src={explosionIcon}
            alt="explosion-icon"
           />
        ) : null}
      </section>
    </div>
  );
}
