import React from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from "@mui/material/Alert";

export const AlertaGenerica = ({alert, handleCloseAlert, duration = 5000}) => {
  return (
    <Snackbar
    open={alert.open}
    autoHideDuration={duration}
    onClose={handleCloseAlert}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
  >
    <Alert
      variant="filled"
      onClose={handleCloseAlert}
      severity={alert.severity}
      sx={{ width: "100%" }}
    >
      {alert.message.split("?").map((line, index) => {
        if(index !== alert.message.split("?").length-1){
          return (<React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>)
        }
    })}
    </Alert>
  </Snackbar>
  )
}
