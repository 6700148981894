import { useEffect, useState } from "react";

const API_URL = "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/control-frentes/emails"

export function useSendManualEmail() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  async function sendReport({ mina, fecha, toAddresses = [], jpgFiles, pdfFile}) {
    const body = {
      mina,
      fecha,
      toAddresses,
      jpgFiles,
      pdfFile
    }

    setLoading(true);

    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error("Error al enviar el correo");
      }

      setSuccess(true);

      return response.json();
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    }
  }, [success]);

  return { sendReport, emailLoading: loading, emailError: error, success };
}