export const colors = [
  "#2C4352",
  "#3167A9",
  "#4A86E8",
  "#01B7C4",
  "#139EA8",
  "#097B83",
  "#023E53",
  "#E2711D4D"
];

export const parsedValues = {
  3: 1,
  2: 0.5,
  1.5: 0.5,
  1: 0,
  0: 0
};

export const activities = [
  "rezagado",
  "amacice",
  "fortificacion",
  "geologia",
  "barrenacion",
  "carga",
  "voladura"
];
