import { createContext, useState } from "react";

const GeneralChartsContext = createContext();
//contexto para manejar graficos
const GeneralCharts = (props) => {
  const [horoumeterLimits, sethoroumeterLimits] = useState({});
//obtener limite minimo y maximo desde grafico de movimientos
  const onHandleSetHoroumeterLimits = (min, max) => {
    sethoroumeterLimits({ min, max });
  };

  return (
    <GeneralChartsContext.Provider value={{ onHandleSetHoroumeterLimits, horoumeterLimits }}>
      {props.children}
    </GeneralChartsContext.Provider>
  );
};

export { GeneralCharts, GeneralChartsContext }; 