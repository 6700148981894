import { useEffect, useState } from 'react'
import { getActividadUsuario } from '../../services/analytics';
import { formatearFechaISO } from '../../commons/FormatearFecha';
import { convertirSegundosToHorasMinutosSegundos } from '../../commons/FormatearTiempo';

export const useGetActividadUsuario = (id_area_operacion, fecha_inicio, fecha_termino, fecha, group_by_fecha, group_by_path_name, group_by_usuario) => {
    const [actividadUsuario, setActividadUsuario] = useState([]);
    const [loadingActividadUsuario, setLoadingActividadUsuario] = useState(false);

    useEffect(() => {
     if (!id_area_operacion || id_area_operacion == 0) return;
        setLoadingActividadUsuario(true);
        getActividadUsuario(id_area_operacion, fecha_inicio, fecha_termino, fecha, group_by_fecha, group_by_path_name, group_by_usuario)
            .then((resp) => {
                let data = formatearDataActividadUsuario(resp.data)
                setActividadUsuario(data)
                setLoadingActividadUsuario(false)
            })
            .catch((err) => {
                console.log(err)
                setLoadingActividadUsuario(false);
            })
    }, [id_area_operacion, fecha_inicio, fecha_termino, fecha, group_by_fecha, group_by_path_name, group_by_usuario]);

    return { actividadUsuario, loadingActividadUsuario }
}

function formatearDataActividadUsuario(data = []) {
    if (data && data.length > 0) {
        let formatedData = data.map((dato) => {
            return { 
                ...dato, 
                primer_registro: formatearFechaISO(dato.primer_registro),
                ultimo_registro: formatearFechaISO(dato.ultimo_registro),
                total_segundos_transcurridos:convertirSegundosToHorasMinutosSegundos(dato.total_segundos_transcurridos)
            }
        });
        return formatedData;
    }

    return [];
}