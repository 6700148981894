import React, { useEffect, useRef, useState } from "react";
import "./CustomPopover.css";

export function CustomPopover({ id, children, content }) {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);
  const triggerRef = useRef(null);

  function handleToggleVisibility() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !triggerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      id={id}
      style={{
        position: "fixed",
        right: "calc(50px + 1.5vw)",
        bottom: "22px",
      }}>
      <section className="popover-container">
        <button
          ref={triggerRef}
          onClick={handleToggleVisibility}
          className="popover-trigger"
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-controls="popover-content">
          {children}
        </button>
        {isOpen && (
          <section
            id="popover-content"
            ref={popoverRef}
            className="popover-content"
            role="dialog"
            aria-modal="true">
            {content}
          </section>
        )}
      </section>
    </div>
  );
}
