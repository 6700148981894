import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, withStyles } from '@material-ui/core'
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react'

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#2f4554",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

export const TablaDeCarga = ({columnas}) => {

  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <>
    <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="customized table">
        <TableHead>
        <TableRow>
            {
                columnas.map(col => (
                    <StyledTableCell key={col} align="left">{t(col)}</StyledTableCell>
                ))
            }

        </TableRow>
        </TableHead>
        <TableBody>
        <StyledTableRow style={{height:'90px'}}>

                {
                    columnas.map(col => (
                        <StyledTableCell key={col} align="left"> <Skeleton animation="wave" /></StyledTableCell>
                    ))
                }
            </StyledTableRow>
            <StyledTableRow style={{height:'90px'}}>
                    {
                        columnas.map(col => (
                            <StyledTableCell key={col} align="left"> <Skeleton animation="wave" /></StyledTableCell>
                        ))
                    }
            </StyledTableRow>
            <StyledTableRow style={{height:'90px'}}>
                    {
                        columnas.map(col => (
                            <StyledTableCell key={col} align="left"> <Skeleton animation="wave" /></StyledTableCell>
                        ))
                    }
            </StyledTableRow>
            <StyledTableRow style={{height:'90px'}}>
                    {
                        columnas.map(col => (
                            <StyledTableCell key={col} align="left"> <Skeleton animation="wave" /></StyledTableCell>
                        ))
                    }
            </StyledTableRow>
            <StyledTableRow style={{height:'90px'}}>
                    {
                        columnas.map(col => (
                            <StyledTableCell key={col} align="left"> <Skeleton animation="wave" /></StyledTableCell>
                        ))
                    }
            </StyledTableRow>
        </TableBody>
     </Table>
    </TableContainer>
  </>
  )
}
