export const getSectorDeOperacionVicon = async (idMina) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_area_operacion: idMina
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/vicons/grupo",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const getAreaAsignadaVicon = async (idMina) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        "id_area_operacion": idMina
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/vicons/tipo",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

 /* GPS */
  export const insertGeocerca = async (id_area_operacion,array_coordenadas) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        "id_area_operacion": id_area_operacion,
        "array_coordenadas":array_coordenadas
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/create-geocerca",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const deactivateGeocerca = async (id_geocerca) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        "id_geocerca":id_geocerca
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/deactivate_geocerca",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const getTiposDeGeocerca = async (id_area_operacion) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        "id_area_operacion":id_area_operacion
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/get-tipo-geocerca",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const insertReglaVueltaGpsControlVersion = async (id,id_area_operacion,id_tipo_maquina,id_tipo_geocerca_inicio,id_tipo_geocerca_fin,solicitante,nombre_tipo_geocerca_inicio,nombre_tipo_geocerca_fin,editing,pathMina,nombre_tipo_maquina,nombre_mina) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id,
        id_area_operacion,
        id_tipo_maquina,
        id_tipo_geocerca_inicio,
        id_tipo_geocerca_fin,
        solicitante,
        nombre_tipo_geocerca_inicio,
        nombre_tipo_geocerca_fin,
        editing,
        pathMina,
        nombre_tipo_maquina,
        nombre_mina
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/create-regla-vuelta-gps-control-versiones",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const acciones_vueltas_gps_by_vueltas_gps_control_version = async (id_tipo_operacion,aprobador,id_version) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_tipo_operacion,
        aprobador,
        id_version
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/accion-by-vueltas-gps-control-version",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const insertTipoGpsVersion = async (id, id_area_operacion, descripcion, solicitante, editing ,pathMina,nombre_mina) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id,
        id_area_operacion,
        solicitante,
        descripcion,
        editing,
        pathMina,
        nombre_mina
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/create-tipo-geocerca-version",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }


  export const acciones_tipo_geocerca_by_version = async (id_tipo_operacion,aprobador,id_version) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_tipo_operacion,
        aprobador,
        id_version
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/accion-by-tipo-geocerca-version",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }


  /* VICONS */

  export const insertarVersionamientoVicon = async (
    {
      descripcion,major,minor,id_tipo_beacon,id_area_operacion,id_grupo,orden,id_tipo_operacion, solicitante,nombre_mina,nombre_sector_operacion,area_asignada,pathMina, es_frente,entrada
    }
  ) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
       descripcion,
       major,
       minor,
       id_tipo_beacon,
       id_area_operacion,
       id_grupo,
       orden,
       id_tipo_operacion,
       solicitante,
       nombre_mina,
       nombre_sector_operacion,
       area_asignada,
       pathMina,
       es_frente,
       entrada
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/administracion/vicon-version/insertar",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const acciones_vicon_by_vicon_version = async (id_vicon_version,id_tipo_operacion,es_frente,entrada) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_vicon_version,
        id_tipo_operacion,
        es_frente,
        entrada
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/vicons/accion-by-vicon-version",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }


  export const getReglasVueltasGps = async (id_area_operacion) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_area_operacion
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/reglas_vueltas",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const insertVueltasManual = async (id_area_operacion, vueltas) => {

    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_area_operacion,
        vueltas
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/produccion/vueltas/insert",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }