import {
  Box,
  Grid
} from "@material-ui/core";
import {
  Divider,
  Typography
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import IconoPortal from '../../assets/icons/icono-ubicacion-portal.png';
import IconoMantencion from '../../assets/icons/icono-mantencion.png';
import IconoUbicacion from '../../assets/icons/icono-ubicacion.png';

import { useTranslation } from 'react-i18next';
import GenericTooltip from "../../components/molecules/GenericTooltip";
import { formatearFechaISO, formatearFechaISOtoUSFormat } from "../../commons/FormatearFecha";
import { translateMachineName } from "../../commons/utiles";


const useStyles = makeStyles(() => ({
  selBox: {
    width: "100%",
    backgroundColor: '#2f4554',
    borderRadius: "2px",
    /* padding:"30px 15px", */
    cursor: "default"
  }
}));

const UbicacionesCard = ({ubicaciones}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const getColor = (key) => {
    const estados = {
      "operativo": "rgb(0, 183, 196)",
      "mantencion": "rgb(255, 191, 0)",
      "fuera de servicio": "#C5283D",
      "sininfo": "rgb(125, 152, 171)",
      "ralenti":"#E9724C"
    }
    return estados[key]
  }

  const getDescripcion = (key) => {
    const estados = {
      "operativo": t("operativo"),
      "mantencion": t("en_mantencion"),
      "fuera de servicio": t("fuera_de_servicio"),
      "sininfo": t("sin_info"),
      "ralenti": t("ralenti")
    }
    return estados[key]
  }

  return(
    <>        
      <Box className={classes.selBox} sx={{padding:"25px"}} >
        <Grid container>
            <Grid item lg={12} md={12} xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <img 
                    src={IconoUbicacion} 
                    width="25px" 
                    alt=" icono ubicacion"
                  />
                </Grid>
                <Grid item lg={5} md={5} xs={5}>
                  <Typography
                    fontWeight="bold"
                    fontSize="14.4px"
                    color="#fff"
                    sx={{padding:"0px 10px"}}
                  >
                    {t("ubicacion_equipos")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <br/>

          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
          >
                            
            {Object.entries(ubicaciones).map(([key,value],index)=>{
              let lg     = 2;
              let md     = 2;
              let xs     = 6;
              let color  = "#00b7c4";
              let icono  = IconoPortal;
              if(value.tipo==="taller"){
                color = "#ffbf00";
                icono = IconoMantencion;
              }
              return(
                <Grid key={index+1} item lg={lg} md={md} xs={xs}>
                    <GenericTooltip
                      sx={{pointerEvents:(value.maquinas.length===0?"none":"auto")}}
                      hide={value.maquinas.length===0}
                      hoverElement={
                        <Grid value
                          container 
                          direction="column" 
                          justifyContent="center" 
                          alignItems="center"
                        >
                          <div
                            style={{  
                              width: "60px",
                              height: "105px",
                              backgroundColor: "#465F70", /* Cambia el color de fondo del cuadrado aquí */
                              borderRadius: "50px 50px 10px 10px",
                              display: "flex",
                              justifyContent: "center",
                              //alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "70px", 
                                height: "70px",
                                backgroundColor: value.maquinas.length===0?"rgb(125, 152, 171)":color,
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position:"absolute"
                              }}
                            >
                              <img 
                                src={icono} 
                                width="35px" 
                                alt=" icono portal"
                              />
                            </div>

                            <div style={{
                              margin:"5px", 
                              marginTop:"75px",
                              color:"#fff",
                              fontWeight:"bold", 
                              fontSize:"14.4px", 
                              height:"20px"
                            }}>
                              {value.maquinas.length!==0?value.maquinas.length:""}
                            </div>
                        </div>

                          <Typography
                            fontWeight="bold"
                            fontSize="14.4px"
                            color="#fff"
                            sx={{paddingTop:"15px"}}
                          >
                            {(value.nombreUbicacion == "sin_ubicacion_asignada" ? t("sin_ubicacion_asignada") : value.nombreUbicacion )}
                          </Typography>
                        </Grid>
                      }
                    >
                      {value.maquinas.map(((maquina,index)=>{
                        return(
                          <div key={index}>
                            <Grid container justifyContent="flex-start">
                              <Grid item lg={1}>                                
                                <Typography
                                  style={{fontWeight: "bold",fontSize: "12px",color: "#2f4554"}}
                                >
                                  {`${index+1}.`}
                                </Typography>
                              </Grid>
                              <Grid container item lg={5} justifyContent="flex-start">
                                <Grid container justifyContent="flex-start">
                                  <Grid item lg={12}>
                                    <Typography
                                      style={{fontWeight: "bold",fontSize: "12px",color: "#2f4554"}}
                                    >
                                      {translateMachineName(maquina.nombre,t,maquina?.id_area_operacion)}
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={12}>
                                    <Typography
                                      style={{fontStyle: "italic",fontSize: "12px",color: "#717171"}}
                                    >
                                      {t("ultima_actualizacion")}
                                    </Typography>
                                  </Grid>                   
                                </Grid>
                              </Grid>
                              <Grid item lg={6}>
                                <Grid container justifyContent="flex-start">
                                  <Grid item lg={12}>
                                    <Typography
                                      style={{fontWeight: "bold",fontSize: "12px"}}
                                      color={maquina.id_tipo_movimiento?getColor(maquina.id_tipo_movimiento):"transparent"}
                                    >
                                      {maquina.id_tipo_movimiento?getDescripcion(maquina.id_tipo_movimiento):"-"}
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={12}>
                                    <Typography
                                      style={{fontSize: "12px",color: "#717171"}}
                                    >
                                      {/* {format(new Date(maquina.fecha_ultima_ubicacion), "dd/MM/yyyy HH:mm")} */}
                                      {(i18n.language === 'en')?
                                          formatearFechaISOtoUSFormat(maquina?.ultima_actualizacion)
                                        :
                                          formatearFechaISO(maquina?.ultima_actualizacion)
                                        }
                                    </Typography>
                                  </Grid>                   
                                </Grid>
                              </Grid>
                              {/* {`${index+1}. ${maquina.nombre}`} */}
                            </Grid>
                            {(value.maquinas.length>(index+1)) &&
                              <>
                                <br/>
                                <Divider/>
                                <br/>
                              </>
                            }
                          </div>
                        )

                      }))}
                    </GenericTooltip>


                </Grid>
              )
            })}
        </Grid>
      </Box>
    </>
  )
}

export default UbicacionesCard;