import React, { useState, useContext, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  createStyles,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Menu, Brightness4, Brightness7 } from "@material-ui/icons";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { AccountContext } from "../../authentication/Account";
import Store from "../../context";
import NavDrawer from "./NavDrawer";
import { useHistory } from "react-router-dom";
import NavDrawerAdmin from "./NavDrawerAdmin";
import Button from "@mui/material/Button";
import { ExitToAppOutlined } from "@material-ui/icons";
import PersonIcon from "@material-ui/icons/Person";
import "../styles.css";
import ChangePassword from "../../authentication/ChangePassword";
import logo from "../../assets/images/LOGO-MINE-WATCH-final-blanco.png";
import NavDrawerEmpresaAdmin from "./NavDrawerEmpresaAdmin";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Grid from "@mui/material/Grid";
import { useTranslation } from 'react-i18next';
import { GeneralMinaContext } from "../../context/GeneralMina";


const useStyles = makeStyles((theme) => ({
  icon: {
    color:"rgb(178,181,184)",
    margin:"0px",
    "&:hover": {
      color: "#7c7c7c",
      cursor: "pointer"
    }
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  root2: {
    color: "white",
  },
  root3: {
   // backgroundColor: "#005829",
   backgroundColor: "#2f4554",
  }
}));

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  
  "& .MuiDialog-paperWidthSm": {
    maxWidth: '800px'
}

}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
/*         <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */""
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const NavBar = () => {
  const { t, i18n } = useTranslation();

  const {  NavDrawerEquipo: NavDrawerEquipoContext, setNavDrawerEquipo } = useContext(GeneralMinaContext);

  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(Store);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [role, setRole] = useState();
  const [isVigalab, setIsVigalab] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [idEmpresa, setIdEmpresa] = useState(0);
  let mode;
  const changeTheme = (mode) => {
    if (state.myTheme === "dark") {
      mode = "light";
    } else {
      mode = "dark";
    }
    dispatch({ type: "THEME", payload: mode });
  };

  const ToggleButton = () => {
    if (state.myTheme === "dark") {
      return <Brightness7 />;
    } else {
      return <Brightness4 />;
    }
  };
  const toggleDrawer = (booleanValue) => () => {
    setDrawerOpened(booleanValue);
  };

  const [/* status */ ,setStatus] = useState(false);

  const { getSession, logout } = useContext(AccountContext);

  useEffect(() => {
    getSession().then((session) => {
      const { idToken } = session;
      const { jwtToken, payload } = idToken;
      const { email, email_verified } = payload;
      try {
        setUserEmail(email);
        const userType = session["custom:role"];
        setRole(userType);
        const userEmpresa = payload["custom:id_empresa"];
        setIdEmpresa(userEmpresa);
        if(userEmpresa == 1){
          setIsVigalab(true);
        }

      } catch (err) {
        console.log(err);
      }
      setStatus(true);
    });
  }, [getSession]);

  const handleLogout = (e) => {
    //e.preventDefault();
    logout();
    setStatus(false);
    //TODO: CLEAR LOCAL DATA
    //console.log("LOCAL Storage Before", localStorage);
    //localStorage.clear();
    //console.log("LOCAL Storage After", localStorage);
    history.push("/login");
  };

  let varAux = role != undefined ? role.includes("admin") : false;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.root3}>
        <Toolbar>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item lg={4} md={4} xs={4}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <Menu />
                </IconButton>
              </Grid>
              <Grid item>
                <img src={logo} width="150px" alt="Logo"/>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={7} md={7} xs={7}>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Button 
                  sx={{
                    pointerEvents:((i18n.language === 'es')?'none':'auto'),
                    color:((i18n.language === 'es')?'#00B7C4':'#fff'),
                  }}
                  style={{fontSize:"14.4px", textTransform:"uppercase"}}
                  onClick={()=>{
                    localStorage.setItem('idioma','es'); 
                    i18n.changeLanguage('es')}
                  }
                >
                  ES
                </Button>

                |

                <Button 
                  sx={{
                    pointerEvents:((i18n.language === 'en')?'none':'auto'),
                    color:((i18n.language === 'en')?'#00B7C4':'#fff'),
                  }}
                  style={{fontSize:"14.4px", textTransform:"uppercase"}}
                  onClick={()=>{
                    localStorage.setItem('idioma','en'); 
                    i18n.changeLanguage('en')}
                  }
                >
                  EN
                </Button>
              </Grid>

              <Grid item sx={{display: { xs: "block", lg: "block", md:"block" }}}>
                <Button 
                  className={`colorBoton`}
                  style={{fontSize:"14.4px", textTransform:"lowercase"}}
                  onClick={handleClickOpen}
                  startIcon={
                    <PersonIcon  
                    />
                  } 
                >
                  {userEmail}
                </Button>
              </Grid>

              <Grid item lg={2} md={2} xs={2} sx={{display: { xs: "block", lg: "block", md:"block" }}}>
                <Button
                  onClick={handleLogout}
                  className={`colorBoton`}
                  size="large"
                  startIcon={<ExitToAppOutlined />}
                  style = {{textTransform:"capitalize", fontSize:"14.4px"}}
                >
                  {t("cerrar_sesion")}
                </Button>
              </Grid>

            </Grid>
          </Grid>

        </Grid>


          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              <Grid
                container
                  justifyContent="space-between"
                  alignItems="center"
              
              >
                <div style = {{fontSize:"18px"}}>                
                  Actualizar datos de usuario
                </div>
                <CancelOutlinedIcon 
                  className={classes.icon}
                  onClick={handleClose}
                  style={{fontSize:"30px"}}
                />
              </Grid>
            </BootstrapDialogTitle>
            <DialogContent dividers style = {{padding:"40px"}}>
              <ChangePassword />
              {/* <ChangeEmail /> */}
            </DialogContent>
          </BootstrapDialog>

        </Toolbar>
      </AppBar>
      {varAux ? (
        isVigalab?( 
        <NavDrawerAdmin 
          drawerOpened={drawerOpened}
          toggleDrawer={toggleDrawer}
        />) : <NavDrawerEmpresaAdmin
        idEmpresa={idEmpresa}
        drawerOpened={drawerOpened}
        toggleDrawer={toggleDrawer}
      />
      ) : (
        <NavDrawer drawerOpened={drawerOpened} toggleDrawer={toggleDrawer} idEmpresa={idEmpresa}/>
      )}
    </div>
  );
};
export default NavBar;
