export const getMotivosJustificaciones = async (id_justificacion_grupo) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_justificacion_grupo
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:raw
      };

      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/justificacion/motivos",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const getGruposMotivosJustificaciones = async (id_area_operacion) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");


      var raw = JSON.stringify({
        id_area_operacion
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/justificacion/-motivos-grupo",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const getMaquinasByIdAreaOperacion = async (id_area_operacion) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id_area_operacion:id_area_operacion
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/maquina/by-area-operacion",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  };

  export const getMaquinasJustificacion = async (fecha,id_area_operacion) => {
    try{

      const month = fecha.getMonth() + 1;
      const year = fecha.getFullYear();

      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "id_area_operacion": id_area_operacion,
        "month":month,
        "year":year
    });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/justificacion/get-justificaciones",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const getMaquinasJustificadas = async (fecha,id_area_operacion) => {
    try{

      const month = fecha.getMonth() + 1;
      const year = fecha.getFullYear();

      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "id_area_operacion": id_area_operacion,
        "month":month,
        "year":year
    });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/justificacion/get-maquinas-justificadas",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const getUltimaUbicacionJustificacion = async (id_area_operacion,id_movimiento) => {
    try{

      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id_area_operacion,
        id_movimiento
    });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/justificacion/get-ultima-ubicacion",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const getUbicacionesPorIdOperacion = async (id_area_operacion) => {
    try{

      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id_area_operacion,
    });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/area-operacion/get-ubicaciones",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const insertJustificacion = async (id_area_operacion,id_tipo_justificacion,observacion,fecha_inicio,fecha_termino,id_maquina,id_registro_movimiento,id_tipo_movimiento,ubicacion) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
      let raw = {
        id_area_operacion,
        id_tipo_justificacion,
        fecha_inicio,
        fecha_termino,
        id_maquina,
        id_registro_movimiento,
        id_tipo_movimiento,
        ubicacion
      };
      if(observacion && observacion != ""){
        raw.observacion = observacion
      }
   
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:JSON.stringify(raw)
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/justificacion/insert",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const insertJustificacionMasiva = async (id_area_operacion,id_tipo_justificacion,observacion,fecha_inicio,fecha_termino,maquinas,id_registro_movimiento,id_tipo_movimiento,ubicacion) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");

      let raw = {
        id_area_operacion,
        id_tipo_justificacion,
        fecha_inicio,
        fecha_termino,
        maquinas,
        id_registro_movimiento,
        id_tipo_movimiento,
        ubicacion
      };
      
      if(observacion && observacion != ""){
        raw.observacion = observacion
      }

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:JSON.stringify(raw)
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/justificacion/insert-masiva",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }
  export const getMaquinasJustificadasDescargar = async (fecha_inicio,fecha_termino,id_area_operacion) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id_area_operacion,
        fecha_inicio,
        fecha_termino
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/justificacion/get-maquinas-justificadas-descarga",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }