import React,{Component} from 'react';
import Chart from 'react-apexcharts'

class BarChart extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        options: {
          chart: {
            id: 'apexchart-example'
          },
          xaxis: {
            categories: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
          }
        },
        series: [{
          name: 'series-1',
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 80, 16,17,18,109,20,35,17,16,19,50,21,32,23,24]
        }]
      }
    }
    render() {
      return (
        <Chart options={this.state.options} series={this.state.series} type="bar" width={1250} height={320} />
      )
    }
  }

  export default BarChart;