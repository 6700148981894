import React from 'react'
import { useTranslation } from 'react-i18next';
import NavBar from '../../components/organisms/NavBar'
import { Container } from '@material-ui/core'
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import { TabGenerica } from '../../components/organisms/TabGenerica'
import { SolicitudesVicons } from './Vicons/SolicitudesVicons'
import { SolicitudesCiclosGPS } from './CiclosGps/SolicitudesCiclosGPS'
import { SolicitudesTipoGeocerca } from './TipoGeocerca/SolicitudesTipoGeocerca'
import { useQuery } from '../../hooks/route/useGetQueryString'
import { BreadCrumbsGenerico } from '../../components/molecules/BreadCrumbsGenerico';

const Solicitudes = ({idMina, nombreMina}) => {
const { t } = useTranslation();
let query = useQuery();

let getInitialValue = () =>{
    //?request=value
    let queryDefaultValue = query.get("request")
    
    if(queryDefaultValue == "vicons"){
        return 0
    }else if(queryDefaultValue == "ciclosgps"){
        return 1
    }else if(queryDefaultValue == "tiposgeocercas"){
        return 2
    }else{
        return 0
    }
    } 
  return (
    <>
    <NavBar/>
    <Container>
        <br/>
        <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
                <BreadCrumbsGenerico
                nombreMina={nombreMina}
                dataRuta={ [
                { id: 1, endpoint: "/", texto: `Mine-Watch` },
                { id: 2, texto: `${t('minaSingular')} ${nombreMina}` },
                { id: 3, texto: t("solicitudes"),destacar:true }
                ]}
                />
            </Grid>
        </Grid>
        <br/>
        <br/>
        <TabGenerica
            tabs={[{
                label:'VICONS',
                children: <SolicitudesVicons idMina={idMina} />
            },{
                label:'CICLOS GPS',
                children:<SolicitudesCiclosGPS idMina={idMina}/>
            },{
                label:'TIPOS GEOCERCAS',
                children:<SolicitudesTipoGeocerca idMina={idMina} nombreMina={nombreMina}/>
            }]}
            selected={getInitialValue()}
        />
    </Container>
    </>
  )
}

export default Solicitudes;