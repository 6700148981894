import React, {useState} from 'react';
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import {
    CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import {MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { es } from "date-fns/locale";
import { makeStyles } from "@material-ui/core/styles";
import PlanillaIcon from '@mui/icons-material/ContentPaste';
import IconoDescargar from '@mui/icons-material/FileDownload';
import {utils,writeFile} from "xlsx";
import { format } from "date-fns";

import '../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from '../../commons/Idiomas';
import { getEncuentrosControlFrentesDescargar } from '../../services/controlFrentes';
import { calcularDiferenciaDeDias, milisegundosAHoras, secondsToHours } from '../../commons/FormatearTiempo';
import { formatearFechaISO, formatearSoloFechaISOtoCL } from '../../commons/FormatearFecha';
import { getActividadUsuario } from '../../services/analytics';


const useStyles = makeStyles({
  icon:{
    fontSize: "40px",
    marginRight:"10px",
    /* color: "#2f4554" */
  },
  titulo:{
    fontWeight: "bold",
    fontSize: "14.4px",
  },
  texto:{
    fontSize: "14.4px"
  },
  buttonSelected: {
    /* width: "180px", */
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  buttonDisabled: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    alignSelf: "center",
    pointerEvents: "none",
    color: "#a4a4a4"

  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        fontSize:"14.4px"
      }
    },

    '& .MuiOutlinedInput-root': { 
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
  },

  }
});

  const columnsToInclude = [
    "usuario",
    "primer_registro",
    "ultimo_registro",
    "count_clicks",
    "count_keys",
    "total_segundos_transcurridos"
  ];

export default function ModalDescargaAnalytics({idMina, nombreMina, open, onClickClose}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [loading, setLoading] = useState(false)
  const [checkDatosAcumuladosByUsuario, setCheckDatosAcumuladosByUsuario] = useState(false);
  const [checkDatosAcumuladosByUsuarioAndDia, setCheckDatosAcumuladosByUsuarioAndDia] = useState(false)

  const handleClose = () => {
    onClickClose(false);
  };
  const handleStartDateChange = (date) => {
    setFechaInicio(date);

    if(calcularDiferenciaDeDias(date,fechaFin) >= 30 ){
        const maxDate = new Date(date);
        maxDate.setDate(date.getDate() + 30);
        setFechaFin(maxDate);
    }
  };

  const onClickDescarga=async(checkDatosAcumuladosByUsuarioAux, checkDatosAcumuladosByUsuarioAndDiaAux)=>{
    setLoading(true);
    const workbook = utils.book_new();
    let fechaInicioFormated = format(fechaInicio,"yyyy-MM-dd");
    let fechaFinFormatted = format(fechaFin,"yyyy-MM-dd");
    let onlyFechaInicio = format(fechaInicio,"yyyy-MM-dd");
    let onlyFechaTermino = format(fechaFin,"yyyy-MM-dd");

    if(checkDatosAcumuladosByUsuarioAux){
        const dataParaDescargar = await getActividadUsuario(idMina,fechaInicioFormated,fechaFinFormatted,null,false,false,true);
        if(dataParaDescargar.success){
        const filteredData = dataParaDescargar?.data?.map((actividad) => {
            const filteredEncuentro = {};
            columnsToInclude.forEach((column) => {
                let columnName = column;
    
                switch (column) {
                    case "usuario":
                        columnName = t("usuario");
                        break;
                    case "primer_registro":
                        columnName = t("primer_registro");
                        break;
                    case "ultimo_registro":
                        columnName = t("ultimo_registro");
                        break;
                    case "count_clicks":
                        columnName = t("clicks_presionados");
                        break;
                    case "count_keys":
                        columnName = t("teclas_presionadas");
                        break;
                    case "total_segundos_transcurridos":
                        columnName = t("tiempo_total")+" Hrs";
                        break;
                }
    
                if(column == "primer_registro" || column == "ultimo_registro"){
                  filteredEncuentro[columnName] = formatearFechaISO(actividad[column])
                }else if(column =="total_segundos_transcurridos"){
                    filteredEncuentro[columnName] = secondsToHours(actividad[column])
                }
                else{
                filteredEncuentro[columnName] = actividad[column];
                }
            });
            
            return filteredEncuentro;
            });
    
            const worksheetResumen = utils.json_to_sheet(filteredData);
            utils.book_append_sheet(workbook, worksheetResumen, t("actividad_por_usuario"));
        }

    }

    if(checkDatosAcumuladosByUsuarioAndDiaAux){
        const dataParaDescargar = await getActividadUsuario(idMina,fechaInicioFormated,fechaFinFormatted,null,true,false,true);
        if(dataParaDescargar.success){
        const filteredData = dataParaDescargar?.data?.map((actividad) => {
            const filteredEncuentro = {};
            [...columnsToInclude,"fecha"].forEach((column) => {
                let columnName = column;
    
                switch (column) {
                    case "usuario":
                        columnName = t("usuario");
                        break;
                    case "fecha":
                        columnName = t("dia");
                        break;
                    case "primer_registro":
                        columnName = t("primer_registro");
                        break;
                    case "ultimo_registro":
                        columnName = t("ultimo_registro");
                        break;
                    case "count_clicks":
                        columnName = t("clicks_presionados");
                        break;
                    case "count_keys":
                        columnName = t("teclas_presionadas");
                        break;
                    case "total_segundos_transcurridos":
                        columnName = t("tiempo_total")+" Hrs";
                        break;
                }
    
                if(column == "primer_registro" || column == "ultimo_registro"){
                
                    filteredEncuentro[columnName] = formatearFechaISO(actividad[column])
                
                }else if(column == "fecha"){
                    filteredEncuentro[columnName] = formatearSoloFechaISOtoCL(actividad[column])
                }else if(column =="total_segundos_transcurridos"){
                    filteredEncuentro[columnName] = secondsToHours(actividad[column])
                }else{
                 
                    filteredEncuentro[columnName] = actividad[column];                
                }
            });
            
            return filteredEncuentro;
            });
    
            const worksheetResumen = utils.json_to_sheet(filteredData);
            utils.book_append_sheet(workbook, worksheetResumen, t("actividad_por_usuario_fecha")); 
        }
    }


    writeFile(workbook,`${t("datos")}_sesion_usuarios_${nombreMina}_${onlyFechaInicio}-${onlyFechaTermino}.xlsx`);
    setLoading(false);
  }
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        /* fullWidth */
        maxWidth="md"
      >
        <DialogContent sx = {{padding:"0"}}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{padding:"13px 20px"}}
          >
            <Grid item lg={9}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid container item direction={"row"}>
                  <PlanillaIcon className={classes.icon}/>
                  <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                    {t("descargar_datos")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.titulo} style={{fontSize:"13px", fontWeight:"normal",marginLeft:"34px"}}>
                    {`30 ${t("dias_maximo")}`}
                    </Typography>
                </Grid>
              </Grid>
            </Grid>
            <div onClick={handleClose} className={classes.texto} style={{fontStyle:"italic", cursor:"pointer", display:loading?'none' : 'block'}}>
              {t("cerrar")}
            </div>
          </Grid>

          <Divider/>
          
          <br/>

          <Grid 
            container 
            alignItems='center' 
            justifyContent='center'
            sx={{padding:"13px 20px"}}
          >
            <Grid item>
              <Grid container 
                alignItems='center' 
                justifyContent='center'
                direction="column"
              >
                <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                  {t("fecha_inicio")}
                </Typography>
                <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    autoOk
                    variant='static'
                    className={classes.datepicker}
                    format="dd/MM/yyyy"
                    /* cancelLabel="Cancelar" */
                    size="small"
                    inputVariant="outlined"
                    value={fechaInicio}
                    onChange={date => handleStartDateChange(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container 
                alignItems='center' 
                justifyContent='center'
                direction="column"
              >
                <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                  {t("fecha_fin")}
                </Typography>
                <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    autoOk
                    variant='static'
                    className={classes.datepicker}
                    format="dd/MM/yyyy"
                    /* cancelLabel="Cancelar" */
                    size="small"
                    inputVariant="outlined"
                    value={fechaFin}
                    onChange={date => setFechaFin(date)}
                    maxDate={new Date(fechaInicio).setDate(fechaInicio.getDate()+30)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems={'flex-start'} sx={{padding:"0px 10px"}}>
            <Grid item container alignItems='center' justifyContent={"center"}>
              <Grid item>
                <FormControlLabel 
                  control={<Checkbox onChange={()=>setCheckDatosAcumuladosByUsuario(!checkDatosAcumuladosByUsuario)} checked={checkDatosAcumuladosByUsuario} color="primary"/>} 
                  label={
                    <Typography style={{fontSize:"14.4px", fontWeight:(checkDatosAcumuladosByUsuario?"bold":"normal")}}>
                      {t("actividad_por_usuario")}
                    </Typography>
                  }
                />
              </Grid>

              <Grid item>
                <FormControlLabel 
                  control={<Checkbox onChange={()=>setCheckDatosAcumuladosByUsuarioAndDia(!checkDatosAcumuladosByUsuarioAndDia)} checked={checkDatosAcumuladosByUsuarioAndDia} color="primary"/>} 
                  label={
                    <Typography style={{fontSize:"14.4px", fontWeight:(checkDatosAcumuladosByUsuarioAndDia?"bold":"normal")}}>
                      {t("actividad_por_usuario_fecha")}
                    </Typography>
                  }
                />
              </Grid>

            </Grid>
          </Grid>

        </DialogContent>


        <DialogActions>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{padding:"5px 20px"}}
          >           
            {
            !loading?
                <Grid>
                  <Button 
                      onClick={async()=> await onClickDescarga(checkDatosAcumuladosByUsuario, checkDatosAcumuladosByUsuarioAndDia)}
                      disabled={!checkDatosAcumuladosByUsuario && !checkDatosAcumuladosByUsuarioAndDia}
                  > 
                  <IconoDescargar sx={{marginRight:"5px"}}/> {t("descargar")}
                  </Button>
                </Grid>
                : (
                <Grid>
                    <CircularProgress style = {{color: "#2f4554",marginBottom:"20px"}} size = {80}/>
                    <br/>
                    <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold",marginBottom:"20px"}}>
                    Descargando...
                    </Typography>
                </Grid>
                )
            }
          </Grid>
        </DialogActions>
        <br/>
      </Dialog>
    </div>
  );
}