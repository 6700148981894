import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const Migajaspan = () => {

  return (
    <Grid container direction="column"  alignItems="center" style={{marginTop: "20px", alignItems: 'flex-start', marginBottom: "20px"}}>
      <Typography style={{color: '#ff6c1c', fontWeight: 'bold', fontSize: '20px'}}>PRODUCTIVIDAD DE EQUIPOS</Typography>
    </Grid>
  );
};

export default Migajaspan;
