import domtoimage from "dom-to-image";
import { useState } from "react";

export function useScreenshots() {
  const [screenshots, setScreenshots] = useState({
    santaRosa: { dia: [], noche: [] },
    lasChispas: { dia: [], noche: [] }
  });
  const [loading, setLoading] = useState({
    santaRosa: { dia: false, noche: false },
    lasChispas: { dia: false, noche: false }
  });
  const [loadingDownload, setLoadingDownload] = useState(false);

  async function handleTakeScreenshoot(elementId, tab, turno) {
    const element = document.getElementById(elementId);
    setLoading({ ...loading, [tab]: { ...loading[tab], [turno]: true } });

    try {
      const jpeg = await domtoimage.toJpeg(element, {
        bgcolor: "white",
        style: {
          backgroundColor: "white"
        },
        width: element.offsetWidth,
        height: element.offsetHeight,
        filter: (node) => node.id !== "control-de-frentes-popover"
      });

      setScreenshots((prevScreenshots) => ({
        ...prevScreenshots,
        [tab]: {
          ...prevScreenshots[tab],
          [turno]: [...prevScreenshots[tab][turno], jpeg]
        }
      }));
    } catch (err) {
      console.warn("Error taking screenshot", err);
    } finally {
      setLoading({ ...loading, [tab]: { ...loading[tab], [turno]: false } });
    }
  }

  function handleJustDownload(
    elementId, 
    currentShift, 
    nombreSubMina, 
    date
  ) {
    const element = document.getElementById(elementId);
    setLoadingDownload(true);

    domtoimage
      .toJpeg(element, {
        bgcolor: "white",
        style: {
          backgroundColor: "white"
        },
        width: element.offsetWidth,
        height: element.offsetHeight,
        filter: (node) => node.id !== "control-de-frentes-popover"
      })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${nombreSubMina}-Turno-${currentShift}-${date}.jpeg`;
        link.href = dataUrl;
        link.click();

        URL.revokeObjectURL(dataUrl);
      })
      .catch((err) => {
        console.warn("Error taking screenshot", err);
      })
      .finally(() => {
        setLoadingDownload(false);
      });
  }

  function handleRemoveScreenshot(tab, turno, index = 0) {
    setScreenshots((prevScreenshots) => {
      const updatedScreenshots = prevScreenshots[tab][turno].filter(
        (_, i) => i !== index
      );
      return {
        ...prevScreenshots,
        [tab]: {
          ...prevScreenshots[tab],
          [turno]: updatedScreenshots
        }
      };
    });
  }

  return {
    screenshots,
    loading,
    loadingDownload,
    handleTakeScreenshoot,
    handleJustDownload,
    handleRemoveScreenshot
  };
}
