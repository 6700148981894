import React from 'react'
import { TablaViconsSolicitud } from './TablaViconsSolicitud'

export const SolicitudesVicons = ({idMina}) => {
  return (
    <div>
        <TablaViconsSolicitud idMina={idMina}/>
    </div>
  )
}
