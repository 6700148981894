import React, { useState } from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    Typography,
  } from '@mui/material';
  import { Box, Button, Checkbox, CircularProgress, FormControl, InputLabel, Link, MenuItem, TextField, makeStyles } from '@material-ui/core';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import SectionHeader from '../molecules/SectionHeader';
import { insertVueltasManual } from '../../services/administracion';

let t_tipo_vuelta = [{
  id:1,
  descripcion:'Vuelta de 2 puntos'
},{
  id:2,
  descripcion:'Vuelta de 3 puntos'
},{
  id:3,
  descripcion:'Vuelta de ingreso'
}]

const useStyles = makeStyles({
  table: {
    width:"100%"
  },
  root: {
    backgroundColor: "#2f4554",
    margin: "px 0",
    textAlign:'center',
    color: "white",
    "&:hover": {
      backgroundColor: "rgb(0, 183, 196)"
    },
    "&:disabled": {
      backgroundColor: "gray"
    }
  }
});


export const ModalVueltasManual = ({id_mina, maquina, turnos, vicons, portales, reglasVueltas, setVisibilidadModal, visibilidadModal, setReloadCharts}) => {
  let  factor_capacidad = maquina?.factor_capacidad ? maquina.factor_capacidad : 1;
  let toneladas = id_mina == 24 ? maquina.capacidad_m3 / 1000 : Math.round(maquina.toneladas)
  let defaultVuelta = {
    "id_area_operacion":id_mina,
    "id_vibot":maquina.id_vibot,
    "id_maquina":maquina.id,
    "maquina":maquina.nombre,
    "dia":"",
    "id_turno":"",
    "turno":"",
    "id_inicio":"",
    "nombre_inicio":"",
    "fecha_inicio":"",
    "id_destino":"",
    "nombre_destino":"",
    "fecha_destino":"",
    "id_fin":"",
    "nombre_fin":"",
    "fecha_fin":"",
    "ubicacion":"",
    "total_minutos":"",
    "toneladas":toneladas,
    "id_tipo_vuelta":"",
    "descripcion":"",
    "factor_capacidad":factor_capacidad,
    "densidad":""
  }
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [vueltas, setVueltas] = useState([defaultVuelta]);
  const [verSuccess, setVerSuccess] = useState(false);
  const [verError, setVerError] = useState(false)
  const [verErrorInsercion, setVerErrorInsercion] = useState(false);

  const handleAddVuelta = () => {
    setVueltas([...vueltas, defaultVuelta]);
  };

  const handleInputChange = (index, field, value) => {
    const newVueltas = vueltas.map((vuelta, i) =>
      i === index ? { ...vuelta, [field]: value } : vuelta
    );
    setVueltas(newVueltas);
  };

  const handleTwoInputChange = (index, field, value, field2, value2) => {
    const newVueltas = vueltas.map((vuelta, i) =>
      i === index ? { ...vuelta, [field]: value, [field2]: value2 } : vuelta
    );
    setVueltas(newVueltas);
  };

  const validateFields = () => {
    for (let i = 0; i < vueltas.length; i++) {
      const vuelta = vueltas[i];
      if (!vuelta.dia || !vuelta.id_turno || !vuelta.id_inicio || !vuelta.fecha_inicio || !vuelta.id_destino || 
          !vuelta.fecha_destino || !vuelta.id_fin || !vuelta.fecha_fin || !vuelta.total_minutos || 
          !vuelta.id_tipo_vuelta || !vuelta.ubicacion || !vuelta.descripcion) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if(!validateFields()){
      setVerError(true);
      return;
    }
    setLoading(true)
    insertVueltasManual(id_mina, vueltas)
    .then(()=>{
      setLoading(false);
      setVerSuccess(true)
      setVueltas([defaultVuelta]);
      setReloadCharts((prevState)  => prevState+=1);
    })
    .catch((error) =>{
      console.log(error);
      setVerErrorInsercion(true)
      setLoading(false);
    })
  };

  const handleClose = () =>{
    setVueltas([defaultVuelta]);
    setVisibilidadModal(false)
  }

  const handleCloseSuccess = () =>{
    setVerSuccess(false)
  }
  const handleCloseError = () =>{
    setVerError(false)
  }
  const handleCloseErrorInsercion = () =>{
    setVerErrorInsercion(false)
  }

  return (
    <div>
        <Dialog
            open={visibilidadModal}
            onClose={handleClose}
            // fullWidth
            // maxWidth="lg"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "900px",
                  maxWidth: "70%",
                  padding:'0 20px'
                },
              },
            }}
        >
               <DialogContent>
                <Grid container flexDirection={'column'}>
                  <Grid item>
                    <SectionHeader
                    title={"Vueltas"}
                    subtitle={"Creación de vueltas no detectadas"}
                    icon={<RouteOutlinedIcon fontSize="large"/>}
                    />
                  </Grid>
                    {
                      loading ? 
                      <Grid container item direction={'column'} justifyContent={'center'} alignItems="center">
                        <CircularProgress style = {{color: "#2f4554",marginBottom:"20px"}} size = {60}/>
                        <br/>
                        <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold",marginBottom:"20px"}}>
                          Enviando...
                        </Typography>
                      </Grid> :
                      vueltas.map((vuelta, index) =>(
                        <Grid key={index} container item sx={{margin:'0 auto', width:'708px'}}>
                         {
                          index > 0 && 
                            <Grid container item justifyContent={'flex-end'}>
                              <Button onClick={() => {
                                const nuevasVueltas = [...vueltas];
                                nuevasVueltas.splice(index,1);
                                setVueltas(nuevasVueltas)
                              }}>X</Button>
                            </Grid>
                         }
                          <Grid key={index} container item flexDirection={'row'} flexWrap={'wrap'} gap={1} sx={{marginBottom:5}} justifyContent={'flex-start'}>
                            <TextField
                              id="margin-none"
                              disabled
                              name="maquina"
                              type="text"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              style={{ marginTop: "15px" }}
                              variant='outlined'
                              label='Maquina'
                              size='small'
                              margin='none'
                              value={vuelta.maquina}
                              onChange={(e) => handleInputChange(index, 'maquina', e.target.value)}
                            />
                            <TextField
                              id="margin-none"
                              name="dia"
                              type="date"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              style={{ marginTop: "15px", minWidth:'230px' }}
                              variant='outlined'
                              label='Dia'
                              size='small'
                              margin='none'
                              value={vuelta.dia}
                              onChange={(e) => handleInputChange(index, 'dia', e.target.value)}
                            />
                            <Select
                              renderValue={(value) => {
                                const selectedTurno = turnos.find((el) => el.id === value);
                                return selectedTurno ? selectedTurno.nombre : <Typography color="gray">Turno</Typography>;
                              }}
                              displayEmpty
                              name="turno"
                              placeholder="Turno"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={vuelta.id_turno}
                              onChange={(e) => {
                                const selectedOption = turnos.find((el) => el.id === e.target.value);
                                handleTwoInputChange(index,'turno',selectedOption.nombre, 'id_turno', e.target.value);
                              }}
                              style={{ maxHeight: '40px', marginTop: '15px', width: '230px' }}
                            >
                              {turnos.map((el, index) => (
                                <MenuItem value={el.id} key={el.id + "-" + index}>{el.nombre}</MenuItem>
                              ))}
                            </Select>
                            <Select
                              renderValue={(value) => {
                                const vueltaInicio = vicons.find((el) => el.id === value);;
                                return vueltaInicio ? vueltaInicio.descripcion : <Typography color="gray">Inicio</Typography>;
                              }}
                              displayEmpty
                              name="inicio"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={vuelta.id_inicio}
                              onChange={(e) => {
                                const selectedOption = vicons.find((el) => el.id === e.target.value);
                                handleTwoInputChange(index,'nombre_inicio',selectedOption.descripcion, 'id_inicio', e.target.value);
                              }}
                              style={{maxHeight:'40px',marginTop:'15px', width:'230px'}}
                              >
                              <MenuItem key={0} value={0}>
                                  {"Inicio"}
                              </MenuItem>
                              {
                                  vicons?.map((el,index) => (
                                      <MenuItem value={el.id} key={el.id+"-"+index}>{el.descripcion}</MenuItem>
                                  ))
                              }
                            </Select>
                            <TextField
                              id="margin-none"
                              name="fecha_inicio"
                              type="datetime-local"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              style={{ marginTop: "15px", minWidth:'230px' }}
                              variant='outlined'
                              label='Fecha inicio'
                              size='small'
                              margin='none'
                              onChange={(e) => handleInputChange(index, 'fecha_inicio', e.target.value)}
                              value={vuelta.fecha_inicio}
                            />
                            <Select
                              renderValue={(value) => {
                                const vueltaOrigen = vicons.find((el) => el.id === value);
                                return vueltaOrigen ? vueltaOrigen.descripcion : <Typography color="gray">Destino</Typography>;
                              }}
                              displayEmpty
                              name="destino"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={vuelta.id_destino}
                              onChange={(e) => {
                                const selectedOption = vicons.find((el) => el.id === e.target.value);
                                handleTwoInputChange(index,'nombre_destino',selectedOption.descripcion, 'id_destino', e.target.value);
                              }}
                              style={{maxHeight:'40px',marginTop:'15px', width:'230px'}}
                              >
                              {
                                  vicons?.map((el,index) => (
                                      <MenuItem value={el.id} key={el.id+"-"+index}>{el.descripcion}</MenuItem>
                                  ))
                              }
                            </Select>
                            <TextField
                              id="margin-none"
                              name="fecha_destino"
                              type="datetime-local"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              style={{ marginTop: "15px", minWidth:'230px' }}
                              variant='outlined'
                              label='Fecha destino'
                              size='small'
                              margin='none'
                              onChange={(e) => handleInputChange(index, 'fecha_destino', e.target.value)}
                              value={vuelta.fecha_destino}
                            />
                            
                            <Select
                              renderValue={(value) => {
                                const vueltaDestino = vicons.find((el) => el.id === value);
                                return vueltaDestino ? vueltaDestino.descripcion : <Typography color="gray">Fin</Typography>;
                              }}
                              displayEmpty
                              value={vuelta.id_fin}
                              name="fin"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(e) => {
                                const selectedOption = vicons.find((el) => el.id === e.target.value);
                                handleTwoInputChange(index,'nombre_fin',selectedOption.descripcion, 'id_fin', e.target.value);
                              }}
                              style={{maxHeight:'40px',marginTop:'15px', width:'230px'}}
                              >
                              {
                                  vicons?.map((el,index) => (
                                      <MenuItem value={el.id} key={el.id+"-"+index}>{el.descripcion}</MenuItem>
                                  ))
                              }
                            </Select>
                            <TextField
                              id="margin-none"
                              name="fecha_fin"
                              type="datetime-local"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              style={{ marginTop: "15px", minWidth:'230px' }}
                              variant='outlined'
                              label='Fecha Fin'
                              size='small'
                              margin='none'
                              onChange={(e) => handleInputChange(index, 'fecha_fin', e.target.value)}
                              value={vuelta.fecha_fin}
                            />
        
                            <TextField
                              id="margin-none"
                              name="tipo_vuelta"
                              type="text"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              style={{ marginTop: "15px" }}
                              variant='outlined'
                              label='Duracion minutos'
                              size='small'
                              margin='none'
                              value={vuelta.total_minutos}
                              onChange={(e) => handleInputChange(index, 'total_minutos', e.target.value)}
                            />
                            <Select
                              renderValue={(value) => {
                                const tipoVueltaSelected = t_tipo_vuelta.find((el) => el.id === value);;
                                return tipoVueltaSelected ? tipoVueltaSelected.descripcion : <Typography color="gray">Tipo vuelta</Typography>;
                              }}
                              displayEmpty
                              name="id_tipo_vuelta"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={vuelta.id_tipo_vuelta}
                              onChange={(e) => {
                                handleInputChange(index, 'id_tipo_vuelta', e.target.value);
                              }}
                              style={{maxHeight:'40px',marginTop:'15px', width:'230px'}}
                              >
                              {
                                  t_tipo_vuelta?.map((el,index) => (
                                      <MenuItem value={el.id} key={el.id+"-"+index}>{el.descripcion}</MenuItem>
                                  ))
                              }
                            </Select>
                            <Select
                              renderValue={(value) => {
                                const ubicacionSelected = portales.find((el) => el === value);;
                                return ubicacionSelected ? ubicacionSelected : <Typography color="gray">Ubicación</Typography>;
                              }}
                              displayEmpty
                              name="ubicacion"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={vuelta.ubicacion}
                              onChange={(e) => {
                                handleInputChange(index, 'ubicacion', e.target.value);
                              }}
                              style={{maxHeight:'40px',marginTop:'15px', width:'230px'}}
                              >
                              {
                                  portales?.map((el,index) => (
                                      <MenuItem value={el} key={el+"-"+index}>{el}</MenuItem>
                                  ))
                              }
                            </Select>
                            <Select
                            sx={{alignSelf:'flex-start'}}
                              renderValue={(value) => {
                                return value ? value : <Typography color="gray">Descripción</Typography>;
                              }}
                              displayEmpty
                              name="descripcion"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={vuelta.descripcion}
                              onChange={(e) => {
                                let selectedOption = reglasVueltas.find((el) => el.descripcion === e.target.value);
                                selectedOption = selectedOption?.densidad ? selectedOption.densidad : 1;
                                handleTwoInputChange(index,'densidad', selectedOption,'descripcion', e.target.value)
                              }}
                              style={{maxHeight:'40px',marginTop:'15px', width:'230px', alignSelf:'flex-start'}}
                              >
                              {
                                  reglasVueltas?.map((el,index) => (
                                      <MenuItem value={el.descripcion} key={el+"-"+index}>{el.descripcion}</MenuItem>
                                  ))
                              }
                            </Select>
                          </Grid>
                        </Grid>

                      ))
                    }
                  <Grid container item justifyContent={'space-around'}>
                  <Button onClick={handleAddVuelta} variant="contained" color="primary" style={{ marginTop: '15px', marginBottom:'15px' }}>
                    Agregar vuelta
                  </Button>
                  <Button onClick={ handleSubmit } variant="contained" style={{ marginTop: '15px', marginBottom:'15px', minWidth:'150px', backgroundColor:'#00B7C4', color:'white' }}>
                    Enviar
                  </Button>
                  </Grid>

                </Grid>
               </DialogContent>
        </Dialog>
        
        <div>
          <Snackbar anchorOrigin={{vertical:'top',horizontal:'center'}} open={verSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
            <Alert
              onClose={handleCloseSuccess}
              severity="success"
              variant="filled"
              sx={{ width: '100%' }}
            >
              Vueltas insertadas con exito
            </Alert>
          </Snackbar>
          <Snackbar anchorOrigin={{vertical:'top',horizontal:'center'}} open={verError} autoHideDuration={6000} onClose={handleCloseError}>
            <Alert
              onClose={handleCloseError}
              severity="error"
              variant="filled"
              sx={{ width: '100%' }}
            >
              Todos los campos son requeridos
            </Alert>
          </Snackbar>
          <Snackbar anchorOrigin={{vertical:'top',horizontal:'center'}} open={verErrorInsercion} autoHideDuration={6000} onClose={handleCloseErrorInsercion}>
            <Alert
              onClose={handleCloseErrorInsercion}
              severity="error"
              variant="filled"
              sx={{ width: '100%' }}
            >
              Ha ocurrido un error en la insercion
            </Alert>
          </Snackbar>
        </div>
    </div>
  )
}





