import React, { useState, useContext } from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { AccountContext } from "./Account";
import {
  TextField,
  Button
} from "@material-ui/core";

export default () => {
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const btnstyle = { margin: "8px 0", backgroundColor: "#005829" };

  const { getSession, authenticate } = useContext(AccountContext);

  const onSubmit = (event) => {
    event.preventDefault();

    getSession().then(({ user, email }) => {
      authenticate(email, password).then(() => {
        const attributes = [
          new CognitoUserAttribute({ Name: "email", Value: newEmail }),
        ];

        user.updateAttributes(attributes, (err, results) => {
          if (err) {
            console.error(err);
          } else {
            console.log(results);
          }
        });
      });
    });
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <TextField
          value={newEmail}
          onChange={(event) => setNewEmail(event.target.value)}
          label="Nuevo email"
          placeholder="Ingresa nuevo email"
          type="email"
          fullWidth
          required
        />

        <TextField
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          label="Contraseña actual"
          placeholder="Ingresa tu clave"
          type="password"
          fullWidth
          required
        />

        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={onSubmit}
        >
          Cambiar email
        </Button>

        {/*         <label>New Email</label>
        <input
          value={newEmail}
          onChange={(event) => setNewEmail(event.target.value)}
        />

        <label>Current password</label>
        <input
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />

        <button type="submit">Change password</button> */}
      </form>
    </div>
  );
};
