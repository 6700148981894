import { CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, makeStyles, withStyles } from '@material-ui/core';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useGetViconsVersion } from '../../../hooks/solicitudes/useGetViconsVersion';
import {TablaDeCarga} from '../../../components/organisms/TablaDeCarga'
import { format } from 'date-fns';
import { formatearFecha } from '../../../commons/FormatearFecha';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { PopUpConfirmarSolicitud } from './PopUpConfirmarSolicitud';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { acciones_vicon_by_vicon_version } from '../../../services/administracion';
import { PopUpSuccessSolicitud } from './PopUpSuccessSolicitud';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#2f4554",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

export const TablaViconsSolicitud = ({idMina}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [reloadData, setreloadData] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [verPopUpConfirmacion,setVerPopUpConfirmacion] = useState(false);
  const [verPopUpSuccess,setVerPopUpSuccess] = useState(false);
  const {ViconsVersion, loadingViconsVersion} = useGetViconsVersion(idMina, reloadData);

  const onHandleVerPopUpConfirmacion = (boolVal) =>{
    setVerPopUpConfirmacion(boolVal)
  }
  const onHandleVerPopUpSuccess = (boolVal) =>{
    setVerPopUpSuccess(boolVal)
  }



  const initialStateForm = {
    id:0,
    id_area_operacion: idMina,
    tipo_operacion:0
  }

  const formik = useFormik({
    initialValues:initialStateForm,
    validationSchema:Yup.object({}),
    onSubmit:async(values) =>{
        await acciones_vicon_by_vicon_version(values.id,values.tipo_operacion, values.es_frente, values.entrada)
        setreloadData(!reloadData);
        setVerPopUpSuccess(true)
    }
  })

  const handleErrorForm = () => {

    if (!formik.isValid) {
      let msg = "";
      const claves = Object.keys(formik.errors);
      for (const clave of claves) {
        const valor = "* " + formik.errors[clave] + "?";
        msg += valor;
      }
      setAlert({
        open: true,
        message: msg,
        severity: "warning",
      });
      return true;
    }else{
        return false
    }
  };

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOnClickSetFormikValues = (vicon) =>{
    formik.setFieldValue("id",vicon.id);
    formik.setFieldValue("descripcion",vicon.descripcion);
    formik.setFieldValue("minor",vicon.minor);
    formik.setFieldValue("orden",vicon.orden);
    formik.setFieldValue("created",vicon.created);
    formik.setFieldValue("solicitante",vicon.solicitante);
    formik.setFieldValue("es_frente",vicon.es_frente);
    formik.setFieldValue("entrada",vicon.entrada);
    formik.setFieldValue("descripcion_tipo_beacon",vicon.descripcion_tipo_beacon);
    formik.setFieldValue("nombre_beacon_grupo",vicon.nombre_beacon_grupo);
  }


  if(loadingViconsVersion){
    <TablaDeCarga
      columnas={[
        t("prioridad"), 
        t("tipo_operacion"), 
        t("nombre_referencia"), 
        t("sector_operacion"),
        t("area_asignada"),
        t("minor"),
        t("fecha solicitud"),
        t("solicitante")
      ]}
    />
  }
  
    return (
    <div>
        <Grid container style={{marginBottom:'20px'}}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="center">{t("prioridad_operacion")}</StyledTableCell>
                            <StyledTableCell align="center">{t("tipo_operacion")}</StyledTableCell>
                            <StyledTableCell align="center">{t("nombre_referencia")}</StyledTableCell>
                            <StyledTableCell align="center">{t("sector_operacion")}</StyledTableCell>
                            <StyledTableCell align="center">{t("area_asignada")}</StyledTableCell>
                            <StyledTableCell align="center">{"Minor"}</StyledTableCell>
                            <StyledTableCell align="center">{"Fecha solicitud"}</StyledTableCell>
                            <StyledTableCell align="center">{"Solicitante"}</StyledTableCell>
                            <StyledTableCell align="center">{t("accion")}</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {
                          ViconsVersion.length < 1 ?
                          (
                            <StyledTableRow key={9999}>
                              <StyledTableCell colSpan={9} align="center" component="th" scope="row">{"Sin solicitudes pendientes"}</StyledTableCell>
                            </StyledTableRow>
                          ) :
                          
                          ViconsVersion.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((vicon,index) =>{
                            return(
                              <StyledTableRow key={vicon.id+"-"+index}>
                                <StyledTableCell align="center" component="th" scope="row">{t(vicon.orden)}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{(vicon.tipo_operacion == 1) ? 'Nuevo Vicón' : (vicon.tipo_operacion == 2) ? "Edición Vicón" : "Desactivar Vicón"}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{t(vicon.descripcion)}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{t(vicon.nombre_beacon_grupo)}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{t(vicon.descripcion_tipo_beacon)}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{t(vicon.minor)}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{formatearFecha(vicon.created)}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">{t(vicon.solicitante)}</StyledTableCell>
                                <StyledTableCell align="center" component="th" scope="row">
                                  <Grid container alignItems='center' justifyContent='space-evenly' spacing={3}>
                                    <CheckCircleIcon onClick={() => {
                                       handleOnClickSetFormikValues(vicon);
                                       formik.setFieldValue("tipo_operacion",vicon.tipo_operacion);
                                      onHandleVerPopUpConfirmacion(true);
                                      }} sx={{color:"green", cursor:"pointer"}}/>
                                    <CancelIcon onClick={() =>{
                                      handleOnClickSetFormikValues(vicon);
                                      formik.setFieldValue("tipo_operacion",4);
                                      onHandleVerPopUpConfirmacion(true);
                                    }} sx={{color:"red", cursor:"pointer"}}/> 
                                  </Grid>
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          })
                        }
                    </TableBody>
                </Table>
                <TablePagination
                rowsPerPageOptions={[30, 40]}
                component="div"
                count={ViconsVersion.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("filas_por_pagina")}
              />
            </TableContainer>
        </Grid>


        {/* pop up confirmar o rechazar cambio */}
        <PopUpConfirmarSolicitud
          formik={formik}
          handleErrorForm={handleErrorForm}
          onHandleShowPopUp={onHandleVerPopUpConfirmacion}
          onHandleShowPopUpSuccess={() =>{}}
          show={verPopUpConfirmacion}
        />

        {/* pop up success */}
        <PopUpSuccessSolicitud
        onHandleShowPopUp={onHandleVerPopUpSuccess}
        show={verPopUpSuccess}
        maxwidth='sm'
        />
    </div>
  )
}
