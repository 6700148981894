import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import './BarTimelineChart.css';
import { useTranslation } from 'react-i18next';

//marca

const BarMapVacion = (props) => {

  const { t} = useTranslation();


  let options = {
    annotations: !props.loading ? {
      /* images: [{
        path: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Bandera_de_Espa%C3%B1a.svg/400px-Bandera_de_Espa%C3%B1a.svg.png",
        x: "55%",
        y: "45%",
        width: 20,
        height: 20,
        appendTo: '.apexcharts-annotations'
      }], */
      points: []
    }:{},
    noData: {
      text: (props.loading?`${t("cargando")} ...`:t("no_hay_datos")),
      offsetX: 70,
      offsetY: -20,
      style: {
        color: "#000000"/* "#00b7c4" */,
        fontSize: '14.4px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      }
    },
    labels: props.labels,
    chart: {
      toolbar:{
        show:true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: true
        }
      },
      height: 350,
      type: 'rangeBar'
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
        rangeBarGroupRows: true
      }
    },
    colors: ['#00b7c4', '#00b7c4', '#2f4554'],
    fill: {
      type: 'solid'
    },
    xaxis: {
      /* Le resto 3 horas en milisengunos por problema de zona horaria */
      min: undefined,
      max: undefined,
      tooltip: {
        enabled: false
      },
      labels: {
        format: 'HH:mm',
        datetimeUTC: false,
        style: {
          colors: ["#2f4554"],
          fontSize: '12px',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        },
        show: false, // Oculta los números en el eje x
      },
      type: "datetime"
    },
    legend: {
      showForSingleSeries:false,
      position: 'bottom'
    },
    tooltip: {    
      x: {
        format: "dd/MM/yy HH:mm:ss",
      }
    }, 
    yaxis:{
      labels: {
        minWidth: 75,
        maxWidth: 10000,
        align: 'right',
        style: {
          colors: ["#2f4554"],
          fontSize: '12px'/* '11px' */,
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          /* fontWeight: "bold" */
        }, 
      },
      show:false
    },
    grid: {
      row: {
        colors: ['#f3f4f5', '#fff'],
        opacity: 1
      }
    }
  };


  return (
    <Chart
        options={options}
        series={[]}
        type="rangeBar"
        height ={props.height}
        width="100%"
    />    
  );

}

export default BarMapVacion;