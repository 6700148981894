import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import { PdfSection } from "./PdfSections";
import { ShiftImageSection } from "./ShiftImageSection";

export function MinasTabs({
  loading,
  screenshots,
  pdfs,
  date,
  handleTakeScreenshot,
  handleRemoveScreenshot,
  generatePDFByMina, 
  deletePDFByMina, 
  downloadPDF, 
  openPDFInNewTab,
  getPDFAsBase64
}) {
  const [value, setValue] = useState("1");

  const handleTabChange = (event, newValue) => setValue(newValue);

  // Identifica si hay capturas en función del tab y turno actual
  const hasScreenshots = (tab, turno) => screenshots[tab]?.[turno]?.length > 0;

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={value}>
        <TabList onChange={handleTabChange} aria-label="Minas Tabs">
          <Tab label="Santa Rosa" value="1" />
          <Tab label="Las Chispas" value="2" />
        </TabList>
        <TabPanel sx={{ padding: 0, paddingBlock: "6px" }} value="1">
          <section
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "10px",
              paddingBlock: "6px"
            }}>
            {/* Turno día */}
            <ShiftImageSection
              loading={loading}
              tab="santaRosa"
              turno="dia"
              hasScreenshots={hasScreenshots}
              handleTakeScreenshot={handleTakeScreenshot}
              handleRemoveScreenshot={handleRemoveScreenshot}
              screenshots={screenshots}
            />

            {/* Turno noche */}
            <ShiftImageSection
              loading={loading}
              tab="santaRosa"
              turno="noche"
              hasScreenshots={hasScreenshots}
              handleTakeScreenshot={handleTakeScreenshot}
              handleRemoveScreenshot={handleRemoveScreenshot}
              screenshots={screenshots}
            />
          </section>
          <PdfSection 
            screenshots={screenshots} 
            tab="santaRosa" 
            pdfs={pdfs}
            date={date}
            generatePDFByMina={generatePDFByMina}
            deletePDFByMina={deletePDFByMina}
            downloadPDF={downloadPDF}
            openPDFInNewTab={openPDFInNewTab}
            getPDFAsBase64={getPDFAsBase64}
          />
        </TabPanel>
        <TabPanel sx={{ padding: 0, paddingBlock: "6px" }} value="2">
          <section style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "10px",
              paddingBlock: "6px"
            }}>
            {/* Turno día */}
            <ShiftImageSection
              loading={loading}
              tab="lasChispas"
              turno="dia"
              hasScreenshots={hasScreenshots}
              handleTakeScreenshot={handleTakeScreenshot}
              handleRemoveScreenshot={handleRemoveScreenshot}
              screenshots={screenshots}
            />

            {/* Turno noche */}
            <ShiftImageSection
              loading={loading}
              tab="lasChispas"
              turno="noche"
              hasScreenshots={hasScreenshots}
              handleTakeScreenshot={handleTakeScreenshot}
              handleRemoveScreenshot={handleRemoveScreenshot}
              screenshots={screenshots}
            />
          </section>
          <PdfSection 
            screenshots={screenshots} 
            tab="lasChispas"
            pdfs={pdfs}
            date={date}
            generatePDFByMina={generatePDFByMina}
            deletePDFByMina={deletePDFByMina}
            downloadPDF={downloadPDF}
            openPDFInNewTab={openPDFInNewTab}
            getPDFAsBase64={getPDFAsBase64}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
