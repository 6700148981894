import React from "react";
import BarChart from "./BarChart";
import ProductividadCard from "./ProductividadCard";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import NavBar from "../../components/organisms/NavBar";
import Migajaspan from "../../components/atoms/MigajasPan";
import Dropdownmaquina from "../../components/molecules/DropDownMaquina";

const Productividad = () => {
  return (
    <Grid container>
      <NavBar />


    <Migajaspan />

    <Dropdownmaquina />

      <Box
        sx={{ flexGrow: 1 }}
        style={{ marginTop: "20px", marginBottom: "40px" }}
      >
        <Grid container spacing={2} columns={10}>
          <Grid item xs={2}>
            <ProductividadCard cantidad={15} texto={"Paladas"} />
          </Grid>
          <Grid item xs={2}>
            <ProductividadCard cantidad={30} texto={"Tonelaje Total"} />
          </Grid>
          <Grid item xs={2}>
            <ProductividadCard cantidad={15} texto={"Tonelaje / Hora"} />
          </Grid>
          <Grid item xs={2}>
            <ProductividadCard cantidad={2} texto={"Hrs Funcionando"} />
          </Grid>
          <Grid item xs={2}>
            <ProductividadCard cantidad={15} texto={"Total de vueltas"} />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} columns={10}>
        <BarChart />
      </Grid>
    </Grid>
  );
};

export default Productividad;
