import React from 'react'
import { GenericPopUp } from '../../../components/molecules/GenericPopUp'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { TextfieldGenerico } from '../../../components/atoms/TextfieldGenerico';
import { ComboboxGenerico } from '../../../components/atoms/ComboboxGenerico';
import { Link } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from "yup";

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "rgb(0, 183, 196)",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "#2f4554"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

  

export const PopUpNombreGeocerca = ({show, onHandleShowPopUp,idGeoCerca,setGeoCercasMemoria,onHandleDeleteGeocercaOnMemoryByID, tiposGeocercas, controlaVelocidad = false}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const initialValues = {
      nombre:'',
      coordenadas:[],
      id_area_operacion:0,
      id_tipo_geocerca:'',
      nombre_tipo_geocerca:'',
      velocidadMaxima:undefined
    };

    const formik = useFormik({
      initialValues:initialValues,
      validationSchema:Yup.object({
          "nombre": Yup.string()
            .required("Campo obligatorio")
            .min(5, 'Minimo 5 caracteres'),
            "velocidadMaxima":Yup.string()
            .test(
              "VerificarNumber",
              "Debe ser un numero valido",
              function(value){
                return (/^-?\d+$/.test(value) && controlaVelocidad)
              }
            )
            .test(
              "verificarVelocidadMax",
              "La velocidad maxima es 130 km/h",
              function(value){
                return (/^-?\d+$/.test(value) && controlaVelocidad && value < 130)
              }
            )
            .test(
              "verificarVlocidadMin",
              "La velocidad minima es 1 km/h",
              function(value){
                return (/^-?\d+$/.test(value) && controlaVelocidad && value > 0)
              }
            ),
           "id_tipo_geocerca":Yup.number().test(
            "seleccionarTipoGeoCerca",
            "Selección obligatoria ",
            function (value) {
              return value !== undefined && value !== 0 && value !== '' && !controlaVelocidad;
            }
          ),
        })
    })


    const updateGeoCercaNombre = () => {

        setGeoCercasMemoria((prevState) =>
          prevState.map((geoCerca) => {
            if (geoCerca.id === idGeoCerca.id) {
              return {
                ...geoCerca,
                properties: {
                  ...geoCerca.properties,
                  nombre: formik.values.nombre,//nombreGeoCerca,,
                  velocidadMaxima: formik.values.velocidadMaxima,
                  id_tipo_geocerca: formik.values.id_tipo_geocerca
                },
              };
            }
            return geoCerca;
          })
        );
      };
      const removeGeoCercaById = () => {
        onHandleDeleteGeocercaOnMemoryByID(idGeoCerca.id);
        setGeoCercasMemoria((prevState) =>
          prevState.filter((geoCerca) => geoCerca.id !== idGeoCerca.id)
        );
      };
      

  return (
    <>
        <GenericPopUp show={show} handleShowPopUp={onHandleShowPopUp} showCancel={false} maxwidth='sm' Children={
            <Grid container justifyContent='center' alignItems='center' direction='column' style={{paddingBottom:'50px'}}>
                <Grid container item justifyContent='center' alignItems='center' direction='row' style={{marginTop:'20px'}}>
                    <Typography
                        style={{marginLeft:'10px', fontWeight: "bolder",fontSize: "20px",color: "#2f4554",maxWidth:'250px', textAlign:'center'}}
                    >
                       {t('Creación geocerca')}
                    </Typography>
                </Grid>
                    <Grid container item spacing={1} direction='row' wrap='nowrap' justifyContent='space-around' alignItems='flex-start' style={{marginTop:'20px',marginBottom:'10px'}}>

                        <Grid container item  xs={6} >
                          <Grid item xs={12} container wrap='nowrap' alignItems='center' style={{marginBottom:'25px'}}>
                            <TextfieldGenerico
                              label={'Nombre geocerca'}
                              name={'nombre'}
                              onChange={(e) =>{
                                formik.setFieldValue("nombre",e.target.value)
                              }}
                              variant='outlined'
                              value={formik.values.nombre}
                              onBlur={formik.handleBlur}
                              error={formik.errors.nombre && formik.touched.nombre ? true : false}
                              helperText={formik.errors.nombre ? formik.errors.nombre :''}
                              showLabelOnTop={true}
                            />
                          </Grid>
                        </Grid>
                        <Grid container item  xs={6} >
                          {
                            controlaVelocidad ? (
                              <Grid item xs={12} container wrap='nowrap' alignItems='center'>
                                <TextfieldGenerico
                                  label={'Velocidad maxima km/h'}
                                  name={'velocidadMaxima'}
                                  onChange={(e) =>{
                                    formik.setFieldValue("velocidadMaxima",e.target.value)
                                  }}
                                  variant='outlined'
                                  value={formik.values.velocidadMaxima}
                                  onBlur={formik.handleBlur}
                                  error={formik.errors.velocidadMaxima && formik.touched.velocidadMaxima ? true : false}
                                  helperText={formik.errors.velocidadMaxima ? formik.errors.velocidadMaxima :''}
                                  showLabelOnTop={true}
                                />
                              </Grid>
                            ) :(
                              <Grid item xs ={12} container wrap='nowrap' alignItems='center'>
                                <ComboboxGenerico
                                  name={'id_tipo_geocerca'}
                                  label={'Tipo de geocerca'}
                                  datos={tiposGeocercas}
                                  onblur={formik.handleBlur}
                                  onChange={(e) =>{
                                    let selectedValue = e.target.value;
                                    if(selectedValue !== formik.values.id_tipo_geocerca){
                                      formik.setFieldValue("id_tipo_geocerca",e.target.value);
                                    }
                                  }}
                                  valueSelected={formik?.values?.id_tipo_geocerca}
                                  showTopLabel={true}
                                  error={formik.errors.id_tipo_geocerca && formik.touched.id_tipo_geocerca ? true : false}
                                  helperText={formik.errors.id_tipo_geocerca ? formik.errors.id_tipo_geocerca :''}
                                />
                              </Grid>
                            )
                          }
                        </Grid>     

                    </Grid>

                    <Grid container justifyContent='center' alignItems='center' direction='column'>
                        <Button                          
                            type="button"
                            variant="contained"
                            className={classes.root}
                            style={{textAlign:'center', marginTop:'35px', padding:'5px 80px', margin:'5px 10px 20px 0px',width:'250px'}}
                            onClick={() =>{
                              let contador = 0;
                                if(!formik.touched.nombre || formik.errors.nombre){
                                    formik.setFieldTouched('nombre', true);
                                    contador +=1;
                                    
                                }
                                if((!formik.touched.velocidadMaxima || formik.errors.velocidadMaxima) && controlaVelocidad){
                                  formik.setFieldTouched("velocidadMaxima",true)
                                  contador +=1
                                }

                                if((!formik.touched.id_tipo_geocerca || formik.errors.id_tipo_geocerca) && !controlaVelocidad){
                                  formik.setFieldTouched("id_tipo_geocerca",true)
                                  contador +=1
                                }

                                if(contador > 0){
                                  return;
                                }
                                updateGeoCercaNombre();
                                // setNombreGeoCerca('');
                                formik.setFieldValue('nombre','');
                                formik.setFieldValue('velocidadMaxima', '');
                                formik.setFieldValue('id_tipo_geocerca', '');
                                formik.setFieldTouched('nombre', false);
                                formik.setFieldTouched('velocidadMaxima', false);
                                formik.setFieldTouched('id_tipo_geocerca', false);
                                onHandleShowPopUp(false);

                            }
                            }
                            >
                            {t("Aceptar")}
                        </Button>
                        <Grid>
                            <Link 
                            style={{cursor:'pointer'}} 
                            onClick={()=>{
                                removeGeoCercaById();
                                onHandleShowPopUp(false);
                                formik.resetForm(initialValues);
                                // setNombreGeoCerca('');
                                formik.setFieldTouched('nombre', false);
                                formik.setFieldValue('velocidadMaxima', '');
                                formik.setFieldValue('id_tipo_geocerca', '');
                                formik.setFieldTouched('velocidadMaxima', false)
                                formik.setFieldTouched('id_tipo_geocerca', false);
                                }} 
                            underline='always' 
                            color='inherit'>{t('cancelar')}</Link>
                          </Grid>
                    </Grid>
                
            </Grid>
        }/>
    </>
  )
}
