import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  es: {
    translation: {
      // varios
      cerrar_sesion                    : "Salir",
      dia                              : "Día",
      noche                            : "Noche",
      turno_dia                        : "Turno Día",
      turno_noche                      : "Turno Noche",
      turno                            : "Turno",
      detalle                          : "Detalle",
      detalle_de                       : "Detalle de",
      detalle_diario                   : "Detalle diario",
      a_la_fecha                       : "a la fecha",
      descargar_datos                  : "Descargar Datos",
      cerrar                           : "Cerrar",
      fecha_inicio                     : "Fecha Inicio",
      fecha_fin                        : "Fecha Fin",
      fecha_final                      : "Fecha de fin",
      lugar_mantencion                 : "Lugar mantención",
      tiros_pernos                     :"Tiros/Pernos",
      datos_dashboard                  : "Datos dashboard",
      datos_scoop                      : "Datos Scoops",
      datos_resumen_mantenciones       : "Datos resumen mantención",
      modalidad                        : "Modalidad",
      datos_resumen_turnos             :"Resumen turnos ",
      datos_control_velocidad          : "Datos control velocidad",
      datos_resumen_turno_general      : "Datos resumen turno general",
      datos_resumen_turno_mina         : "Datos resumen turno mina",
      datos_base_gps                   : "Datos base gps",
      datos_base                       : "Datos base",
      datos_vueltas                    : "Datos vueltas",
      datos_jumbo                      : "Datos Jumbo",
      datos_percutores                 : "Datos Percutores",
      datos_justificaciones            : "Datos justificaciones",
      descargar                        : "Descargar",
      info_grafico_produccion_acumulada: "Utiliza el puntero sobre la leyenda y haz clic para interactuar con el gráfico",
      turno_primero                    : "Primer turno",
      turno_segundo                    : "Segundo turno",
      turno_tercero                    : "Tercer turno",
      dumper                           : "Camión",
      Dumpers                          : "Dumpers",
      Dumpers_traducir                 : "Camiones",
      Scoops_traducir                  : "Scoops",
      Jumbos_traducir                  : "Jumbos",
      Bolters_traducir                 : "Bolters",
      Extractores_traducir             : "Extractor",
      Auxiliares_traducir              : "Auxiliar",
      flota_jumbo                      : "Flota Jumbo",
      flota_camion                     : "Flota Dumper",
      flota_lhd                        : "Flota Scoop",
      flota_bolter                     : "Flota Bolter",
      flota_telehandler                : "Flota Telehandler",
      flota_hormigonera                : "Flota Hormigonera",
      flota_extractor                  : "Flota Extractor",
      flota_auxiliar                   : "Flota Auxiliar",
      flota_jumbo_traducir             : "Flota Jumbo",
      flota_camion_traducir            : "Flota Camión",
      flota_lhd_traducir               : "Flota Scoop",
      flota_bolter_traducir            : "Flota Bolter",
      flota_telehandler_traducir       : "Flota Telehandler",
      flota_hormigonera_traducir       : "Flota Hormigonera",
      flota_extractor_traducir         : "Flota Extractor",
      flota_auxiliar_traducir          : "Flota Auxiliar",
      LHD                              : "Scoop",
      metros                           : "Metros",
      horas                            : "Horas",
      brazo                            : "Brazo",
      brazo_1                          : "Brazo 1",
      brazo_2                          : "Brazo 2",
      metros_restantes                 : "Metros Restantes",
      metros_programados               : "Metros Programados",
      metros_realizados                : "Metros Realizados",
      no_hay_datos: "No hay datos",
      cargando: "Cargando",
      horas_de_perforacion: "Horas de perforación",
      metros_totales: "Metros totales",
      tiempo_encendido: "Tiempo encendido",
      acaba_de_pasar_por: "acaba de pasar por",

      // Modal descarga
      fecha_movimiento  : "Fecha movimiento",
      fecha             : "Fecha",
      turno             : "Turno",
      equipo            : "Equipo",
      tipo_de_equipo    : "Tipo de equipo",
      nominal           : "Nominal" ,
      encendido         : "Encendido",
      ingreso           : "Ingreso",
      salida            : "Salido",
      primer_ciclo      : "Primer ciclo",
      ultimo_ciclo      : "Ultimo ciclo",
      salid             : "salida",
      disponible        : "Disponible",
      indisponible      : "Indisponible",
      operativo         : "Operativo",
      reserva           : "Reserva",
      efectivo          : "Efectivo",
      perdida           : "Perdida",
      rendimiento       : "Rendimiento",
      beacon_inicio     : "Beacon inicio",
      beacon_fin        : "Beacon fin",
      beacon_destino    : "Beacon destino",
      fecha_destino     : "Fecha destino",
      duracion_carga    : "Duración carga",
      duracion_descarga : "Duración descarga",
      tipo_carga        : "Tipo carga",
      duracion          : "Duración",
      perdida_operacional: "Perdida operacional",
      datos              : "Datos",
      brazo: "Brazo",
      inicio: "Inicio",
      fin: "Fin",
      ubicacion: "Ubicación",
      duracion: "Duración",
      tipo: "Tipo",
      nombre_maquina:"Nombre maquina",
      tipo_maquina:"Tipo de maquina",

      // control diario
      control_de_frentes: "Control de Frentes",
      control_diario: "Control Diario",
      camion: 'Camión',
      camiones: 'Camiones',
      primera_vuelta: "Primera Vuelta",
      primer_ingreso: "Primer Ingreso",
      ultima_vuelta : "Última Vuelta",
      ultima_salida: "Última Salida",
      ultima_actualizacion: "Última Actualización",
      fecha_ultima_actualizacion: "Fecha ultima Actualización",
      portal: "Portal",
      hora: "Hora",
      primer_ingreso_vuelta: "Primer ingreso/vuelta",
      ultima_salida_vuelta: "Ultima salida/vuelta",

      // operaciones
      operaciones: "Operaciones",
      operaciones_2: "Operaciones 2",
      monitoreo:"Monitoreo",
      panel:"Panel",
      ejecutivo:"Ejecutivo",
      cuadrillas: "Cuadrillas",
      planificacion: "Planificación",
      resumen_diario: "Resumen Diario",
      prod_total: "Producción Total",
      estado_equipos: "Estado de Equipos",
      control_de_tiempo: "Control de Tiempo",
      prod_por_equipo: "Producción por Equipo",
      prod_por_portales: "Producción por Portales",
      prod_por_mina: "Producción por mina",
      prod_diferenciada:"Producción diferenciada",
      vueltas: "Vueltas",
      cargas:"Cargas",
      baldadas:"Baldadas",
      equipo_cargado:"Equipo cargado",
      tonelaje: "Tonelaje",
      operativo: "Operativo",
      en_mantencion: "En Mantención",
      fuera_de_servicio: "Reserva",
      sin_info: "Sin Información",
      encuentro: "Encuentro",
      ton_realizadas: "Toneladas Realizadas",
      ton_programadas: "Toneladas Programadas",
      ton_restantes: "Toneladas Restantes",
      ubicacion_actual: "Ubicación Actual",
      ultima_ubicacion: "Última Ubicación",
      fecha_ultima_ubicacion: "Fecha ultima Ubicación",
      toneladas: "Toneladas",
      programado: "Programado",
      realizado: "Realizado",
      ralenti: "Ralentí",
      ralenti_aux:"Ralentí bruto",
      minutos: "minutos",
      horas_percutidas:"Horas percutidas",
      horas_programadas:"Horas programadas",
      voladuras:"Voladuras",
      frentes:"Frentes",
      frente: "Frente",
      tiros:"Tiros",
      pernos:"Pernos",
      todos_maquinas:"Todas las máquinas",
      todos_flotas:"Todas las flotas",
      tipo_flota:"Tipo de flota",
      detalle_vuelta: "Detalle por vuelta superficie",
      ubicacion_equipos: "Ubicación de los equipos",
      ingreso_mina:"Ingreso mina",
      salida_mina:"Salida mina",
      primer_encuentro_frente:"Primer encuentro frente",
      ultimo_encuentro_frente:"Ultimo encuentro frente",
      carga:'Carga',
      descarga:'Descarga',

      // operaciones 2
      todos: "Todos",
      kpis_de_prod_diaria: "KPIs de producción diaria",
      movimiento_y_horometro: "Movimiento y Horómetro",
      horometro: "Horómetro",
      detalle_por_portal: "Detalle por portal",
      detalle_por_vuelta:"Detalle por vuelta",
      nombre: "Nombre",
      total: "Total",
      estado: "Estado",

      // produccion
      resumen_produccion: "Resumen Producción",
      meta: "Meta",
      produccion_acumulada: "Producción Acumulada",
      produccion: "Producción",
      tonelaje_acumulado: "Tonelaje Acumulado",
      meta_acumulada: "Meta Acumulada",
      equipo: "Equipo",
      numero_de_vueltas: "Número de Vueltas",
      tiempo_efectivo: "Tiempo Efectivo",

      // produccion 2
      kpis_de_prod_a_la_fecha: "KPIs de producción a la fecha",
      rendimiento: "Rendimiento",
      cantidad: "Cantidad",
      promedio: "Promedio",
      disponibilidad: "Disponibilidad",
      factor_operacional: "Factor Operacional",
      utilidad_efectiva: "Utilidad Efectiva",
      eficiencia_global: "Eficiencia Global",
      tiempo_disponible: "Tiempo Disponible",
      tiempo_nominal: "Tiempo Nominal",
      tiempo_indisponible: "Tiempo Indisponible",
      tiempo_encendido_en_taller:"Tiempo encendido en el taller",
      tiempo_operativo: "Tiempo Operativo",
      tiempo_reserva: "Tiempo Reserva",
      tiempo_perdida_operacional: "Tiempo Pérdida Operacional",
      prod_por_turnos_a_la_fecha: "Producción por turnos a la fecha",
      cancelar: "Cancelar",

      //produccion 3
      funcionamiento_diario: "Funcionamiento Diario",
      tiempo_asarco: "Tiempo ASARCO",
      horas_del_dia: "Horas del día",
      tiempo_total_en_taller: "Tiempo total en taller",
      tiempo_total_de_vueltas: "Tiempo total de vueltas",
      tiempo_total_de_ralenti: "Tiempo total de ralentí",
      tiempo_ralenti:"Tiempo Ralentí",
      tiempo_ratio:"Tiempo Ratio",
      tiempo_total_ratio:"Tiempo operativo / Tiempo ralentí",

      //Jumbo
      arm1_motor: "Motor brazo 1",
      arm1_percutor: "Percutor brazo 1",
      arm2_motor: "Motor brazo 2",
      arm2_percutor: "Percutor brazo 2",
      brazo1_motor: "Motor brazo 1",
      brazo1_percutor: "Percutor brazo 1",
      brazo1_compresor: "Compresor brazo 1",
      brazo2_compresor: "Compresor brazo 2",
      brazo2_motor: "Motor brazo 2",
      brazo2_percutor: "Percutor brazo 2",
      horometro_jumbo: "Horómetro Jumbo",
      horometro_bolter: "Horómetro Bolter",
      horas_percutor: "Horas percutor",
      resumen_diario_brazos: "Resumen diario brazos",
      detalle_diario_brazos: "Detalle diario brazos",
      horometro_motor_brazos:"Horometro motor brazo",
      horometro_motor:"Horometro motor",
      horometro_percutor_brazos:"Horometro percutor brazos",

      //Justificacion
      justificacion:"Justificación",
      justificaciones:"Justificaciones",
      justificacionMacro:"Justificación Macro",
      tipo_alarma:"Tipo de Alarma",
      accion:"Acción",
      justificar:"Justificar",
      por_justificar: "a Justificar",
      detalle:"Detalle",
      motivo:"Motivo",
      motivo_detalle:"Detalles del motivo",
      registrar:"Registrar",
      otro_motivo:"Otro motivo",
      modificar_intervalo_tiempo:"Modificar intervalo de tiempo",
      seleccione_opcion:"-- Seleccione una opción --",
      ingrese_ubicacion:"Ingrese una ubicación",
      equipos:"Equipos",
      validacion_form_campos_vacios:"Debes ingresar todos los campos del formulario",
      validacion_campo_justificacion:"El motivo de justificación es obligatorio",
      validacion_justificacion:"El motivo es obligatorio",
      validacion_fecha_inicio:"La fecha de inicio es obligatoria",
      validacion_fecha_fin:"La fecha de termino es obligatoria",
      validacion_fecha_inicio_mayor_fecha_fin:"La fecha de inicio no puede ser mayor a la fecha de término",
      validacion_equipo:"El equipo es obligatorio",
      validacion_ubicacion:"La ubicación es obligatoria",
      validacion_tipo_alarma:"El tipo de alarma es obligatorio",
      filas_por_pagina:"Filas por página:",
      prolongado:"Prolongado",
      en_mantencion_justificacion: "Mantención Prologanda",
      ralenti_justificacion:"Ralentí Prolongado",
      fuera_de_servicio_justificacion: "Reserva Prolongada",
      todos_los_equipos:"Todos los equipos",
      todas_las_alarmas:"Todas las alarmas",
      pendientes:"Pendientes",
      justificados:"Justificados",
      justificadoSingular:"Justificado",
      justificacion_personalizada:"Justificación personalizada",
      sin_ubicacion_asignada:"Sin ubicación asignada",
      mantencion: "Mantención",
      'fuera de servicio':"Reserva",
      camions:"Dumpers",
      jumbos:"Jumbos",
      lhds:"Scoops",
      bolters:"Bolters",
      auxiliars:"Auxiliares",
      camions_traducir:"Camiones",
      jumbos_traducir:"Jumbos",
      lhds_traducir:"Scoops",
      auxiliars_traducir:"Auxiliares",
      
      justificacion_grupo_id_1:'Climaticas',
      justificacion_grupo_id_2:'Motor/Transmisión',
      justificacion_grupo_id_3:'Chasis/Cabina',
      justificacion_grupo_id_4:'Neumáticos',
      justificacion_grupo_id_5:'Incidentes',
      justificacion_grupo_id_6:'Mantenimiento Programado',
      justificacion_grupo_id_7:'Operacionales',

      justificacion_tipo_1:'Clima caluroso',
      justificacion_tipo_2:'Visibilidad',
      justificacion_tipo_3:'Nieve',
      justificacion_tipo_4:'Terremoto',
      justificacion_tipo_5:'Viento',
      justificacion_tipo_6:'Tormenta eléctrica',
      justificacion_tipo_7:'Otro',
      justificacion_tipo_8:'Temperatura motor',
      justificacion_tipo_9:'Potencia',
      justificacion_tipo_10:'Manguera',
      justificacion_tipo_11:'Aire',
      justificacion_tipo_12:'Lubricación',
      justificacion_tipo_13:'Fuga',
      justificacion_tipo_14:'Falla elèctrica',
      justificacion_tipo_15:'Problema partida',
      justificacion_tipo_16:'Falla mecánica desconocida',
      justificacion_tipo_17:'Otro',
      justificacion_tipo_18:'Falla luces',
      justificacion_tipo_19:'Problema parabrisas',
      justificacion_tipo_20:'Problema tolva',
      justificacion_tipo_21:'Problema espejos',
      justificacion_tipo_22:'Aire acondicionado',
      justificacion_tipo_23:'Calefacción',
      justificacion_tipo_24:'Filtración polvo',
      justificacion_tipo_25:'Radio',
      justificacion_tipo_26:'Condicion cabina',
      justificacion_tipo_27:'Otro',
      justificacion_tipo_28:'Pinchado',
      justificacion_tipo_29:'Temperatura neumático',
      justificacion_tipo_30:'Rajadura neumático',
      justificacion_tipo_31:'Cambio neumático',
      justificacion_tipo_32:'Otro',
      justificacion_tipo_33:'Derrumbe',
      justificacion_tipo_34:'Choque/Colisión',
      justificacion_tipo_35:'Atropello',
      justificacion_tipo_36:'Fuego',
      justificacion_tipo_37:'Descarrilamiento',
      justificacion_tipo_38:'Tmeas sociales',
      justificacion_tipo_39:'Emergencia mayor',
      justificacion_tipo_40:'Otro',
      justificacion_tipo_41:'Mantenimiento programado',
      justificacion_tipo_42:'Traslado a taller',
      justificacion_tipo_43:'Otro',
      justificacion_tipo_44:'Tronadura',
      justificacion_tipo_45:'Combustible',
      justificacion_tipo_46:'Estado camino',
      justificacion_tipo_47:'Por solicitud',
      justificacion_tipo_48:'Traslado a taller',
      justificacion_tipo_49:'Otro', 

      //administracion
      maquinas: 'Máquinas',
      maquina: 'Máquina',
      mina: 'Mina',
      dispositivos_instalados:'Dispositivos actualmente instalados',
      editar: 'Editar',
      eliminar: 'Eliminar',
      agregar:'Agregar nuevo',
      agregar_nuevo_registro:'Agregar nuevo registro',
      prioridad:'Prioridad',
      nombre_referencia:'Nombre de referencia',
      sector_operacion:'Sector de operación',
      area_asignada:'Área asignada',
      guardar_cambios:'Guardar cambios',
      recuerdo_aprobacion:'Recuerda que los cambios realizados serán enviados para su aprobación antes de ser implementados',
      minaSingular:'Mina',
      nombre_referencia_vicon:'Nombre de referencia del VICON',
      prioridad_operacion:'Prioridad en operación',
      tipo_operacion:'Tipo de operación',
      prioridad_operacion_subtitle:'Prioridad respecto a los dispositivos ya instalados',
      area_asignada_subtitle:'Tipo de área donde será instalado',
      id_vicon_subtitle: 'Número ID impreso el dispositivo',
      validacion_10_caracteres:'Máximo 10 caracteres',
      editar_vicon_subtitle:'Ajusta las especificaciones de un dispositivo',
      cambios_guardados_title:'Sus cambios fueron guardados exitosamente',
      cambios_guardados_subtitle:'Acabas de enviar una solicitud de cambios, te notificaremos a tu correo electrónico cuando ésta sea aprobada',
      entendido:'Entendido',
      eliminar_vicon_info:'Está a punto de eliminar el siguiente VICON',
      eliminar_regla_ciclo_gps:'Está a punto de liminar la siguiente regla de ciclo gps',
      eliminar_vicon_boton:'Eliminar VICON',
      confirmar_cambios_vicon:'Confirmar cambios en VICON',
      confirmar_cambios:'Confirmar cambios',
      confirmar_datos_nuevo_vicon:'Confirmar datos de nuevo VICON',
      agregar_nuevo_vicon:'Agregar nuevo VICON',
      agregar_nuevo_vicon_subtitulo:'Te recomendamos tener a mano el dispositivo',
      agregarr:'Agregar',
      geocerca:'Geocerca',
      geocerca_velocidad:'Geocerca velocidad',
      tipo_geocerca:'Tipo geocerca',
      nombre_geocerca:"Nombre geocerca",
      tipo_control_geocerca:'Tipo control',
      velocidad_maxima:"Velocidad maxima",
      velocidad:"velocidad",
      administracion:"Administración",
      ubicado_en_frente: "Ubicado en un frente",
      ubicado_en_entrada_mina:"Ubicado en la entrada de la mina",
      vicons:"Vicons",
      geocercas:"Geocercas",
      tipos_geocercas:"Tipos de geocercas",
      ciclo_gps:"Ciclos Gps",
      creadas_actualmente:"Creadas actualmente",
      agregar_nuevo_ciclo:"Agregar nuevo ciclo",
      editar_ciclo:"Editar ciclo",
      inicio_ciclo:"Inicio ciclo",
      inicio_ciclo_subtitulo:"Tipo de geocerca donde se inicia el ciclo",
      fin_ciclo:"Fin ciclo",
      fin_ciclo_subtitulo:"tipo de geocerca donde se finaliza el ciclo",
      actualizacion_de_la_informacion:"Actualización de la información",
      agregar_nuevo_tipo_geocerca:"Agregar nuevo tipo de geocerca",
      editar_tipo_geocerca:"Editar tipo de geocerca",
      nombre_mina:"Nombre mina",
      advertencia_eliminacion_tipo_geocerca:"Estas a punto de eliminar el siguiente tipo de geocerca",
      tipo_geocerca_inicio:"Tipo de geocerca inicio",
      tipo_geocerca_fin:"Tipo de geocerca fin",


      //Control de frentes
      control_frentes:'Control Frentes',
      equipos_en_frentes:'Equipos en frentes',
      plan_de_frentes:'Plan de frentes',
      lugar:'Lugar',
      actividad:'Actividad',
      ultimo_equipo: 'Último equipo',
      horas_desde_ultimo_encuentro: 'Horas desde último encuentro',
      perforacion:'Perforación',
      limpieza_scoop:'Limpieza Scoop',
      refuerzo:'Refuerzo',
      refuerzo_buritica:'Sostenimiento',
      detalle_de_equipos: 'Detalle de equipos',
      mostrar_ultimo_encuentro:'Mostrar último encuentro',
      ocultar_ultimo_encuentro:'Ocultar último encuentrro',
      dias_maximo:"Dias máximos",
      
      //solicitudes
      solicitudes:'Solicitudes',

      //analytics
      usuario:'Usuario',
      primer_registro:'Primer registro recibido',
      ultimo_registro:'Ultimo registro recibido',
      clicks_presionados:'Clicks presionados',
      teclas_presionadas:'Teclas presionadas',
      tiempo_total:'Tiempo total',
      seleccion_opcion_mina:'Seleccione una mina desde el selector para desplegar la información.',
      monitoreo_dashboard:'Monitoreo dashboard',
      filtrar_por_mina:'Filtrar por mina',
      tiempo_en_sesion:'Tiempo en sesión',
      actividad_por_usuario:'Actividad por usuario',
      actividad_por_usuario_fecha:'Actividad por usuario y dia',

      //General
      filtrar_por:'Filtrar por',
      buscar:'Buscar',
      mes:'Mes',
      quincena:'Quincena',
      origen:'Origen',
      tipo_de_material:'Tipo de Material',
      capacidad_ton2:'Capacidad Ton2',
      cantidad_de_viajes:'Cantidad de Viajes',
      destino:"Destino",
      densidad:"Densidad",
      ton_ambiental:"Ton Ambiental",
      ton:"Ton",
      acarreo:"Acarreo"
    },
  },
  en: {
    translation: {
      //varios
      cerrar_sesion: "Log out",
      dia: "Day",
      noche: "Night",
      turno_dia: "Day Shift",
      turno_noche: "Night Shift",
      turno  : "Shift",
      detalle: "Detail",
      detalle_de: "Detail of the",
      detalle_diario: "Day Detail",
      a_la_fecha: "to date",
      descargar_datos: "Data Download",
      cerrar: "Close",
      fecha_inicio: "Start Date",
      fecha_fin: "End Date",
      lugar_mantencion: "Maintenance place",
      fecha_final: "Final Date",
      tiros_pernos:"Shootings/Bolts",
      datos_dashboard: "Dashboard data",
      datos_scoop: "Data Scoops",
      datos_resumen_mantenciones:"maintenance summary data",
      modalidad: "Modality",
      datos_resumen_turnos: "Shift resume",
      datos_control_velocidad: "Speed control data",
      datos_resumen_turno_general: "General shift resume data",
      datos_resumen_turno_mina: "Mine shift resume data",
      datos_base: "Base data",
      datos_vueltas: "Cycle data",
      datos_base_gps:"Base gps data",
      datos_jumbo: "Jumbo data",
      datos_percutores: "Drilling machines",
      datos_justificaciones: "Justifications data",
      descargar: "Download",
      info_grafico_produccion_acumulada: "Hover the legend and click to interact with the chart",
      turno_primero: "First shift",
      turno_segundo: "Second shift",
      turno_tercero: "Third shift",
      dumper                           : "Dumper",
      Dumpers                          : "Dumpers",
      Dumpers_traducir                 : "Dumpers",
      Scoops_traducir                  : "Scoops",
      Jumbos_traducir                  : "Jumbos",
      Bolters_traducir                 : "Bolters",
      Extractores_traducir             : "Extractor",
      Auxiliares_traducir              : "Auxiliar",
      flota_jumbo                      : "Jumbo Fleet",
      flota_camion                     : "Dumper Fleet",
      flota_lhd                        : "Scoop Fleet",
      flota_bolter                     : "Bolter Fleet",
      flota_telehandler                : "Telehandler Fleet",
      flota_hormigonera                : "Concrete Mixer Fleet",
      flota_extractor                  : "Extractor Fleet",
      flota_auxiliar                   : "Auxiliar Fleet",
      flota_jumbo_traducir             : "Jumbo Fleet",
      flota_camion_traducir            : "Dumper Fleet",
      flota_lhd_traducir               : "Scoop Fleet",
      flota_bolter_traducir            : "Bolter Fleet",
      flota_telehandler_traducir       : "Telehandler Fleet",
      flota_hormigonera_traducir       : "Concrete Mixer Fleet",
      flota_extractor_traducir         : "Extractor Fleet",
      flota_auxiliar_traducir          : "Auxiliar Fleet",
      metros                           : "Meters",
      horas                            : "Hours",
      brazo                            : "Arm",
      brazo_1                          : "Arm 1",
      brazo_2                          : "Arm 2",
      metros_restantes                 : "Remaining Meters",
      metros_programados               : "Scheduled Meters",
      metros_realizados                : "Accomplished Meters",
      no_hay_datos: "No data",
      cargando: "Loading",
      horas_de_perforacion: "Drilling hours",
      metros_totales: "Total meters",
      tiempo_encendido: "Power package",
      acaba_de_pasar_por: "has just passed",

      // Modal descarga
      fecha_movimiento  : "Movement date",
      fecha             : "Date",
      turno             : "Shift",
      equipo            : "Equipment",
      tipo_de_equipo    : "Type of equipment",
      nominal           : "Nominal",
      encendido         : "Power Package",
      ingreso           : "Entry",
      salida            : "Exit",
      primer_ciclo      : "First cycle",
      ultimo_ciclo      : "Last cycle",
      disponible        : "Available",
      indisponible      : "Unavailable",
      operativo         : "Operative",
      reserva           : "Reserve",
      efectivo          : "Effective",
      perdida           : "Loss",
      rendimiento       : "Performance",
      beacon_inicio     : "Start beacon",
      beacon_fin        : "End beacon",
      beacon_destino    : "Destination beacon",
      fecha_destino     : "Destination Date",
      duracion_carga    : "Load duration",
      duracion_descarga : "Unload duration",
      tipo_carga        : "Load type",
      perdida_operacional: "Operational Loss",
      datos: "Data",
      brazo: "Arm",
      inicio: "Start",
      fin: "End",
      ubicacion: "Location",
      duracion: "Duration",
      tipo: "Type",
      ralenti:"Idle",
      ralenti_aux:"Raw idle",
      nombre_maquina:"Machine name",
      tipo_maquina:"Machine type",

      // control diario
      control_de_frentes: "Mining Cycle",
      control_diario: "Daily Control",
      camion: 'Truck',
      camiones: "Trucks",
      primera_vuelta: "First Cycle",
      primer_ingreso: "First Entry",
      ultima_vuelta : "Last Cycle",
      ultima_salida: "Last Departure",
      ultima_actualizacion: "Last Update",
      fecha_ultima_actualizacion: "Last update Date",
      portal: "Pithead",
      hora: "Time",
      primer_ingreso_vuelta: "First entry/cycle",
      ultima_salida_vuelta: "Last departure/cycle",

      // operaciones
      operaciones: "Operations",
      operaciones_2: "Operations 2",
      monitoreo: "Monitoring",
      panel:"Panel",
      ejecutivo:"Executive",
      cuadrillas: "Crews",
      planificacion: "Planification",
      resumen_diario: "Daily Summary",
      prod_total: "Total Production",
      estado_equipos: "Equipment Status",
      control_de_tiempo: "Time Control",
      prod_por_equipo: "Equipment Production",
      prod_por_portales: "Productions by Mine access",
      prod_por_mina: "Productions by Mine",
      prod_diferenciada:"Differentiated production",
      vueltas: "Cycles",
      cargas:"Loads",
      baldadas:"Scoops",
      equipo_cargado:"loaded equipment",
      tonelaje: "Tonnage",
      operativo: "Operative",
      en_mantencion: "Maintenance",
      fuera_de_servicio: "Stand By",
      sin_info: "Without information",
      encuentro: "Encounter",
      ton_realizadas: "Accomplished Tons",
      ton_programadas: "Scheduled Tons",
      ton_restantes: "Remaining Tons",
      ubicacion_actual: "Current location",
      ultima_ubicacion: "Last location",
      fecha_ultima_ubicacion: "Last location date",
      toneladas: "Tons",
      programado: "Scheduled",
      realizado: "Carried out",
      minutos: "minutes",
      horas_percutidas:"Percussion hours",
      horas_programadas:"Scheduled hours",
      detalle_vuelta: "Detail by lap surface",
      ubicacion_equipos:"Equipment Locations",
      ingreso_mina:"Mine entry",
      salida_mina:"Mine exit",
      primer_encuentro_frente:"First face encounter",
      ultimo_encuentro_frente:"Last face encounter",
      carga:'Load',
      descarga:'Unload',

      // operaciones 2
      todos: "All",
      kpis_de_prod_diaria: "Daily production KPIs",
      movimiento_y_horometro: "Movements and Hourmeter",
      horometro: "Hourmeter",
      detalle_por_portal: "Detail by portal",
      detalle_por_vuelta:"Detail by cycle",
      nombre: "Name",
      total: "Total",
      voladuras:"Blasting",
      frentes:"Faces",
      frente: "Face",
      tiros:"Shooting",
      pernos:"Bolts",
      estado:"State",
      todos_maquinas:"All Machines",
      todos_flotas:"All fleets",
      tipo_flota:"Fleet type",

      // produccion
      resumen_produccion: "Resume Production",
      meta: "Goal",
      produccion_acumulada: "Accumulated Production",
      produccion: "Production",
      tonelaje_acumulado: "Accumulated Tonnage",
      meta_acumulada: "Accumulated Goal",
      equipo: "Equipment",
      numero_de_vueltas: "Number of Cycles",
      tiempo_efectivo: "Effective Time",

      //produccion 2
      kpis_de_prod_a_la_fecha: "Production KPIs to date",
      rendimiento: "Performance",
      cantidad: "Quantity",
      promedio: "Average",
      disponibilidad: "Availability",
      factor_operacional: "Operational Factor",
      utilidad_efectiva: "Effective Profit",
      eficiencia_global: "Overall Efficiency",
      tiempo_disponible: "Available Time",
      tiempo_nominal: "Nominal Time",
      tiempo_indisponible: "Unavailable Time",
      tiempo_encendido_en_taller:"Runtime in workshop",
      tiempo_operativo: "Operating Time",
      tiempo_reserva: "Reserve Time",
      tiempo_perdida_operacional: "Operational Loss Time",
      prod_por_turnos_a_la_fecha: "Production per shift to date",
      cancelar: "Cancel",

      //produccion 3
      funcionamiento_diario: "Daily Operation",
      tiempo_asarco: "ASARCO Time",
      horas_del_dia: "Daily hours",
      tiempo_total_en_taller: "Total time in workshop",
      tiempo_total_de_vueltas: "Total cycle time",
      tiempo_total_de_ralenti: "Total idle time",
      tiempo_ralenti:"Idle Time",
      tiempo_ratio:"Ratio Time",
      tiempo_total_ratio:"Operating Time / Idle Time",
      //Jumbo
      arm1_motor: "Power package 1",
      arm1_percutor: "Percussion 1",
      arm2_motor: "Power package 2",
      arm2_percutor: "Percussion 2",
      brazo1_motor: "Power package 1",
      brazo1_percutor: "Percussion 1",
      brazo1_compresor: "Compressor 1",
      brazo2_compresor: "Compressor 2",
      brazo2_motor: "Power package 2",
      brazo2_percutor: "Percussion 2",
      horometro_jumbo: "Hourmeter Jumbo",
      horometro_bolter:  "Hourmeter Bolter",
      horas_percutor: "Percussion hours",
      resumen_diario_brazos: "Daily Summary Arms",
      detalle_diario_brazos: "Day Detail Arms",
      horometro_motor_brazos:"Motor arms hourmeter",
      horometro_motor:"Motor hourmeter",
      horometro_percutor_brazos:"Striker arms hourmeter",

      //Justificacion
      justificacion:"Justify",
      justificaciones:"Justifications",
      justificacionMacro:"Macro Justify",
      equipo:"Equipment",
      tipo_alarma:"Alarm Type",
      accion:"Action",
      justificar:"Justify",
      por_justificar: "to Justify",
      detalle:"Detail",
      motivo:"Reason",
      motivo_detalle:"Reason details",
      registrar:"Register",
      otro_motivo:"Another reason",
      modificar_intervalo_tiempo:"Modify time interval",
      seleccione_opcion:"-- Select an option --",
      ingrese_ubicacion:"Enter a location",
      equipos:"Equipment",
      validacion_form_campos_vacios:"You must enter all the fields of the form",
      validacion_campo_justificacion:"Justification reason is required",
      validacion_justificacion:"Reason is required",
      validacion_fecha_inicio:"Start date is required",
      validacion_fecha_fin:"End date is required",
      validacion_fecha_inicio_mayor_fecha_fin:"The start date cannot be greater than the end date",
      validacion_equipo:"Equipment is required",
      validacion_ubicacion:"Location is required",
      validacion_tipo_alarma:"Alarm type is required",
      filas_por_pagina:"Rows per page:",
      prolongado:"Extended",
      en_mantencion_justificacion: "Extended Maintenance",
      fuera_de_servicio_justificacion: "Extended Stand By",
      ralenti_justificacion:"Extended Idle",
      todos_los_equipos:"All equipment",
      todas_las_alarmas:"All alarms",
      pendientes:"Pending",
      justificados:"Justified",
      justificadoSingular:"Justified",
      justificacion_personalizada:"Custom justification",
      sin_ubicacion_asignada:"No assigned location",
      mantencion: "Maintenance",
      'fuera de servicio':"Stand by",
      camions:"Dumpers",
      jumbos:"Jumbos",
      lhds:"Scoops",
      bolters:"Bolters",
      auxiliars:"Auxiliars",
      camions_traducir:"Dumpers",
      jumbos_traducir:"Jumbos",
      lhds_traducir:"Scoops",
      auxiliars_traducir:"Auxiliars",
      bolter_traducir:"Bolters",
      
      justificacion_grupo_id_1:'Wheater',
      justificacion_grupo_id_2:'Engine/Transmission',
      justificacion_grupo_id_3:'Chassis/Cabina',
      justificacion_grupo_id_4:'Tires',
      justificacion_grupo_id_5:'Incidents',
      justificacion_grupo_id_6:'Scheduled maintenance',
      justificacion_grupo_id_7:'Operational',

      justificacion_tipo_1:'Hot weather',
      justificacion_tipo_2:'Visibility',
      justificacion_tipo_3:'Snow',
      justificacion_tipo_4:'Earthquake',
      justificacion_tipo_5:'Wind',
      justificacion_tipo_6:'Electric storm',
      justificacion_tipo_7:'Other',
      justificacion_tipo_8:'Engine temperature',
      justificacion_tipo_9:'Power',
      justificacion_tipo_10:'Hose',
      justificacion_tipo_11:'Air',
      justificacion_tipo_12:'Lubrication',
      justificacion_tipo_13:'Leak',
      justificacion_tipo_14:'Electric failure',
      justificacion_tipo_15:'Starting problem',
      justificacion_tipo_16:'Unknown mechanical failure',
      justificacion_tipo_17:'Other',
      justificacion_tipo_18:'Lights failure',
      justificacion_tipo_19:'Windshield problem',
      justificacion_tipo_20:'Hopper problem',
      justificacion_tipo_21:'Mirror problems',
      justificacion_tipo_22:'Air conditioning',
      justificacion_tipo_23:'Heating',
      justificacion_tipo_24:'Dust fitration',
      justificacion_tipo_25:'Radio',
      justificacion_tipo_26:'Cabin condition',
      justificacion_tipo_27:'Other',
      justificacion_tipo_28:'Punctured',
      justificacion_tipo_29:'Tire temperature',
      justificacion_tipo_30:'Tire crack',
      justificacion_tipo_31:'Tire change',
      justificacion_tipo_32:'Other',
      justificacion_tipo_33:'Demolition',
      justificacion_tipo_34:'Crash/Collision',
      justificacion_tipo_35:'Run over',
      justificacion_tipo_36:'Fire',
      justificacion_tipo_37:'Derailment',
      justificacion_tipo_38:'Social topics',
      justificacion_tipo_39:'Major emergency',
      justificacion_tipo_40:'Other',
      justificacion_tipo_41:'Scheduled maintenance',
      justificacion_tipo_42:'Transfer to garage',
      justificacion_tipo_43:'Otro',
      justificacion_tipo_44:'Blasting',
      justificacion_tipo_45:'Fuel',
      justificacion_tipo_46:'Road State',
      justificacion_tipo_47:'By application',
      justificacion_tipo_48:'Transfer to garage',
      justificacion_tipo_49:'Other',
      
      //administracion
      maquinas: 'Machines',
      maquina: 'Machine',
      mina: 'Mines',
      dispositivos_instalados:'Devices currently installed',
      editar: 'Edit',
      eliminar: 'Delete',
      agregar:'Add new',
      agregar_nuevo_registro:'Add new record',
      prioridad:'Priority',
      nombre_referencia:'Reference name',
      sector_operacion:'Operating sector',
      area_asignada:'Assigned area',
      guardar_cambios:'Save changes',
      recuerdo_aprobacion:'Remind that the changes made will be send for its approval before being implemented',
      minaSingular:'Mine',
      nombre_referencia_vicon:'VICON reference name',
      prioridad_operacion:'Priority in operation',
      tipo_operacion:'Operation type',
      prioridad_operacion_subtitle:'Priority over devices already installed',
      area_asignada_subtitle:'Area type where it will be installed',
      id_vicon_subtitle: 'ID printed on the device',
      validacion_10_caracteres:'10 characters maximum',
      editar_vicon_subtitle:'Adjust the specifications of a device',
      cambios_guardados_title:'Your changes were saved successfully',
      cambios_guardados_subtitle:'You have just submitted a change request, we will notify you by email when it is approved.',
      entendido:'Understood',
      eliminar_vicon_info:'You are about to delete the following VICON',
      eliminar_regla_ciclo_gps:'You are about to delete the following cycle rule',
      eliminar_vicon_boton:'Delete VICON',
      confirmar_cambios_vicon:'Confirm changes in VICON',
      confirmar_cambios:'Confirm changes',
      confirmar_datos_nuevo_vicon:'Confirm new VICON data',
      agregar_nuevo_vicon:'Add new VICON',
      agregar_nuevo_vicon_subtitulo:'We recommend you to have the device at hand',
      agregarr:'Add',
      geocerca:'Geofence',
      geocerca_velocidad:'Speed geofence',
      nombre_geocerca:'Geofence name',
      tipo_geocerca:'Geofence type',
      tipo_control_geocerca:'Control type',
      velocidad_maxima:"Max speed",
      velocidad:"speed",
      administracion:"Administration",
      ubicado_en_frente: "Located in a workface",
      ubicado_en_entrada_mina:"Located in an entrance",
      vicons:"Vicons",
      geocercas:"Geofences",
      tipo_geocercas:"Geofence types",
      ciclo_gps:"Gps cycle",
      creadas_actualmente:"Currently created",
      agregar_nuevo_ciclo:"Add new cycle",
      editar_ciclo:"Edit cycle",
      inicio_ciclo:"Cycle start",
      inicio_ciclo_subtitulo:"Type of geofence where the cycle starts",
      fin_ciclo:"Cycle end",
      fin_ciclo_subtitulo:"Type of geofence where the cycle ends",
      actualizacion_de_la_informacion:"Update of informacion",
      agregar_nuevo_tipo_geocerca:"Add new geofence type",
      editar_tipo_geocerca:"Edit geofence type",
      nombre_mina:"Mine name",
      advertencia_eliminacion_tipo_geocerca:"You are about to delete the following geofence type",
      tipo_geocerca_inicio:"Type of starting geofence",
      tipo_geocerca_fin:"Type of ending geofence",

      //Control de frentes
      control_frentes:'Workface control',
      equipos_en_frentes:'Equipment at the workface',
      plan_de_frentes:'Workface plan',
      lugar:'Location',
      actividad:'Activity',
      ultimo_equipo: 'Last equipment',
      horas_desde_ultimo_encuentro: 'Hours since last encounter',
      perforacion:'Drilling',
      limpieza_scoop:'Scoop cleaning',
      refuerzo:'Reinforcement',
      refuerzo_buritica:'Sustenance',
      detalle_de_equipos: 'Equipment details',
      mostrar_ultimo_encuentro:'Show last encounter',
      ocultar_ultimo_encuentro:'Hide last encounter',
      dias_maximo:"Maximum days",

      //solicitudes
      solicitudes:'Requests',

      //analytics
      usuario:'User',
      primer_registro:'First record received',
      ultimo_registro:'Last record received',
      clicks_presionados:'Clicks pressed',
      teclas_presionadas:'Keys pressed',
      tiempo_total:'Total  time',
      seleccion_opcion_mina:'Select a mine from the dropdown to display the information.',
      monitoreo_dashboard:'Dashboard monitoring',
      filtrar_por_mina:'Filter by mine',
      tiempo_en_sesion:'Session time',
      actividad_por_usuario:'Activity by user',
      actividad_por_usuario_fecha:'Activity by user and day',

      //General
      filtrar_por:'Filter by',
      buscar:'Search',
      mes: 'Month',
      quincena: 'Fortnight',
      origen: 'Origin',
      tipo_de_material: 'Material Type',
      capacidad_ton2: 'Capacity Ton2',
      cantidad_de_viajes: 'Number of Trips',
      destino: 'Destination',
      densidad: 'Density',
      ton_ambiental: 'Environmental Tons',
      ton:"Ton",
      acarreo:"Acarreo"
    },
  },
};

const idiomaSeleccionado = localStorage.getItem('idioma') || 'es';

i18n.use(initReactI18next).init({
  resources,
  lng: idiomaSeleccionado,

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;