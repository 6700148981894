import { useEffect, useState } from 'react';
import { getInformacionVueltasGPS } from '../../../services/operaciones';

export const useGetInfoVueltasGPS = (fechaDia, id_maquina) => {
    const [loadingInfoVueltasGPS, setLoadingInfoVueltasGPS] = useState(false);
    const [dataVueltasGPS, setDataVueltasGPS] = useState({});

    useEffect(() => {
        setLoadingInfoVueltasGPS(true);
        getInformacionVueltasGPS(id_maquina, fechaDia)
            .then((res) => {
                setDataVueltasGPS(res.data);
                setLoadingInfoVueltasGPS(false);
            })
            .catch((err) => {
                console.log(err);
                setLoadingInfoVueltasGPS(false)
            })

    }, [fechaDia, id_maquina])

    return { dataVueltasGPS, loadingInfoVueltasGPS }
}
