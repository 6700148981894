export const getDashboardEmbeddedRegisteredAccess = async (dashboardId) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        dashboardId
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/quicksight/get-registered-user-embed-url",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }