import { useEffect, useState } from 'react'
import { getUltimosEncuentrosFrente } from '../services/controlFrentes'

const cache = new Map()

export function useGetUltimosEncuentrosFrente(id_area_operacion) {
  const [ultimoEncuentroFrente, setUltimoEncuentroFrente] = useState([])
  const [loadingUltimoEncuentroFrente, setLoadingUltimoEncuentroFrente] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoadingUltimoEncuentroFrente(true)

      // Verificar si los datos ya están en la caché
      const cacheKey = `${id_area_operacion}`
      if (cache.has(cacheKey)) {
        setUltimoEncuentroFrente(cache.get(cacheKey))
        setLoadingUltimoEncuentroFrente(false)
        return
      }

      try {
        const res = await getUltimosEncuentrosFrente(id_area_operacion)
        setUltimoEncuentroFrente(res.data)
        setLoadingUltimoEncuentroFrente(false)

        // Guardar en la caché
        cache.set(cacheKey, res.data)
      } catch (error) {
        console.error(error)
        setLoadingUltimoEncuentroFrente(false)
      }
    }

    fetchData()
  }, [id_area_operacion])

  return { ultimoEncuentroFrente, loadingUltimoEncuentroFrente}
}