import React, {useState} from 'react';
import { Button } from "@material-ui/core";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import {MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from "@material-ui/core/styles";
import PlanillaIcon from '@mui/icons-material/ContentPaste';
import IconoDescargar from '@mui/icons-material/FileDownload';
import {utils,writeFile} from "xlsx";
import { format } from "date-fns";

import '../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from '../../commons/Idiomas';
import { getEncuentrosControlFrentesDescargar } from '../../services/controlFrentes';
import { milisegundosAHoras } from '../../commons/FormatearTiempo';
import { formatearFechaISO } from '../../commons/FormatearFecha';


const useStyles = makeStyles({
  icon:{
    fontSize: "40px",
    marginRight:"10px",
    /* color: "#2f4554" */
  },
  titulo:{
    fontWeight: "bold",
    fontSize: "14.4px",
  },
  texto:{
    fontSize: "14.4px"
  },
  buttonSelected: {
    /* width: "180px", */
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  buttonDisabled: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    alignSelf: "center",
    pointerEvents: "none",
    color: "#a4a4a4"

  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        fontSize:"14.4px"
      }
    },

    '& .MuiOutlinedInput-root': { 
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
  },

  }
});
function calcularDiferenciaDeDias(fechaInicio, fechaFin) {
    // Convertir ambas fechas a objetos Date
    const dateInicio = new Date(fechaInicio);
    const dateFin = new Date(fechaFin);
  
    // Calcular la diferencia en milisegundos
    const diferenciaEnMilisegundos = dateFin - dateInicio;
  
    // Calcular la diferencia en días
    const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
  
    return diferenciaEnDias;
  }
function agruparPorDescripcionYMaquina(data) {
const groupedData = {};

data.forEach(obj => {
    const key = `${obj.descripcion}_${obj.nombre_maquina}`;      
    const fechaInicio = new Date(obj.fecha_inicio);
    const fechaFinal = new Date(obj.fecha_final);

    if (groupedData[key]) {
    groupedData[key].fecha_inicio = new Date(obj.fecha_inicio) < new Date(groupedData[key].fecha_inicio) ? obj.fecha_inicio : groupedData[key].fecha_inicio;
    groupedData[key].fecha_final = new Date(obj.fecha_final) > new Date(groupedData[key].fecha_final) ? obj.fecha_final : groupedData[key].fecha_final;
    groupedData[key].fecha_final = new Date(obj.fecha_final) > new Date(groupedData[key].fecha_final) ? obj.fecha_final : groupedData[key].fecha_final;
    groupedData[key].toneladas += obj.toneladas;
    groupedData[key].cantidad_registro_tiro += obj.cantidad_registro_tiro;
    groupedData[key].duracion += (fechaFinal - fechaInicio);
    } else {
    groupedData[key] = { ...obj, duracion:(fechaFinal - fechaInicio) };
    }
});
  
    return Object.values(groupedData);
  }

  const columnsToInclude = [
    "nombre_maquina",
    "dia",
    "descripcion",
    "fecha_inicio",
    "fecha_final",
    "cantidad_registro_tiro",
    "duracion"
  ];

export default function ModalDescargaFrentes({idMina, nombreMina, open, onClickClose}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    onClickClose(false);
  };
  const handleStartDateChange = (date) => {
    setFechaInicio(date);

    if(calcularDiferenciaDeDias(date,fechaFin) >= 30 ){
        const maxDate = new Date(date);
        maxDate.setDate(date.getDate() + 30);
        setFechaFin(maxDate);
    }
  };

  const onClickDescarga=async()=>{
    setLoading(true);
    let fechaInicioFormated = format(fechaInicio,"yyyy-MM-dd");
    let fechaFinFormatted = format(fechaFin,"yyyy-MM-dd")
    let onlyFechaInicio = format(fechaInicio,"yyyy-MM-dd");
    let onlyFechaTermino = format(fechaFin,"yyyy-MM-dd")

    const dataParaDescargar = await getEncuentrosControlFrentesDescargar(fechaInicioFormated, fechaFinFormatted,idMina);
    if(dataParaDescargar.success){
    const filteredData = dataParaDescargar?.data?.map((encuentro) => {
      let fechaInicio = new Date(encuentro.fecha_inicio);
      let fechaTermino = new Date(encuentro.fecha_final);
      let difMilisegundos = fechaTermino - fechaInicio;
      encuentro["duracion"] = difMilisegundos;
        const filteredEncuentro = {};
        columnsToInclude.forEach((column) => {
            let columnName = column;

            switch (column) {
                case "nombre_maquina":
                    columnName = t("nombre_maquina");
                    break;
                case "descripcion":
                    columnName = t("ubicacion");
                    break;
                case "fecha_inicio":
                    columnName = t("fecha_inicio");
                    break;
                case "fecha_final":
                    columnName = t("fecha_final");
                    break;
                case "cantidad_registro_tiro":
                    columnName = t("tiros_pernos");
                    break;
                case "toneladas":
                    columnName = t("toneladas");
                    break;
                case "duracion":
                    columnName = t("duracion");
                    break;
                case "dia":
                  columnName = t("dia")
                  break;
            }

            if(column == "duracion"){
            filteredEncuentro[columnName] = milisegundosAHoras(encuentro[column]);
            }else if(column == "fecha_inicio" || column == "fecha_final"){
              filteredEncuentro[columnName] = formatearFechaISO(encuentro[column])
            }
            else{
            filteredEncuentro[columnName] = encuentro[column];
            }
        });
        
        return filteredEncuentro;
        });

        const workbook = utils.book_new();
        const worksheetResumen = utils.json_to_sheet(filteredData);
        utils.book_append_sheet(workbook, worksheetResumen, "datos_base");
        
        writeFile(
            workbook,
            `${t("datos")}_frentes_${nombreMina}_${onlyFechaInicio}-${onlyFechaTermino}.xlsx`
        );
        
    setLoading(false);
    }else{
    setLoading(false);
    }

  }
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        /* fullWidth */
        maxWidth="md"
      >
        <DialogContent sx = {{padding:"0"}}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{padding:"13px 20px"}}
          >
            <Grid item lg={9}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid container item direction={"row"}>
                  <PlanillaIcon className={classes.icon}/>
                  <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                    {t("descargar_datos")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.titulo} style={{fontSize:"13px", fontWeight:"normal",marginLeft:"34px"}}>
                    {`30 ${t("dias_maximo")}`}
                    </Typography>
                </Grid>
              </Grid>
            </Grid>
            <div onClick={handleClose} className={classes.texto} style={{fontStyle:"italic", cursor:"pointer", display:loading?'none' : 'block'}}>
              {t("cerrar")}
            </div>
          </Grid>

          <Divider/>
          
          <br/>

          <Grid 
            container 
            alignItems='center' 
            justifyContent='center'
            sx={{padding:"13px 20px"}}
          >
            <Grid item>
              <Grid container 
                alignItems='center' 
                justifyContent='center'
                direction="column"
              >
                <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                  {t("fecha_inicio")}
                </Typography>
                <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    autoOk
                    variant='static'
                    className={classes.datepicker}
                    format="dd/MM/yyyy"
                    /* cancelLabel="Cancelar" */
                    size="small"
                    inputVariant="outlined"
                    value={fechaInicio}
                    onChange={date => handleStartDateChange(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container 
                alignItems='center' 
                justifyContent='center'
                direction="column"
              >
                <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                  {t("fecha_fin")}
                </Typography>
                <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    autoOk
                    variant='static'
                    className={classes.datepicker}
                    format="dd/MM/yyyy"
                    /* cancelLabel="Cancelar" */
                    size="small"
                    inputVariant="outlined"
                    value={fechaFin}
                    onChange={date => setFechaFin(date)}
                    maxDate={new Date(fechaInicio).setDate(fechaInicio.getDate()+30)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>

        </DialogContent>


        <DialogActions>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{padding:"5px 20px"}}
          >           
            {
            !loading?
                <Grid>
                  <Button 
                      onClick={async()=> await onClickDescarga()}
                  > 
                  <IconoDescargar sx={{marginRight:"5px"}}/> {t("descargar")}
                  </Button>
                </Grid>
                : (
                <Grid>
                    <CircularProgress style = {{color: "#2f4554",marginBottom:"20px"}} size = {80}/>
                    <br/>
                    <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold",marginBottom:"20px"}}>
                    Descargando...
                    </Typography>
                </Grid>
                )
            }
          </Grid>
        </DialogActions>
        <br/>
      </Dialog>
    </div>
  );
}