import React from 'react'
import { GenericPopUp } from '../../../components/molecules/GenericPopUp'
import { Box, Button, Grid, InputLabel, Link, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Pool from '../../../authentication/../UserPool'
import { insertReglaVueltaGpsControlVersion } from '../../../services/administracion';

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

export const PopUpDeleteCicloGps = ({show, onHandleShowPopUp, ciclo, nombre_mina, id_area_operacion,pathMina, showPopUpSuccess}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const onSubmitForm =  async() =>{
        const initialStateForm = {
            id: ciclo?.id ? ciclo?.id : 0,
            id_area_operacion: id_area_operacion,
            id_tipo_geocerca_fin: ciclo?.id_tipo_geocerca_fin ? ciclo.id_tipo_geocerca_fin : 0,
            id_tipo_geocerca_inicio: ciclo?.id_tipo_geocerca_inicio ? ciclo?.id_tipo_geocerca_inicio : 0,
            id_tipo_maquina: ciclo?.id_tipo_maquina ? ciclo?.id_tipo_maquina : 0,
            nombre_tipo_maquina: ciclo?.nombre_tipo_maquina ? ciclo.nombre_tipo_maquina : "",
            nombre_tipo_geocerca_fin: ciclo?.tipo_geocerca_fin ? ciclo?.tipo_geocerca_fin : "",
            nombre_tipo_geocerca_inicio: ciclo?.tipo_geocerca_inicio ? ciclo?.tipo_geocerca_inicio : "",
            id_area_operacion: id_area_operacion,
            nombre_mina: nombre_mina,
            pathMina: pathMina,
            editing: false
        };
    
        // Pass initialStateForm directly
        const resp = await insertReglaVueltaGpsControlVersion(
            initialStateForm.id,
            initialStateForm.id_area_operacion,
            initialStateForm.id_tipo_maquina,
            initialStateForm.id_tipo_geocerca_inicio,
            initialStateForm.id_tipo_geocerca_fin,
            Pool.getCurrentUser().username,
            initialStateForm.nombre_tipo_geocerca_inicio,
            initialStateForm.nombre_tipo_geocerca_fin,
            false,        
            pathMina,
            initialStateForm.nombre_tipo_maquina   ,
            nombre_mina 
        );
          if(resp.success){
            showPopUpSuccess(true)
          }
    }

  return (
    <>
        <GenericPopUp show={show} handleShowPopUp={onHandleShowPopUp} showCancel={false} Children={
            <Grid container justifyContent='center' alignItems='center' direction='column' style={{paddingBottom:'50px'}}>
                <Grid container item justifyContent='center' alignItems='center' direction='row'>
                    <Grid style={{margin:'20px 20px'}}>
                        <WarningAmberIcon fontSize='large'/>
                    </Grid>
                    <Typography
                        style={{fontWeight: "bolder",fontSize: "15px",color: "#2f4554", textAlign:'center'}}
                    >
                       {t('eliminar_regla_ciclo_gps')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Box sx={{color:'#2f4554', fontWeight:'500' ,padding:'45.5px 45px', backgroundColor:'#f3f4f7',textAlign:'center'}}>
                        <Grid container item xs={12} spacing={1}>

                            <Grid container item xs={6} spacing={1}>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>{t("tipo_geocerca_inicio")}</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{(ciclo?.tipo_geocerca_inicio) ? ciclo?.tipo_geocerca_inicio : "Sin información"}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>{t("tipo_geocerca_fin")}</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{(ciclo?.tipo_geocerca_fin) ? ciclo?.tipo_geocerca_fin : "Sin información"}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid container item xs={6} spacing={1}>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>{t("tipo_maquina")}</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{ciclo?.nombre_tipo_maquina ? ciclo?.nombre_tipo_maquina : "Sin información"}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>{t("mina")}</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{nombre_mina}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
                <Grid container direction='column' justifyContent='center' alignItems='center'>
                    <Button
                            
                            type="button"
                            variant="contained"
                            className={classes.root}
                            style={{textAlign:'center', marginTop:'35px', padding:'8px 80px'}}
                            onClick={async() =>{
                                await onSubmitForm()
                                onHandleShowPopUp(false);
                            }
                            }
                            >
                            {t("eliminar")}
                    </Button>
                    <Box sx={{display:'flex',justifyContent:'center', marginTop:'30px'}}>
                        <Link 
                        style={{cursor:'pointer'}} 
                        onClick={()=>{
                            onHandleShowPopUp(false);
                        }} 
                        underline='always' 
                        color='inherit'>{t('cancelar')}</Link>
                    </Box>
                </Grid>
            </Grid>
        }/>
    </>
  )
}
