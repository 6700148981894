import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function ProductividadCard(props) {

  return (
    <Card sx={{ minWidth: 225 }}>
      <CardContent>
{/*         <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography> */}
        <Typography variant="h5" component="div" color="text.secondary" style={{color: 'teal', textAlign: 'center'}}>
          {props.cantidad}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{textAlign: 'center'}}>
        {props.texto}
        </Typography>
{/*         <Typography sx={{ mb: 1.5 }} color="text.secondary">
          adjective
        </Typography>
        <Typography variant="body2">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography> */}
      </CardContent>
{/*       <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}