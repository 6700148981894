import {React} from 'react';
import {
	Box
} from "@material-ui/core";
import {	
  Grid, 
	Card, 
	CardContent
} from "@mui/material"
/* import { createTheme, ThemeProvider } from '@mui/material/styles';*/
import { makeStyles } from "@material-ui/core/styles";
import '../../translator';
import { useTranslation } from 'react-i18next';

/* const theme = createTheme(); */
const useStyles = makeStyles(() => ({
  generalFont: {
    fontSize:"12px"
  },
  upperFont:{
    textTransform: "uppercase"
  }
}));

const VueltaEquipoCard = ({nombre, ubicacion, fecha}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getColor = (key) => {
    const estados = {
      "operativo": "#01b7c4",
      "mantencion": "#ffbf00",
      "fuera de servicio": "#C5283D",
      "sininfo": "#d6dce4"
    }
    return estados[key]
  }



  return(
    <>
      <Card sx={{boxShadow:4}}>
        <CardContent style={{padding:"0"}}>
          <Grid container>
            <Grid item lg={2} md={2} xs={2}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: getColor(((ubicacion==="-") && (fecha==="-"))?"sininfo":"operativo")
                }}
              />			
            </Grid>
            
            <Grid item lg={10} md={10} xs={10} sx={{padding:"20px 20px"}} className={classes.generalFont}>
              <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                  <b style={{color:getColor(((ubicacion==="-") && (fecha==="-"))?"sininfo":"operativo"), filter:"brightness(75%)"}}>{nombre}</b>
                </Grid>
              </Grid>
              <br/>
              <Grid container>
                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("portal")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {ubicacion}
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("hora")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {fecha}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default VueltaEquipoCard;