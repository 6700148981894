import React from 'react'
import { GenericPopUp } from '../../../components/molecules/GenericPopUp'
import { Box, Button, Grid, InputLabel, Link, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Pool from '../../../authentication/../UserPool'
import { insertarVersionamientoVicon } from '../../../services/administracion';

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

export const PopUpDelete = ({show, onHandleShowPopUp, vicon, nombre_mina, id_area_operacion,pathMina, showPopUpSuccess}) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const onSubmitForm =  async() =>{
        const initialStateForm = {
            descripcion:(vicon?.descripcion) ? vicon.descripcion : "Sin información",
            major: 1,
            minor:(vicon?.minor) ? vicon?.minor : null,
            id_tipo_beacon: (vicon?.id_area_asignada )? vicon.id_area_asignada : null,
            id_area_operacion: id_area_operacion,
            id_grupo:vicon?.id_sector_operacion ? vicon.id_sector_operacion : null,
            id_tipo_operacion:3,
            solicitante:await Pool.getCurrentUser().username,
            nombre_mina: nombre_mina,
            nombre_sector_operacion: (vicon?.nombre_sector_operacion) ? vicon.nombre_sector_operacion : "Sin información",
            area_asignada:vicon?.area_asignada ? vicon.area_asignada : "Sin información",
            pathMina:pathMina,
            id:vicon.id,
            orden:(vicon?.orden) ? vicon.orden : null,
          }

          const resp = await insertarVersionamientoVicon(initialStateForm);
          if(resp.success){
            showPopUpSuccess(true)
          }

    }

  return (
    <>
        <GenericPopUp show={show} handleShowPopUp={onHandleShowPopUp} showCancel={false} Children={
            <Grid container justifyContent='center' alignItems='center' direction='column' style={{paddingBottom:'50px'}}>
                <Grid container item justifyContent='center' alignItems='center' direction='row'>
                    <Grid style={{margin:'20px 20px'}}>
                        <WarningAmberIcon fontSize='large'/>
                    </Grid>
                    <Typography
                        style={{fontWeight: "bolder",fontSize: "15px",color: "#2f4554", textAlign:'center'}}
                    >
                       {t('eliminar_vicon_info')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Box sx={{color:'#2f4554', fontWeight:'500' ,padding:'45.5px 45px', backgroundColor:'#f3f4f7',textAlign:'center'}}>
                        <Grid container item xs={12} spacing={1}>

                            <Grid container item xs={6} spacing={1}>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Sector de operacion</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{(vicon?.nombre_sector_operacion) ? vicon?.nombre_sector_operacion : "Sin información"}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Area asignada</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{(vicon?.area_asignada) ? vicon?.area_asignada : "Sin información"}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid container item xs={6} spacing={1}>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Nombre referencia</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{vicon?.descripcion}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "400",fontSize: "15px",color: "#2f4554"}}>Prioridad en operación</Typography>
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel >
                                            <Typography align='left' style={{fontWeight: "bold",fontSize: "15px",color: "#2f4554"}}>{vicon?.orden}</Typography>
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
                <Grid container direction='column' justifyContent='center' alignItems='center'>
                    <Button
                            
                            type="button"
                            variant="contained"
                            className={classes.root}
                            style={{textAlign:'center', marginTop:'35px', padding:'8px 80px'}}
                            onClick={async() =>{
                                await onSubmitForm()
                                onHandleShowPopUp(false);
                            }
                            }
                            >
                            {t("eliminar_vicon_boton")}
                    </Button>
                    <Box sx={{display:'flex',justifyContent:'center', marginTop:'30px'}}>
                        <Link 
                        style={{cursor:'pointer'}} 
                        onClick={()=>{
                            onHandleShowPopUp(false);
                        }} 
                        underline='always' 
                        color='inherit'>{t('cancelar')}</Link>
                    </Box>
                </Grid>
            </Grid>
        }/>
    </>
  )
}
