// PageTimingContext.js
import React, { createContext, useContext } from 'react';
import usePageTiming from '../hooks/general/usePageTiming';

const PageTimingContext = createContext();

export const PageTimingProvider = ({ children, idAreaOperacion }) => {
  const pageTiming = usePageTiming(idAreaOperacion);

  return (
    <PageTimingContext.Provider value={pageTiming}>
      {children}
    </PageTimingContext.Provider>
  );
};

export const usePageTimingContext = () => {
  return useContext(PageTimingContext);
};
