import { useEffect, useState } from "react";
import { getViconsVersion } from "../../services/solicitudes";

export const useGetViconsVersion = (id_area_operacion, reloadData= false) => {
  const [ViconsVersion, setViconsVersion] = useState([]);
  const [loadingViconsVersion, setLoadingViconsVersion] = useState(false);
  useEffect(() => {
    setLoadingViconsVersion(true)
    getViconsVersion(id_area_operacion)
      .then((res) => {
        setViconsVersion(res.data);
        setLoadingViconsVersion(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingViconsVersion(false);
      });
  }, [id_area_operacion,reloadData]);

  return { loadingViconsVersion, ViconsVersion };
};
