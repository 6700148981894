import React, {useState} from 'react';
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
  CircularProgress
} from '@mui/material';
import {MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from "@material-ui/core/styles";
import PlanillaIcon from '@mui/icons-material/ContentPaste';
import IconoDescargar from '@mui/icons-material/FileDownload';
import {utils,writeFile} from "xlsx";
import { format } from "date-fns";

import {descargarControlVelocidad, descargarResumenMantenciones, descargarResumenTurnos, descargarResumenTurnosPorGrupoBeacon, descargarVueltasGps, getJumboResumen, getReporteResumen, getReporteVueltas} from '../../services/operaciones';
import '../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from '../../commons/Idiomas';
import { getMaquinasJustificadasDescargar } from '../../services/justificacion';
import { formatearFechaISO } from '../../commons/FormatearFecha';
import { ConvertMinutosToHoras } from '../../commons/FormatearTiempo';
import { formatearCantidadDecimales } from '../../commons/Formatos';
import { getDescargarCargasScoop } from '../../services/produccion';
import { unstable_resetCreateSelectorCache } from '@mui/x-data-grid/utils/createSelector';
import { translateMachineName } from '../../commons/utiles';
import { getReporteAcarreo } from '../../services/reportes';

//import LandslideIcon from '@mui/icons-material/Landslide';

const useStyles = makeStyles({
  icon:{
    fontSize: "40px",
    marginRight:"10px",
    /* color: "#2f4554" */
  },
  titulo:{
    fontWeight: "bold",
    fontSize: "14.4px",
  },
  texto:{
    fontSize: "14.4px"
  },
  buttonSelected: {
    /* width: "180px", */
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  buttonDisabled: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    alignSelf: "center",
    pointerEvents: "none",
    color: "#a4a4a4"

  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        fontSize:"14.4px"
      }
    },

    '& .MuiOutlinedInput-root': { 
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
  },

  }
});


export default function ModalDescargaPlanilla({idMina, nombreMina, open, onClickClose, esSubterranea = true}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [datosDashboardCheck, setDatosDashboardCheck] = useState(false)
  const [resumenCheck, setResumenCheck] = useState(false);
  const [vueltasCheck, setVueltasCheck] = useState(false);
  const [jumboCheck, setJumboCheck] = useState(false);
  const [controlVelocidadCheck, setControlVelocidadCheck] = useState(false);
  const [justificacionesCheck, setjustificacionesCheck] = useState(false);
  const [cargasCheck, setCargasCheck] = useState(false)
  const [resumenTurnosCheck, setResumenTurnosCheck] = useState(false)
  const [loading, setloading] = useState({
    resumenDescarga:false,
    vueltasDescarga:false,
    jumboDescarga:false,
    justificacionDescarga:false
  })
  const [loadingTest, setLoadingTest] = useState(false)

  const handleClose = () => {
    onClickClose(false);
  };

  const handleStartDateChange = (date) => {
    setFechaInicio(date);
    if(calcularDiferenciaDeDias(date,fechaFin) >= 30 ){
        // maxima diferencia de dias 7 entre inicio y termino
        const maxDate = new Date(date);
        maxDate.setDate(date.getDate() + 30);
        setFechaFin(maxDate);
    }else if(calcularDiferenciaDeDias(date,fechaFin) < 0){
      setFechaFin(date);
    }
  };

  function calcularDiferenciaDeDias(fechaInicio, fechaFin) {
    const dateInicio = new Date(fechaInicio);
    const dateFin = new Date(fechaFin);
  
    const diferenciaEnMilisegundos = dateFin - dateInicio;
  
    const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
  
    return diferenciaEnDias;
  }

  function procesarExcelVueltas(resVueltas, utils, workbook, t){
    resVueltas.data.forEach((value) =>{
      value.maquina = translateMachineName(value.maquina,t,idMina)
    })
    const worksheetVueltas = utils.json_to_sheet(
      resVueltas.data,
      {
        header:[
          "fecha",
          "turno",
          "maquina",
          "tipo_maquina",
          "beacon_inicio",
          "fecha_inicio",
          "beacon_destino",
          "fecha_destino",
          "beacon_fin",
          "fecha_fin",
          "duracion_carga",
          "duracion_descarga",
          "duracion_minutos",
          "tipo_carga",
          "toneladas"
        ]
      }
    );  
    utils.book_append_sheet(workbook, worksheetVueltas, t("datos_vueltas"));
    utils.sheet_add_aoa(
      worksheetVueltas, 
      [[
        t("fecha"),
        t("turno"),
        t("equipo"),
        t("tipo_de_equipo"),
        t("beacon_inicio"),
        t("fecha_inicio"),
        t("beacon_destino"),
        t("fecha_destino"),
        t("beacon_fin"),
        t("fecha_fin"),
        t("duracion_carga"),
        t("duracion_descarga"),
        `${t("duracion")}(min)`,
        t("tipo_carga"),
        t("toneladas")
      ]], 
      { origin: "A1" }
    );

  }

  function procesarExcelAcarreo(resAcarreo, utils, workbook, t){
    const worksheetVueltas = utils.json_to_sheet(
      resAcarreo.data,
      {
        header:[
          "fecha",
          "mes",
          "quincena",
          "turno",
          "origen",
          "tipo_de_material",
          "OPEX/CAPEX",
          "equipo",
          "capacidad_ton2",
          "cantidad_de_viajes",
          "destino",
          "densidad",
          "ton_ambiental",
          "ton",
          "m3"
        ]
      }
    );  
    utils.book_append_sheet(workbook, worksheetVueltas, t("acarreo"));
    utils.sheet_add_aoa(
      worksheetVueltas, 
      [[
        t("fecha"),
        t("mes"),
        t("quincena"),
        t("turno"),
        t("origen"),
        t("tipo_de_material"),
        t("OPEX/CAPEX"),
        t("equipo"),
        t("capacidad_ton2"),
        t("cantidad_de_viajes"),
        t("destino"),
        t("densidad"),
        t("ton_ambiental"),
        t("ton"),
        t("m3")
      ]], 
      { origin: "A1" }
    );

  }
  
  function procesarDatosDashboard(resVueltas, utils, workbook, t){
    resVueltas.data.forEach((value) =>{
      value.equipo = translateMachineName(value.equipo,t, idMina)
    })
    const worksheetResumen = utils.json_to_sheet(
      resVueltas.data,
      {
        header:[
          "fecha",
          "turno",
          "equipo",
          "tipo de equipo",
          "nominal",
          "encendido",
          "disponible",
          "indisponible",
          "operativo",
          "reserva",
          "efectivo",
          "perdida_operacional",
          "ralenti",
          "vueltas",
          "toneladas",
          "rendimiento",
          "disponibilidad",
          "factor operacional",
          "utilidad efectiva",
          "eficiencia global",
          // "ratio" TEMPORAL, se desactiva tambien de api
        ]
      }
    );
    utils.book_append_sheet(workbook, worksheetResumen, t("datos_dashboard"));
    utils.sheet_add_aoa(
      worksheetResumen, 
      [[
        t("fecha"),
        t("turno"),
        t("equipo"),
        t("tipo_de_equipo"),
        `${t("nominal")} (hr)`,
        `${t("encendido")} (hr)`,
        `${t("disponible")} (hr)`,
        `${t("indisponible")} (hr)`,
        `${t("operativo")} (hr)`,
        `${t("reserva")} (hr)`,
        `${t("efectivo")} (hr)`,
        `${t("perdida_operacional")} (hr)`,
        `${t("ralenti")} (hr)`,
        t("vueltas"),
        `${t("toneladas")} (T)`,
        `${t("rendimiento")} (T/hr)`,
        `${t("disponibilidad")} (%)`,
        `${t("factor_operacional")} (%)`,
        `${t("utilidad_efectiva")} (%)`,
        `${t("eficiencia_global")} (%)`,
        `${t("ralenti_aux")} (hr)`
        // ,`Ratio (TO / RA)` temporal
      ]], 
      { origin: "A1" }
    );
  }

  function procesarDatosPercutores(resPercutores, utils, workbook, t){

    const allData = [];
    
        for (const [nombre_maquina, rows_maquina] of Object.entries(resPercutores.data)) {
          const rows_maquina_con_nombre = rows_maquina.map(row => ({ ...row, nombre_maquina }));
          allData.push(...rows_maquina_con_nombre);
        }
    
        const worksheetResumen = utils.json_to_sheet(allData, {
          header: [
            "nombre_maquina",
            "tipo_maquina",
            "fecha",
            "turno",
            "motor brazo 1",
            "percutor brazo 1",
            "motor brazo 2",
            "percutor brazo 2",
            "total_motor",
            "total_percusion",
            "total_tiros",
            "total_golpes",
            "frentes",
            "operativo"
          ]
        });
    
        utils.book_append_sheet(workbook, worksheetResumen, t("datos_percutores"));
    
        utils.sheet_add_aoa(
          worksheetResumen,
          [[
            t("nombre_maquina"),
            t("tipo_maquina"),
            t("fecha"),
            t("turno"),
            `${t("Motor Brazo 1")} (hrs)`,
            `${t("Percutor Brazo 1")} (hrs)`,
            `${t("Motor Brazo 2")} (hrs)`,
            `${t("Percutor Brazo 2")} (hrs)`,
            `${t("Total Motor")} (hrs)`,
            `${t("Total Percusión")} (hrs)`,
            `${t("Total Tiros")}`,
            `${t("Total Golpes")}`,
            `${t("Frentes")}`,
            `${t("horometro_motor")}`
          ]],
          { origin: "A1" }
        );

  }

  function procesarDatosCargasScoop(resCargasScoop, utils, workbook, t){
    const worksheetResumen = utils.json_to_sheet(
      resCargasScoop.data,
      {
        header:[
          "pala_carga",
          "dumper",
          "turno",
          "dia",
          "fecha_inicio",
          "fecha_fin",
          "duracion_minutos",
        ]
      }
    );
    utils.book_append_sheet(workbook, worksheetResumen, t("datos_scoop"));
    utils.sheet_add_aoa(
      worksheetResumen, 
      [[
        "Scoop cargador",
        "Dumper cargado",
        "Turno",
        "Dia",
        "Fecha inicio",
        "Fecha Fin",
        "Duración (min)",
      ]], 
      { origin: "A1" }
    );  

  }

  function procesarResumenTurnos(resResumenTurnos, utils, workbook, t){
    const worksheetResumen = utils.json_to_sheet(
      resResumenTurnos.data,
      {
        header:[
          "nombre",
          "fecha",
          "turno",
          "encendido",
          "ingreso",
          "salida",
          "primer_ciclo",
          "ultimo_ciclo"
        ]
      }
    );
    utils.book_append_sheet(workbook, worksheetResumen, t("datos_resumen_turno_general"));
    utils.sheet_add_aoa(
      worksheetResumen, 
      [[
        t("nombre"),
        t("fecha"),
        t("turno"),
        t("encendido"),
        t("ingreso"),
        t("salida"),
        t("primer_ciclo"),
        t("ultimo_ciclo")
      ]], 
      { origin: "A1" }
    );   
  }
  
  function procesarResumenTurnosByMina(resResumenTurnos, utils, workbook, t){
    const worksheetResumen = utils.json_to_sheet(
      resResumenTurnos.data,
      {
        header:[
          "nombre",
          "dia",
          "turno",
          "fecha_movimiento",
          "nombre_mina",
          "accion"
        ]
      }
    );
    utils.book_append_sheet(workbook, worksheetResumen, t("datos_resumen_turno_mina"));
    utils.sheet_add_aoa(
      worksheetResumen, 
      [[
        t("nombre"),
        t("fecha"),
        t("turno"),
        t("fecha_movimiento"),
        t("mina"),
        t("accion"),
      ]], 
      { origin: "A1" }
    ); 
  }

  function procesarResumenMantenciones(resResumenTurnos, utils, workbook, t){
    const worksheetResumen = utils.json_to_sheet(
      resResumenTurnos.data,
      {
        header:[
          "nombre",
          "tipo_maquina",
          "fecha",
          "turno",
          "inicio",
          "fin",
          "lugar_mantencion",
          "modalidad",
          "ubicacion"
        ]
      }
    );
    utils.book_append_sheet(workbook, worksheetResumen, t("datos_resumen_mantenciones"));
    utils.sheet_add_aoa(
      worksheetResumen, 
      [[
        t("nombre_maquina"),
        t("tipo_maquina"),
        t("fecha"),
        t("turno"),
        t("fecha_inicio"),
        t("fecha_fin"),
        t("lugar_mantencion"),
        t("modalidad"),
        t("ubicacion")
      ]], 
      { origin: "A1" }
    ); 
  }

  const onClickDescarga = async (datosDashboardCheck, justificacionCheck, controlVelocidadCheck) => {
    setLoadingTest(true)
    const workbook = utils.book_new(); 

    if(datosDashboardCheck){

      const [promiseVueltas,promiseAcarreo, promiseDatosDashboard, promisePercutores, promiseCargasScoop, promiseResumenTurnos, promiseResumenTurnosByMina, promiseResumenMantenciones] = await Promise.all([
        getReporteVueltas(idMina, format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd")),
        getReporteAcarreo(idMina,format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd")),
        getReporteResumen(idMina, format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd")),
        getJumboResumen(idMina, format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd")),
        getDescargarCargasScoop(idMina, format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd")),
        descargarResumenTurnos(idMina, format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd")),
        descargarResumenTurnosPorGrupoBeacon(idMina, format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd")),
        descargarResumenMantenciones(idMina,format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd")),
      ]);

      if(promiseVueltas.success){
        procesarExcelVueltas(promiseVueltas, utils, workbook, t);
      }

      if(idMina == 24 && promiseAcarreo?.success){
        procesarExcelAcarreo(promiseAcarreo,utils,workbook,t)
      }

      if(promiseDatosDashboard.success){
        procesarDatosDashboard(promiseDatosDashboard, utils, workbook, t)
      }

      if(promisePercutores.success && idMina != 24){
        procesarDatosPercutores(promisePercutores, utils, workbook, t)
      }

      if(promiseCargasScoop.success){
        procesarDatosCargasScoop(promiseCargasScoop, utils,workbook, t);
      }
      
      if(promiseResumenTurnos.success){
        procesarResumenTurnos(promiseResumenTurnos, utils, workbook, t)
      }

      if(promiseResumenTurnosByMina.success){
        procesarResumenTurnosByMina(promiseResumenTurnosByMina, utils, workbook, t)
      }

      if(promiseResumenMantenciones.success){
        procesarResumenMantenciones(promiseResumenMantenciones,utils,workbook, t);
      }
    }

    if(justificacionCheck){
      const resJustificaciones = await getMaquinasJustificadasDescargar(format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd"),idMina);

      if(resJustificaciones.success) {

        const filteredJustificaciones = resJustificaciones.data.map((item) => {
          return {
            duracion_minutos_movimiento: ConvertMinutosToHoras(item.duracion_minutos_movimiento),
            fecha_inicio: formatearFechaISO(item.fecha_inicio),
            fecha_termino: formatearFechaISO(item.fecha_termino),
            nombre: item.nombre,
            observacion: item.observacion,
            nombre_maquina: item.nombre_maquina,
            nombre_tipo_movimiento: item.nombre_tipo_movimiento,
            ubicacion: item.ubicacion,
          };
        });

        const worksheetResumen = utils.json_to_sheet(
          filteredJustificaciones,
          {
            header:[
              "duracion_minutos_movimiento",
              "fecha_inicio",
              "fecha_termino",
              "nombre",
              "observacion",
              "nombre_maquina",
              "nombre_tipo_movimiento",
              "ubicacion"
            ]
          }
        );
        utils.book_append_sheet(workbook, worksheetResumen, t("datos_justificaciones"));
        utils.sheet_add_aoa(
          worksheetResumen, 
          [[
            "Duración (Hrs)",
            "Fecha de inicio",
            "Fecha de termino",
            "Justificacion",
            "Observacion",
            "Nombre maquina",
            "Estado justificado",
            "Ubicación"
          ]], 
          { origin: "A1" }
        );   
      }

    }

    /*descargarControlVelocidad */
    if(controlVelocidadCheck){
      const resControlVelocidad = await descargarControlVelocidad(idMina,format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd"));

      if(resControlVelocidad.success) {

        const filteredControlVelocidad = resControlVelocidad.data.map((item) => {
          return {
            fecha:formatearFechaISO(item.fecha),
            velocidad:item.speed,
            maxima_velocidad:item.max_speed,
            nombre_maquina:item.nombre_maquina,
            nombre_geocerca:item.nombre_geocerca
          };
        });

        const worksheetResumen = utils.json_to_sheet(
          filteredControlVelocidad,
          {
            header:[
              "fecha",
              "velocidad",
              "maxima_velocidad",
              "nombre_maquina",
              "nombre_geocerca"
            ]
          }
        );
        utils.book_append_sheet(workbook, worksheetResumen, t("datos_control_velocidad"));
        utils.sheet_add_aoa(
          worksheetResumen, 
          [[
            "Fecha",
            "Velocidad (km/h)",
            "Maxima velocidad (km/h)",
            "Maquina",
            "Geocerca",
          ]], 
          { origin: "A1" }
        );   
      }

    }    

    if(vueltasCheck && !esSubterranea){
      const resDescargarVueltasGps = await descargarVueltasGps(idMina,format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd"));

      if(resDescargarVueltasGps.success) {

        const filteredDescargarVueltasGps = resDescargarVueltasGps.data.map((item) => {
          return {
            dia:formatearFechaISO(item.dia),
            turno:item.turno,
            equipo:item.equipo,
            tipo_equipo:item.tipo_equipo,
            geocerca_inicio:item.geocerca_inicio,
            fecha_inicio:formatearFechaISO(item.fecha_inicio),
            geocerca_fin:item.geocerca_fin,
            fecha_fin:item.fecha_fin,
            duracion_minutos:item.duracion_minutos,
            promedio_velocidad:formatearCantidadDecimales(item?.promedio_velocidad,1) 
          };
        });

        const worksheetResumen = utils.json_to_sheet(
          filteredDescargarVueltasGps,
          {
            header:[
              "dia",
              "turno",
              "equipo",
              "tipo_equipo",
              "geocerca_inicio",
              "fecha_inicio",
              "geocerca_fin",
              "fecha_fin",
              "duracion_minutos",
              "promedio_velocidad"
            ]
          }
        );
        utils.book_append_sheet(workbook, worksheetResumen, t("datos_base_gps"));
        utils.sheet_add_aoa(
          worksheetResumen, 
          [[
            "Dia",
            "Turno",
            "Equipo",
            "Tipo equipo",
            "Geocerca inicio",
            "Fecha inicio",
            "Geocerca fin",
            "Fecha fin",
            "Duración minutos",
            "Promedio velocidad (km/h)"
          ]], 
          { origin: "A1" }
        );   
      }

    }

    if(cargasCheck){
      const resCargasScoop = await getDescargarCargasScoop(idMina, format(fechaInicio, "yyyy-MM-dd"), format(fechaFin, "yyyy-MM-dd"));

      if(resCargasScoop.success) {

        const worksheetResumen = utils.json_to_sheet(
          resCargasScoop.data,
          {
            header:[
              "pala_carga",
              "dumper",
              "turno",
              "dia",
              "fecha_inicio",
              "fecha_fin",
              "duracion_minutos",
            ]
          }
        );
        utils.book_append_sheet(workbook, worksheetResumen, t("datos_scoop"));
        utils.sheet_add_aoa(
          worksheetResumen, 
          [[
            "Scoop cargador",
            "Dumper cargado",
            "Turno",
            "Dia",
            "Fecha inicio",
            "Fecha Fin",
            "Duración (min)",
          ]], 
          { origin: "A1" }
        );   
      }

    }
    writeFile(workbook, `${t("datos")}_${nombreMina.replace(" ","-")}_${format(fechaInicio, "yyyy-MM-dd")}_${format(fechaFin, "yyyy-MM-dd")}.xlsx`);
    setLoadingTest(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        /* fullWidth */
        maxWidth="md"
      >
        <DialogContent sx = {{padding:"10px 60px"}}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{padding:"13px 20px"}}
          >
            <Grid item lg={9}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid container item direction={"row"}>
                  <PlanillaIcon className={classes.icon}/>
                  <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                    {t("descargar_datos")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.titulo} style={{fontSize:"13px", fontWeight:"normal",marginLeft:"34px"}}>
                    {`30 ${t("dias_maximo")}`}
                    </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid onClick={handleClose} className={classes.texto} style={{fontStyle:"italic", cursor:"pointer"}}>
              {t("cerrar")}
            </Grid>
          </Grid>

          <Divider/>
          
          <br/>

          <Grid 
            container 
            alignItems='center' 
            justifyContent='center'
            sx={{padding:"13px 20px"}}
          >
            <Grid item>
              <Grid container 
                alignItems='center' 
                justifyContent='center'
                direction="column"
              >
                <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                  {t("fecha_inicio")}
                </Typography>
                <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    autoOk
                    variant='static'
                    className={classes.datepicker}
                    format="dd/MM/yyyy"
                    /* cancelLabel="Cancelar" */
                    size="small"
                    inputVariant="outlined"
                    value={fechaInicio}
                    onChange={date => handleStartDateChange(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container 
                alignItems='center' 
                justifyContent='center'
                direction="column"
              >
                <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                  {t("fecha_fin")}
                </Typography>
                <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    autoOk
                    variant='static'
                    className={classes.datepicker}
                    format="dd/MM/yyyy"
                    /* cancelLabel="Cancelar" */
                    size="small"
                    inputVariant="outlined"
                    value={fechaFin}
                    onChange={date => setFechaFin(date)}
                    maxDate={new Date(fechaInicio).setDate(fechaInicio.getDate()+30)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems={'flex-start'} sx={{padding:"0px 10px"}}>
            <Grid item container>
              <Grid item xs={3}>
                <FormControlLabel 
                  control={<Checkbox onChange={()=>setDatosDashboardCheck(!datosDashboardCheck)} checked={datosDashboardCheck} color="primary"/>} 
                  label={
                    <Typography style={{fontSize:"14.4px", fontWeight:(datosDashboardCheck?"bold":"normal")}}>
                      {t("datos_dashboard")}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                    control={<Checkbox onChange={()=>setjustificacionesCheck(!justificacionesCheck)} checked={justificacionesCheck} color="primary"/>}
                    label={
                      <Typography style={{fontSize:"14.4px", fontWeight:(justificacionesCheck?"bold":"normal")}}>
                        {t("justificaciones")}
                      </Typography>
                    }
                />
              </Grid>
              {
                !esSubterranea &&
                    <Grid item xs={4}>
                      <FormControlLabel 
                        control={<Checkbox onChange={()=>setControlVelocidadCheck(!controlVelocidadCheck)} checked={controlVelocidadCheck} color="primary"/>} 
                        label={
                          <Typography style={{fontSize:"14.4px", fontWeight:(controlVelocidadCheck?"bold":"normal")}}>
                            {t("datos_control_velocidad")}
                          </Typography>
                        }
                      />
                    </Grid>

              }
            </Grid>
          </Grid>
        </DialogContent>

      <DialogActions>
        <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{padding:"5px 20px"}}
          >           
            {
            !loadingTest?
              <Button 
              onClick={()=>onClickDescarga(datosDashboardCheck,justificacionesCheck, controlVelocidadCheck)}
              className={(datosDashboardCheck || justificacionesCheck || controlVelocidadCheck)?classes.buttonSelected:classes.buttonDisabled}
              > 
                <IconoDescargar sx={{marginRight:"5px"}}/> {t("descargar")}
              </Button>
                : (
                <Grid>
                    <CircularProgress style = {{color: "#2f4554",marginBottom:"20px"}} size = {60}/>
                    <br/>
                    <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold",marginBottom:"20px"}}>
                    Descargando...
                    </Typography>
                </Grid>
                )
            }
          </Grid>
        </DialogActions>

        <br/>
      </Dialog>
    </div>
  );
}