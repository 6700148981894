import {
  Grid,
  Card
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HtmlTooltip, StyledLinearProgress } from "../atoms/StyledComponents";
import { formatearAPorcentaje } from "../../commons/Formatos";
import Typography from '@mui/material/Typography';
import '../../translator';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  unSelBox: {
    width: "100%",
    backgroundColor: '#fff',
    borderRadius: "2px",
    padding:"20px 20px",
    cursor:"help"
  },
}));

const PercentageKpiCard = ({titulo, subtitulo, dataTurnos, tooltipMessage}) => {
  const classes = useStyles();
  const {t} = useTranslation();
  const getColor = (i) => {
    const esPar = (i%2)===0;
    if(esPar){
      return "#01b7c4"
    }
    return "#ff6600"
  }

  return(
    <>        
      <HtmlTooltip
        enterDelay={0}
        title={
          <Typography color="inherit" fontSize="12px" alignItems="center" textAlign="center">
            {tooltipMessage}
          </Typography>
        }
        disableHoverListener={tooltipMessage?false:true}
      >
        <Card elevation={4} className={classes.unSelBox}>
          <Grid container justifyContent="center" alignItems="center">
            {/* ENCABEZADO */}
            <Grid item xs={12} md={12} lg={12}>
              <div style={{textAlign:"center", marginBottom:"15px"}}>
                <span style={{fontSize:"12px", fontWeight:"bold", color: "#2f4554"}}>
                  {`${titulo} `} 
                </span>
                <span style={{fontSize:"12px", color: "#2f4554"}}>
                  {` (${subtitulo})`} 
                </span>
              </div>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>    
            {/* TOTAL */}
            <Grid item xs={12} md={5} lg={5}>
              <Grid container justifyContent="space-between" alignItems="center">
                <span style={{color:"#2f4554", fontSize:"12px"}}>
                  Total
                </span> 
                <span style={{color:"#2f4554", fontWeight:"bold", fontSize:"20px"}}>
                  {`${dataTurnos.total?formatearAPorcentaje(dataTurnos.total):0}%`}
                </span>                  
              </Grid>
              <StyledLinearProgress sx={{margin:"5px 0"}} variant="determinate" value={dataTurnos.total?formatearAPorcentaje(dataTurnos.total):0} />
            </Grid>

            {/* TURNOS */}
            <Grid item xs={12} md={7} lg={7}>
              <Grid container alignItems="center">
                {Object.entries(dataTurnos).map(([key,value],index)=>{
                      if(key !== "total"){
                        return(
                          <Grid item key={`${key}-${index}`} xs={12} md={12} lg={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                              <Grid item>
                                <span style={{color:"#2f4554", fontSize:"12px"}}>
                                  {t(`turno_${key.toLowerCase()}`)}
                                </span> 
                              </Grid>
                              <Grid item>
                                <span style={{color:"#2f4554", fontWeight:"bold", fontSize:"18px", color:getColor(index)}}>
                                  {`${formatearAPorcentaje(value)}%`}
                                </span>                     
                              </Grid>   
                            </Grid>
                          </Grid>
                        )
                      }
                    })
                }
              </Grid>
            </Grid>
          </Grid>
          
        </Card>
      </HtmlTooltip>
    </>
  )
}

export default PercentageKpiCard;