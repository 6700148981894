import {
  Box,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@mui/material";
import IconoVueltasLight from '../../assets/icons/icono-vueltas.png';
import IconoTonelajeLight from '../../assets/icons/icono-tonelaje.png';
import IconoVueltasDark from '../../assets/icons/icono-vueltas-dark.png';
import IconoTonelajeDark from '../../assets/icons/icono-tonelaje-dark.png';
import '../../translator';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  selBox: {
    width: "100%",
    backgroundColor: '#2f4554',
    borderRadius: "2px",
    padding:"30px 15px",
    pointerEvents:"none"
  },
  unSelBox: {
    width: "100%",
    backgroundColor: '#f4f4f4',
    borderRadius: "2px",
    padding:"30px 15px",
    pointerEvents:"none"
  },
  selDescripcion: {
    color: "#fff",
    fontSize: "12px !important",
    fontWeight:"bold !important"
  },
  unSelDescripcion: {
    color: "#2f4554",
    fontSize: "12px !important",
    fontWeight:"bold !important"
  },
  selValue:{
    color: "#fff",
    fontSize: "18px !important",
    fontWeight:"bold !important"
  },
  unSelValue:{
    color: "#00b7c4",
    fontSize: "18px !important",
    fontWeight:"bold !important"
  },
  selValueDes:{
    color: "#fff",
    fontSize: "12px !important",
  },
  unSelValueDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  }
}));

const ResumenProdCard = ({titulo,subtitulo,vueltas,tonelaje,meta,isSelect}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return(
    <>        
      <Box className={isSelect?classes.selBox:classes.unSelBox}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item>
            <div className={isSelect?classes.selDescripcion:classes.unSelDescripcion}>
              <div>{titulo}</div>
              <div>{subtitulo}</div>
            </div>
          </Grid>

          <Grid item lg={3} md={3} xs={3}>
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <div className={isSelect?classes.selValue:classes.unSelValue}>
                {vueltas}
              </div>
              <div className={isSelect?classes.selValueDes:classes.unSelValueDes}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                >
                  
                  <img 
                    style={{marginRight:"4px"}} 
                    src={isSelect?IconoVueltasLight:IconoVueltasDark} 
                    width="15px" 
                    alt=" icono vueltas"
                  />
                  {t("vueltas")}
                </Grid>
              </div>
            </Grid>
          </Grid>

          <Divider orientation="vertical" flexItem sx={{background:isSelect?"#fff":"#d7d7d7"}}/>

          <Grid item lg={3} md={3} xs={3}>
            <Grid
              container
              direction="column"
              alignItems="center"
              sx={{marginLeft:"10%"}}
            >
              <div className={isSelect?classes.selValue:classes.unSelValue}>
                {tonelaje}
              </div>
              <div className={isSelect?classes.selValueDes:classes.unSelValueDes}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                >
                  <img 
                    style={{marginRight:"4px"}} 
                    src={isSelect?IconoTonelajeLight:IconoTonelajeDark} 
                    width="15px" 
                    alt=" icono vueltas"
                  />
                  {t("tonelaje")}
                </Grid>
              </div>
            </Grid>
          </Grid>
          
          <Divider orientation="vertical" flexItem sx={{background:isSelect?"#fff":"#d7d7d7"}}/>

          <Grid item lg={3} md={3} xs={3}>
            <Grid
              container
              direction="column"
              alignItems="center"
              sx={{marginLeft:"10%"}}
            >
              <div className={isSelect?classes.selValue:classes.unSelValue}>
                {meta}
              </div>
              <div className={isSelect?classes.selValueDes:classes.unSelValueDes}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                >
                  <img 
                    style={{marginRight:"4px"}} 
                    src={isSelect?IconoTonelajeLight:IconoTonelajeDark} 
                    width="15px" 
                    alt=" icono vueltas"
                  />
                  {t("meta")}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ResumenProdCard;