import { Button, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';

export const GenericPopUp = ({show, handleShowPopUp, Children, showCancel = true, maxwidth="md"}) => {

    const { t } = useTranslation();

    const handleClose = (event, reason) => {
      if (reason && reason == "backdropClick") 
          return;
      handleShowPopUp(false)
  }

  return (
    <>
      <Dialog
        disableEnforceFocus
        fullWidth
        maxWidth={maxwidth}
        open={show}
        onClose={handleClose}
      >
        <DialogContent>
            { Children}
        </DialogContent>
        {
            showCancel && (
                <>
                <br/>
                   <DialogActions>
                   <Button
                       onClick={() => {
                           handleShowPopUp(false);
                       }}
                   >
                       {t("cancelar")}
                   </Button>
                   </DialogActions>
                </>
            )
        }
      </Dialog>
    </>
  )
}
