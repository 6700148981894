import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { getLocaleLanguage } from "../../commons/Idiomas";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  icon: {
    fontSize: "40px",
    marginRight: "10px",
    /* color: "#2f4554" */
  },
  titulo: {
    fontWeight: "bold",
    fontSize: "14.4px",
  },
  texto: {
    fontSize: "14.4px",
  },
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4",
    },
  },
  datepicker: {
    width: "190px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {
        borderColor: "error.main",
      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        fontSize: "14.4px",
      },
    },

    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#00B7C4",
      },
    },
  },
});

function calcularDiferenciaDeDias(fechaInicio, fechaFin) {
  // Convertir ambas fechas a objetos Date
  const dateInicio = new Date(fechaInicio);
  const dateFin = new Date(fechaFin);

  // Calcular la diferencia en milisegundos
  const diferenciaEnMilisegundos = dateFin - dateInicio;

  // Calcular la diferencia en días
  const diferenciaEnDias = Math.floor(
    diferenciaEnMilisegundos / (1000 * 60 * 60 * 24)
  );

  return diferenciaEnDias;
}

export const GenericDateRange = ({
  open,
  onClickClose,
  fechaInicio,
  fechaFin,
  setFechaInicio,
  setFechaFin,
  titulo,
  subtitulo,
  icono,
  iconoTextoFooter,
  rangoMaximo = 4
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [fechaInicioInterna, setFechaInicioInterna] = useState(fechaInicio);
  const [fechaFinInterna, setfechaFinInterna] = useState(fechaFin)
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClickClose(false);
  };

  const handleStartDateChange = (date) => {
    setFechaInicioInterna(date);
    const maxDate = new Date(date);
    if (calcularDiferenciaDeDias(date, fechaFin) >= rangoMaximo) {
      maxDate.setDate(date.getDate() + rangoMaximo);
      maxDate.setHours(23, 59, 59, 0);
      setfechaFinInterna(maxDate);
    }else if(calcularDiferenciaDeDias(date,fechaFin) <= 0){
      let startDayPlusOne = new Date(date);
      startDayPlusOne.setDate(startDayPlusOne.getDate() + 1)
      setfechaFinInterna(startDayPlusOne)
    }if(calcularDiferenciaDeDias(date,fechaFin) <= 0){
      let startDayPlusOne = new Date(date);
      startDayPlusOne.setDate(startDayPlusOne.getDate() + 1)
      setfechaFinInterna(startDayPlusOne)
    }
  };

  const onHandleAcceptDateChange = () =>{
    setFechaInicio(fechaInicioInterna);
    setFechaFin(fechaFinInterna);

    handleClose();

  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        /* fullWidth */
        maxWidth="md"
      >
        <DialogContent sx={{ padding: "0" }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: "13px 20px" }}
          >
            <Grid item lg={9}>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid container item direction={"row"}>
                  {icono}
                  <Typography
                    className={classes.titulo}
                    style={{ fontSize: "14.4px", fontWeight: "bold" }}
                  >
                    {titulo}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.titulo}
                    style={{
                      fontSize: "13px",
                      fontWeight: "normal",
                      marginLeft: "34px",
                    }}
                  >
                    ({subtitulo})
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <div
              onClick={handleClose}
              className={classes.texto}
              style={{
                fontStyle: "italic",
                cursor: "pointer",
                display: loading ? "none" : "block",
              }}
            >
              {t("cerrar")}
            </div>
          </Grid>

          <Divider />

          <br />

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "13px 20px" }}
          >
            <Grid item>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <Typography
                  className={classes.titulo}
                  style={{ fontSize: "14.4px", fontWeight: "bold" }}
                >
                  {t("fecha_inicio")}
                </Typography>
                <MuiPickersUtilsProvider
                  locale={getLocaleLanguage(i18n.language)}
                  utils={DateFnsUtils}
                >
                  <DatePicker
                    disableToolbar
                    autoOk
                    variant="static"
                    className={classes.datepicker}
                    format="dd/MM/yyyy"
                    /* cancelLabel="Cancelar" */
                    size="small"
                    inputVariant="outlined"
                    value={fechaInicioInterna}
                    onChange={(date) => handleStartDateChange(date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <Typography
                  className={classes.titulo}
                  style={{ fontSize: "14.4px", fontWeight: "bold" }}
                >
                  {t("fecha_fin")}
                </Typography>
                <MuiPickersUtilsProvider
                  locale={getLocaleLanguage(i18n.language)}
                  utils={DateFnsUtils}
                >
                  <DatePicker
                    disableToolbar
                    autoOk
                    variant="static"
                    className={classes.datepicker}
                    format="dd/MM/yyyy"
                    /* cancelLabel="Cancelar" */
                    size="small"
                    inputVariant="outlined"
                    value={fechaFinInterna}
                    onChange={(date) => {
                      const dateAux = new Date(date);
                      dateAux.setHours(23, 59, 59, 0);
                      setfechaFinInterna(dateAux);

                      if(calcularDiferenciaDeDias(date,fechaInicio) <= 0){
                        let startDayMinusOne = new Date(date);
                        startDayMinusOne.setDate(startDayMinusOne.getDate() - 1)
                        setFechaInicioInterna(startDayMinusOne)
                      }
                    }}
                    maxDate={new Date(fechaInicioInterna).setDate(
                        fechaInicioInterna.getDate() + rangoMaximo
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{ padding: "5px 20px" }}
          >
            {
              <Grid>
                <Button
                onClick={()=>  onHandleAcceptDateChange()}
                >
                    {
                        iconoTextoFooter && iconoTextoFooter
                    }
                  {t("aceptar")}
                </Button>
              </Grid>
            }
          </Grid>
        </DialogActions>
        <br />
      </Dialog>
    </div>
  );
};
