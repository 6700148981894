import {React} from 'react';
import {
	Box
} from "@material-ui/core";
import {	
  Grid, 
	Card, 
	CardContent
} from "@mui/material"
/* import { createTheme, ThemeProvider } from '@mui/material/styles';*/
import { makeStyles } from "@material-ui/core/styles";
import '../../translator';
import { useTranslation } from 'react-i18next';
import { formatearFecha } from '../../commons/FormatearFecha';

/* const theme = createTheme(); */
const useStyles = makeStyles(() => ({
  generalFont: {
    fontSize:"12px"
  },
  upperFont:{
    textTransform: "uppercase"
  }
}));

const EstadoEquipoCard = ({estado,nombre,turno,vueltas,toneladas,powerPackage,ultimaUbicacion,tiempoUltimaUbicacion,onClick, allMaqData={}}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  var fechaActual = new Date();
  

  const getColor = (key) => {
    const estados = {
      "operativo": "#01b7c4",
      "mantencion": "#ffbf00",
      "fuera de servicio": "#C5283D",
      "sininfo": "#d6dce4",
      "ralenti":"#E9724C"
    }
    return estados[key]
  }

  const getDescripcion = (key) => {
    const estados = {
      "operativo": t("operativo"),
      "ralenti":t("ralenti"),
      "mantencion": t("en_mantencion"),
      "fuera de servicio": t("fuera_de_servicio"),
      "sininfo": t("sin_info")
    }
    return estados[key]
  }

  const getEstadoActual = (estado) => {
    if(estado == "sininfo"){
      return t("sin_info");
    }
    else if(estado == "operativo"){
      return t("operativo")/* "En tránsito" */;
    }
    else if(estado == "ralenti"){
      return t("ralenti");
    }
    else if(estado == "fuera de servicio" || estado == "fuera_de_servicio"){
      return t("fuera_de_servicio");
    }
    else if(estado == "mantencion"){
      return t("en_mantencion");
    }
    else{
      return t("sin_info");
    }
  }

  return(
    <>
      <Card 
        sx={{boxShadow:4, cursor: "pointer", "&:hover": {boxShadow:16}}}
        onClick={()=>onClick(allMaqData)}
      >
        <CardContent style={{padding:"0"}}>
          <Grid container>
            <Grid item lg={2} md={2} xs={2}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: getColor(estado)
                }}
              />			
            </Grid>
            
            <Grid item lg={10} md={10} xs={10} sx={{padding:"20px 20px"}} className={classes.generalFont}>
              <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                  <b style={{color:getColor(estado), filter:"brightness(75%)"}}>{getDescripcion(estado)}</b>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                  <b className={classes.upperFont}>{nombre}</b>
                </Grid>
              </Grid>
              <br/>
              <Grid container>
                <Grid item lg={6} md={6} xs={6}>
                  <b>{t(("estado"))}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {getEstadoActual(estado)}
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("ultima_ubicacion")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {ultimaUbicacion}
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("fecha_ultima_ubicacion")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {formatearFecha(tiempoUltimaUbicacion)}
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("vueltas")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {vueltas}
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("toneladas")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {toneladas}
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("horometro")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {`${powerPackage} hrs`}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default EstadoEquipoCard;