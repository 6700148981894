import jsPDF from "jspdf";
import { useState } from "react";

export function usePDF() {
  const [pdfs, setPdfs] = useState({
    santaRosa: null,
    lasChispas: null,
  });

  async function generatePDFByMina(minaName, screenshots) {
    const pdf = new jsPDF();

    // Verifica si la mina existe en los screenshots
    if (!screenshots[minaName]) {
      console.warn(`No hay screenshots para la mina ${minaName}`);
      return;
    }

    const turnos = screenshots[minaName];

    // Iterar sobre los turnos (día y noche)
    for (const [turno, images] of Object.entries(turnos)) {
      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          const img = new Image();
          img.src = images[i];

          // Esperar a que la imagen cargue antes de agregarla al PDF
          await new Promise((resolve) => {
            img.onload = () => {
              if (pdf.getNumberOfPages() > 1 || (i > 0 || turno !== "dia")) {
                pdf.addPage(); // Nueva página para cada imagen excepto la primera
              }

              const pageWidth = pdf.internal.pageSize.getWidth();
              const pageHeight = pdf.internal.pageSize.getHeight();
              const imgWidth = img.width;
              const imgHeight = img.height;

              // Calcula la escala para mantener la proporción
              const scale = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);

              // Calcula el tamaño final de la imagen
              const imgScaledWidth = imgWidth * scale;
              const imgScaledHeight = imgHeight * scale;

              // Centrar la imagen en la página
              const xOffset = (pageWidth - imgScaledWidth) / 2;
              const yOffset = 0;

              // Pegar la imagen directamente en el PDF
              pdf.addImage(img, "JPEG", xOffset, yOffset, imgScaledWidth, imgScaledHeight);
              resolve();
            };
          });
        }
      }
    }

    // Almacenar el PDF en el estado
    setPdfs((prevPdfs) => ({
      ...prevPdfs,
      [minaName]: pdf,
    }));
  }

  function getPDFAsBase64(minaName) {
    const pdf = pdfs[minaName];

    console.log(pdf);
  
    if (!pdf) {
      console.warn(`No hay PDF generado para la mina ${minaName}`);
      return null;
    }
  
    try {
      const pdfBlob = pdf.output("blob");
      const reader = new FileReader();
      
      // Convierte el Blob a Base64 automáticamente
      const base64 = new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result.split(",")[1]); // Base64 sin prefijo
        reader.onerror = (err) => reject(`Error leyendo el Blob: ${err}`);
        reader.readAsDataURL(pdfBlob);
      })

      return base64;
    } catch (error) {
      console.error(`Error al generar el Base64 para la mina ${minaName}:`, error);
      return null; // Devuelve null si ocurre algún error
    }
  }
  

  async function getAllPDFsAsBase64() {
    const pdfBase64Array = {};

    for (const minaName in pdfs) {
      if (pdfs[minaName]) {
        const base64 = await getPDFAsBase64(minaName);
        pdfBase64Array[minaName] = base64;
      }
    }

    return pdfBase64Array;
  }

  async function openPDFInNewTab(minaName) {
    const pdf = pdfs[minaName];
    if (pdf) {
      const pdfBlob = pdf.output("blob"); // Generar un Blob del PDF
      const pdfURL = URL.createObjectURL(pdfBlob); // Crear una URL del Blob
      window.open(pdfURL, "_blank"); // Abrir en una nueva pestaña
    } else {
      console.warn(`No hay PDF generado para la mina ${minaName}`);
    }
  }

  async function generateAllPDFs(screenshots) {
    // Generar PDF para cada mina
    for (const minaName of Object.keys(screenshots)) {
      await generatePDFByMina(minaName, screenshots);
    }
  }

  function downloadPDF(minaName) {
    const pdf = pdfs[minaName];
    if (pdf) {
      pdf.save(`${minaName}.pdf`);
    } else {
      console.warn(`No hay PDF generado para la mina ${minaName}`);
    }
  }

  function deletePDFByMina(minaName) {
    setPdfs((prevPdfs) => ({
      ...prevPdfs,
      [minaName]: null,
    }));
  }

  function clearAllPDFs() {
    setPdfs({
      santaRosa: null,
      lasChispas: null,
    });
  }

  return {
    pdfs,
    generatePDFByMina,
    generateAllPDFs,
    getPDFAsBase64,
    getAllPDFsAsBase64,
    openPDFInNewTab,
    downloadPDF,
    deletePDFByMina,
    clearAllPDFs,
  };
}
