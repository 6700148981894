import { useEffect, useState } from "react";
import { getEncuentroGPS } from "../services/operaciones";

export const useGetEncuentroGPS = (id_area_operacion, id_maquina, fecha) => {
  const [EncuentrosGPS, setEncuentrosGPS] = useState([]);
  const [loadingEncuentrosGPS, setLoadingEncuentrosGPS] = useState(true);

  useEffect(() => {
    getEncuentroGPS(id_area_operacion, id_maquina, fecha)
      .then((res) => {
        setEncuentrosGPS(res.data);
        setLoadingEncuentrosGPS(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingEncuentrosGPS(false);
      });
  }, [id_maquina, fecha]);

  return { loadingEncuentrosGPS, EncuentrosGPS };
};
