import { useEffect } from "react";
import { useState } from "react"
import { getAreaAsignadaVicon, getSectorDeOperacionVicon } from "../services/administracion";

export const useGetSectorYAreaoperacionVicon = (id_area_operacion) => {
    const [loading, setLoading] = useState();
    const [estado, setEstado] = useState({sectorDeOperacion:[], areaAsignada: []});

    useEffect(() => {
      try {
        setLoading(true);
        Promise.all([
            getSectorDeOperacionVicon(id_area_operacion),
            getAreaAsignadaVicon(id_area_operacion)
          ])
            .then(([respuestaSectorOperaciones, respuestaAreaAsignada]) => {
              setEstado({
                sectorDeOperacion: respuestaSectorOperaciones.data,
                areaAsignada: respuestaAreaAsignada.data
              });
            })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
      } catch (error) {
        console.log(error)
      }

    }, [])
    
    return {sectorDeOperacion:estado.sectorDeOperacion, areaAsignada:estado.areaAsignada, loading}
}
