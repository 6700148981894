import {React} from 'react';
import {
	Box
} from "@material-ui/core";
import {	
  Grid, 
	Card, 
	CardContent
} from "@mui/material"
/* import { createTheme, ThemeProvider } from '@mui/material/styles';*/
import { makeStyles } from "@material-ui/core/styles";
import '../../../translator';
import { useTranslation } from 'react-i18next';
import { formatoDecimales } from "../../../commons/Formatos";
import { formatearFecha } from '../../../commons/FormatearFecha';

/* const theme = createTheme(); */
const useStyles = makeStyles(() => ({
  generalFont: {
    fontSize:"12px"
  },
  upperFont:{
    textTransform: "uppercase"
  }
}));

const EstadoJumboCard = ({estado,nombreMaquina,fechaInicio,fechaFin,horometro_motor_brazos,horometro_percutor_brazos,ultimaUbicacion,tiempoUltimaUbicacion, onClick ,allMaqData}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const fechaActual = new Date();
  var dateFechaFin = new Date(fechaFin);

  var msDiff = fechaActual - dateFechaFin;
  var minuteDiff = msDiff / 60000;

  if(minuteDiff > 1440){
    estado = "sininfo";
  }

  const getColor = (key) => {
    const estados = {
      "operativo": "#01b7c4",
      "mantencion": "#ffbf00",
      "fuera de servicio": "#C5283D",
      "ralenti":"#E9724C",
      "sininfo": "#d6dce4"
    }
    return estados[key]
  }
  const getDescripcion = (key) => {
    const estados = {
      "operativo": t("operativo"),
      "mantencion": t("en_mantencion"),
      "fuera de servicio": t("fuera_de_servicio"),
      "ralenti":t("ralenti"),
      "sininfo": t("sin_info")
    }
    return estados[key]
  }

  const getUbicacionActual = (estado) => {
    if(estado == "sininfo"){
      return t("sin_info");
    }
    else if(estado == "operativo"){
      return t("operativo")/* "En tránsito" */;
    }
    else if(estado == "fuera de servicio"){
      return t("fuera_de_servicio");
    }
    else if(estado == "mantencion"){
      return t("en_mantencion");
    }
    else if(estado == "ralenti"){
      return t("ralenti");
    }
    else{
      return t("sin_info");
    }
  }

  return(
    <>
      <Card 
        sx={{boxShadow:4, cursor: "pointer", "&:hover": {boxShadow:16}}}
        onClick={()=>onClick(allMaqData)} 
      >
        <CardContent style={{padding:"0"}}>
          <Grid container>
            <Grid item lg={2} md={2} xs={2}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: getColor(estado, fechaFin)
                }}
              />			
            </Grid>
            
            <Grid item lg={10} md={10} xs={10} sx={{padding:"20px 20px"}} className={classes.generalFont}>
              <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                  <b style={{color:getColor(estado), filter:"brightness(75%)"}}>{getDescripcion(estado)}</b>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                  <b className={classes.upperFont}>{nombreMaquina}</b>
                </Grid>
              </Grid>
              <br/>
              <Grid container>
                <Grid item lg={6} md={6} xs={6}>
                  <b>{t(("ubicacion_actual"))}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {getUbicacionActual(estado)}
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("ultima_ubicacion")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {ultimaUbicacion ? ultimaUbicacion :"-"}
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("fecha_ultima_ubicacion")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {formatearFecha(tiempoUltimaUbicacion)}
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("horometro_motor_brazos")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {((horometro_motor_brazos) ? formatoDecimales(horometro_motor_brazos) : 0)+ " " + t('hrs')}
                </Grid>

                <Grid item lg={6} md={6} xs={6}>
                  <b>{t("horometro_percutor_brazos")}</b>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  {((horometro_percutor_brazos) ? formatoDecimales(horometro_percutor_brazos) : 0)+ " " + t('hrs')}
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default EstadoJumboCard;