import { useEffect, useState } from "react";
import { getSolicitudesTipoGeocercaGPS } from "../../services/solicitudes";

export const useGetSolicitudesTipoGeocercaGPS = (id_area_operacion, reloadData = false) => {
    const [SolicitudesTipoGeocerca, setSolicitudesTipoGeocerca] = useState([]);
    const [loadingSolicitudesTipoGeocerca, setLoadingSolicitudesTipoGeocerca] = useState(false);

    useEffect(() => {
        setLoadingSolicitudesTipoGeocerca(true)
        getSolicitudesTipoGeocercaGPS(id_area_operacion)
            .then((res) => {
                setSolicitudesTipoGeocerca(res.data);
                setLoadingSolicitudesTipoGeocerca(false);
            })
            .catch((err) => {
                console.log(err);
                setLoadingSolicitudesTipoGeocerca(false);
            });
    }, [id_area_operacion, reloadData]);

    return { loadingSolicitudesTipoGeocerca, SolicitudesTipoGeocerca };
};
