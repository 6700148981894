import React, { useEffect, useState } from 'react';
import { usePageTimingContext } from '../../context/PageTimingContext';
import { useLocation } from "react-router-dom";

import { getDashboardEmbeddedRegisteredAccess } from '../../services/quicksight';

import NavBar from '../../components/organisms/NavBar';
import { Card } from "@mui/material";

const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none', 
    overflowY:'hidden'
  };

const QuickSight = ({  recarga_automatica, dashboardId= ""}) => {
    const HALFHOUR_IN_MS = 30 * 60 * 1000;
    const location = useLocation();
    const { sendTime } = usePageTimingContext();
    const [embeddedState, setEmbeddedState] = useState({
      isLoading:false,
      url:''
    });

    const onHandleGetEmbeddedDashboard = async() =>{
      setEmbeddedState({...embeddedState, isLoading:true});
      try {
        let resp = getDashboardEmbeddedRegisteredAccess(dashboardId)
                    .then(resp => setEmbeddedState({...embeddedState, url:resp?.data?.embedUrl}));
        setEmbeddedState({...embeddedState, url:resp?.data?.embedUrl})
      } catch (error) {
        setEmbeddedState((prev) => ({...prev, isLoading:false}))
        console.log("Error: "+ error)
      }
      setEmbeddedState({...embeddedState, isLoading:false});
    }

      useEffect(() => {
        let isMounted = true;
    
        const fetchData = async () => {
          setEmbeddedState((prev) => ({ ...prev, isLoading: true }));
          try {
            const resp = await getDashboardEmbeddedRegisteredAccess(dashboardId);
            if (isMounted) {
              setEmbeddedState({ isLoading: false, url: resp?.data?.embedUrl });
            }
          } catch (error) {
            if (isMounted) {
              console.log("Error: " + error);
              setEmbeddedState((prev) => ({ ...prev, isLoading: false }));
            }
          }
        };
    
        fetchData();
    
        if (recarga_automatica) {
          const interval = setInterval(() => {
            window.location.reload();
          }, HALFHOUR_IN_MS);
    
          return () => {
            isMounted = false;
            sendTime(location.pathname);
            clearInterval(interval);
          };
        }
    
        return () => {
          isMounted = false;
          sendTime(location.pathname);
        };
      }, [dashboardId]);
      
      if(embeddedState.isLoading) <>...loading</>

    return (
       <>
        <NavBar />
        <Card sx={{height:"100vh"}}>
            <iframe
                style={iframeStyle}
                src={embeddedState.url}
                allowFullScreen={true}
                height={'100vh'}
            ></iframe>
        </Card>
       </>
    )
};

export default QuickSight;