import React from 'react'

import IconoProdTotal from '../../assets/icons/icono-prod-total.png'
import { useTranslation } from 'react-i18next';
import { Typography } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import SectionHeader from "../molecules/SectionHeader";
import { HtmlTooltip } from "../atoms/StyledComponents";
import { makeStyles } from "@material-ui/core/styles";
import { formatoDecimales } from "../../commons/Formatos";

const useStyles = makeStyles({
    buttonSelected: {
      backgroundColor: "#2f4554",
      color: "white",
      padding: "5px 30px",
      fontSize: "14.4px",
      textTransform: "none",
      borderRadius: "4px",
      margin: "3px",
      height: "38px",
      width:"160px",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "#00B7C4"
      }
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642",
      },
      "&:disabled": {
        backgroundColor: "gray",
      },
    },
    buttonUnselected: {
      backgroundColor: "#f4f4f4",
      padding: "5px 30px",
      fontSize: "14.4px",
      textTransform: "none",
      borderRadius: "4px",
      margin: "3px",
      height: "38px",
      width:"160px",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "#00B7C4"
      }
    },
    prodDes:{
      color: "#2f4554",
      fontSize: "12px !important",
    },
    prodValue:{
      fontSize: "20px",
      fontWeight:"bold"
    },
    bordermd:{
      '@media (min-width:1200px)': {
        borderRight:"2px solid #d7d7d7"
      }
    },
    marginmd:{
      '@media (min-width:1200px)': {
        marginLeft:"10px"
      }
    },
    datepicker: {
      width: "190px",
      backgroundColor:"white",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00B7C4"
      },
      "& .MuiInputBase-root": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        "& .MuiButtonBase-root": {
  
          borderColor: "error.main"
  
        },
        "& .MuiInputBase-input": {
          backgroundColor: "#ffffff",
          borderColor: "error.main",
          /* fontSize:"14.4px" */
        }
      },
  
      '& .MuiOutlinedInput-root': { 
        '&:hover fieldset': {
            borderColor: "#00B7C4",
        }
      },
    }
  });

export const TiempoAsarcoGenerico = ({tiemposAsarcoPorTurno, ocultarRalenti=false, mostrarRatio=false}) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();

  return (
    <>
    <SectionHeader
                  title={t("tiempo_asarco")}
                  subtitle={""}
                  icon={<img src={IconoProdTotal} width="35px" alt=" Icono Resumen Diario"/>}
                />
                <br/>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  columns={mostrarRatio && !ocultarRalenti ? 9 : 
                          (mostrarRatio && ocultarRalenti) ? 8 :
                          (!mostrarRatio && !ocultarRalenti) ? 8 : 7
                          }
                  sx={{padding:"0px 30px 0px 0px"}}
                >
                  <Grid item lg={1} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
                    <HtmlTooltip
                      enterDelay={0}
                      title={
                        <Typography color="inherit" fontSize="12px">
                          {t("horas_del_dia")}
                        </Typography>
                      }
                    >
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{cursor:"help"}}
                      >
                        <div className={classes.prodValue} style={{color:"#2f4554"}}>
                          {formatoDecimales(tiemposAsarcoPorTurno.nominal)}
                        </div>
                        <div className={classes.prodDes}>
                          {t("tiempo_nominal")}
                        </div>
                      </Grid>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item lg={1} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
                    <HtmlTooltip
                      enterDelay={0}
                      title={
                        <Typography color="inherit" fontSize="12px">
                          {`${t("tiempo_nominal")} - ${t("tiempo_indisponible")}`}
                        </Typography>
                      }
                    >
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{cursor:"help"}}
                      >
                        <div className={classes.prodValue} style={{color:"#01b7c4"}}>
                          {formatoDecimales(tiemposAsarcoPorTurno.disponible)}
                        </div>
                        <div className={classes.prodDes}>
                          {t("tiempo_disponible")}
                        </div>
                      </Grid>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item lg={1} md={2} xs={2} className={classes.bordermd}>
                    <HtmlTooltip
                      enterDelay={0}
                      title={
                        <Typography color="inherit" fontSize="12px">
                          {t("tiempo_total_en_taller")}
                        </Typography>
                      }
                    >
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{cursor:"help"}}
                      >
                        <div className={classes.prodValue} style={{color:"#ff6600"}}>
                          {formatoDecimales(tiemposAsarcoPorTurno.indisponible)}
                        </div>
                        <div className={classes.prodDes}>
                          {t("tiempo_indisponible")}
                        </div>
                      </Grid>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item lg={1} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
                    <HtmlTooltip
                      enterDelay={0}
                      title={
                        <Typography color="inherit" fontSize="12px">
                          {`${t("horometro")} - ${t("tiempo_encendido_en_taller")}`}
                        </Typography>
                      }
                    >
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{cursor:"help"}}
                      >
                        <div className={classes.prodValue} style={{color:"#01b7c4"}}>
                          {formatoDecimales(tiemposAsarcoPorTurno.operativo)}
                        </div>
                        <div className={classes.prodDes}>
                          {t("tiempo_operativo")}
                        </div>
                      </Grid>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item lg={1} md={2} xs={2} sx={{borderRight:"2px solid #d7d7d7"}}>
                    <HtmlTooltip
                      enterDelay={0}
                      title={
                        <Typography color="inherit" fontSize="12px">
                          {`${t("tiempo_disponible")} - ${t("tiempo_operativo")}`}
                        </Typography>
                      }
                    >
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{cursor:"help"}}
                      >
                        <div className={classes.prodValue} style={{color:"#C5283D"}}>
                          {formatoDecimales(tiemposAsarcoPorTurno.reserva)}
                        </div>
                        <div className={classes.prodDes}>
                          {t("tiempo_reserva")}
                        </div>
                      </Grid>
                    </HtmlTooltip>
                  </Grid>
                  <Grid item lg={1} md={2} xs={2} className={classes.bordermd}>       
                    <HtmlTooltip
                      enterDelay={0}
                      title={
                        <Typography color="inherit" fontSize="12px">
                          {t("tiempo_total_de_vueltas")}
                        </Typography>
                      }
                    >
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{cursor:"help"}}
                      >
                        <div className={classes.prodValue} style={{color:"#01b7c4"}}>
                          {formatoDecimales(tiemposAsarcoPorTurno.efectivo)}
                        </div>
                        <div className={classes.prodDes}>
                          {t("tiempo_efectivo")}
                        </div>
                      </Grid>
                    </HtmlTooltip>   
                  </Grid>                  
                  <Grid item lg={1} md={2} xs={2} 
                  className={ocultarRalenti && !mostrarRatio ? '' : classes.bordermd}>
                    <HtmlTooltip
                      enterDelay={0}
                      title={
                        <Typography color="inherit" fontSize="12px">
                          {`${t("tiempo_operativo")} - ${t("tiempo_efectivo")}`}
                        </Typography>
                      }
                    >
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{cursor:"help"}}
                      >
                        <div className={classes.prodValue} style={{color:"#ff6600"}}>
                          {formatoDecimales(tiemposAsarcoPorTurno.perdida_operacional)}
                        </div>                
                        <div style={{textAlign:'center'}} className={classes.prodDes}>
                          {t("tiempo_perdida_operacional")}
                        </div>
                      </Grid>
                    </HtmlTooltip>
                  </Grid> 
                  {
                    !ocultarRalenti && (
                      <Grid item lg={1} md={2} xs={2}
                       className={!mostrarRatio ? '' : classes.bordermd}>     
                        <HtmlTooltip
                          enterDelay={0}
                          title={
                            <Typography color="inherit" fontSize="12px">
                              {t("tiempo_total_de_ralenti")}
                            </Typography>
                          }
                        >
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{cursor:"help"}}
                          >
                            <div className={classes.prodValue} style={{color:"#01b7c4"}}>
                                {formatoDecimales(tiemposAsarcoPorTurno.ralenti)}
                            </div>
                            <div className={classes.prodDes}>
                              {t("tiempo_ralenti")}
                            </div>
                          </Grid>
                        </HtmlTooltip>   
                      </Grid>                           
                    )
                  }

{
                    mostrarRatio && (
                      <Grid item lg={1} md={2} xs={2}>     
                        <HtmlTooltip
                          enterDelay={0}
                          title={
                            <Typography color="inherit" fontSize="12px">
                              {t("tiempo_total_ratio")}
                            </Typography>
                          }
                        >
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            sx={{cursor:"help"}}
                          >
                            <div className={classes.prodValue} style={{color:"#01b7c4"}}>
                                {formatoDecimales(tiemposAsarcoPorTurno.ratio)}
                            </div>
                            <div className={classes.prodDes}>
                              {t("tiempo_ratio")}
                            </div>
                          </Grid>
                        </HtmlTooltip>   
                      </Grid>                           
                    )
                  }
                </Grid>
    </>
  )
}
