import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TablaDeCarga } from "../../../components/organisms/TablaDeCarga";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Paper,
  TablePagination,
  Tooltip,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useGetViconsByIdOperacion } from "../../../hooks/useGetViconsByIdOperacion";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { PopUpDelete } from "./PopUpDelete";
import { PopUpSuccessDelete } from "./PopUpSuccessDelete";

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#2f4554",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? "rgb(0, 183, 196)" : "",
  color: "#fafafa",
  ...draggableStyle,
});

export const TablaVicons = ({editingViconTable = false ,onHandleViewViconForm, onHandleEditVicons, vicons, loading, setVicons, onHandleSetFormikValues, refForm, id_area_operacion, nombre_mina, pathMina}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showPopUpDelete, setShowPopUpDelete] = useState(false);
  const [showPopUpDeleteSuccess, setShowPopUpDeleteSuccess] = useState(false);
  const [viconDeleteData, setViconDeleteData] = useState();

  const onHandleShowPopUpSuccess = (show) =>{
    setShowPopUpDeleteSuccess(show);
  }

  const onHandleShowPopUpDelete = (show,vicon) =>{
    setShowPopUpDelete(show)
    setViconDeleteData(vicon)
  }

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onHandleRefFormClick = () =>{
    refForm.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let movedItems = reorder(
      vicons,
      result.source.index, 
      result.destination.index 
    );
    setVicons(movedItems);
  };

  if (loading) {
   return (
    <>
        <TablaDeCarga columnas={[t("prioridad"),t("nombre_referencia"),t("sector_operacion"),t("area_asignada"),"ID Vicon"]}/>
    </>
   )
  }
  return (
  <>
    <Grid container style={{ marginBottom: "20px" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              {/* {editingViconTable && (
                <StyledTableCell align="center">{""}</StyledTableCell>
              )} */}
              <StyledTableCell align="center">{t("prioridad")}</StyledTableCell>
              <StyledTableCell align="center">
                {t("nombre_referencia")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("sector_operacion")}
              </StyledTableCell>
              <StyledTableCell align="center">
                {t("area_asignada")}
              </StyledTableCell>
              <StyledTableCell align="center">{"ID Vicon"}</StyledTableCell>
              {editingViconTable && (
                <StyledTableCell align="center">{""}</StyledTableCell>
              )}
            </StyledTableRow>
          </TableHead>
          {/* <TableBody> */}
          <DragDropContext  onDragEnd={onDragEnd}> 
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <TableBody
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  // style={getListStyle(snapshot.isDraggingOver)}
                >
                  {vicons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((vicon,index) => (
                    <Draggable
                      key={vicon.id}
                      draggableId={"q-" + vicon.id}
                      index={index}
                      //isDragDisabled={!editingViconTable}
                      isDragDisabled={true}
                    >
                      {(provided, snapshot) => (
                        <StyledTableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {/* {editingViconTable && (
                            <StyledTableCell align="left">
                              <DragIndicatorOutlinedIcon cursor={"pointer"} />
                            </StyledTableCell>
                          )} */}
                          <StyledTableCell
                            align="center"
                            component="th"
                            scope="row"
                          >
                            {vicon.orden}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {vicon.descripcion}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {vicon.nombre_sector_operacion
                              ? vicon.nombre_sector_operacion
                              : t("sin_info")}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {vicon.area_asignada
                              ? vicon.area_asignada
                              : t("sin_info")}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {vicon.minor}
                          </StyledTableCell>
                          {editingViconTable && (
                            <StyledTableCell align="center">
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-evenly"
                              >
                                <Box
                                  onClick={() => {
                                    onHandleRefFormClick()
                                    onHandleEditVicons(true);
                                    onHandleViewViconForm(true, false);
                                    onHandleSetFormikValues(vicon)
                                  }}
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "rgb(0, 183, 196)",
                                      borderRadius: "50%",
                                      padding: "7px",
                                    },
                                    padding: "7px",
                                  }}
                                >
                                  <Tooltip title={t("editar")}>
                                    <BorderColorIcon
                                      style={{
                                        color: "#2f4554",
                                        margin: "auto",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                                <Box
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "rgb(0, 183, 196)",
                                      borderRadius: "50%",
                                      padding: "7px",
                                    },
                                    padding: "7px",
                                  }}
                                >
                                  <Tooltip title={t("eliminar")}
                                    onClick={()=> {
                                      onHandleShowPopUpDelete(true, vicon)
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon
                                      style={{
                                        color: "#2f4554",
                                        margin: "auto",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              </Grid>
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
          {/* </TableBody> */}
        </Table>
        <TablePagination
                rowsPerPageOptions={[10, 20, 40]}
                component="div"
                count={vicons.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("filas_por_pagina")}
            />
      </TableContainer>
    </Grid>

    {/* PopUp delete */}
    <PopUpDelete 
    vicon={viconDeleteData}
    onHandleShowPopUp={onHandleShowPopUpDelete} 
    show={showPopUpDelete} 
    id_area_operacion={id_area_operacion} 
    nombre_mina={nombre_mina}
    pathMina={pathMina}
    showPopUpSuccess={onHandleShowPopUpSuccess}
    />

    <PopUpSuccessDelete
      onHandleShowPopUp={onHandleShowPopUpSuccess}
      show={showPopUpDeleteSuccess}

    />
  </>
  );
};
