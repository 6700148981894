import React, { useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Container
} from "@material-ui/core";
import {
  Card,
  CardMedia,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import "moment/locale/es";
import NavBar from "../../components/organisms/NavBar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SectionHeader from "../../components/molecules/SectionHeader";

import { format } from "date-fns";

import {
  getVueltasDia, 
  getInfoGeneralMina,
  getMovimientosDia
} from "../../services/operaciones";

import IconoResumenDiario from '../../assets/icons/icono-resumen-diario.png';
import IconoProdTotal from '../../assets/icons/icono-prod-total.png';
import IconoEstadoEquipos from '../../assets/icons/icono-estado-equipos.png';
import IconoControlTiempo from '../../assets/icons/icono-control-tiempo.png';
import IconoProdEquipoCamion from '../../assets/icons/icono-prod-equipo-camion.png';
import IconoProdEquipoLHD from '../../assets/icons/icono-prod-equipo-lhd.png'
import IconoProdPortales from '../../assets/icons/icono-prod-portal.png';

import ImagenScoop from "../../assets/images/lhd.png";
import ImagenCamion from "../../assets/images/camion.png";

import PantallaCarga from "../../components/molecules/PantallaCarga";
import TablaPersonas from "./TablaPersonas";
import HorizontalTimeChart from "../Operaciones/HorizontalTimeChart";

const useStyles = makeStyles({
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  buttonUnselected: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  prodDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  },
  prodValue:{
    fontSize: "20px",
    fontWeight:"bold"
  },
  bordermd:{
    ['@media (min-width:1200px)']: {
      borderRight:"2px solid #d7d7d7"
    }
  },
  marginmd:{
    ['@media (min-width:1200px)']: {
      marginLeft:"10px"
    }
  }
});

const PersonasDemo = ({idMina,nombreMina}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [movimientos, setMovimientos] = useState(
    [
      {
          "name": "En la oficina",
          "color": "#01b7c4",
          "data": [
              {
                  "x": "Horómetro",
                  "y": [
                      1661173586000,
                      1661182166000
                  ]
              },
              {
                  "x": "Horómetro",
                  "y": [
                      1661182226000,
                      1661193412000
                  ]
              },
              {
                  "x": "Horómetro",
                  "y": [
                      1661193473000,
                      1661194922000
                  ]
              },
              {
                  "x": "Horómetro",
                  "y": [
                      1661195553000,
                      1661207064000
                  ]
              }
          ]
      },
      {
          "name": "Fuera de la Oficina",
          "color": "#ff6600",
          "data": [
              {
                  "x": "Horómetro",
                  "y": [
                      1661169600000,
                      1661170737000
                  ]
              },
              {
                  "x": "Horómetro",
                  "y": [
                      1661193412000,
                      1661193473000
                  ]
              },
              {
                  "x": "Horómetro",
                  "y": [
                      1661194922000,
                      1661195553000
                  ]
              },
              {
                  "x": "Horómetro",
                  "y": [
                      1661212800000,
                      1661213696000
                  ]
              },
              {
                  "x": "Horómetro",
                  "y": [
                      1661239216000,
                      1661256000000
                  ]
              }
          ]
      },
      {
          "name": "Sin Información",
          "color": "#d6dce4",
          "data": [
            {
              "x": "Horómetro",
              "y": [
                  1661170737000,
                  1661173586000
              ]
          },
          {
              "x": "Horómetro",
              "y": [
                  1661182166000,
                  1661182226000
              ]
          },
          {
              "x": "Horómetro",
              "y": [
                  1661207064000,
                  1661212800000
              ]
          },
          {
              "x": "Horómetro",
              "y": [
                  1661207064000,
                  1661212800000
              ]
          },
          {
              "x": "Horómetro",
              "y": [
                  1661213696000,
                  1661239216000
              ]
          }
          ]
      }
  ]
  )
  return(

    <>{loading? <PantallaCarga loading={loading}/>:<>

      <NavBar />
      <Container>
        <br/>
        <br/>

        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                color: "#00B7C4",
                textTransform:"uppercase"
              }}
            >
              {`Mina ${nombreMina}`}
            </Typography>
            <Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                style={{fontSize:"14.4px"}}
                color="inherit"
                href="/"
              >
                Mine-Watch
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                style={{fontSize:"14.4px"}}
                color="inherit"
              >
                {`Mina ${nombreMina}`}
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                style={{fontSize:"14.4px", color:"#00b7c4"}}
                color="inherit"
              >
                {"Personas"}
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>

       {/*  <Typography
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            color:"#00b7c4"
          }}
        >
          Personas
        </Typography> */}
        
        <br/>
        <br/>

        <Card sx={{padding:"25px"}}>

          <SectionHeader 
            title="Resumen Movimientos"
            icon={<img src={IconoResumenDiario} width="35px" alt=" Icono Resumen Diario"/>}
          />

          <br/>

          <TablaPersonas/>

          <br/>
          <br/>
          <br/>

          <Grid 
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
              <Grid container justifyContent="center" alignItems="center" >
                <span 
                  style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#01b7c4",
                    borderRadius:"50%",
                    display: "inline-block"
                  }}
                />
                <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                  {`En la Oficina`}
                </span>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
              <Grid container justifyContent="center" alignItems="center" >

                <span 
                  style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#ff6600",
                    borderRadius:"50%",
                    display: "inline-block"
                  }}
                />
                <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                  {`Fuera de la Oficina`}
                </span>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important"}}>
            <Grid container justifyContent="center" alignItems="center" >

              <span 
                style={{
                  margin:"0px 5px 0px 0px",
                  height:"13px",
                  width:"13px",
                  backgroundColor: "#d6dce4" ,
                  borderRadius:"50%",
                  display: "inline-block"
                }}
              />
              <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                {`Sin Información`}
              </span>
              </Grid>
            </Grid>
          </Grid>


          <HorizontalTimeChart
            series = {movimientos} 
            turno={{min:new Date('2022-08-22 08:00:00'), max:new Date('2022-08-23 08:00:00')}}
            numCategorias={1}
          />

        </Card>
      </Container>

      </>}
    </>
  )
} 

export default PersonasDemo;