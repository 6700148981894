import { useEffect, useState } from "react";
import { getInfoMina } from "../../services/controlDeFrentes";

export function useGetInfoMina(idAreaOperacion) {
const [data, setData] = useState([]);
const [loading, setLoading] = useState(false);
const [error, setError] = useState({ success: false, error: null });

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      const response = await getInfoMina(idAreaOperacion, signal);

      setLoading(true);

      if (response.success) {
        setLoading(false);
        setData(response.data);
        setError({ success: response.success, error: null });
      } else {
        setLoading(false);
        setData(response.data);
        setError({ success: response.success, error: response.error });
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [idAreaOperacion]);

  return [data, loading, error];
}