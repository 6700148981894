import React from 'react'
import { useTranslation } from 'react-i18next';
import { Typography} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { format } from "date-fns";

import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

export const SelectorFechaFlechas = ({onChangeFecha, restarDias, fechaActual, sumarDias}) => {
    const { t, i18n } = useTranslation();
    return (
        <Grid item alignItems="center">
            <Typography
                style={{
                    fontWeight: "bold",
                    fontSize: "14.4px",
                    display: "flex",
                    alignItems: "end"
                }}
            >
                <ArrowBackIosNewRoundedIcon onClick={() => onChangeFecha(restarDias(fechaActual, 1))} sx={{ color: '#fafafa', backgroundColor: 'rgb(0, 183, 196)', cursor: 'pointer', borderRadius: '50px', padding: '3px', marginRight: "10px", ':hover': { backgroundColor: '#2f4554' } }} />

                {(i18n.language === 'es') ?
                    (`Datos del día ${fechaActual.getDate()} de ${fechaActual.toLocaleString('es-ES', { month: 'long', year: 'numeric' })}`)
                    :
                    (`Data of ${fechaActual.toLocaleString('en-EN', { month: 'long', year: 'numeric', day: 'numeric' })}`)
                }
                {
                    (format(fechaActual, "yyyy-MM-dd") !== format((new Date()), "yyyy-MM-dd")) &&
                    <ArrowForwardIosRoundedIcon onClick={() => onChangeFecha(sumarDias(fechaActual, 1))} sx={{ color: '#fafafa', backgroundColor: 'rgb(0, 183, 196)', cursor: 'pointer', borderRadius: '50px', padding: '3px', marginLeft: "10px", ':hover': { backgroundColor: '#2f4554' } }} />
                }
            </Typography>
        </Grid>
    )
}
