export const getTurnosMina = async (idMina) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_mina: idMina
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
      'https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/demo/get-turnos-mina',
      requestOptions
    );

    if (!res.ok) throw new Error(res.text);
    const json = await res.json();
    return { success: true, data: json };
  } catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
};

export const getTiemposAsarco = async (idMina,idMaquina,inicio,fin) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: idMina,
      id_maquina: idMaquina,
      fecha_inicial: inicio,
      fecha_final: fin
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/maquina/get-asarco",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getInfoGeneralMina = async (id,fecha) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion:id,
      fecha:fecha
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/get-info-general-mina",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getAsarcoArOpMaq = async (idMina,inicio,fin,controller) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: idMina,
      fecha_inicial: inicio,
      fecha_final: fin
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/area-operacion/asarco",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getViconsByIdOperacion = async (idMina) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: idMina
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/vicons/get-data",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getMinorsOfViconsByIdOp = async (idMina) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: idMina
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/vicons/minors",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}
export const getRutaAreaOperacion = async (id) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "id_empresa":id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
      // signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/area-operacion/rutas",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getRutaRedireccionByIdEmpresa = async (idEmpresa) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "id_empresa":idEmpresa
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
      // signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/ruta-redireccion-empresa",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getAllTiposDeMaquinas = async () => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      // signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/maquina/tipos",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}


export const insertActividadUsuario = async (id_area_operacion, usuario, fecha_inicio, fecha_termino, clicks, teclas_presionadas, cantidad_scroll, segundos_transcurridos, pathName) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion,
      usuario,
      fecha_inicio,
      fecha_termino,
      clicks,
      teclas_presionadas,
      cantidad_scroll,
      segundos_transcurridos,
      pathName
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/insert-actividad-usuario",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const insertActividadDashboard = async (id_area_operacion, usuario, fecha_inicio, fecha_termino, segundos_transcurridos, pathName) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion,
      usuario,
      fecha_inicio,
      fecha_termino,
      segundos_transcurridos,
      pathName
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/insert-actividad-dashboard",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getAllAreaOperacion = async (activo) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      activo
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
      // signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/area-operacion/get-all",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}