import React, {useState, useEffect, useContext} from "react";
import {
  Area,
  AreaChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  linearGradient,
  ReferenceArea,
  ResponsiveContainer,
  Label,
  Dot
} from "recharts";
import { add, format, differenceInCalendarDays, isFuture, differenceInHours } from "date-fns";
import HomeIcon from '@mui/icons-material/Home';
import { GeneralChartsContext } from "../../context/GeneralCharts";

const binarySearch = (arr,fecha) =>{
  let inicio = 0; 
  let final = arr.length-1;
  let medio =  Math.floor((inicio+final)/2)


  while (arr[medio]?.fin !== fecha && inicio <= final) {
     
      if(fecha < arr[medio].fin){
          final = medio - 1;
      }
      else{
          inicio = medio + 1;
      }
      medio = Math.floor((inicio+final)/2)
    
  }
  return arr[medio]?.fin == fecha ? medio : -1 
} 

const dateFormatter = date => {

  /* return formatearFecha(new Date(date)); */
  return format(new Date(date), "HH:mm");
};

const getTicks = (startDate, endDate) => {
  const diffDays = differenceInHours(endDate, startDate);
  let ticks = [startDate.getTime()];
  
  for(let i=0; i<(diffDays-1); i++){
    startDate.setHours(startDate.getHours() + 1);
    ticks.push(startDate.getTime());
  }
  ticks.push(endDate.getTime());

  return ticks;
};

function convertirFormatoFecha(cadenaFecha) {
  // Parsea la cadena de fecha al objeto Date en el primer formato
  let fecha = new Date(cadenaFecha.replace(" ", "T"));

  // Ajusta la zona horaria a UTC
  fecha.setMinutes(fecha.getMinutes() - fecha.getTimezoneOffset());

  // Formatea el objeto Date en el segundo formato
  let formatoFinal = fecha.toISOString();

  return formatoFinal;
}

export default function MotionChart({series, limites, fecha, esEncuentrosGPS = false, vueltas = [], seriesGPS=[], seriesCargas=[]}) {
  const {onHandleSetHoroumeterLimits} = useContext(GeneralChartsContext)
  const startDate = new Date(limites.min);
  const endDate = new Date(limites.max);
  const domain = [dataMin => dataMin, () => endDate.getTime()];
  const ticks = getTicks(startDate, endDate);
  const axesColor = '#e0e0e0'/* 'rgb(0,0,0,0.15)' */;

  if(vueltas && vueltas.length > 0){
    vueltas.map(vuelta =>{
      let fechaFinVueltas = convertirFormatoFecha(vuelta.fecha_inicio);
      let resultBusqueda = binarySearch(series,fechaFinVueltas);
      if(resultBusqueda != -1){
        series[resultBusqueda].scoop = vuelta.cargador.nombre_cargador;
        series[resultBusqueda].beacon_inicio = vuelta?.cargador?.vuelta?.beacon_inicio
      }
    })
  }
  // Se definen los beacons que contienen los registros y se construyen los objetos de series para graficar
  let beaconsVisitados=[];
  let seriesMerged = [...series,...seriesGPS, ...seriesCargas]
  const seriesMovimientoMina = seriesMerged.map((dato, index) => {
    if (!beaconsVisitados.some(beacon => beacon.nombre === dato.nombre)) {
      if(dato.nombre){
        beaconsVisitados.push({
          nombre: dato.nombre,
          orden: (dato.orden || dato.orden === 0) ? dato.orden : 0
        });
      }
    }
    if(dato?.maquina_cargada && dato?.encuentro?.descripcion){
      return(
        {
          name: dato.encuentro?.descripcion,
          fechaInicio: (new Date(dato.encuentro?.inicio.replace("T"," ").split(".")[0])).getTime(),
          fechaFin: (new Date(dato.encuentro?.fin.replace("T"," ").split(".")[0])).getTime(),
          dumper: dato?.maquina_cargada ? dato.maquina_cargada : undefined
        }
      )
    }else{
      if(!dato?.maquina_cargadora){
        return(
          {
            name: dato.nombre,
            fechaInicio: (new Date(dato?.inicio.replace("T"," ").split(".")[0])).getTime(),
            fechaFin: (new Date(dato?.fin.replace("T"," ").split(".")[0])).getTime(),
            scoop: dato?.scoop ? dato.scoop : undefined,
            beacon_inicio: dato.beacon_inicio ? dato.beacon_inicio : undefined
          }
        )
      }
    }
    
  });
  seriesMovimientoMina.sort((a, b) => a.fechaInicio - b.fechaInicio);
  // Filtrar y separar los elementos con orden nulo
 const elementosConOrden = beaconsVisitados.filter(item => item.orden !== null);
  const elementosSinOrden = beaconsVisitados.filter(item => item.orden === null);
  
  // Ordenar los elementos con orden por la propiedad "orden"
  elementosConOrden.sort((a, b) => a.orden - b.orden);
  // Obtener un arreglo con solo los nombres ordenados
  const resultado = elementosConOrden.map(item => item.nombre);
  const resultadoSinOrden = elementosSinOrden.map(item => item.nombre);
  // Unir los elementos con orden y los elementos sin orden al final
  const resultadoFinal = [...resultado, ...resultadoSinOrden];

  // Se crean las series nulas que definen el orden de ylabel categorico
  const seriesOrdenMina = resultadoFinal.map((dato) => {
    return(
      {
        name: dato,
        fechaInicio: null,
        fechaFin: null
      }
    )
  });

  // Se junta array con nulos (solo para orden) y series generadas para graficar
  const dataSeries = [...seriesOrdenMina, ...seriesMovimientoMina];
  const estadoData = {
    data: seriesMovimientoMina,
    left: "dataMin",
    right: "dataMax",
    refAreaLeft: "",
    refAreaRight: "",
    /* top: "dataMax+1",
    bottom: "dataMin-1",
    top2: "dataMax+20",
    bottom2: "dataMin-20", */
    animation: true
  };

  const [initialState, setInitialState] = useState(estadoData);

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = initialState;
    const { data } = initialState;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setInitialState({
        ...initialState,
        refAreaLeft: "",
        refAreaRight: ""
      });
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    onHandleSetHoroumeterLimits(refAreaLeft, refAreaRight)
    setInitialState({
      refAreaLeft: "",
      refAreaRight: "",
      data: data.slice(),
      left: refAreaLeft,
      right: refAreaRight,
      /* bottom: bottom,
      top: top, */
      /* bottom,top,
      bottom2: initialState.bottom,
      top2: initialState.top2 */
    });
  }

  const zoomOut = () => {
    const { data } = initialState;
    setInitialState({
      data: data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
      left: "dataMin",
      right: "dataMax",
      /* top: "dataMax+1",
      bottom: "dataMin",
      top2: "dataMax+50",
      bottom2: "dataMin+50" */
    });
    onHandleSetHoroumeterLimits(undefined, undefined)
  }

  const getLimiteDiarioLabel = () => {
    const fechaActual = new Date(`${fecha} 00:00:00`);
    let fechaAnterior = new Date(`${fecha} 00:00:00`);
    fechaAnterior.setDate(fechaAnterior.getDate() + 1);
    const diaActualLabel = fechaActual.getDate();
    const diaAnteriorLabel= fechaAnterior.getDate();
    const mesAnteriorLabel = fechaAnterior.toLocaleString('default', { month: 'long' });
    const mesActualLabel = fechaActual.toLocaleString('default', { month: 'long' }); 

    return (`${diaActualLabel} ${mesActualLabel} - ${diaAnteriorLabel} ${mesAnteriorLabel}`);

  
  };

  return (
    <div className="highlight-bar-charts" style={{ userSelect: "none" }}>
      <HomeIcon 
        style={{color:"#6E8192", fontSize:"20.5px", float: "right", marginRight:"15px", cursor:"pointer"}}
        onClick={()=>zoomOut()}
      />
      <br/>
      <ResponsiveContainer width="100%" height={beaconsVisitados.length * 40 < 500 ? 500 : beaconsVisitados.length * 40}>

      <LineChart
        onMouseDown={(e) =>{
          if(e){
            setInitialState({ ...initialState,refAreaLeft: e.activeLabel })
          }
        }}
        onMouseMove={(e) =>
          initialState.refAreaLeft &&
          setInitialState({...initialState, refAreaRight: e.activeLabel })
        }
        // eslint-disable-next-line react/jsx-no-bind
        onMouseUp={()=>zoom()}

        /* width={1200}
        height={300} */
        data={dataSeries/* data */}
        margin={{
          top: 15,
          right: 15,
          left: 10,
          bottom: 5
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#00b7c4" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#00b7c4" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid stroke="transparent" vertical={false} horizon horizontalFill={['#f3f4f5', '#ffffff']}/>
        <XAxis 
          /* axisLine={false} */
          allowDataOverflow

          tickLine={{ stroke: axesColor }}
          axisLine={{ stroke: axesColor }} 
          style = {{fontSize:"12px"/* "11px" */, fill:"#2f4554"/* , fontWeight:"bold" */}}
          dataKey="fechaInicio" 
          type="number" 
          scale="time" 
          hasTick
          /* domain={domain} */
          domain={[initialState.left,initialState.right]}
          ticks={ticks}
          tickFormatter={dateFormatter}
        />
        <YAxis 
          /* axisLine={false} */
          yAxisId="1"
          axisLine={{ stroke: axesColor }} 
          tickLine={false}
          style = {{fontSize:"12px"/* "11px" */, fill:"#2f4554"/* , fontWeight:"bold" */}}
          width = {90}
          type="category" 
          dataKey="name" 
          padding={{ top: 30, bottom: 30 }}
        />
        <Tooltip 
          itemStyle={{color:"#2f4554"}}
          // formatter={(value, name, props) => 
          //   [`${format(new Date(props?.payload?.fechaInicio), "dd/MM/yy HH:mm:ss")} - 
          //   ${format(new Date(props?.payload?.fechaFin), "dd/MM/yy HH:mm:ss")} <br /> ${props.payload.scoop}`,
          //    value]}
          formatter={() =>[null,null]}
          labelFormatter={(value,props)=> {
            let name = props[0]?.payload.name || "";
            let scoopName = props[0]?.payload.scoop || "";
            let dumperName = props[0]?.payload.dumper || "";
            let fechaInicio = props[0]?.payload.fechaInicio || "";
            let fechaFin = props[0]?.payload.fechaFin || "";
            let scoopBeaconInicio = props[0]?.payload.beacon_inicio || "";
            if(props.length < 1){
              return <span></span>
            }
           return(
           <span>
            {`${name} : ${format(new Date(fechaInicio), "dd/MM/yy HH:mm:ss")} - ${format(new Date(fechaFin), "dd/MM/yy HH:mm:ss")}`}
              {
                scoopName && scoopBeaconInicio && 
                (
                  <>
                    <br/>
                    {`Scoop: ${scoopName}`}
                    <br/>
                    {`Ubicacion inicio Scoop: ${scoopBeaconInicio}`}
                  </>
                )
              }
              {
                dumperName && 
                (
                  <>
                    <br/>
                    {`Equipo cargado: ${dumperName}`}
                  </>
                )
              }

           </span>)
          }/* format(new Date(value), "dd/MM/yy HH:mm:ss") */}
        />
        <Line 
          isAnimationActive={false}
          yAxisId="1"
          type="monotone"
          dataKey="name"
          stroke="#00b7c4" /* fill="#2f4554" */ /* fill="url(#colorUv)" */
          dot={(props) =>{
            let encuentroEquipos = props?.payload?.scoop || props?.payload?.dumper;
            return (
              <Dot
              {...props}
              fill={encuentroEquipos ? "#00b7c4" : props.fill}
              stroke={encuentroEquipos ? "#000000" : props.stroke}
              />
            )
          }}
        />

        <ReferenceLine 
          x={(new Date(`${fecha} 23:59:59`)).getTime()} 
          label={{fontStyle:"italic", position: 'top',  value: getLimiteDiarioLabel(), fill: '#3474E0'/* '#2f4554' */, fontSize: 12, fontWeight:"bold"}}
          yAxisId="1"
          stroke=/* 'rgb(249,173,61)' */'#3474E0'
          strokeDasharray="2 2"
          strokeWidth={2}
        />

          {initialState.refAreaLeft && initialState.refAreaRight ? (
            <ReferenceArea
              yAxisId="1"
              x1={initialState.refAreaLeft}
              x2={initialState.refAreaRight}
              stroke={"rgb(133,165,208)"}
              strokeOpacity={1}
              fill={"rgb(133,165,208)"}
              fillOpacity={0.3}
            />
          ) : null}     

      </LineChart>
      </ResponsiveContainer>
    </div>
  );
}