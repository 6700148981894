import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../../components/organisms/NavBar";
import { AccountContext } from "../../authentication/Account";
import {
  CircularProgress,
  Container,
  makeStyles,
} from "@material-ui/core";

import {
  Grid
} from "@mui/material";

import IconoDescargar from '@mui/icons-material/FileDownload';

import { Login } from "@mui/icons-material";
import SectionHeader from "../../components/molecules/SectionHeader";
import IconoControlTiempo from "../../assets/icons/icono-control-tiempo.png";
import { useTranslation } from 'react-i18next';
import { TablaEquipos } from "../../components/organisms/TablaEquipos";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getMaquinasByIdAreaOperacion,
  getMotivosJustificaciones,
  getUltimaUbicacionJustificacion,
  insertJustificacion,
  insertJustificacionMasiva,
} from "../../services/justificacion";
import moment from "moment";
import { TablaEquiposJustificados } from "../../components/organisms/TablaEquiposJustificados";
import { useGetGruposMotivosJustificaciones } from "../../hooks/useGetGruposMotivosJustificaciones";
import { PopupRegistroJustificacionCustom } from "./PopupRegistroJustificacionCustom";
import { PopupRegistroJustificacionSinHorometro } from "./PopUpRegistroJustificacionSinHorometro";
import { TabGenerica } from "../../components/organisms/TabGenerica";
import { PopupDetalleJustificacion } from "./PopupDetalleJustificacion";
import { eliminarDuplicadosPorPropiedad } from "../../commons/Filtros";
import ModalDescargaPlanilla from "../../components/organisms/ModalDescargaPlanilla";
import { usePageTimingContext } from "../../context/PageTimingContext";
import { AlertaGenerica } from "../../components/molecules/AlertaGenerica";
import { BreadCrumbsGenerico } from "../../components/molecules/BreadCrumbsGenerico";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#2f4554",
    margin: "8px 0",
    color: "white",
    "&:hover": {
      backgroundColor: "#253642",
    },
    "&:disabled": {
      backgroundColor: "gray",
    },
  },
  iconClick:{
    width:"25px",
    color: "#2f4554",
    cursor: "pointer",
    "&:hover": {
      color: "#00b7c4"
    }
  }
});

export const JustificacionMacro = ({
  idMina,
  nombreMina,
  rutaMina,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { sendTime } = usePageTimingContext();
  const { getSession } = useContext(AccountContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [LoadingApis, setLoadingApis] = useState(false);
  const [loadingPopUp, setLoadingPopUp] = useState(false);
  const [ListadoMotivos, setListadoMotivos] = useState([]);
  const [ListadoMaquinas, setListadoMaquinas] = useState([]);
  const [ubicacion, setUbicacion] = useState();
  const [open, setOpen] = React.useState(false);
  const [openCustom, setOpenCustom] = React.useState(false);
  const [openDetalleJustificacion, setOpenDetalleJustificacion] = useState(false);
  const [mostrarTiempoAJustificar, setmostrarTiempoAJustificar] = useState(false)
  const [FormJustificados,setFormJustificados] = useState(false);
  const [recargarTabla, setRecargarTabla] = useState(0);
  const [openModalDescarga, setOpenModalDescarga] = useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });
const {gruposJustificaciones,loadingGrupoJustificaciones} = useGetGruposMotivosJustificaciones(idMina);

  const arregloMovimientos =[
    {id:2,nombre:t("en_mantencion_justificacion")},
    {id:3,nombre:t("fuera_de_servicio_justificacion")},
    {id:5,nombre:t("ralenti_justificacion")}
  ];

  const handleClickOpen = (id_movimiento,formJustificados=false) => {
    setOpen(true);
    setLoadingPopUp(true);
    setFormJustificados(formJustificados);
    
    if(formJustificados){
      setUbicacion({id:999,nombre:(formik.values.ubicacion) ? formik.values.ubicacion : t("sin_info")})
      formik.initialValues['ubicacion'] = (formik.values.ubicacion) ? formik.values.ubicacion : t("sin_info");
      setLoadingPopUp(false);
    }else {
      formik.initialValues['nombre_motivo'] = ""
      getUltimaUbicacionJustificacion(idMina, id_movimiento)
      .then((resp) => {
        formik.initialValues['ubicacion'] = resp.data.nombre;
        setUbicacion(resp.data);
        setLoadingPopUp(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingPopUp(false);
      });
    }

  };

  const handleClickOpenCustom = () =>{
    setFormJustificados(false);
    formik.setValues(initialState);
    setOpenCustom(true);
  }

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  const initialState ={
    id_area_operacion: idMina,
    id_tipo_justificacion: 0,
    id_grupo_tipo_justificacion:0,
    observacion: "",
    fecha_inicio: "",
    fecha_termino: "",
    id_maquina: 0,
    id_registro_movimiento:0,
    id_movimiento:0,
    ubicacion:"",
    nombre_motivo:"",
    maquinas:[]
  }; 

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: Yup.object({
      id_tipo_justificacion: Yup.number().test(
        "seleccionar-motivo",
        t("validacion_campo_justificacion"),
        function (value) {
          return value !== undefined && value !== 0;
        }
      ),
      observacion: Yup.string().when("id_tipo_justificacion", {
        is:(value) => [7 ,17 , 27 ,32, 40, 43 , 49].includes(value),
        then: () => Yup.string().required(t("validacion_justificacion")),
      }),
      fecha_inicio: Yup.string().required(t("validacion_fecha_inicio")),
      fecha_termino: Yup.string()
        .required(t("validacion_fecha_fin"))
        .test(
          "fecha-termino",
          t("validacion_fecha_inicio_mayor_fecha_fin"),
          function (value) {
            const { fecha_inicio } = this.parent; // Obtenemos el valor de fecha_inicio del objeto actual
            return (
              !fecha_inicio ||
              !value ||
              moment(value).isSameOrAfter(fecha_inicio)
            );
          }
        ),
      id_maquina: Yup.number().test(
        "seleccionar-equipo",
        t("validacion_equipo"),
        function (value) {
          if(!openCustom){
            return value !== undefined && value !== 0;
          }else{
            return true
          }
         
        }
      ),
      maquinas:Yup.array().test(
        "Seleccion-equipos",
        "Se debe ingresar por lo menos un equipo",
        function (value){
          if(openCustom){
            return value !== undefined && value !== 0;
          }else{
            return true
          }
        }
      ),
      id_movimiento:Yup.number().test(
        "seleccionar-tipo-alarma",
        t("validacion_tipo_alarma"),
        function (value) {
          return value !== undefined && value !== 0;
        }
      ),
      ubicacion:Yup.string().required(t("validacion_ubicacion"))
    }),
    async onSubmit(values) {
      try {
        let resp;
        if(!openCustom){
          resp = await insertJustificacion(
            values.id_area_operacion,
            values.id_tipo_justificacion,
            values.observacion,
            values.fecha_inicio,
            values.fecha_termino,
            values.id_maquina,
            values.id_registro_movimiento,
            values.id_movimiento,
            values.ubicacion
          );
        }else{
          let arrMaquinasUnicas = eliminarDuplicadosPorPropiedad(values.maquinas,"nombre")
          resp = await insertJustificacionMasiva(
            values.id_area_operacion,
            values.id_tipo_justificacion,
            values.observacion,
            values.fecha_inicio,
            values.fecha_termino,
            arrMaquinasUnicas,
            values.id_registro_movimiento,
            values.id_movimiento,
            values.ubicacion
            )
        }

        if (!resp.success) {
          throw new Error("error");
        }
        setRecargarTabla(recargarTabla+1);
        setAlert({
          open: true,
          message: "Justificación ingresada con exito?",
          severity: "success",
        });
        handleClose();
        setOpenCustom(false);
        formik.resetForm();
      } catch (error) {
        console.log(error);
        setAlert({
          open: true,
          message: "Algo salio mal?",
          severity: "error",
        });
      }
    }
  
  });

  useEffect(() => {
    getSession()
      .then((data) => {
        setLoggedIn(true);
        setLoadingPage(false);
      })
      .catch((e) => setLoadingPage(false));
  }, [loggedIn]);

  useEffect(() => {
    setLoadingApis(true);

    Promise.all([
      getMaquinasByIdAreaOperacion(idMina),
    ]).then((values) => {
      setListadoMaquinas(values[0].data);
    });
    setLoadingApis(false);
  }, [idMina]);

  useEffect(() => {
    if(formik.values.id_grupo_tipo_justificacion && formik.values.id_grupo_tipo_justificacion !== 0){
      getMotivosJustificaciones(formik.values.id_grupo_tipo_justificacion).then(resp => {
        setListadoMotivos(resp.data);
      })
      .catch(err => console.log(err));
    }
  }, [formik.values.id_grupo_tipo_justificacion])

  useEffect(() => {
    return () => {
      sendTime(location.pathname)
    }
  }, [])
  

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ open: false, message: alert.message, severity: alert.severity });
  };

  const handleShowDetalleJustificacion = (show) =>{
    setOpenDetalleJustificacion(show);
  }

  const handleErrorForm = () => {
    if (
        !formik.touched.fecha_inicio &&
        !formik.touched.fecha_termino &&
        !formik.touched.id_maquina &&
        !formik.touched.id_tipo_justificacion
      ) {
      setAlert({
        open: true,
        message: t("validacion_form_campos_vacios")+"?",
        severity: "warning",
      });
      return;
    } else if (!formik.isValid) {
      let msg = "";
      const claves = Object.keys(formik.errors);
      for (const clave of claves) {
        const valor = "* " + formik.errors[clave] + "?";
        msg += valor;
      }
      setAlert({
        open: true,
        message: msg,
        severity: "warning",
      });
      return;
    }
  };

  if (loadingPage || LoadingApis || loadingGrupoJustificaciones) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "25%",
        }}
      >
        <CircularProgress style={{ color: "#2f4554" }} size={100} />
      </div>
    );
  } else if (!loggedIn) {
    return <Login />;
  }

  return (
    <>
      <NavBar />
      <Container>
        <br />
        <br />
        <BreadCrumbsGenerico
                nombreMina={nombreMina}
                dataRuta={ [
                { id: 1, endpoint: "/", texto: `Mine-Watch` },
                { id: 2, texto: `${t('minaSingular')} ${nombreMina}` },
                { id: 3, texto: t("justificacionMacro"),destacar:true }
                ]}
                />

        <br />
        <Grid container spacing={1} direction={'row'}>
          <Grid item>
            <SectionHeader
              title={t("justificacionMacro")}
              subtitle={""}
              icon={
                <img
                  src={IconoControlTiempo}
                  width="35px"
                  alt=" Icono Justificación Macro"
                />
              }
              />
            </Grid>
            <Grid item alignSelf="center">
              <IconoDescargar
                className={classes.iconClick} 
                onClick={()=>setOpenModalDescarga(true)}
              />
            </Grid>
        </Grid>

        <br />
          {/* TABS */}
          <TabGenerica
            tabs={[{
              label:'pendientes',
              children:<TablaEquipos
                        equipos={ListadoMaquinas}
                        abrirModal={handleClickOpen}
                        id_area_operacion={idMina}
                        formik={formik}
                        recargarTabla={recargarTabla}
                        abrirModalCustom ={handleClickOpenCustom}
                        abrirDetalle={handleShowDetalleJustificacion}
                        setMostrarTiempoAJustificar={setmostrarTiempoAJustificar}
                      />
            },{
              label:'justificados',
              children:<TablaEquiposJustificados
                      equipos={ListadoMaquinas}
                      abrirModal={handleClickOpen}
                      id_area_operacion={idMina}
                      formik={formik}
                      abrirDetalle={handleShowDetalleJustificacion}
                      idMina={idMina}
                      setMostrarTiempoAJustificar={setmostrarTiempoAJustificar}
                    />
            }]}
          />
      </Container>

      {/* MODAL DE INGRESO DE JUSTIFICACION */}
      <PopupRegistroJustificacionSinHorometro 
        FormJustificados={FormJustificados}
        ListadoMaquinas={ListadoMaquinas}
        classes={classes}
        formik={formik}
        handleClose={handleClose}
        handleErrorForm={handleErrorForm}
        initialState={initialState}
        loadingPopUp={loadingPopUp}
        open={open}
        ubicacion={ubicacion}
        gruposJustificaciones={gruposJustificaciones}
        ListadoMotivos={ListadoMotivos}
      />

      {/*MODAL CUSTOM JUSTIFICACION*/}
      <PopupRegistroJustificacionCustom
        FormJustificados={FormJustificados}
        ListadoMaquinas={ListadoMaquinas}
        ListadoMotivos={ListadoMotivos}
        arregloMovimientos={arregloMovimientos}
        classes={classes}
        formik={formik}
        gruposJustificaciones={gruposJustificaciones}
        handleErrorForm={handleErrorForm}
        openCustom={openCustom}
        setOpenCustom={setOpenCustom}
      />

      {/*MODAL DETALLE JUSTIFICACION*/}
      <PopupDetalleJustificacion handleShowPopUpDetalleJustificacion={handleShowDetalleJustificacion} show={openDetalleJustificacion} mostrarJustificar={mostrarTiempoAJustificar}/>

      {/* ALERTA DE VALIDACIONES */}
      <AlertaGenerica alert={alert} handleCloseAlert={handleCloseAlert} duration={5000} />
      {/* DESCARGAR JUSTIFICACIONES */}
      <ModalDescargaPlanilla idMina={idMina} nombreMina={nombreMina} open={openModalDescarga} onClickClose={setOpenModalDescarga}
    />
    </>
  );
};
/**
 


 */
