export class extendDrawBar {
    constructor(opt) {
      let ctrl = this;
      ctrl.draw = opt.draw;
      ctrl.buttons = opt.buttons || [];
      ctrl.onAddOrig = opt.draw.onAdd;
      ctrl.onRemoveOrig = opt.draw.onRemove;
    }
    onAdd(map) {
      let ctrl = this;
      ctrl.map = map;
      ctrl.elContainer = ctrl.onAddOrig(map);
      ctrl.buttons.forEach((b) => {
        ctrl.addButton(b);
      });
      return ctrl.elContainer;
    }
    onRemove(map) {
      let ctrl = this;
      ctrl.buttons.forEach((b) => {
        ctrl.removeButton(b);
      });
      ctrl.onRemoveOrig(map);
    }
    addButton(opt) {
      let ctrl = this;
      var elButton = document.createElement('button');
      elButton.className = 'mapbox-gl-draw_ctrl-draw-btn';
      if (opt.classes instanceof Array) {
        opt.classes.forEach((c) => {
          elButton.classList.add(c);
        });
      }
      if (opt.content) {
        if (opt.content instanceof Element) {
          elButton.appendChild(opt.content);
        } else {
          elButton.innerHTML = opt.content
        }
      }
      elButton.addEventListener(opt.on, opt.action);
      ctrl.elContainer.appendChild(elButton);
      opt.elButton = elButton;
    }
    removeButton(opt) {
      opt.elButton.removeEventListener(opt.on, opt.action);
      opt.elButton.remove();
    }
  }

  export function getColorForSpeed(speed) {
    // Define una paleta de colores para diferentes rangos de velocidad
    const colorRanges = [
      { minSpeed: 0, maxSpeed: 3, color: '#ff0000' },   // Rojo
      { minSpeed: 4, maxSpeed: 6, color: '#ff1a00' },
      { minSpeed: 7, maxSpeed: 9, color: '#ff3400' },
      { minSpeed: 10, maxSpeed: 12, color: '#ff4e00' },
      { minSpeed: 13, maxSpeed: 15, color: '#ff6900' },
      { minSpeed: 16, maxSpeed: 18, color: '#ff8300' },
      { minSpeed: 19, maxSpeed: 21, color: '#ff9d00' },
      { minSpeed: 22, maxSpeed: 24, color: '#ffb700' },
      { minSpeed: 25, maxSpeed: 27, color: '#ffd100' },
      { minSpeed: 28, maxSpeed: 30, color: '#ffeb00' },
      { minSpeed: 31, maxSpeed: 33, color: '#ffff00' },
      { minSpeed: 34, maxSpeed: 36, color: '#ebff00' },
      { minSpeed: 37, maxSpeed: 39, color: '#d1ff00' },
      { minSpeed: 40, maxSpeed: 42, color: '#b7ff00' },
      { minSpeed: 43, maxSpeed: 45, color: '#9dff00' },
      { minSpeed: 46, maxSpeed: 48, color: '#83ff00' },
      { minSpeed: 49, maxSpeed: 51, color: '#69ff00' },
      { minSpeed: 52, maxSpeed: 54, color: '#4eff00' },
      { minSpeed: 55, maxSpeed: 57, color: '#34ff00' },
      { minSpeed: 58, maxSpeed: 60, color: '#1aff00' },
      { minSpeed: 61, maxSpeed: 63, color: '#00ff00' },  // Verde
      { minSpeed: 64, maxSpeed: 66, color: '#00ff1a' },
      { minSpeed: 67, maxSpeed: 69, color: '#00ff34' },
      { minSpeed: 70, maxSpeed: 72, color: '#00ff4e' },
      { minSpeed: 73, maxSpeed: 75, color: '#00ff69' },
      { minSpeed: 76, maxSpeed: 78, color: '#00ff83' },
      { minSpeed: 79, maxSpeed: 81, color: '#00ff9d' },
      { minSpeed: 82, maxSpeed: 84, color: '#00ffb7' },
      { minSpeed: 85, maxSpeed: 87, color: '#00ffd1' },
      { minSpeed: 88, maxSpeed: 90, color: '#00ffeb' },
      { minSpeed: 91, maxSpeed: 93, color: '#00ffff' },
      { minSpeed: 94, maxSpeed: 96, color: '#00ebff' },
      { minSpeed: 97, maxSpeed: 99, color: '#00d1ff' },
      { minSpeed: 100, maxSpeed: 102, color: '#00b7ff' },
      { minSpeed: 103, maxSpeed: 105, color: '#009dff' },
      { minSpeed: 106, maxSpeed: 108, color: '#0083ff' },
      { minSpeed: 109, maxSpeed: 111, color: '#0069ff' },
      { minSpeed: 112, maxSpeed: 114, color: '#004eff' },
      { minSpeed: 115, maxSpeed: 117, color: '#0034ff' },
      { minSpeed: 118, maxSpeed: 120, color: '#001aff' }
    ];
  
    // Encuentra el rango correspondiente para la velocidad
    for (let i = 0; i < colorRanges.length; i++) {
      const range = colorRanges[i];
      if (speed >= range.minSpeed && speed <= range.maxSpeed) {
        return range.color;
      }
    }
  
    // Si la velocidad no coincide con ningún rango, devuelve un color predeterminado
    return '#000000';  // Negro por defecto
  }

  export let drawStyle =[
    // ACTIVE (being drawn)
    // line stroke
    {
        "id": "gl-draw-line",
        "type": "line",
        "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-color": "#00b7c4",
          "line-dasharray": [0.2, 2],
          "line-width": 4,
        }
    },
    // polygon fill
    {
      "id": "gl-draw-polygon-fill",
      "type": "fill",
      "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
      "paint": {
        "fill-color": "#00b7c4",
        "fill-outline-color": "#00b7c4",
        "fill-opacity": 0.5
      }
    },
    // polygon mid points
    {
      'id': 'gl-draw-polygon-midpoint',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'midpoint']],
      'paint': {
        'circle-radius': 4,
        'circle-color': '#fbb03b'
      }
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
      "id": "gl-draw-polygon-stroke-active",
      "type": "line",
      "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
      "layout": {
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-color": "#00b7c4",
        "line-dasharray": [0.2, 2],
        "line-width": 4
      }
    },
    // vertex point halos
    {
      "id": "gl-draw-polygon-and-line-vertex-halo-active",
      "type": "circle",
      "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
      "paint": {
        "circle-radius": 4,
        "circle-color": "#FFF"
      }
    },
    // vertex points
    {
      "id": "gl-draw-polygon-and-line-vertex-active",
      "type": "circle",
      "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
      "paint": {
        "circle-radius": 4,
        "circle-color": "#00b7c4",
      }
    },
  
    // INACTIVE (static, already drawn)
    // line stroke
    {
        "id": "gl-draw-line-static",
        "type": "line",
        "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-color": "#000",
          "line-width": 4
        }
    },
    // polygon fill
    {
      "id": "gl-draw-polygon-fill-static",
      "type": "fill",
      "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
      "paint": {
        "fill-color": "#000",
        "fill-outline-color": "#000",
        "fill-opacity": 0.5
      }
    },
    // polygon outline
    {
      "id": "gl-draw-polygon-stroke-static",
      "type": "line",
      "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
      "layout": {
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-color": "#000",
        "line-width": 4
      }
    }
  ]