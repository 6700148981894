import { useEffect, useRef } from "react";
import Pool from "../../UserPool";
import { insertActividadDashboard, insertActividadUsuario } from "../../services/general";
import { useLocation } from "react-router-dom";

const CONST_LIMIT_INACTIVE= 180000;//3mins
const CONST_LIMIT_INACTIVE_GLOBAL= 1800000;//30minutos

const usePageTiming = (id_area_operacion) => {
  const location = useLocation();
  const timeRef = useRef(new Date());
  const timeSiteRef = useRef(0);
  const keyPressCountRef = useRef(0);
  const clickCountRef = useRef(0);
  const scrollCountRef = useRef(0);
  const inactivityTimeoutRef = useRef(null);
  const startTimeRef = useRef(new Date());
  const endTimeRef = useRef(null);
  const isProduction = process.env.NODE_ENV === 'production';

  const timeRefGlobal = useRef(new Date());
  const timeSiteRefGlobal = useRef(0);
  const inactivityTimeoutRefGlobal = useRef(null);
  const startTimeRefGlobal = useRef(new Date());
  const endTimeRefGlobal = useRef(null);
  
  const resetCounters = () => {
    timeRef.current = new Date();
    timeSiteRef.current = 0;
    keyPressCountRef.current = 0;
    clickCountRef.current = 0;
    scrollCountRef.current = 0;
    startTimeRef.current = new Date();
  };

  const resetCountersGlobal = () =>{
    timeRefGlobal.current = new Date();
    timeSiteRefGlobal.current = 0;
    startTimeRefGlobal.current = new Date();
    endTimeRefGlobal.current = null;
  }

  const sendTime = (ruta) => {
    if (!isProduction) {
      return;
    }
    sendTimeActividad(ruta);
    sendTimeGlobal(ruta);
  };

  const sendTimeActividad = (ruta) => {
    if (!isProduction) {
      return;
    }
    
    const now = new Date();
    timeSiteRef.current += (now - timeRef.current) / 1000;
    endTimeRef.current = now;
    
    const usuario = Pool?.getCurrentUser()?.username;
    if (id_area_operacion && usuario) {
      const data = {
        id_area_operacion: parseInt(id_area_operacion),
        usuario,
        start_time: startTimeRef.current.toISOString(),
        end_time: endTimeRef.current.toISOString(),
        clicks: clickCountRef.current,
        key_presses: keyPressCountRef.current,
        scrolls: scrollCountRef.current,
        time_on_site: timeSiteRef.current,
        pathName: ruta || location.pathname
      };

      insertActividadUsuario(
        data.id_area_operacion,
        data.usuario,
        data.start_time,
        data.end_time,
        data.clicks,
        data.key_presses,
        data.scrolls,
        data.time_on_site,
        data.pathName
      )
        .then(res => res)
        .catch(err => console.log("Error: " + err));

      resetCounters();
      resetInactivityTimeout();
    }
  };

  const sendTimeGlobal = (ruta) => {
    if (!isProduction) {
      return;
    }
    
    const now = new Date();
    endTimeRefGlobal.current = now;
    
    const usuario = Pool?.getCurrentUser()?.username;
    if (id_area_operacion && usuario && timeSiteRefGlobal.current > 0) {

      const data = {
        id_area_operacion: parseInt(id_area_operacion),
        usuario,
        start_time: startTimeRefGlobal.current.toISOString(),
        end_time: endTimeRefGlobal.current.toISOString(),
        time_on_site: timeSiteRefGlobal.current,
        pathName: ruta || location.pathname
      };

      insertActividadDashboard(
        data.id_area_operacion,
        data.usuario,
        data.start_time,
        data.end_time,
        data.time_on_site,
        data.pathName
      )
        .then(res => res)
        .catch(err => console.log("Error: " + err));

      resetCountersGlobal();
      resetInactivityTimeoutGlobal();
    }
  };
  

  const resetInactivityTimeout = () => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    inactivityTimeoutRef.current = setTimeout(() => {
      if (keyPressCountRef.current > 0 || clickCountRef.current > 0 || scrollCountRef.current > 0) {
        sendTimeActividad(location.pathname);
        resetCounters();
      } else {
        resetInactivityTimeout();
        resetCounters();
      }
    }, CONST_LIMIT_INACTIVE);
  };
  

  const resetInactivityTimeoutGlobal = () => {
    if (inactivityTimeoutRefGlobal.current) {
      clearTimeout(inactivityTimeoutRefGlobal.current);
    }
    inactivityTimeoutRefGlobal.current = setTimeout(() => {
        sendTimeGlobal(location.pathname);
        resetCountersGlobal();
        resetInactivityTimeoutGlobal();

    }, CONST_LIMIT_INACTIVE_GLOBAL);
  };

  useEffect(() => {
    //no hace nada si no esta en prod
    if (!isProduction) {
      return;
    }
    resetCounters();
    resetInactivityTimeout();

    resetCountersGlobal();
    resetInactivityTimeoutGlobal();

    const now = new Date();
    timeRef.current = now;

    const timePrevSession = JSON.parse(localStorage.getItem("timePrevSession"));
    const timePrevSessionGlobal = JSON.parse(localStorage.getItem("timePrevSessionGlobal"))
    if (timePrevSession) {
      if(timePrevSession?.clicks >0 || timePrevSession?.key_pressed >0 || timePrevSession?.scrolls >0){
        insertActividadUsuario(
          timePrevSession.id_area_operacion,
          timePrevSession.usuario,
          timePrevSession.start_time,
          timePrevSession.end_time,
          timePrevSession.clicks,
          timePrevSession.key_presses,
          timePrevSession.scrolls,
          timePrevSession.time_on_site,
          timePrevSession.pathName
        )
          .then(res => res)
          .catch(err => console.log("Error: " + err));
        
        localStorage.removeItem("timePrevSession");
        resetCounters();
        resetInactivityTimeout();
      }
    }
    if(timePrevSessionGlobal){
      if(timePrevSessionGlobal?.time_on_site > 0){
        insertActividadDashboard(
          timePrevSessionGlobal.id_area_operacion,
          timePrevSessionGlobal.usuario,
          timePrevSessionGlobal.start_time,
          timePrevSessionGlobal.end_time,
          timePrevSessionGlobal.time_on_site,
          timePrevSessionGlobal.pathName
        )
          .then(res => res)
          .catch(err => console.log("Error: " + err))
      }
      localStorage.removeItem("timePrevSessionGlobal");
      resetCountersGlobal();
      resetInactivityTimeoutGlobal();
    }

    const onBlur = () => {
      const now = new Date();
      timeSiteRef.current += (now - timeRef.current) / 1000;
    };

    const onFocus = () => {
      const now = new Date();
      timeRef.current = now;
    };

    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        timeRefGlobal.current = new Date();
      } else {
      }
    };

    const onBeforeUnload = () => {
      const now = new Date();
      timeSiteRef.current += (now - timeRef.current) / 1000;
      endTimeRef.current = now;
      endTimeRefGlobal.current = now;

      const usuario = Pool.getCurrentUser().username;

      if (id_area_operacion && usuario) {
        const data = {
          id_area_operacion: parseInt(id_area_operacion),
          usuario,
          start_time: startTimeRef.current.toISOString(),
          end_time: endTimeRef.current.toISOString(),
          clicks: clickCountRef.current,
          key_presses: keyPressCountRef.current,
          scrolls: scrollCountRef.current,
          time_on_site: timeSiteRef.current,
          pathName: location.pathname
        };

        localStorage.setItem('timePrevSession', JSON.stringify(data));

          const dataGlobal = {
            id_area_operacion: parseInt(id_area_operacion),
            usuario,
            start_time: startTimeRefGlobal.current.toISOString(),
            end_time: endTimeRefGlobal.current.toISOString(),
            time_on_site: timeSiteRefGlobal.current,
            pathName: location.pathname
          };
          localStorage.setItem('timePrevSessionGlobal', JSON.stringify(dataGlobal));
        
      }
    };
  
    const onUnload = () => {
      onBeforeUnload();
    };

    const onKeyPress = () => {
      keyPressCountRef.current += 1;
      resetInactivityTimeout();
    };

    const onClick = () => {
      clickCountRef.current += 1;
      resetInactivityTimeout();
    };

    const onScroll = () => {
      scrollCountRef.current += 1;
      resetInactivityTimeout();
    };

    const updateVisibleTime = () => {
      if (document.visibilityState === "visible") {
        const now = new Date();
        timeSiteRefGlobal.current += (now - timeRefGlobal.current) / 1000;
        timeRefGlobal.current = now;
      }
    };
    

    const interval = setInterval(updateVisibleTime, 1000);

    window.addEventListener("visibilitychange", onVisibilityChange);
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    window.addEventListener('beforeunload', onBeforeUnload);
    window.addEventListener('unload', onUnload);
    window.addEventListener('keypress', onKeyPress);
    window.addEventListener('click', onClick);
    window.addEventListener('scroll', onScroll);

    return () => {
      clearInterval(interval);
      window.removeEventListener("visibilitychange", onVisibilityChange);
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('beforeunload', onBeforeUnload);
      window.removeEventListener('unload', onUnload);
      window.removeEventListener('keypress', onKeyPress);
      window.removeEventListener('click', onClick);
      window.removeEventListener('scroll', onScroll);
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      if (inactivityTimeoutRefGlobal.current) {
        clearTimeout(inactivityTimeoutRefGlobal.current);
      }
    };
  }, [location.pathname]);

  return {
    sendTime,
    timeSite: timeSiteRef.current,
    keyPressCount: keyPressCountRef.current,
    clickCount: clickCountRef.current,
    scrollCount: scrollCountRef.current,
    startTime: startTimeRef.current,
    endTime: endTimeRef.current
  };
};

export default usePageTiming;