import { useEffect, useState } from 'react'
import { getTiposDeGeocerca } from '../../../services/administracion';

export const useGetTiposDeGeocerca = (id_area_operacion, reloadData = false) => {
  const [tiposDeGeocerca, setTiposDeGeocerca] = useState();
  const [loadingTiposGeocerca, setLoadingTiposGeocerca] = useState(false);

  useEffect(() => {
    setLoadingTiposGeocerca(true);
    getTiposDeGeocerca(id_area_operacion)
    .then((resp) => {
        setTiposDeGeocerca(resp.data)
        setLoadingTiposGeocerca(false)
    })
    .catch((err) =>{
        console.log(err)
        setLoadingTiposGeocerca(false);
    })
  }, [reloadData]);

  return {tiposDeGeocerca, loadingTiposGeocerca}
}

