export const separarPorMiles = (num) => {
  let stringNum = Number(num).toLocaleString('en-US');
  stringNum = stringNum.split(".")
  return `${stringNum[0].replaceAll(",",".")}`;
}

export const formatoDecimales = (num) => {
  return (Math.round((num)*10)/10).toLocaleString('de-DE')
}

export const formatearAPorcentaje = (num) => {
  return (Math.round((num)*10000)/100).toLocaleString('de-DE')
}

export const formatearCantidadDecimales = (num, decimales) =>{
  return num.toFixed(decimales)
}

export const normalizeText = (text) => {
  return text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export const upperCaseFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
