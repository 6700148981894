import React from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';

export const CategoriasGrafico = ({categoriasGrafico=[{id:0,nombre:"", color:""}]}) => {
    const useStyles = makeStyles({
        iconClick:{
          width:"25px",
          color: "#2f4554",
          cursor: "pointer",
          "&:hover": {
            color: "#00b7c4"
          }
        },
        root: {
          backgroundColor: "#2f4554",
          margin: "8px 0",
          color: "white",
          "&:hover": {
            backgroundColor: "#253642",
          },
          "&:disabled": {
            backgroundColor: "gray",
          },
        },
        buttonSelected: {
          backgroundColor: "#2f4554",
          color: "white",
          padding: "5px 30px",
          fontSize: "12px",
          textTransform: "none",
          borderRadius: "4px",
          margin: "0px",
          height: "38px",
          width:"150px",
          alignSelf: "center",
          pointerEvents:"none"
        },
        buttonUnselected: {
          backgroundColor: "#f4f4f4",
          padding: "5px 30px",
          fontSize: "12px",
          textTransform: "none",
          borderRadius: "4px",
          margin: "0px",
          height: "38px",
          width:"150px",
          alignSelf: "center",
          "&:hover": {
            backgroundColor: "#00B7C4",
            color:"#fff"
          }
        },
        prodDes:{
          color: "#2f4554",
          fontSize: "12px !important",
        },
        prodValue:{
          fontSize: "20px",
          fontWeight:"bold"
        },
        datepicker: {
          width: "190px",
          backgroundColor:"white",
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00B7C4"
          },
          "& .MuiInputBase-root": {
            backgroundColor: "#ffffff",
            borderColor: "error.main",
            "& .MuiButtonBase-root": {
      
              borderColor: "error.main"
      
            },
            "& .MuiInputBase-input": {
              backgroundColor: "#ffffff",
              borderColor: "error.main",
              /* fontSize:"14.4px" */
            }
          },
      
          '& .MuiOutlinedInput-root': { 
            '&:hover fieldset': {
                borderColor: "#00B7C4",
            }
          },
        }
      });
  const classes = useStyles()

  return (
    <Grid 
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {
                categoriasGrafico.map((catGraf) =>(
                    <Grid key={catGraf.id} item lg={2} md={2} xs={2} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
                    <Grid container justifyContent="center" alignItems="center" >
                        <span 
                        style={{
                            margin:"0px 5px 0px 0px",
                            height:"13px",
                            width:"13px",
                            backgroundColor: catGraf.color,
                            borderRadius:"50%",
                            display: "inline-block"
                        }}
                        />
                        <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                            {catGraf.nombre}
                        </span>
                    </Grid>
                    </Grid>
                ))
            }
          </Grid>
  )
}
