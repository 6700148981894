import { createContext, useContext } from "react";
import { useGetInfoMina } from "../hooks/controlDeFrentes/useGetInfoMina";

const InfoMinaContext = createContext(null);

export function InfoMinaProvider({ children, idAreaOperacion }) {
  const [
    infoMina,
    loadingInfoMina, 
    { success, error }
  ] = useGetInfoMina(idAreaOperacion);

  return (
    <InfoMinaContext.Provider value={{
      infoMina,
      loadingInfoMina,
      success,
      error
    }}>
      {children}
    </InfoMinaContext.Provider>
  )
}

export function useInfoMina() {
  const { infoMina, loadingInfoMina, success, error } = useContext(InfoMinaContext);
  
  return {
    infoMina,
    loadingInfoMina,
    success,
    error
  };
}