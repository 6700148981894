import {
	Avatar,
	Button,
	Collapse,
	Grid,
	Link,
	Paper,
	TextField
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { makeStyles } from "@material-ui/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import React, { useState, useContext, useEffect, useMemo } from "react";
import UserPool from "../UserPool";
import { AccountContext } from "../authentication/Account";
import NavBar from "../components/organisms/NavBar";
import { useGetCognitoAttributes } from "../hooks/registroUsuarios/useGetCognitoAttributes";
import Produccion from "../views/Produccion";

const useStyles = makeStyles({
	root: {
		backgroundColor: "#2f4554",
		margin: "8px 0",
		color: "white",
		"&:hover": {
			backgroundColor: "#253642"
		},
		"&:disabled": {
			backgroundColor: "#d3d3d3"
		}
	},
	form: {
		"&:disabled": {
			cursor: "not-allowed"
		}
	}
});

const Signup = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [nombre, setNombre] = useState("");
	const [apellido, setApellido] = useState("");

	const [rol, setRol] = useState();
	const [role, setRole] = useState();

	const [nombreEmpresa, setNombreEmpresa] = useState("");
	const [empresaId, setEmpresaId] = useState("");
	const [operacionId, setOperacionId] = useState("");
	const [areaOperacionId, setAreaOperacionId] = useState("");

	const { dataEmpresas, allEmpresas, getAreaOperaciones } =
		useGetCognitoAttributes();

	const areaOperaciones = useMemo(() => getAreaOperaciones(nombreEmpresa) ?? [], [getAreaOperaciones, nombreEmpresa]);

	const [errorsEmail, setErrorsEmail] = useState();
	const [errorsPassword, setErrorsPassword] = useState();
	const [errorsNombre, setErrorsNombre] = useState();
	const [errorsApellido, setErrorsApellido] = useState();

	const { getSession } = useContext(AccountContext);
	const [loggedIn, setLoggedIn] = useState(false);
	const [alert, setAlert] = React.useState({
		open: false,
		message: "",
		severity: "success"
	});
	const classes = useStyles();
	const handleOpenAlert = (mensaje, tipo) => {
		setAlert({ open: true, message: mensaje, severity: tipo });
	};

	const handleCloseAlert = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setAlert({ open: false, message: alert.message, severity: alert.severity });
	};

	useEffect(() => {
		getSession().then((session) => {
			const { idToken } = session;
			const { jwtToken, payload } = idToken;
			const { email, email_verified } = payload;
			try {
				const userType = session["custom:role"];
				setRole(userType);
			} catch (err) {
				console.log(err);
			}

			setLoggedIn(true);
		});
	}, [loggedIn, getSession]);

	const onSubmit = async (event) => {
		event.preventDefault();

		const attributeList = [];

		const dataEmail = {
			Name: "email",
			Value: email
		};

		const dataName = {
			Name: "given_name",
			Value: nombre
		};

		const dataLastName = {
			Name: "family_name",
			Value: apellido
		};

		const dataIdEmpresa = {
			Name: "custom:id_empresa",
			Value: empresaId
		};

		const dataIdOperacion = {
			Name: "custom:id_operacion",
			Value: operacionId
		};

		const dataIdAreaOperacion = {
			Name: "custom:id_area_operacion",
			Value: areaOperacionId
		};

		const dataRol = {
			Name: "custom:role",
			Value: rol
		};

		const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
			dataEmail
		);
		const attributeDataName = new AmazonCognitoIdentity.CognitoUserAttribute(
			dataName
		);
		const attributeDataLastName =
			new AmazonCognitoIdentity.CognitoUserAttribute(dataLastName);
		const attributeIdEmpresa = new AmazonCognitoIdentity.CognitoUserAttribute(
			dataIdEmpresa
		);
		const attributeIdOperacion = new AmazonCognitoIdentity.CognitoUserAttribute(
			dataIdOperacion
		);
		const attributeIdAreaOperacion =
			new AmazonCognitoIdentity.CognitoUserAttribute(dataIdAreaOperacion);
		const attributeRol = new AmazonCognitoIdentity.CognitoUserAttribute(
			dataRol
		);

		attributeList.push(attributeEmail);
		attributeList.push(attributeDataName);
		attributeList.push(attributeDataLastName);
		attributeList.push(attributeIdEmpresa);
		attributeList.push(attributeIdOperacion);
		attributeList.push(attributeIdAreaOperacion);
		attributeList.push(attributeRol);

		UserPool.signUp(email, password, attributeList, null, async (err, data) => {
			switch (String(err)) {
				case "InvalidPasswordException: Password did not conform with policy: Password must have uppercase characters":
					handleOpenAlert("Password must have uppercase characters", "error");
					break;
				case "InvalidPasswordException: Password did not conform with policy: Password not long enough":
					handleOpenAlert("Password not long enough", "error");
					break;
				case "InvalidPasswordException: Password did not conform with policy: Password must have symbol characters":
					handleOpenAlert("Password must have symbol characters", "error");
					break;
				case "InvalidPasswordException: Password did not conform with policy: Password must have numeric characters":
					handleOpenAlert("Password must have numeric characters", "error");
					break;
				case "UsernameExistsException: User already exists":
					handleOpenAlert("Usuario ya existe", "error");
					break;
				default:
					break;
			}

			if (data !== null) {
				handleOpenAlert("Registro exitoso. Confirmar email", "success");
				setEmail("");
				setPassword("");
				setNombre("");
				setApellido("");
				setNombreEmpresa("");
				setEmpresaId("");
				setOperacionId("");
				setAreaOperacionId("");
				setRol("");

				//agrega usuario a grupo
				AWS.config.update({ region: "us-east-1" });

				const cognito = new AWS.CognitoIdentityServiceProvider();
				const params = {
					GroupName: rol,
					UserPoolId: process.env.REACT_APP_USER_POOL_ID,
					Username: email
				};

				try {
					await cognito.adminAddUserToGroup(params).promise();
				} catch (error) {
					console.error(error);
				}
			}
		});
	};

	const paperStyle = {
		width: "100%",
		maxWidth: "600px",
		padding: "20px 40px",
		margin: "20px auto"
	};

	const avatarStyle = { backgroundColor: "#2f4554" };
	const varAux = role !== undefined ? role.includes("admin") : false;

	const handleEmailChange = (event) => {
		const {
			target: { value }
		} = event;
		setErrorsEmail({ telefono: "" });
		setEmail(value);

		const reg = new RegExp(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		).test(value);

		if (!reg) {
			setErrorsEmail({ email: "Formato de email incorrecto" });
		}
	};

	const handlePasswordChange = (event) => {
		const {
			target: { value }
		} = event;
		setErrorsPassword({ password: "" });
		setPassword(value);

		const reg = new RegExp(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/
		).test(value);

		if (!reg) {
			setErrorsPassword({ password: "Formato de contraseña incorrecta" });
		}
	};

	const handleNombreChange = (event) => {
		const {
			target: { value }
		} = event;
		setErrorsNombre({ nombre: "" });
		setNombre(value);

		const reg = new RegExp(
			/^[a-zA-Z]+(?:(?:|['_\. ])([a-zA-Z]*(\.\s)?[a-zA-Z])+)*$/
		).test(value);

		if (!reg) {
			setErrorsNombre({ nombre: "Formato de Nombre incorrecto" });
		}
	};

	const handleApellidoChange = (event) => {
		const {
			target: { value }
		} = event;
		setErrorsApellido({ apellido: "" });
		setApellido(value);

		const reg = new RegExp(
			/^[a-zA-Z]+(?:(?:|['_\. ])([a-zA-Z]*(\.\s)?[a-zA-Z])+)*$/
		).test(value);

		if (!reg) {
			setErrorsApellido({ apellido: "Formato de Apellido incorrecto" });
		}
	};

	const handleEmpresaChange = (event) => {
		const empresaIdValue = String(event.target.value);
		setEmpresaId(empresaIdValue);
		setOperacionId("");
		setAreaOperacionId("");
	};

	const handleRolChange = (event) => {
		setRol(event.target.value);
	};

	return (
		<>
			{loggedIn && varAux ? (
				<>
					<NavBar />
					<Breadcrumbs
						aria-label="breadcrumb"
						style={{
							marginTop: "20px",
							maxWidth: "calc(100% - 3.2rem)",
							marginInline: "auto"
						}}>
						<Link
							underline="hover"
							sx={{ display: "flex", alignItems: "center" }}
							color="inherit"
							href="/">
							Home
						</Link>
						<Link
							underline="hover"
							sx={{ display: "flex", alignItems: "center" }}
							color="inherit"
							href="#">
							Registrar usuarios
						</Link>
					</Breadcrumbs>

					<Paper elevation={10} style={paperStyle}>
						<Grid align="center">
							<Avatar style={avatarStyle}>
								<AddCircleIcon />
							</Avatar>
							<h2>Registrar un nuevo usuario</h2>
						</Grid>
						<Grid
							direction="row"
							container
							justifyContent="space-evenly"
							spacing={3}
							style={{
								marginBlock: 25
							}}>
							<Grid
								container
								item
								xs={6}
								alignItems="flex-start"
								spacing={6}
								style={{
									padding: 20
								}}>
								<TextField
									value={email}
									onChange={handleEmailChange}
									inputProps={{ maxLength: 35 }}
									label="Email"
									placeholder="Ingresar email"
									type="email"
									fullWidth
									required
									error={Boolean(errorsEmail?.email)}
									helperText={errorsEmail?.email}
								/>
								<TextField
									value={password}
									inputProps={{ maxLength: 35 }}
									onChange={handlePasswordChange}
									label="Contraseña"
									placeholder="Ingresar contraseña"
									type="password"
									fullWidth
									required
									error={Boolean(errorsPassword?.password)}
									helperText={errorsPassword?.password}
								/>
								<TextField
									value={nombre}
									inputProps={{ maxLength: 35 }}
									onChange={handleNombreChange}
									label="Nombre"
									placeholder="Ingrese su Nombre"
									type="text"
									fullWidth
									error={Boolean(errorsNombre?.nombre)}
									helperText={errorsNombre?.nombre}
								/>
								<TextField
									value={apellido}
									inputProps={{ maxLength: 45 }}
									onChange={handleApellidoChange}
									label="Apellido"
									placeholder="Ingrese su Apellido"
									type="text"
									fullWidth
									error={Boolean(errorsApellido?.apellido)}
									helperText={errorsApellido?.apellido}
								/>
							</Grid>
							<Grid
								container
								item
								xs={6}
								direction="column"
								spacing={6}
								style={{
									rowGap: 10
								}}>
								<Box sx={{ minWidth: 120 }}>
									<FormControl
										required
										fullWidth
										classes={{ form: classes.form }}>
										<InputLabel id="empresa-select-label">Empresa</InputLabel>
										<Select
											labelId="empresa-select-label"
											id="empresa-select"
											value={empresaId}
											label="Empresa"
											onChange={handleEmpresaChange}>
											{allEmpresas?.map((empresaNombre) => {
												const id = dataEmpresas[empresaNombre]?.[0]?.te_id_empresa;

												return (
													<MenuItem
                            key={id}
														value={id}
														onClick={() => setNombreEmpresa(empresaNombre)}
														style={{
															display: "flex",
															justifyContent: "space-between"
														}}>
														<span>{empresaNombre}</span>
														<span
															style={{
																color: "GrayText",
																marginInlineStart: "4px"
															}}>
															({id})
														</span>
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Box>

								<Box>
									<FormControl
										required
										fullWidth
										classes={{ form: classes.form }}>
										<InputLabel id="operacion-select-label">
											Operación
										</InputLabel>
										<Select
											disabled={empresaId === ""}
											labelId="operacion-select-label"
											id="operacion-select"
											value={operacionId}
											label="Operación"
											onChange={(event) =>
												setOperacionId(String(event.target.value))
											}>
											{empresaId !== "" &&
												areaOperaciones?.map((areaOperacion) => {
													return (
														<MenuItem
                              key={areaOperacion.idOperacion}
															value={areaOperacion.idOperacion}
															style={{
																display: "flex",
																justifyContent: "space-between"
															}}>
															<span>{areaOperacion.nombreOperacion}</span>
															<span
																style={{
																	color: "GrayText",
																	marginInlineStart: "4px"
																}}>
																({areaOperacion.idOperacion})
															</span>
														</MenuItem>
													);
												})}
										</Select>
									</FormControl>
								</Box>

								<Box>
									<FormControl
										required
										fullWidth
										classes={{ form: classes.form }}>
										<InputLabel id="area-operacion-select-label">
											Área de Operación
										</InputLabel>
										<Select
											disabled={operacionId === "" || empresaId === ""}
											labelId="area-operacion-select-label"
											id="area-operacion-select"
											value={areaOperacionId}
											label="Área de Operación"
											onChange={(event) =>
												setAreaOperacionId(String(event.target.value))
											}>
											{empresaId !== "" &&
											operacionId !== "" &&
											areaOperaciones?.length > 0 ? (
												areaOperaciones?.map((areaOperacion) => {
													return (
														<MenuItem
                              key={areaOperacion.idAreaOperacion}
															value={areaOperacion.idAreaOperacion}
															style={{
																display: "flex",
																justifyContent: "space-between"
															}}>
															<span>{areaOperacion.nombreAreaOperacion}</span>
															<span
																style={{
																	color: "GrayText",
																	marginInlineStart: "4px"
																}}>
																({areaOperacion.idAreaOperacion})
															</span>
														</MenuItem>
													);
												})
											) : (
												<MenuItem value={""}>
													No hay áreas de operación
												</MenuItem>
											)}
										</Select>
									</FormControl>
								</Box>

								<Box sx={{ minWidth: 120 }}>
									<FormControl
										required
										fullWidth
										classes={{ form: classes.form }}>
										<InputLabel id="rol-select-label">Rol</InputLabel>
										<Select
                      key={rol}
											labelId="rol-select-label"
											id="rol-select"
											value={rol}
											label="Rol"
											onChange={handleRolChange}>
											<MenuItem value={"admin"}>Administrador</MenuItem>
											<MenuItem value={"user"}>Usuario</MenuItem>
										</Select>
									</FormControl>
								</Box>
							</Grid>
						</Grid>

						<Collapse in={email?.length > 0 && password?.length > 7}>
							<Button
								type="submit"
								variant="contained"
								fullWidth
								onClick={onSubmit}
								disabled={
									errorsEmail?.email?.length > 0 ||
									errorsPassword?.password?.length > 0 ||
									errorsNombre?.nombre?.length > 0 ||
									errorsApellido?.apellido?.length > 0 ||
									empresaId === undefined ||
                  operacionId === undefined ||
                  areaOperacionId === undefined ||
									rol === undefined
								}
								className={classes.root}>
								Registrar
							</Button>
						</Collapse>
					</Paper>

					<Snackbar
						open={alert.open}
						autoHideDuration={6000}
						onClose={handleCloseAlert}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}>
						<Alert
							variant="filled"
							onClose={handleCloseAlert}
							severity={alert.severity}
							sx={{ width: "100%" }}>
							{alert.message}
						</Alert>
					</Snackbar>
				</>
			) : (
				<Produccion />
			)}
		</>
	);
};

export default Signup;
