import { useEffect, useState } from "react";
import { formatearSoloFechaISO } from "../../commons/FormatearFecha";
import { getDataPlanControlFrentes } from "../../services/controlFrentes";

const activities = [
  "rezagado",
  "amacice",
  "fortificacion",
  "geologia",
  "barrenacion",
  "carga",
  "voladura"
]

export function useGetDataPlanControlFrentes(idSubMina, date, idTurno) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    async function fetchPlanControlFrentes(idAreaOperacion, fecha, idTurno) {
      setLoading(true);
  
      const parsedFecha = formatearSoloFechaISO(fecha.toISOString());
      const { success, data, error } = await getDataPlanControlFrentes({
        idAreaOperacion,
        fecha: parsedFecha,
        idTurno
      });
  
      if (success) {
        const newData = [...data].filter(item => 
          activities.some(activity => 
            item[activity] > 1 ||
            item[`plan_${activity}`] > 1 ||
            (item.comentario !== null && item.comentario !== ""))
        ).sort((a, b) => b.id_frente - a.id_frente);
  
        const uniqueGroups = ["Todos", ...new Set(newData.map((item) => item.grupo_frente))];
        setData(newData);
        setGroups(uniqueGroups);
      } else {
        setError(error);
      }
      setLoading(false);
    }

    fetchPlanControlFrentes(idSubMina, date, idTurno);
  }, [date, idSubMina, idTurno]);
  

  return {
    groupsPlanControlFrentes: groups,
    dataPlanControlFrentes: data,
    loadingPlanControlFrentes: loading,
    errorPlanControlFrentes: error
  };
}
