import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@mui/icons-material/Error";
import { Grid } from "@mui/material";
import explosionIcon from "../../assets/icons/explosion-icon.png";
import circleIcon from "../../assets/icons/yellow-circle-icon.png";

export const LeyendaControlFrente = () => {
  const useStyles = makeStyles({
    prodDes: {
      color: "#2f4554",
      fontSize: "12px !important",
      whiteSpace: "nowrap !important"
    },
    leyendaContainer: {
      flexDirection: "row !important",
      width: "fit-content !important",
      flexWrap: "nowrap !important",
      whitespace: "nowrap !important",
      alignItems: "center !important",
      justifyItems: "center !important",
      columnGap: ".45rem",
      paddingInline: "1.45rem"
    },
    img: {
      aspectRatio: "1/1",
      display: "block",
      maxWidth: "100%",
      marginInline: "auto"
    }
  });
  const classes = useStyles();
  return (
    <Grid container width="100%" sx={{ flexDirection: "row", justifyContent: "center", rowGap: ".45rem" }}>
      <Grid
        className={classes.leyendaContainer}
        container
        item
        sx={{ borderRight: "2px solid #d7d7d7" }}>
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "nowrap"
          }}>
          <SemiSquare color={"#2C4352"} left={true} />
          <Square color={"#3167A9"} />
          <Square color={"#4A86E8"} />
          <Square color={"#01B7C4"} />
          <Square color={"#139EA8"} />
          <Square color={"#097B83"} />
          <SemiSquare color={"#023E53"} left={false} />
        </section>
        <span className={classes.prodDes}>Avance actual</span>
      </Grid>
      <Grid
        className={classes.leyendaContainer}
        container
        item
        sx={{ borderRight: "2px solid #d7d7d7" }}>
        <span
          style={{
            height: "15px",
            width: "15px",
            backgroundColor: "#E2711D",
            opacity: 0.7,
            display: "inline-block",
            marginInline: "auto"
          }}
        />
        <span className={classes.prodDes}>Plan</span>
      </Grid>
      <Grid container item className={classes.leyendaContainer}>
        <ErrorIcon sx={{ margin: "0", marginInline: "auto" }} fontSize="small" color="error" />
        <span className={classes.prodDes}>Comentario</span>
      </Grid>
      <Grid
        className={classes.leyendaContainer}
        container
        item
        sx={{ borderLeft: "2px solid #d7d7d7" }}>
        <img src={circleIcon} alt="circle-icon" className={classes.img} />
        <span className={classes.prodDes}>Última actividad</span>
      </Grid>
      <Grid
        className={classes.leyendaContainer}
        container
        item
        sx={{ borderLeft: "2px solid #d7d7d7" }}>
        <img src={explosionIcon} alt="circle-icon" className={classes.img} />
        <span className={classes.prodDes}>Voladura completada</span>
      </Grid>
    </Grid>
  );
};

const Circle = () => {
  return (
    <span
      style={{
        height: "15px",
        width: "15px",
        backgroundColor: "#E2711D",
        opacity: 0.7,
        borderRadius: "100%",
        display: "inline-block",
        marginInline: "auto"
      }}
    />
  );
};

const SemiSquare = ({ color, left = false }) => {
  return (
    <span
      style={{
        height: "13px",
        width: "13px",
        backgroundColor: color,
        borderRadius: left ? "50% 0 0 50%" : "0 50% 50% 0",
        display: "inline-block",
      }}
    />
  );
};

const Square = ({ color }) => {
  return (
    <span
      style={{
        height: "13px",
        width: "13px",
        backgroundColor: color,
        display: "inline-block"
      }}
    />
  );
};
