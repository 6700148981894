import React, {useState, useEffect, Fragment} from 'react';
import {Button} from "@material-ui/core";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import {KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { es } from "date-fns/locale";
import { makeStyles } from "@material-ui/core/styles";
import BarChartIcon from '@mui/icons-material/BarChart';
import RouteIcon from '@mui/icons-material/Route';
import getMeta from './getMeta';
import updateMeta from './updateMeta';
import insertMeta from './insertMeta';
//import LandslideIcon from '@mui/icons-material/Landslide';

import IconoCalendar from '@mui/icons-material/CalendarMonth';
import IconoFlota from '../../../assets/icons/icono-prod-equipo-lhd.png'
import IconoProdTotal from '../../../assets/icons/icono-prod-total.png';

const useStyles = makeStyles({
  icon:{
    fontSize: "40px",
    marginRight:"10px"
  },
  titulo:{
    fontWeight: "bold",
    fontSize: "14.4px",
  },
  texto:{
    fontSize: "14.4px"
  },
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "0px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    pointerEvents:"none"
  },
  buttonUnselected: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "0px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4",
      color:"#fff"
    }
  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },

    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        fontSize: "14.4px",
        backgroundColor: "#ffffff",
        borderColor: "error.main"
      }
    },

    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
  },

  }
});

export default function ModalMetas({fechaInicio,idMina, open, closeModal, tipoMaquina}) {
  const tipoCamion = 0;
  const tipoEquipo = 1;
  const [fechaModal, setFechaModal] = useState(/* new Date() */fechaInicio);
  const auxMeta = {
    año: 0,
    descripcion_meta: "",
    id_mina: 0,
    id_tipo_maquina: 0,
    id_tipo_meta: 0,
    mes: 0,
    nombre_maquina: "",
    progreso: 0,
    total: 0,
    total_minimo: 0
  }
  
  const [nuevaMeta, setNuevaMeta] = useState(0);
  const [nuevaMetaMinima, setNuevaMetaMinima] = useState(0);
  const [metaBBDDExist, setMetaBBDDExist] = useState(false);
  const [meta, setMeta] = useState(auxMeta);
  const [tipoFlota, setTipoFlota] = useState("Camion");

  const classes = useStyles();

  const handleClose = () => {
    closeModal(false);
  };

  useEffect(()=>{
    setFechaModal(fechaInicio);
  },[fechaInicio])

  /* const getMetaMensual = (idMina, idTipoMeta, month, year) => {
    getMeta(idMina, idTipoMeta, month, year)
    .then(({success,data}) => {
      if(success){
        if(data.length>0){
          setMeta(data[0]);
          setNuevaMeta(data[0].total);
          setNuevaMetaMinima(data[0]?.total_minimo==undefined?0:data[0]?.total_minimo);
          setMetaBBDDExist(true);
        }
        else{
          setMeta(auxMeta);
          setNuevaMeta(0);
          setNuevaMetaMinima(0);
          setMetaBBDDExist(false);
        }
      }
    })
  }; */

  /* const updateMetaMensual = (idMeta, idMina, idTipoMaquina, total, idTipoMeta) => {
    const month = fechaModal.getMonth()+1;
    const year = fechaModal.getFullYear();
    updateMeta(idMeta, idMina, idTipoMaquina , month, year, total, idTipoMeta)
    .then((success,data)=> {
      setMeta(auxMeta);
      setNuevaMeta(0);
      setNuevaMetaMinima(0);
      setMetaBBDDExist(false);
      handleClose();
    })
  }; */

  /* const insertMetaMensual = (idMina, idTipoMaquina, total, idTipoMeta) => {
    const month = fechaModal.getMonth()+1;
    const year = fechaModal.getFullYear();
    // idMina, tipoMaquina , month, year, nuevAmETA, (Number(tipoMaquina)===tipoCamion?2:1)
    insertMeta(idMina, tipoMaquina , month, year, nuevaMeta, (Number(tipoMaquina)===tipoCamion?2:1))
    .then((success,data)=> {
      setMeta(auxMeta);
      setNuevaMeta(0);
      setNuevaMetaMinima(0);
      setMetaBBDDExist(false);
      handleClose();
    })
  }; */

   /* const handleGuardarMeta = () => {
     if(metaBBDDExist){
       // Si existe meta, se actualiza
       updateMetaMensual(meta.id_meta, idMina, tipoMaquina, nuevaMeta, (Number(tipoMaquina)===tipoCamion?2:1));
     }
     else{
       // Si no existe meta, se inserta
       insertMetaMensual(idMina, tipoMaquina, nuevaMeta, (Number(tipoMaquina)===tipoCamion?2:1));
     }
   }; */

  /* useEffect(()=>{
    const month = fechaModal.getMonth()+1;
    const year = fechaModal.getFullYear();
    getMetaMensual(idMina,(Number(tipoMaquina)===tipoCamion?2:1), month, year );
  },[fechaModal, tipoMaquina]); */


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent sx = {{padding:"0"}}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{padding:"13px 20px"}}
          >
            <Grid item lg={9}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <BarChartIcon className={classes.icon}/>
                <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                  Configuración Meta Mensual
                </Typography>
              </Grid>
            </Grid>
            <div onClick={handleClose} className={classes.texto} style={{fontStyle:"italic", cursor:"pointer"}}>
              Cerrar
            </div>
          </Grid>

          <Divider/>

          <div style={{padding:"20px 20px"}}>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <IconoCalendar className={classes.icon}/>
              <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                Mes de Operación
              </Typography>
            </Grid>
            <br/>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  /* okLabel = "Confirmar" */
                  cancelLabel="Cancelar"
                  className={classes.datepicker}
                  size="small"
                  inputVariant="outlined"
                  openTo="month"
                  minDate={new Date('2021-11-01 00:00:00')}
                  maxDate={(new Date().setHours(23,59,59))}
                  views={["year", "month"]}
                  value={fechaModal}
                  onChange={(newValue) => {setFechaModal(newValue)}}  
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <br/>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <img src={IconoFlota} width="40px" alt=" Icono flota" className={classes.icon}/>
              <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                Flota
              </Typography>
            </Grid>
            <br/>
            <br/>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid container justifyContent='center' alignItems="center">    
                <Button
                  onClick={()=>setTipoFlota("Camion")}
                  className={tipoFlota==="Camion"?classes.buttonSelected:classes.buttonUnselected}
                >
                  Dumpers
                </Button>
                <Button
                  onClick={()=>setTipoFlota("LHD")}
                  className={tipoFlota==="LHD"?classes.buttonSelected:classes.buttonUnselected}
                >
                  Scoops
                </Button>
              </Grid>

            </Grid>
            <br/>
            <br/>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <img src={IconoProdTotal} width="30px" alt="Icono Prod Ton" className={classes.icon}/>
              <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                Toneladas
              </Typography>
            </Grid>
            <br/>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <TextField 
                  type="number" 
                  size="small" 
                  value={nuevaMeta} 
                  className={classes.datepicker} 
                  sx={{width:"190px"}}/>
              </Grid>
            </Grid>

          </div>

        </DialogContent>
        <DialogActions sx={{paddingBottom:"20px"}}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{padding:"5px 20px"}}
          >
            <Button onClick={handleClose} className={classes.buttonSelected}>Guardar</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}