import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../components/molecules/SectionHeader";
import IconoResumenDiario from "../../assets/icons/icono-resumen-diario.png";
import { useState } from "react";
import { getMovimientosPorMaquina } from "../../services/diario";
import { getInfoGeneralMina } from "../../services/general";
import HorizontalTimeChart from "../Operaciones/HorizontalTimeChart";
import { useEffect } from "react";
import { sortByDate } from "../../commons/Filtros";
import BarTimelineChartVacio from "../Produccion/BarTimelineChartVacio";
import { getResumenMensualMaquina } from "../../services/produccion";
import getEncuentrosMovMaquina from "../FuncionamientoDiario/getEncuentrosMovMaquina";
import MotionChart from "../FuncionamientoDiario/MotionChart";
import PantallaCarga from "../../components/molecules/PantallaCarga";
import { formatearUsToClDate, generateDatabaseDateTimeES } from "../../commons/FormatearFecha";

const useStyles = makeStyles({
    buttonSelected: {
      backgroundColor: "#2f4554",
      color: "white",
      padding: "5px 30px",
      fontSize: "14.4px",
      textTransform: "none",
      borderRadius: "4px",
      margin: "3px",
      height: "38px",
      width:"150px",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "#00B7C4"
      }
    },
    buttonUnselected: {
      backgroundColor: "#f4f4f4",
      padding: "5px 30px",
      fontSize: "14.4px",
      textTransform: "none",
      borderRadius: "4px",
      margin: "3px",
      height: "38px",
      width:"150px",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "#00B7C4"
      }
    },
    prodDes:{
      color: "#2f4554",
      fontSize: "12px !important",
    },
    prodValue:{
      fontSize: "20px",
      fontWeight:"bold"
    },
    bordermd:{
      '@media (min-width:1200px)': {
        borderRight:"2px solid #d7d7d7"
      }
    },
    marginmd:{
      '@media (min-width:1200px)': {
        marginLeft:"10px"
      }
    }
  });


  

export const PopupDetalleJustificacion = ({
  handleShowPopUpDetalleJustificacion,
  show,
  mostrarJustificar
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const datosMaqVacios = {
    nombre: "",
    modelo: "",
    tipo: "",
    contadorVueltas: 0,
    contadorHoras: 0,
    vueltas: [],
    horometros: [],
    fecha: "2020-05-05"
  };
  const turnoTodos = {
    nombre: "turnotodos",
    hora_fin: "23:59:59",
    hora_inicio: "00:00:00",
    id: 0,
    id_mina: 0,
    id_tipo_turno: 0,
    orden: 0
  }
  
  const estadoInicial = {
    turno: turnoTodos,
    datosMaquina: datosMaqVacios,
    estadosDataSeries: [],
    limites: { min: undefined, max: undefined },
    loading: false,
    seriesMovilizacionMaquina: [],
    seriesMovilizacionPorTurno: [],
    listaTurnos: [],
  };
  
   const [estado, setEstado] = useState(estadoInicial);
   const [retryCount, setRetryCount] = useState(0)
   const [loading, setLoading] = useState(false);

  const getDataSeriesEstados = (estados, lim) => {

    const datosEstados = [...estados]

    const dataOperativos = [];
    const dataMantencion = [];
    const dataFueradeServ = [];
    const dataSinInfo = [];
    const dataRalenti = [];
    const dataJustificada =[];
    const dataAJustificar = [];

    // Array para guardar los ultimos estados de cada maquina
    // Se itera por cada maquina
  
      // Ordena valores por fecha
      const sortedValues = sortByDate(datosEstados,"inicio");

      // Horarios de turno con su fecha tipo Date
      //const minmax = getMinMax(seriesTurno);
      const fechaInicioTurno = lim.min
      const fechaFinTurno = lim.max
      
      // Ultima hora para analizar estados sin info
      let ultimaHoraSinInfo = lim.min
      sortedValues.forEach(estado => {
        const data = {
          x: (localStorage.getItem("nombreMaquinaJustificado") ? localStorage.getItem("nombreMaquinaJustificado") : ""),
          y:[
            (new Date(estado.inicio)).getTime(),
            (new Date(estado.fin)).getTime()
          ]
        }
        if(!estado.tipo_justificacion){
        switch(estado.movimiento){
          case "operativo":
            dataOperativos.push(data);
            break;
          case "mantencion":
            dataMantencion.push(data);
            break;
          case "fuera de servicio":
            dataFueradeServ.push(data);
            break;
          case "ralenti":
            dataRalenti.push(data);
          default:
            break
        }
      }else if(estado?.tipo_justificacion){
        (estado.tipo_justificacion.toLowerCase() == "otro") 
         ? dataJustificada.push({...data,tipo_justificacion:estado?.observacion}) 
         : dataJustificada.push({...data,tipo_justificacion:estado?.tipo_justificacion});
     }

        /*
          Se obtiene el estado que se queire justificar
        */
        if(estado.inicio == localStorage.getItem("fechaInicioJustificacionPendiente") && mostrarJustificar){
          dataAJustificar.push({
            x:`${t('por_justificar')}`,
            y:[(new Date(estado.inicio)).getTime(),
              (new Date(estado.fin)).getTime()],
          customTooltip:`${t(estado.movimiento)} ${t('por_justificar')}`
          })
        }

         /* 
         Si hay una diferencia de tiempo entre la hora final del 
         estado anterior y la inicial del actual, agrega estado sin info 
         */
        if(((new Date(estado.inicio)).getTime() - ultimaHoraSinInfo.getTime())/1000>1){
          dataSinInfo.push({
            x: t("sin_info"),
            y:[
              ultimaHoraSinInfo.getTime(),
              (new Date(estado.inicio)).getTime(),
            ]
          })
        }

        ultimaHoraSinInfo = new Date(estado.fin)

      })

      /* 
      Si la maquina no tiene estados, agrega estado sininfo con el largo del inicio y fin de turno
      Si tiene datos, comprueba si hay un valle de tiempo entre el fin de ultimo estado y el fin del turno y agrega un estado sininfo
      */
      if(sortedValues.length===0){
        dataSinInfo.push({
          x: t("sin_info"),
          y:[
            fechaInicioTurno.getTime(),
            fechaFinTurno.getTime()
          ]
        })
      }
      else{
        const finEstadoFinal = new Date(sortedValues[sortedValues.length-1].fin)
          if((fechaFinTurno.getTime() - finEstadoFinal.getTime())/1000>1){
            dataSinInfo.push({
            x: t("sin_info"),
            y:[
              finEstadoFinal.getTime(),
              fechaFinTurno.getTime(),
            ]
          })
        }
      }

    return([
      {
        name: 'Operativo',
        color: "#01b7c4",
        data: dataOperativos
      },
      {
        name: 'En Mantención',
        color: "#ffbf00",
        data: dataMantencion
      },
      {
        name: 'Reserva',
        color: "#C5283D",
        data: dataFueradeServ
      },
      {
        name: 'Sin Información',
        color: "#d6dce4",
        data: dataSinInfo
      },
      {
        name:t("ralenti"),
        color:'#E9724C',
        data:dataRalenti
      },
      {
        name:'justificado',
        color:'#28B463',
        data:dataJustificada
      },
      {
        name:`${dataAJustificar[0]?.customTooltip ? dataAJustificar[0]?.customTooltip : ''}`,
        color:'#c4016b',
        data:dataAJustificar
      }
    ])
  } 

  const procesarMovimientosMaquina = (idMaq, fechaMaq, lim) => {
    const idMina = localStorage.getItem("idMina");
    const resEstados = getMovimientosPorMaquina(idMina, idMaq, fechaMaq, fechaMaq);
    resEstados.then(({success,data}) => {
      if(success == true && data[fechaMaq]){
        const estadosTotales = getEstadosTotalDia(data[fechaMaq]);        
        setEstado((estadoAnterior) =>({
            ...estadoAnterior,
            estadosDataSeries:getDataSeriesEstados(estadosTotales, lim)
        }))
        
      }
    })
  }

  const filtrarSeriesMovilizacionPorTurno = (movilizaciones,t) => {
    var series;
    if(t === turnoTodos){
      series = [];
      for(const [nombre_turno, movilizaciones_turno] of Object.entries(movilizaciones)){
        series = series.concat(movilizaciones_turno);
      }
    }
    else{
      series = movilizaciones[t.nombre];
    }
    return series?series:[];
  }

  const updateDataMovilizacionMaquina = (idMaquina,fecha) => {
    setLoading(true);
    if(idMaquina){
      getEncuentrosMovMaquina(parseInt(idMaquina),fecha)
      .then(({success, data}) => {
        if(success){
          setEstado((prevState) =>({
            ...prevState,
            seriesMovilizacionMaquina:data["beacons"],
            seriesMovilizacionPorTurno:filtrarSeriesMovilizacionPorTurno(data["beacons"], turnoTodos)
          }))
        }
        else{
          console.log("Ocurrio un error");
        }
        setLoading(false)
      })  
    }
    else{
      console.log("No se ha seleccionado ninguna máquina");
    }
  };


  const getEstadosTotalDia = (estados) => {
    let totales = []
    Object.values(estados).forEach( valueTurno => {
      totales = [...totales, ...valueTurno]
    })
    return totales;
  } 

  const getMinMaxTurnos = (turnos) => {
    const sortedTurnos =  turnos.sort(function(a, b) { 
      return a.orden - b.orden;
    });

    return {min: new Date(sortedTurnos[0].inicio), max: new Date(sortedTurnos[turnos.length-1].fin)}
  }

  const getMinMaxGrafico = () => {

    let fechaStorage = localStorage.getItem("diaJustificacionDetalle");
    let fechaInicio = new Date(`${fechaStorage} ${estado.turno.hora_inicio}`);
    let fechaFin = new Date(`${fechaStorage} ${estado.turno.hora_fin}`);

    switch(Number(estado.turno.id_tipo_turno)){
      case 1:
        // El turno se encuentro entre dos dias, partiendo por la madrugada del dia anterior
        fechaInicio.setDate(fechaInicio.getDate() - 1);
        return ({
          min: fechaInicio,
          max: fechaFin
        })
      case 2:
        // El turno es dentro de horario de un mismo dia
        return ({
          min: fechaInicio,
          max: fechaFin
        })
        // Case 3 representaria turno entre dos dias que termina en el dia siguiente
      case 3:
        fechaFin.setDate(fechaFin.getDate() + 1);
        return ({
          min: fechaInicio,
          max: fechaFin
        })
      default:
        // El turno tiene id 0 o distinto, por lo tanto, se deben mostrar todos los datos
        estado.listaTurnos.forEach(itemTurno => {
          switch(itemTurno.orden){
            case 0:        
              // Primer turno      
              fechaInicio = new Date(`${fechaStorage} ${itemTurno.hora_inicio}`);
              if(Number(itemTurno.id_tipo_turno)===1){
                fechaInicio.setDate(fechaInicio.getDate() - 1);
              }
            case (Number(estado.listaTurnos.length)-1):
              // Ultimo turno
              fechaFin = new Date(`${fechaStorage} ${itemTurno.hora_fin}`);
              if(Number(itemTurno.id_tipo_turno)===3){
                fechaFin.setDate(fechaFin.getDate() + 1);
              }
            default:
              // Turnos intermedios no se consideran
              break;
          }
        })
        return({
          min: fechaInicio,
          max: fechaFin
        })
    }
  };
 
  useEffect(() => {

    if(show){
            setEstado(estadoInicial);
            setLoading(true);
            const idMina = localStorage.getItem("idMina");
            const idMaquina = localStorage.getItem("idMaquina");
            const fecha = localStorage.getItem("diaJustificacionDetalle");
            const fechaTipoDate = new Date(fecha);
            const month = fechaTipoDate.getMonth() + 1;
            const year = fechaTipoDate.getFullYear();
            

            Promise.all([
                getResumenMensualMaquina(idMina,idMaquina,month,year),
                getInfoGeneralMina(idMina,fecha)
            ])
            .then(([respuestaResumen, respuestaInfo]) =>{
                const { success, data } = respuestaResumen;
                const { success: exitoInfo, data: data2 } = respuestaInfo;

                if(success){
                    setEstado((estadoPrevio) => ({
                        ...estadoPrevio,
                        datosMaquina:data,
                        limites:getMinMaxTurnos(data2.turnos),
                        listaTurnos:data2.turnos
                    }));

                    procesarMovimientosMaquina(idMaquina, fecha, getMinMaxTurnos(data2.turnos));
                    updateDataMovilizacionMaquina(idMaquina, fecha);

                    setLoading(false);
                }else{
                    console.log("Error inesperado")
                    setRetryCount(retryCount + 1);
                }
            })
            .catch((err) => {
               console.log(err);
               setRetryCount(retryCount + 1);
               setLoading(false)
            })
    }

  }, [show])


  useEffect(() => {

    if(show){
            setEstado(estadoInicial);
            setLoading(true);
            const idMina = localStorage.getItem("idMina");
            const idMaquina = localStorage.getItem("idMaquina");
            const fecha = localStorage.getItem("diaJustificacionDetalle");
            const fechaTipoDate = new Date(fecha);
            const month = fechaTipoDate.getMonth() + 1;
            const year = fechaTipoDate.getFullYear();
            

            Promise.all([
                getResumenMensualMaquina(idMina,idMaquina,month,year),
                getInfoGeneralMina(idMina,fecha)
            ])
            .then(([respuestaResumen, respuestaInfo]) =>{
                const { success, data } = respuestaResumen;
                const { success: exitoInfo, data: data2 } = respuestaInfo;

                if(success){
                    setEstado((estadoPrevio) => ({
                        ...estadoPrevio,
                        datosMaquina:data,
                        limites:getMinMaxTurnos(data2.turnos),
                        listaTurnos:data2.turnos
                    }));

                    procesarMovimientosMaquina(idMaquina, fecha, getMinMaxTurnos(data2.turnos));
                    updateDataMovilizacionMaquina(idMaquina, fecha);

                    setLoading(false);
                }else{
                    console.log("Error inesperado")
                    setRetryCount(retryCount + 1);
                }
            })
            .catch((err) => {
               console.log(err);
               setLoading(false)
            })
    }

  }, [retryCount])
 
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={show}
        onClose={() => handleShowPopUpDetalleJustificacion(false)}
      >
        <DialogContent>
            {
              (loading) ?
              <div style={{width:'500px', height:'500px'}}>
                <PantallaCarga loading={true}/>
              </div> :
          <Grid item>
            <Grid>
              <Box sx={{display:'flex',justifyContent:'space-between'}}>
                <SectionHeader
                            title={t("movimiento_y_horometro")}
                            subtitle={""}
                            icon={
                            <img
                                src={IconoResumenDiario}
                                width="35px"
                                alt=" Icono Resumen Diario"
                            />
                            }
                        />
                <Typography
                  style={{fontWeight: "bold",fontSize: "14.4px",color: "#2f4554",width:'100%',textAlign:'right'}}
                >
                  {localStorage.getItem("diaJustificacionDetalle") ? formatearUsToClDate(localStorage.getItem("diaJustificacionDetalle")) : ''}
                </Typography>

              </Box>
              
              <div style={{ width: "100%" }}>
              {
                (estado.seriesMovilizacionPorTurno.length > 0 && getMinMaxGrafico().min && getMinMaxGrafico().max) ?
                  <MotionChart
                  series = {estado.seriesMovilizacionPorTurno}
                  limites={getMinMaxGrafico()}
                  fecha={estado.datosMaquina.fecha}
                />
                :
                <BarTimelineChartVacio
                limites={estado.limites}
                labels={['Carga', 'Descarga']}
                height="300"
                loading={true}
            
              />
              }

              <br/>
              <br/>
              <br/>

                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  style={{width:'95%',margin:'0 auto'}}
                >


                  <Grid
                    item
                    lg={1}
                    md={1}
                    xs={1}
                    sx={{
                      padding: "3px 0px !important",
                      borderRight: "2px solid #d7d7d7"
                    }}
                  >
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <span
                        style={{
                          margin: "0px 5px 0px 0px",
                          height: "13px",
                          width: "13px",
                          backgroundColor: "#01b7c4",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <span
                        className={classes.prodDes}
                        style={{ marginLeft: "3px" }}
                      >
                        {t("operativo")}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    md={1}
                    xs={1}
                    sx={{
                      padding: "3px 0px !important",
                      borderRight: "2px solid #d7d7d7",
                    }}
                  >
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <span
                        style={{
                          margin: "0px 5px 0px 0px",
                          height: "13px",
                          width: "13px",
                          backgroundColor: "#E9724C",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <span
                        className={classes.prodDes}
                        style={{ marginLeft: "3px" }}
                      >
                        {t("ralenti")}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    md={1}
                    xs={1}
                    sx={{
                      padding: "3px 0px !important",
                      borderRight: "2px solid #d7d7d7",
                    }}
                  >
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <span
                        style={{
                          margin: "0px 5px 0px 0px",
                          height: "13px",
                          width: "13px",
                          backgroundColor: "#ffbf00",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <span
                        className={classes.prodDes}
                        style={{ marginLeft: "3px" }}
                      >
                        {t("en_mantencion")}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    md={1}
                    xs={1}
                    sx={{
                      padding: "3px 0px !important",
                      borderRight: "2px solid #d7d7d7",
                    }}
                  >
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <span
                        style={{
                          margin: "0px 5px 0px 0px",
                          height: "13px",
                          width: "13px",
                          backgroundColor: "#C5283D",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <span
                        className={classes.prodDes}
                        style={{ marginLeft: "3px" }}
                      >
                        {t("fuera_de_servicio")}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={1}
                    md={1}
                    xs={1}
                    sx={{
                      padding: "3px 0px !important",
                      borderRight: "2px solid #d7d7d7",
                    }}
                  >
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <span
                        style={{
                          margin: "0px 5px 0px 0px",
                          height: "13px",
                          width: "13px",
                          backgroundColor: "#28B463",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <span
                        className={classes.prodDes}
                        style={{ marginLeft: "3px" }}
                      >
                        {t("Justificado")}
                      </span>
                    </Grid>
                  </Grid>
                  {estado.estadosDataSeries[6]?.data.length > 0 && (<Grid
                    item
                    lg={1}
                    md={1}
                    xs={1}
                    sx={{
                      padding: "3px 0px !important",
                      borderRight: "2px solid #d7d7d7",
                    }}
                  >
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <span
                        style={{
                          margin: "0px 5px 0px 0px",
                          height: "13px",
                          width: "13px",
                          backgroundColor: "#c4016b",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <span
                        className={classes.prodDes}
                        style={{ marginLeft: "3px" }}
                      >
                        {t("justificar")}
                      </span>
                    </Grid>
                  </Grid>)}
                  <Grid
                    item
                    lg={1}
                    md={1}
                    xs={1}
                    sx={{ padding: "3px 0px !important" }}
                  >
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                      <span
                        style={{
                          margin: "0px 5px 0px 0px",
                          height: "13px",
                          width: "13px",
                          backgroundColor: "#d6dce4",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <span
                        className={classes.prodDes}
                        style={{ marginLeft: "3px" }}
                      >
                        {t("sin_info")}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>

                {
                    estado.estadosDataSeries?.length > 0 ?
                    <HorizontalTimeChart
                        series = {estado.estadosDataSeries} 
                        turno={estado.limites}
                        numCategorias={
                          estado.estadosDataSeries[3].data.length && estado.estadosDataSeries[6].data.length > 0 ? 3 :
                          estado.estadosDataSeries[3].data.length > 0 ? 2 :
                          estado.estadosDataSeries[6].data.length > 0 ? 2 :
                          1
                        }
                        MostrarLabelCompleto={true}
                    />
                    :
                    <BarTimelineChartVacio
                       limites={getMinMaxGrafico()}
                       labels={[t("horometro")]}
                       height="130"
                       loading={true}
                    />
                }

              </div>
            </Grid>
          </Grid>
            }

        </DialogContent>
         <br/>
         <br/>
            <DialogActions>
            <Button
                onClick={() => {
                handleShowPopUpDetalleJustificacion(false);
                }}
            >
                {t("cancelar")}
            </Button>
            </DialogActions>
      </Dialog>
    </>
  );
};