import { useEffect, useState } from "react";

const API_URL =
	"https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/area-operacion/get-empresas";

export const useGetCognitoAttributes = () => {
	const [dataEmpresas, setDataEmpresas] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(API_URL, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": process.env.REACT_APP_API_KEY,
					}
				});
				const data = await response.json();

				setDataEmpresas(data);
				setLoading(false);
			} catch (error) {
				console.error(error);
				setError(true);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	const allEmpresas = Object.keys(dataEmpresas) ?? [];

	function getAreaOperaciones(empresa) {
		return (
			dataEmpresas[empresa]?.map((el) => {
				return {
					idOperacion: el.to_id_operacion,
					nombreOperacion: el.operacion_nombre,
					idAreaOperacion: el.tao_id_area_operacion,
					nombreAreaOperacion: el.area_operacion_nombre
				};
			}) ?? []
		);
	}

	return { dataEmpresas, allEmpresas, getAreaOperaciones, loading, error };
};
