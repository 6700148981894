import { useLocation } from "react-router-dom";
import { CardHeader, Container, Link, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import "moment/locale/es";
import React, { useEffect, useState, useContext } from "react";
import { AccountContext } from "../../authentication/Account";
import NavBar from "../../components/organisms/NavBar";
import maquina from "./maquina.png";
import camion from "./camion.png";
import ImagenScoop from "../../assets/images/lhd.png";
import ImagenCamion from "../../assets/images/camion.png";
import camionDumper from "./camion-dumper.png";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { CircularProgress } from "@mui/material";
import { es } from "date-fns/locale";
import Login from "../../authentication/Login";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "./calendarSyles.scss";
import TablaMaquinas from "./Tablas/TablaMaquinas";
import getDatosMina from "./getDatosMina";
import ReplayIcon from '@mui/icons-material/Replay';
import './datepicker.css';
import {KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
//mport GraficoToneladas from "./Metas/GraficoToneladas";
import ModalMetas from "./Metas/ModalMetas";
//import GraficoRendimiento from "./Metas/GraficoRendimiento";
import SettingsIcon from '@mui/icons-material/Settings';
//import getMeta from './Metas/getMeta';
import StackedChart from "./StackedChart";
import IconoResumenDiario from '../../assets/icons/icono-resumen-diario.png';
import IconoProdTotal from '../../assets/icons/icono-prod-total.png';
import SectionHeader from "../../components/molecules/SectionHeader";
import ResumenProdCard from "./ResumenProdCard";
import {getTurnosMina} from "../../services/general";
import { separarPorMiles } from "../../commons/Formatos";
import { getInfoGeneralMina } from "../../services/operaciones";
import { format } from "date-fns";
import InfoIcon from '@mui/icons-material/Info';
import InfoTooltip from "../../components/molecules/InfoTooltip";
import { getResumenMensualAreaOp } from "../../services/produccion";
import IconoDescargar from '@mui/icons-material/FileDownload';
import ModalDescargaPlanilla from "../../components/organisms/ModalDescargaPlanilla";
import '../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from "../../commons/Idiomas";
import { GeneralMinaContext } from "../../context/GeneralMina";
import { usePageTimingContext } from "../../context/PageTimingContext";
import { BreadCrumbsGenerico } from "../../components/molecules/BreadCrumbsGenerico";

const useStyles = makeStyles({
  iconClick:{
    width:"25px",
    color: "#2f4554",
    cursor: "pointer",
    "&:hover": {
      color: "#00b7c4"
    }
  },
  icon:{   
    color: "#2f4554",
    cursor: "pointer",
    "&:hover": {
      color: "#00b7c4"
    }
  },
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"160px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  buttonUnselected: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"160px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },

    "& .MuiInputBase-root": {

      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main"
      }
    },

    '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
      /* '& fieldset': {            // - The <fieldset> inside the Input-root
          borderColor: 'pink',   // - Set the Input border
      }, */
      '&:hover fieldset': {
          borderColor: "#00B7C4", // - Set the Input border when parent has :hover
      }/* ,
      '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
          borderColor: 'green',
      }, */
  },

  },
  card:{
    boxShadow:"none",
    border:"1px solid rgba(0, 0, 0, 0.1)"
  }
});

const minToHours = (minutes) => {
  return Math.round((minutes/60) * 100) / 100/* (minutes/60).toFixed(2); */
}

const Produccion = (props) => {
  const { getSession } = useContext(AccountContext);
  const location = useLocation();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { sendTime } = usePageTimingContext();

  const [loggedIn, setLoggedIn] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [cargando, setCargando] = useState(true);

  const [fecha, setFecha] = useState(new Date());
  const [idMina, setIdMina] = useState(0);
  const [rowsEquipos, setRowsEquipos] = useState([]);
  const [rowsCamiones, setRowsCamiones] = useState([]);
  const [equipos, setEquipos] = useState({});
  const [camiones, setCamiones] = useState({});
  const [imagenCamion, setImagenCamion] = useState(camion);
  const [datosProd, setDatosProd] = useState({});
  const [listaTurnos, setListaTurnos] = useState([]);

  const seleccionTipoCamion = 0;
  const seleccionTipoEquipo = 1;
  const [tipoMaquina, setTipoMaquina] = useState(seleccionTipoCamion);


  /* const [meta, setMeta] = useState(0); */
  const [openModalMetas, setOpenModalMetas] = useState(false);
  /* const auxMeta = {
    año: 0,
    descripcion_meta: "",
    id_mina: 0,
    id_tipo_maquina: 0,
    id_tipo_meta: 0,
    mes: 0,
    nombre_maquina: "",
    progreso: 0,
    total: 0
  } */

  /* const [metaCamiones, setMetaCamiones] = useState(auxMeta);
  const [metaEquipos, setMetaEquipos] = useState(auxMeta); */
  const [openModalDescarga, setOpenModalDescarga] = useState(false);
  const [listaTiposDeFlota, setListaTiposDeFlota] = useState([]);
  const { ListadoMaquinas, loadingMaquinas, onHandleSetDataGeneral} = useContext(GeneralMinaContext);
  


  const getTurnos = (id) =>{
    getTurnosMina(id)
    .then(({success, data}) => {
      if(success){
        setListaTurnos(data);
      }
    })
  }

  const getUltimoDiaAcumulado = () => {
    const auxFecha = new Date();
    if((auxFecha.getMonth() === fecha.getMonth()) && (auxFecha.getFullYear() === fecha.getFullYear())){
      return auxFecha.getDate();
    }
    else{
      return (new Date(fecha.getFullYear(),fecha.getMonth()+1,0)).getDate();
    }
  };

  const procesarDatosAreaOp  = (dataMina) => {
      if(ListadoMaquinas.length >0){
        const lTiposFlota = [...new Set(ListadoMaquinas.map( m => m.tipo))]
        setListaTiposDeFlota(lTiposFlota)
        if(!lTiposFlota.includes("Camion")) {setTipoMaquina(seleccionTipoEquipo||seleccionTipoCamion)};

        const maquinasConKey= ListadoMaquinas.reduce((obj, item) => ((obj[item.id] = item, obj)),{});
        let rowsEquipos = [];
        let rowsCamiones = [];
        let equipos = {};
        let camiones = {};
        Object.entries(dataMina).forEach(([keyMaq, valueMaq]) => {
          let vueltasAcumuladas = 0;
          let tonAcumuladas = 0;
          let minutosAcumulados = 0;
          Object.entries(valueMaq.vueltas).forEach(([keyDia, valueDia])=>{
            Object.values(valueDia).forEach((valueTurno)=>{
              vueltasAcumuladas+=valueTurno.total_vueltas
              tonAcumuladas+=valueTurno.total_toneladas
              minutosAcumulados+=valueTurno.total_minutos_vueltas
            })
          })
          let row = {
            id: keyMaq,
            nombre: dataMina[keyMaq].nombre,
            horometro: minToHours(minutosAcumulados),
            vueltas: vueltasAcumuladas,
            toneladas: dataMina[keyMaq].toneladas,
            tonelaje: tonAcumuladas,
            ultimaActualizacion: maquinasConKey[keyMaq]?.fecha_ultima_ubicacion,
            ultimaUbicacion: maquinasConKey[keyMaq]?.tipo_ultima_ubicacion
          };
  
          switch(dataMina[keyMaq].tipo){
            case "LHD":
              rowsEquipos.push(row);
              equipos[keyMaq] = dataMina[keyMaq];
              break;
            case "Camion":
              rowsCamiones.push(row);
              camiones[keyMaq] = dataMina[keyMaq];
              break;
            default:
              break;
          }

        })

        setEquipos(equipos);
        setCamiones(camiones);
        setRowsEquipos(rowsEquipos);
        setRowsCamiones(rowsCamiones);
        setCargando(false)
      }
  }

  const updateDatosMina = (id,fecha) => {
    setCargando(true);
    setFecha(fecha);
    localStorage.setItem('mes',fecha);
    const month = fecha.getMonth() + 1;
    const year = fecha.getFullYear();

    getResumenMensualAreaOp(id,month,year).then(({success, data}) => {
      if(success){procesarDatosAreaOp(data)}
      else {console.log("Error inesperado")};
    })
  };

  useEffect(() => {
    onHandleSetDataGeneral(false,props.nombreMina,props.idMina)
    localStorage.setItem("nombreMina", props.nombreMina);
    localStorage.setItem("idMina", props.idMina);
    localStorage.setItem("rutaMina", props.rutaMina);
  }, [props.nombreMina, props.idMina, props.rutaMina]);

  useEffect(() => {
    getSession().then((data) => {
      setLoggedIn(true);
      setLoadingPage(false);
    }).
    catch((e)=> setLoadingPage(false))
  }, [loggedIn]);

  useEffect(()=>{
    onHandleSetDataGeneral(false,props.nombreMina,props.idMina)

    getTurnos(props.idMina);
    //let mes = localStorage.getItem('mes');
    let mes = null; //Se reinicia la fecha cada vez que se entra a la pag
    if(mes){
      mes= new Date(mes);
      setFecha(new Date(mes));
    }
    else{
      mes = new Date();
      setFecha(new Date(mes));
      localStorage.setItem('mes', new Date());
    }

    if(Number(props.idMina) !== 0){
      setIdMina(props.idMina);
      updateDatosMina(props.idMina,mes);
      /* getMetaMensual(props.idMina, fecha.getMonth(), fecha.getFullYear); */
      switch (props.idMina) {
        case 4:
          setImagenCamion(camionDumper);
          break;
        default:
          setImagenCamion(camion);
          break
      }

    }
  },[props.idMina, ListadoMaquinas]);

  useEffect(()=>{
    if(listaTurnos.length>0){
      const maquinasSelec = tipoMaquina===seleccionTipoCamion?camiones:equipos;
      if(Object.keys(maquinasSelec).length > 0){
        let objProdPorTurnos = listaTurnos.reduce((obj, item) => ((obj[item.nombre] = {vueltas:0,ton:0,meta:0}, obj)),{})
        objProdPorTurnos["turnotodos"] = {vueltas:0,ton:0,meta:0};
        listaTurnos.forEach((turno) => {
          Object.values(maquinasSelec).forEach((datos)=>{
            Object.entries(datos.vueltas).forEach(([key,value])=>{
              objProdPorTurnos[turno.nombre]["vueltas"] += value[turno.nombre]["total_vueltas"]
              objProdPorTurnos[turno.nombre]["ton"] += value[turno.nombre]["total_toneladas"]
              objProdPorTurnos["turnotodos"]["vueltas"] += value[turno.nombre]["total_vueltas"]
              objProdPorTurnos["turnotodos"]["ton"] += value[turno.nombre]["total_toneladas"]
            })
          })
        })
        setDatosProd(objProdPorTurnos)
      }
    }
  },[listaTurnos, tipoMaquina, camiones, equipos]);

  useEffect(() => {
    return () => {
      sendTime(location.pathname)
    }
  }, [])


  /* const recargarConDatosActuales = () => {
    const mes = new Date();
    updateDatosMina(idMina, mes);
  }; */

    /* const getMetaMensual = (idMina, month, year) => {
    getMeta(idMina, 1, month, year)
      .then(({success,data}) => {
        if(success){
          if(data.length>0){
            setMetaEquipos(data[0]);
          }
          else{
            setMetaEquipos(auxMeta);
          }
        }
      })
    
    getMeta(idMina, 2, month, year)
    .then(({success,data}) => {
      if(success){
        if(data.length>0){
          setMetaCamiones(data[0]);
        }
        else{
          setMetaCamiones(auxMeta);
        }
      }
    })

  } */


  return (
    <>
    { 
      !loadingPage? (
        loggedIn? (
          <>
          <NavBar />

          <Container>
            <br/>

            <BreadCrumbsGenerico
            nombreMina={props.nombreMina}
            dataRuta={ [
              { id: 1, endpoint: "/", texto: `Mine-Watch` },
              { id: 2, texto: `${t('minaSingular')} ${props.nombreMina}` },
              { id: 3, texto: t("produccion"),destacar:true }
            ]}
            />

            <br/>

            <Grid container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={0}
            >
              <Grid item lg={8} md={8}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        /* okLabel = "Confirmar" */
                        cancelLabel={t("cancelar")}
                        className={classes.datepicker}
                        size="small"
                        inputVariant="outlined"
                        openTo="month"
                        minDate={new Date('2021-11-01 00:00:00')}
                        maxDate={(new Date().setHours(23,59,59))}
                        views={["year", "month"]}
                        value={fecha}
                        onChange={(newValue) => {
                          updateDatosMina(idMina,newValue);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item>    
                    <IconoDescargar
                      className={classes.iconClick} 
                      onClick={()=>setOpenModalDescarga(true)}
                    />
                  </Grid>
                  {/* <Grid item>
                    <Button className={classes.buttonSelected}>Descargar</Button>
                  </Grid> */}
                  {/* <Grid item>
                    <SettingsIcon
                      className={classes.icon}
                      onClick = {()=>setOpenModalMetas(true)}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>

            <br/>

            <Card sx={{padding:"30px 20px 20px 20px"}}>       
              <CardHeader
                title={
                  <Grid 
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item lg = {4}>
                      <Grid 
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <div style={{width: "120px"}}>
                          <CardMedia
                            component="img"
                            image={tipoMaquina===seleccionTipoCamion?ImagenCamion:ImagenScoop}
                            alt="Imagen Maquina"
                            style={{ width: "95px", margin: "0 auto" }}
                          />
                        </div>
                        <Grid item>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: "14.4px",
                              color: "#00b7c4"
                            }}
                          >
                            {`${tipoMaquina===seleccionTipoCamion? (idMina==24 ? (t("Dumpers_traducir")): t("Dumpers")): "Scoops"}`}
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: "14.4px",
                            }}
                          >
                            {
                            (i18n.language === 'en')?
                              (`${fecha.toLocaleString('en-EN', { month: 'long', year:'numeric'})}`)
                            :
                              (`${fecha.toLocaleString('es-ES', { month: 'long' }).substr(0,1).toUpperCase()}${fecha.toLocaleString('es-ES', { month: 'long' }).substr(1)} ${fecha.getFullYear()}, mostrando del 1 al ${getUltimoDiaAcumulado()}`)
                            }             
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={8} md={8}>
                      <Grid container justifyContent='flex-end' alignItems="center">
                        {listaTiposDeFlota.includes("Camion") &&

                          <Button
                              onClick={()=>setTipoMaquina(seleccionTipoCamion)}
                              className={tipoMaquina===seleccionTipoCamion?classes.buttonSelected:classes.buttonUnselected}
                          >
                            {idMina == 24 ? t("flota_camion_traducir") : t("flota_camion")}
                          </Button>
                        }
                        
                        {listaTiposDeFlota.includes("LHD") &&
                          <Button
                              onClick={()=>setTipoMaquina(seleccionTipoEquipo)}
                              className={tipoMaquina===seleccionTipoEquipo?classes.buttonSelected:classes.buttonUnselected}
                          >
                            {t("flota_lhd")}
                          </Button>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
              <br/>
              <CardContent style={{padding:"20px"}}>
                <SectionHeader
                  title={t("resumen_produccion")}
                  subtitle={""}
                  icon={<img src={IconoResumenDiario} width="35px" alt=" Icono Resumen Diario"/>}
                />
                <br/>
                <Grid container spacing={1}>
                  {listaTurnos.map((turno,index)=>{
                    return (
                      <Grid key={index} item lg={4} md={12} xs={12}>
                        <ResumenProdCard 
                          titulo={t("detalle_de")} 
                          subtitulo={t(`turno_${turno.nombre.toLowerCase()}`)}
                          vueltas={datosProd[turno.nombre]?separarPorMiles(datosProd[turno.nombre].vueltas):0}
                          tonelaje = {datosProd[turno.nombre]?separarPorMiles(datosProd[turno.nombre].ton):0}
                          meta = {0}
                          isSelect = {false}
                        />
                      </Grid>
                    )
                  })}
                  <Grid key={"turnotodos"} item lg={4} md={12} xs={12}>
                    <ResumenProdCard 
                      titulo={t("produccion")}
                      subtitulo={t("a_la_fecha")}
                      vueltas={datosProd["turnotodos"]?separarPorMiles(datosProd["turnotodos"].vueltas):0}
                      tonelaje = {datosProd["turnotodos"]?separarPorMiles(datosProd["turnotodos"].ton):0}
                      meta={0}
                      isSelect = {true}
                    />
                  </Grid>
                </Grid>
                <br/>
                <br/>

                <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item lg = {10} md={10} xs={10}>
                    <SectionHeader
                      title={t("produccion_acumulada")}
                      icon={<img src={IconoProdTotal} width="35px" alt="Icono"/>}
                    />
                  </Grid>
                  <Grid item>
                    <InfoTooltip>
                      {t("info_grafico_produccion_acumulada")}
                    </InfoTooltip>
                  </Grid>
                </Grid>
                <br/>
                <br/>

                <Grid container justifyContent="space-between" alignItems="center" sx={{padding:"0px 30px 0px 20px"}}>
                  <Grid item>
                    <span style={{fontWeight:"bold",color: "#2f4554", fontSize: "12px"}}>
                      {`${t("produccion")} (${t("tonelaje")})`}
                    </span>
                  </Grid>
                  <Grid item>
                    <span style={{fontWeight:"bold",color: "#ff6600", fontSize: "12px"}}>
                      {`${t("produccion_acumulada")} (${t("tonelaje")})`}
                    </span>
                  </Grid>
                </Grid>

                <StackedChart data={seleccionTipoCamion===tipoMaquina?camiones:equipos}/>                      <br/>
                
                <TablaMaquinas
                  data={seleccionTipoCamion===tipoMaquina?rowsCamiones:rowsEquipos} 
                  maquinas={seleccionTipoCamion===tipoMaquina?camiones:equipos} 
                  cargando={cargando}  
                  fecha={fecha}
                  idMina={idMina}
                />
              </CardContent>
            </Card>
            <ModalDescargaPlanilla
              idMina={props.idMina}
              nombreMina={props.nombreMina} 
              open={openModalDescarga} 
              onClickClose={setOpenModalDescarga}
            />
          </Container>
          </>
        )
        :(
          <Login/>
        )
      )
      :(
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", marginTop:"25%"
        }}>
          <CircularProgress style = {{color: "#2f4554"}} size = {100}/>
        </div>
      )
    }
      <ModalMetas 
        fechaInicio={fecha}
        idMina={props.idMina} 
        open={openModalMetas} 
        closeModal={setOpenModalMetas} 
        tipoMaquina={tipoMaquina}
      />
    </>
  );
};

export default Produccion;
