import {
  Box,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@mui/material";
import { separarPorMiles } from "../../commons/Formatos";
import '../../translator';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  selBox: {
    width: "100%",
    backgroundColor: '#2f4554',
    borderRadius: "2px",
    padding:"30px 15px",
    pointerEvents:"none"
  },
  unSelBox: {
    width: "100%",
    backgroundColor: '#f4f4f4',
    borderRadius: "2px",
    padding:"30px 15px",
    pointerEvents:"none"
  },
  selDescripcion: {
    color: "#fff",
    fontSize: "12px !important",
    fontWeight:"bold !important"
  },
  unSelDescripcion: {
    color: "#2f4554",
    fontSize: "12px !important",
    fontWeight:"bold !important"
  },
  selValue:{
    color: "#fff",
    fontSize: "22px !important",
    fontWeight:"bold !important"
  },
  unSelValue:{
    color: "#00b7c4",
    fontSize: "22px !important",
    fontWeight:"bold !important"
  },
  selValueDes:{
    color: "#fff",
    fontSize: "12px !important",
  },
  unSelValueDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  }
}));

const getColor = (i) => {
  const esPar = (i%2)===0;
  if(esPar){
    return "#01b7c4"
  }
  return "#ff6600"
}

const ProdKpiCard = ({titulo,subtitulo,dataTurnos,isSelect,icono,turno}) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return(
    <>        
      <Box className={isSelect?classes.selBox:classes.unSelBox}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item>
            <div className={isSelect?classes.selDescripcion:classes.unSelDescripcion}>
              {icono}
              <div>{titulo}</div>
              <div style={{fontWeight:"normal"}}>{subtitulo}</div>
            </div>
          </Grid>

          <Grid item lg={3} md={3} xs={3}>
            <Grid
              container
              direction="column"
              alignItems="center"
            >
              <div 
                className={isSelect?classes.selValue:classes.unSelValue}
                style={{opacity:(((turno==="turnotodos")||(!turno))?1:0.5)}}
              >
                {dataTurnos.turnotodos?separarPorMiles(dataTurnos.turnotodos):0}
              </div>
              <div 
                className={isSelect?classes.selValueDes:classes.unSelValueDes}
                style={{opacity:(((turno==="turnotodos")||(!turno))?1:0.5)}}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                >
                  {`Total`}
                </Grid>
              </div>
            </Grid>
          </Grid>

          <Divider orientation="vertical" flexItem sx={{width:"2px",background:isSelect?"#465f70":"#d7d7d7"}}/>
          <Grid item lg={6} md={6} xs={6}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-evenly"
              >
                {Object.entries(dataTurnos).map(([key,value],index)=>{
                  if(key !== "turnotodos"){
                    return(
                      <Grid key={key} item lg={6} md={3} xs={3}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          sx={{marginLeft:"10%"}}
                        >
                          <div 
                            className={isSelect?classes.selValue:classes.unSelValue} 
                            style={{color:getColor(index), opacity:(((turno===key)||(!turno))?1:0.5)}}
                          >
                            {separarPorMiles(value)}
                          </div>
                          <div 
                            className={isSelect?classes.selValueDes:classes.unSelValueDes}
                            style={{opacity:(((turno===key)||(!turno))?1:0.5)}}
                          >
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                            >
                              {t(`turno_${key.toLowerCase()}`)}
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                    )
                  } 
                })}
            </Grid>
          </Grid>


        </Grid>
      </Box>
    </>
  )
}

export default ProdKpiCard;