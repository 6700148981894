import { useEffect, useState } from 'react';
import { getHora_ingreso_egreso } from '../services/operaciones';

export const useGetHoraIngresoEgreso = (id_area_operacion, fecha, id_tipo_maquina, id_maquina) => {
  const [loadingIngresoEgreso, setLoadingIngresoEgreso] = useState(false);
  const [dataIngresoEgreso, setDataIngresoEgreso] = useState({});

  useEffect(() => {
    setLoadingIngresoEgreso(true);
    getHora_ingreso_egreso(id_area_operacion,fecha,id_tipo_maquina,id_maquina)
      .then((res) =>{
        (id_tipo_maquina ==1) ? 
          setDataIngresoEgreso(res.data.EncuentrosScoopsPrimerFrenteTurnos) : 
          setDataIngresoEgreso(res.data.ingresoGeneralTurnos)
          setLoadingIngresoEgreso(false);
      })
      .catch((err) =>{
        console.log(err);
        setLoadingIngresoEgreso(false)
      })

  }, [fecha, id_maquina])
  
  return {dataIngresoEgreso,loadingIngresoEgreso}
}
