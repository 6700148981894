import {Button} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  titulo:{
    fontWeight: "bold",
    fontSize: "14.4px",
  },
  texto:{
    fontSize: "14.4px"
  },
  buttonSelected: {
    /* width: "180px", */
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  buttonDisabled: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    alignSelf: "center",
    pointerEvents: "none",
    color: "#a4a4a4"

  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        fontSize:"14.4px"
      }
    },

    '& .MuiOutlinedInput-root': { 
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
  },

  }
});


export default function ModalBase({open, onClickClose, titulo, children, icon}) {
  const classes = useStyles();

  const handleClose = () => {
    onClickClose(false);
  };


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
      >
        <DialogContent sx = {{padding:"0"}}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{padding:"13px 20px"}}
          >
            <Grid item lg={10}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                {icon}
                <Typography className={classes.titulo} style={{fontSize:"14.4px", fontWeight:"bold"}}>
                  {titulo}
                </Typography>
              </Grid>
            </Grid>
            
            <Grid item lg={2}>
              <div onClick={handleClose} className={classes.texto} style={{fontStyle:"italic", cursor:"pointer"}}>
                Cerrar
              </div>
            </Grid>
          </Grid>

          <Divider/>
          
          <br/>

          <div style={{padding:"20px 25px"}}>
            {children}
          </div>

        </DialogContent>

        <br/>
        <br/>

        <DialogActions>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{padding:"5px 20px"}}
          >           
            <Button 
              /* onClick={()=>onClickDescarga(resumenCheck, vueltasCheck)}  */
              className={classes.buttonSelected}
            > 
              Guardar
            </Button>
          </Grid>
        </DialogActions>
        <br/>
      </Dialog>
    </div>
  );
}