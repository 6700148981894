import React, { useEffect } from 'react'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Grid,
    Checkbox,
    ListItemText,
    FormControlLabel,
  } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useState } from 'react';

export const PopupRegistroJustificacionCustom =  React.memo(({
    openCustom,
    setOpenCustom,
    formik,
    ListadoMaquinas,
    arregloMovimientos,
    FormJustificados,
    gruposJustificaciones,
    ListadoMotivos,
    handleErrorForm,
    classes
}) => {

    const paperStyle = {
        padding: 20,
        width: 480,
      };
      
    const [multiplesEquipos, setMultiplesEquipos] = useState([]);
    const [selectEquiposDesactivados, setselectEquiposDesactivados] = useState(false)
    const [estadoCheckboxs, setestadoCheckboxs] = useState({});    
    const { t } = useTranslation();

    const setInitialStateCheckbox = (esVerdadero = false) =>{ 
      let initialStateCheckbox = {};
      ListadoMaquinas.map((maq) =>{
        if(!initialStateCheckbox[maq.nombre_tipo_maquina]){
          initialStateCheckbox[maq.nombre_tipo_maquina] = esVerdadero;
        }
      })
      return initialStateCheckbox
    }

    useEffect(() => {
      let inicial = setInitialStateCheckbox();
        setestadoCheckboxs(inicial)
    }, [ListadoMaquinas])
    
    const agruparMaquinas = () =>{
      let objMaquinas = {};
      let initialStateCheckbox = {};
      ListadoMaquinas.map((maq) =>{
        if(!objMaquinas[maq.tipo]){
          objMaquinas[maq.tipo] = []
          objMaquinas[maq.tipo].push(maq);
          initialStateCheckbox[maq.tipo] = false;
        }else{
          objMaquinas[maq.tipo].push(maq);
        }
      })
      return objMaquinas
    }
    const handleChange = (event) => {
      const { target: { value }} = event;
      setMultiplesEquipos(typeof value === 'string' ? value.split(',') : value,); 
      formik.setFieldValue("maquinas",typeof value === 'string' ? value.split(',') : value)
    };

    const onHandleChangeCheckbox = (event, checked, key) =>{
      
      if(checked){
        if(key == "todos"){
          setselectEquiposDesactivados(true);
          setMultiplesEquipos([]);
          formik.setFieldValue("maquinas", ListadoMaquinas);
          setestadoCheckboxs(setInitialStateCheckbox(true));
        }else{
          let oldArrayMaquinas = formik.values.maquinas;
          formik.setFieldValue("maquinas", [...oldArrayMaquinas, ...agruparMaquinas()[key]]);
          setestadoCheckboxs({...estadoCheckboxs, [key]:true})
        }
      }else{
        if(key == "todos"){
          setselectEquiposDesactivados(false);
          formik.setFieldValue("maquinas", []);
          setestadoCheckboxs(setInitialStateCheckbox(false))
        }else{
          let maquinasFiltradas = formik.values["maquinas"].filter(maq => maq.nombre_tipo_maquina != key);
          formik.setFieldValue("maquinas", maquinasFiltradas);
          setestadoCheckboxs({...estadoCheckboxs, [key]:false});
        }
      }
    }

  return (
    <>
      <Dialog open={openCustom} onClose={() => setOpenCustom(false)}>
        <DialogContent>
          <Grid container item xs direction="row" spacing={0}>
            <Paper elevation={0} style={paperStyle}>
              <form onSubmit={formik.handleSubmit}>
                <Grid>

                  <Box sx={{ minWidth: 120 }} style={{ marginTop: "15px" }}>
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-label">
                        {t("equipo")}
                      </InputLabel>
                      <Select
                        name="id_maquina"
                        onBlur={formik.handleBlur}
                        defaultValue={0}
                        labelId="razon-select-label"
                        id="razon-select"
                        value={multiplesEquipos}
                        label="Seleccione una opción"
                        multiple
                        displayEmpty
                        disabled={selectEquiposDesactivados}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <> {t("seleccione_opcion")}</>;
                          }
                          // Obtén los nombres de los objetos seleccionados
                          const selectedNames = selected.map((item) => item.nombre);
                          return selectedNames.join(', ');
                        }}
                        onChange={(e) =>{
                            handleChange(e)
                            //formik.setFieldValue("id_maquina", e.target.value)
                          }
                        }
                      >
                        <MenuItem disabled value="">
                          <em> {t("seleccione_opcion")}</em>
                        </MenuItem>

                        {ListadoMaquinas.map((maquina) => (
                          <MenuItem key={maquina.id} value={maquina}>
                            <Checkbox 
                              checked = {multiplesEquipos.indexOf(maquina) > -1}
                              // color='primary'
                              style={{color : "#2f4554"}} 
                            />
                            <ListItemText primary={maquina.nombre} />
                            {/* {maquina.nombre} */}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Grid container justifyContent='flex-start'>
                    <Grid item xs={4}>
                      <Box sx={{ minWidth: 120 }} style={{ marginTop: "15px" }}>
                        <FormControlLabel control={<Checkbox style={{ color: "#2f4554" }} onChange={(e, checked) => onHandleChangeCheckbox(e, checked, "todos")} />} label="Todos" />
                      </Box>
                    </Grid>

                    {Object.entries(agruparMaquinas()).map(([key, value]) => (
                      <Grid item xs={4} key={key}>
                        <Box sx={{ minWidth: 120 }} style={{ marginTop: "15px", display: 'flex', alignItems: 'center' }}>
                          <FormControlLabel
                            control={
                             <Checkbox style={{ color: "#2f4554" }} 
                              onChange={(e, checked) => onHandleChangeCheckbox(e, checked, key)} 
                              checked={estadoCheckboxs[key]}
                              disabled={selectEquiposDesactivados}
                             />
                            }
                            label={t(key.toLowerCase()+"s")}
                          />
                        </Box>
                      </Grid>
                    ))}
                </Grid>                  

                  <TextField
                    id="datetime-local-desde"
                    name="fecha_inicio"
                    onBlur={formik.handleBlur}
                    label={t("fecha_inicio")}
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      formik.setFieldValue("fecha_inicio",e.target.value.toString())
                    }
                    value={formik.values.fecha_inicio}
                    style={{ marginTop: "15px" }}
                    fullWidth
                  />
                  <br />

                  <TextField
                    id="datetime-local-hasta"
                    name="fecha_termino"
                    onBlur={formik.handleBlur}
                    label={t("fecha_fin")}
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "fecha_termino",
                        e.target.value.toString()
                      )
                    }
                    value={formik.values.fecha_termino}
                    style={{ marginTop: "25px" }}
                    fullWidth
                  />
                  <br />

                  <Box sx={{ minWidth: 120}} style={{ marginTop: "25px" }}>
                    <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                        {t("tipo_alarma")}
                      </InputLabel>
                    <Select
                        style={{height:'40px'}}
                        name='id_movimiento'
                        defaultValue={0}
                        labelId="id_movimiento_label"
                        id="id_movimiento"
                        value={formik.values.id_movimiento}
                        label="Seleccione una opción"
                        onChange={(e) =>{
                          formik.setFieldValue("id_movimiento", e.target.value);
                        }}
                    >
                      <MenuItem key={0} value={0}>{t("seleccione_opcion")}</MenuItem>
                      {
                        arregloMovimientos.map(mov =>(
                          <MenuItem key={mov.id} value={mov.id}>{mov.nombre}</MenuItem>
                        ))
                      }
                    </Select>
                    </FormControl>
                  </Box> 

                  <Box sx={{ minWidth: 120 }} style={{ marginTop: "25px" }}>
                    <TextField
                        onBlur={formik.handleBlur}
                        name="ubicacion"
                        type="text"
                        value={formik.values.ubicacion}
                        onChange={formik.handleChange}
                        label={t('ubicacion')}
                        placeholder={t('ingrese_ubicacion')}
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={2}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                  </Box>

                  <Box sx={{ minWidth: 120 }} style={{ marginTop: "25px" }}>
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-label">
                        {t("motivo")}
                      </InputLabel>
                      <Select
                        disabled={FormJustificados}
                        name="id_grupo_tipo_justificacion"
                        onBlur={formik.handleBlur}
                        defaultValue={0}
                        labelId="id_grupo_tipo_justificacion"
                        id="id_grupo_tipo_justificacion"
                        value={formik.values.id_grupo_tipo_justificacion}
                        label="Seleccione una opción"
                        onChange={(e) =>{
                          formik.setFieldValue('id_tipo_justificacion',0);
                          formik.setFieldValue(
                            "id_grupo_tipo_justificacion",
                            e.target.value
                          )}
                        }
                      >
                        <MenuItem key={0} value={0}>
                         {t("seleccione_opcion")}
                        </MenuItem>
                        {gruposJustificaciones.map((grupoMotivo) => (
                          <MenuItem key={grupoMotivo.id} value={grupoMotivo.id}>
                            {grupoMotivo.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box sx={{ minWidth: 120 }} style={{ marginTop: "25px", display:!formik.values.id_grupo_tipo_justificacion ? 'none' : '' }}>
                    <FormControl fullWidth>
                      <InputLabel shrink={true} id="demo-simple-select-label">
                        {t("motivo_detalle")}
                      </InputLabel>
                      <Select
                        disabled={FormJustificados}
                        name="id_tipo_justificacion"
                        onBlur={formik.handleBlur}
                        defaultValue={0}
                        labelId="razon-select-label"
                        id="razon-select"
                        value={formik.values.id_tipo_justificacion}
                        label="Seleccione una opción"
                        onChange={(e) =>
                          formik.setFieldValue(
                            "id_tipo_justificacion",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem key={0} value={0}>
                         {t("seleccione_opcion")}
                        </MenuItem>
                        {ListadoMotivos.map((motivo) => (
                          <MenuItem key={motivo.id} value={motivo.id}>
                            {motivo.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <br />
                  {/* Estos son los ids de las opciones 'otros' */}
                  {[7 ,17 , 27 ,32, 40, 43 , 49].includes(formik.values.id_tipo_justificacion) && (
                    <TextField
                      disabled={FormJustificados}
                      onBlur={formik.handleBlur}
                      name="observacion"
                      type="text"
                      value={formik.values.observacion}
                      onChange={formik.handleChange}
                      label="Otro motivo"
                      fullWidth
                      multiline
                      minRows={1}
                      maxRows={4}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}

                  <Button
                    onClick={handleErrorForm}
                    style={{ marginTop: "35px", display: FormJustificados ? 'none' : '' }}
                    type="submit"
                    variant="contained"
                    /* style={btnstyle} */
                    fullWidth
                    disabled={formik.isSubmitting}
                    className={classes.root}
                  >
                    {t("registrar")}
                  </Button>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => {
            formik.resetForm();
            formik.setFieldValue("maquinas",[]);
            setMultiplesEquipos([]);
            setestadoCheckboxs(setInitialStateCheckbox(false));
            setselectEquiposDesactivados(false);
            setOpenCustom(false);
            }}>{t("cancelar")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})
