export const getResumenMensualAreaOp = async (id, month, year) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: id,
      month: month,
      year: year
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/resumen-mensual-area-operacion",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getResumenMensualMaquina = async (idMina, idMaquina,month,year) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: idMina,
      id_maquina: idMaquina,
      month: month,
      year: year
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/resumen-mensual-maquina",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getKpisMaquina = async (idMina, idMaquina,month,year) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: idMina,
      id_maquina: idMaquina,
      month: month,
      year: year
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/maquina/get-kpi",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getDescargarCargasScoop = async (id_area_operacion, fecha_inicio, fecha_fin) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion,
      fecha_inicio,
      fecha_fin
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/produccion/cargas",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

