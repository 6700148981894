import * as React from 'react';
import { 
  DataGrid, 
} from '@mui/x-data-grid';
import "moment/locale/es";
import { esES } from '@mui/material/locale';
import { createTheme} from '@mui/material/styles';
import './paginationStyles.css';
import { makeStyles } from "@material-ui/core/styles";
const theme = createTheme(
  esES
);
var _ = require("lodash");


const defaultCols = [
  {
    field: "id",
    align: "center",
    headerAlign: 'center',
    headerName: "Nombre",
    width: 100,
    headerClassName:"cell-font",
    hide: true
  },
  {
    field: "nombre",
    align: "center",
    headerAlign: 'center',
    headerName: "Nombre",
    width: 100,
    headerClassName:"border-right-header cell-font",
  },
  {
    field: "horaIngreso",
    headerName: "Hora Ingreso",
    align: "center",
    headerAlign: 'center',
    flex:1,
    valueFormatter: params => params?.value.toFixed(0),       
    cellClassName: "cell-total",
    headerClassName:"border-right-header cell-font",
  },
  {
    field: "horSalida",
    headerName: "Hora Salida",
    align: "center",
    headerAlign: 'center',
    flex:1,
    cellClassName: "cell-total",
    headerClassName:"border-right-header cell-font",
    valueFormatter: params => params?.value.toFixed(0),       
  },
  {
    field: "estadoActual",
    headerName: "Estado Actual",
    align: "center",
    headerAlign: 'center',
    flex:1,
    cellClassName: "cell-total",
    headerClassName: "border-right-header cell-font",
    valueFormatter: params => params?.value.toFixed(2),        
  }
]

export default function TablaPersonas({data,cols,headCols}) {

  return (
      <DataGrid
        sx={{
          '& .MuiDataGrid-columnHeader': {
            backgroundColor:"#465f70",
            color: "#fff",
          },
          '& .MuiDataGrid-columnHeaderTitle':{
            fontWeight:"200"
          },
          '& .MuiDataGrid-iconSeparator':{
            color: "transparent"
          },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(2n)": { 
              backgroundColor: "rgba(47, 69, 84, .07)"
            },
            "&:hover": {backgroundColor: "rgb(0, 183, 196, 0.15)"}
          },
          "& .MuiDataGrid-cell":{
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":{
            borderBottom: "none"
          },
          "& .MuiDataGrid-columnHeaders":{
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0"
          }
        }}
        hideFooter
        rows={[]}
        columns={defaultCols}
        disableSelectionOnClick
        density={"compact"}
        autoHeight
      />
  );
}
