import React, { useEffect, useState } from "react";
import moment from "moment";
import { Calendar, momentLocalizer  } from 'react-big-calendar';
import NavBar from "../../components/organisms/NavBar.js";
import { Breadcrumbs, Container, Typography, Link } from "@material-ui/core";
import { Grid, CardMedia, Card, CardContent } from "@mui/material";
import ImagenEquipo from "../Produccion/maquina.png";
import imgCamionDumper from "../Produccion/camion-dumper.png";
import { useHistory, useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import PantallaCarga from "../../components/molecules/PantallaCarga.js";
import imgCamion from "../Produccion/camion.png";
import {getTiemposAsarco, getTurnosMina} from "../../services/general";
import StackedChart from "../Produccion/StackedChart";
import SectionHeader from "../../components/molecules/SectionHeader.js";
import IconoProdTotal from '../../assets/icons/icono-prod-total.png';
import IconoKpi from '../../assets/icons/icono-kpi.png';
import IconoVueltas from '../../assets/icons/icono-vueltas.png';
import IconoTonelaje from '../../assets/icons/icono-tonelaje.png';
import IconoRendimiento from '../../assets/icons/icono-rendimiento.png';
import ProdKpiCard from "../../components/organisms/ProdKpiCard.js";
import PercentageKpiCard from "../../components/organisms/PercentageKpiCard.js";
import { separarPorMiles } from "../../commons/Formatos";
import InfoTooltip from "../../components/molecules/InfoTooltip.js";
import { format } from "date-fns";
import { getKpisMaquina, getResumenMensualMaquina } from "../../services/produccion";
import '../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from "../../commons/Idiomas";
import { usePageTimingContext } from "../../context/PageTimingContext.js";

const localizer = momentLocalizer(moment);

const useStyles = makeStyles({
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  icon:{   
    color: "#2f4554",
    cursor: "pointer",
    "&:hover": {
      color: "#00b7c4"
    }
  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },

    "& .MuiInputBase-root": {

      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main"
      }
    },

    '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
      /* '& fieldset': {            // - The <fieldset> inside the Input-root
          borderColor: 'pink',   // - Set the Input border
      }, */
      '&:hover fieldset': {
          borderColor: "#00B7C4", // - Set the Input border when parent has :hover
      }/* ,
      '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
          borderColor: 'green',
      }, */
  },

  }
});



const Equipo = () => {
  let history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { sendTime } = usePageTimingContext();

  const maqVacia = {
    horometros: {},
    modelo: "",
    nombre: "",
    tipo: "",
    vueltas: {}
  };
  const [loading, setLoading] = useState(false);
  const [fecha, setFecha] = useState(new Date());
  const [eventos, setEventos] = useState([]);
  const [idMaquina, setIdMaquina] = useState(0);
  const [idMina, setIdMina] = useState(0);
  const [nombreMina, setNombreMina] = useState("");
  const [imagenCamion, setImagenCamion] = useState(imgCamion);
  const [maquina, setMaquina] = useState(maqVacia);
  const [listaTurnos, setListaTurnos] = useState([]);
  const [seriesPorTurno, setSeriesPorTurno] = useState({});
  const [kpiProd, setKpiProd] = useState({vueltas:{},tonelaje:{},rendimiento:{}});
  const [efectivosPorTurno, setEfectivosPorTurno] = useState({turnotodos:0})
  const [kpiPorcentajes, setKpiPorcentajes] = useState({
    disponibilidad: {},
    factor_operacional: {},
    utilidad_efectiva: {},
    eficiencia_global: {}
  })
  const formats = {
    weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
  }

  const getAsarco = (idMina, idMaq, t) => {
    const mes = localStorage.getItem('mes');
    const inicio = `${format(new Date(mes), "yyyy-MM-")}01`
    const fin = `${format(new Date(mes), "yyyy-MM-")}${getUltimoDiaAcumulado(new Date(mes))}`
    const resAsarco = getTiemposAsarco(idMina, idMaq, inicio, fin)
    resAsarco.then(({success,data}) => { 
      if(success){
        const auxTiemposPorTurno = t.reduce((obj, item) => ((obj[item.nombre] = {efectivo:0}, obj)),{turnotodos:{efectivo:0}});
           
        Object.values(data).forEach( turnoPorDia=> {
          Object.entries(turnoPorDia).forEach(([key,value]) =>{
            const valEfectivo = value.efectivo?value.efectivo:0
            auxTiemposPorTurno[key].efectivo += valEfectivo
            auxTiemposPorTurno["turnotodos"].efectivo += valEfectivo
          })
        }) 

        setEfectivosPorTurno(auxTiemposPorTurno)    
      } 
    })
  }

  const getTurnos = (id,maq,idMaq) =>{
    getTurnosMina(id)
    .then(({success, data}) => {
      if(success){
        setListaTurnos(data);
        procesarSeriesPorTurno(maq,data)
        getAsarco(id,idMaq,data)
      }
    })
  }

  const procesarSeriesPorTurno = (maq,turnos) => {
    if(turnos.length>0){
      let objVueltasTurnos = {}
      turnos.forEach((turno)=>{
        objVueltasTurnos[turno.nombre] = {vueltas:{},toneladas:maq.toneladas,nombre:`turno_${turno.nombre.toLowerCase()}`}
        Object.keys(maq.vueltas).forEach((keyFecha) => {
          const auxTurno = {}
          auxTurno[turno.nombre] = {total_toneladas: maq["vueltas"][keyFecha][turno.nombre]["total_toneladas"]}
          objVueltasTurnos[turno.nombre]["vueltas"][keyFecha] = auxTurno
        })
      })
      setSeriesPorTurno(objVueltasTurnos)
    }
  }

  useEffect(()=>{
    if(listaTurnos.length>0){
      if((maquina!==maqVacia) && (Object.values(efectivosPorTurno).length>1)){
        let objTurnos = listaTurnos.reduce((obj, item) => ((obj[item.nombre] = 0, obj)),{})
        objTurnos["turnotodos"] = 0;
        let objKpis = {vueltas:{...objTurnos},tonelaje:{...objTurnos},rendimiento:{...objTurnos}}
        listaTurnos.forEach((turno) => {

          Object.entries(maquina.vueltas).forEach(([key,value])=>{  
            let nVueltas = value[turno.nombre]["total_vueltas"];
            let nTon = value[turno.nombre]["total_toneladas"];
            objKpis["vueltas"][turno.nombre] += nVueltas
            objKpis["tonelaje"][turno.nombre] += nTon
            objKpis["rendimiento"][turno.nombre] += (efectivosPorTurno[turno.nombre].efectivo>0)?nTon/efectivosPorTurno[turno.nombre].efectivo:0
            objKpis["vueltas"]["turnotodos"] += nVueltas
            objKpis["tonelaje"]["turnotodos"] += nTon
            objKpis["rendimiento"]["turnotodos"] += 0

          }) 

          listaTurnos.forEach( t => {
            objKpis["rendimiento"][t.nombre] += (efectivosPorTurno[t.nombre].efectivo>0)?objKpis["tonelaje"][t.nombre]/efectivosPorTurno[t.nombre].efectivo:0
          })

          objKpis["rendimiento"]["turnotodos"] += (efectivosPorTurno["turnotodos"].efectivo>0)?objKpis["tonelaje"]["turnotodos"]/efectivosPorTurno["turnotodos"].efectivo:0

        })

        setKpiProd(objKpis)
      }
    }
  },[listaTurnos, maquina, efectivosPorTurno])



  const getUltimoDiaAcumulado = (fecha) => {
    const auxFecha = new Date();
    if((auxFecha.getMonth() === fecha.getMonth()) && (auxFecha.getFullYear() === fecha.getFullYear())){
      return auxFecha.getDate();
    }
    else{
      return (new Date(fecha.getFullYear(),fecha.getMonth()+1,0)).getDate();
    }
  };

  const procesarEventosCalendario = (data, idMaq) => {
    if(data){
      let events = [];
      let cont = 0;
      for (let dia in data.vueltas) {
        let vueltas = 0;
        let tons = 0;
        let minutos = 0;
        Object.values(data.vueltas[dia]).forEach((valVuelta) => {
          vueltas+=valVuelta["total_vueltas"]
          tons+=valVuelta["total_toneladas"]
          minutos+=valVuelta["total_minutos_vueltas"]
        })
        if( Date.parse(dia) <= (new Date()) ){
          let resourceData = {
            idMaquina: idMaq,
            nombre: data.nombre,
            modelo: data.modelo,
            tipo: data.tipo,
            contadorVueltas: vueltas,
            contadorTonelaje: tons,//tonelajes,
            contadorHoras: minutos,
            vueltas: data.vueltas[dia],
            //horometros: maquina.horometros[dia],
            fecha: dia,
            toneladas: data.toneladas
          };
          let evento = {
            id: cont,
            title: 
              <div 
                title = {`Nro vueltas ${vueltas} \n ${"Toneladas"} ${(separarPorMiles(tons))}`}
              >
                <div style={{display: "flex",  width: "100%"}}>
                  <div style={{width:"65%"}}>
                    {t("vueltas")}
                  </div>
                  <div>
                    {String(vueltas)}
                  </div>
                </div>
                <div style={{display: "flex",  width: "100%"}}>
                  <div style={{width:"65%"}}>
                    {t("toneladas")}
                  </div>
                  <div>
                    {separarPorMiles(tons)}
                  </div>
                </div>
                <br/>
              </div>,
            start: dia,
            end: dia,
            resource: resourceData
  
          }
          events.push(evento);
          cont+=1;
        }
      }
      setEventos(events);
    }
  }

  // RENDERIZADO INICIO
  useEffect(()=>{
    setLoading(true);
    const idMaquina = localStorage.getItem('idMaquina');
    const idMina = localStorage.getItem("idMina");
    const nombreMina = localStorage.getItem("nombreMina");
    setNombreMina(nombreMina);
    setIdMaquina(idMaquina);
    setIdMina(idMina);

    let fechaLocal = new Date()
    if(localStorage.getItem('mes')){
      fechaLocal  = new Date(localStorage.getItem('mes'));
    }
    else{
      localStorage.setItem('mes', new Date());
    }
    setFecha(fechaLocal);

    const month = fechaLocal.getMonth() + 1;
    const year = fechaLocal.getFullYear();

    getResumenMensualMaquina(idMina,idMaquina,month,year).then(({success, data}) => {
      if(success){
        getTurnos(idMina,data,idMaquina);
        setMaquina(data);   
        procesarEventosCalendario(data,idMaquina)
        setLoading(false);
      }
      else {console.log("Error inesperado")};
    })

    getKpisMaquina(idMina,idMaquina,month,year).then(({success, data}) => {
      if(success) setKpiPorcentajes(data);
    })

  },[]);

  // IMAGEN MAQUINA
  useEffect(() => {
    if(idMina === "4"){
      setImagenCamion(imgCamionDumper);
    }

  }, [idMina]);

  useEffect(() => {
    return () => {
      sendTime(location.pathname)
    }
  }, [])

  // SELECCIONAR CALENDARIO
  const onClickDia = (data) => {

    localStorage.setItem("datosDia", JSON.stringify(data.resource));
    history.push("funcionamientodiario");
    
  };

  // CAMBIAR FECHA
  const onChangeFecha = (fecha) => {
    setLoading(true);
    localStorage.setItem('mes', fecha);
    setFecha(fecha);
    const month = fecha.getMonth() + 1;
    const year = fecha.getFullYear();

    getResumenMensualMaquina(idMina,idMaquina,month,year).then(({success, data}) => {
      if(success){
        setMaquina(data);   
        procesarEventosCalendario(data, idMaquina)
        procesarSeriesPorTurno(data,listaTurnos)
        setLoading(false);
      }
      else {console.log("Error inesperado")};
    })

    getKpisMaquina(idMina,idMaquina,month,year).then(({success, data}) => {
      if(success) setKpiPorcentajes(data);
    })

  };

 /*  const recargarConDatosActuales = () => {
    const mes = new Date();
    localStorage.setItem("mes", mes);
    setFecha(mes);
    updateDatosMina(idEmpresa, mes);
  }; */

  return(
    <>      
      <NavBar />
      <Container>
        <br/>
        <Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px"}}
            color="inherit"
            href="/"
          >
            Mine-Watch
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px"}}
            color="inherit"
            href={"produccion/" + nombreMina.toLocaleLowerCase().replace(/ /g,'')}
          >
            {"Mina " + nombreMina}
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            style={{fontSize:"14.4px", color:"#00b7c4"}}
            color="inherit"
          >
            {maquina.nombre}
          </Link>
        </Breadcrumbs>
        <br/>
        <br/>

        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
          > 

              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <CardMedia
                      component="img"
                      image={maquina.tipo==="Camion"?imagenCamion:maquina.tipo==="LHD"?ImagenEquipo:""}
                      alt="Paella dish"
                      style={{ width: maquina.tipo==="Camion"?"80px":"120px", marginRight: "20px" }}
                    />
                  </Grid>

                  <Grid item>
                  <Grid 
                  container
                  direction="column"
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "#00B7C4"
                      }}
                    >
                      {/* nombreMina */}
                      {/* Equipo */}{maquina.nombre}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14.4px",
                      }}
                    >
                      {(i18n.language === 'en')?
                        (`${fecha.toLocaleString('en-EN', { month: 'long', year:'numeric'})}`)
                      :
                        ("Mostrando " + String(fecha.toLocaleString('default', { month: 'long' })) + " del " + String(fecha.getFullYear()))
                      }
                    </Typography>
                  </Grid>
                </Grid>
                  </Grid>
                </Grid>


              
              </Grid>
              <Grid item lg={8} md={8} >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        /* okLabel = "Confirmar" */
                        cancelLabel={t("cancelar")}
                        className={classes.datepicker}
                        size="small"
                        inputVariant="outlined"
                        openTo="month"
                        minDate={new Date('2021-11-01 00:00:00')}
                        maxDate={(new Date().setHours(23,59,59))}
                        views={["year", "month"]}
                        value={fecha}
                        onChange={(newValue) => {
                          onChangeFecha(newValue);
                        }}     
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  {/* <Grid item>
                    <Button className={classes.buttonSelected}>Descargar</Button>
                  </Grid> */}
                </Grid>

              </Grid>
            {/* </Grid>
          </Grid>  */}  

        </Grid>

        <br/>

        <Card
          className="main-card mb-3"
          style={{ width: "-webkit-fill-available"}}
          sx={{padding:"30px 20px 20px 20px"}}
        >
          <CardContent>
            <SectionHeader
              title={t("kpis_de_prod_a_la_fecha")}
              subtitle={""}
              icon={<img src={IconoKpi} width="35px" alt=" Icono Resumen Diario"/>}
            />
            <br/>
            <br/>
            <Grid container sx={{backgroundColor:"#2f4554", borderRadius:"2px"}}>
              <Grid item lg={4} md={12} xs={12} sx={{borderRight:"1px solid #465f70"}}>
                <ProdKpiCard 
                  titulo={t("vueltas")}
                  subtitulo={`(${t("cantidad")})`}
                  dataTurnos={kpiProd.vueltas}
                  isSelect = {true}
                  icono={<img src={IconoVueltas} width="18px" alt=" icono vueltas"/>}
                />               
              </Grid>
              <Grid item lg={4} md={12} xs={12} sx={{borderRight:"1px solid #465f70"}}>
                <ProdKpiCard 
                  titulo={t("tonelaje")}
                  subtitulo="(T)" 
                  dataTurnos={kpiProd.tonelaje}
                  isSelect = {true}
                  icono={<img src={IconoTonelaje} width="18px" alt=" icono vueltas"/>}
                />    
              </Grid>
              <Grid item lg={4} md={12} xs={12}>
                <ProdKpiCard 
                  titulo={t("rendimiento")}
                  subtitulo="(T/h)" 
                  dataTurnos={kpiProd.rendimiento}
                  isSelect = {true}
                  icono={<img src={IconoRendimiento} width="23px" alt=" icono vueltas"/>}
                />    
              </Grid>
            </Grid>


            <br/>
            <br/>

            <Grid container spacing={1}>
              <Grid item lg={3} md={6} xs={12}>
                <PercentageKpiCard 
                  titulo={t("disponibilidad")}
                  subtitulo={t("promedio")} 
                  dataTurnos={kpiPorcentajes.disponibilidad}
                  tooltipMessage = {<div>{t("tiempo_disponible")}<hr/>{t("tiempo_nominal")}</div>}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <PercentageKpiCard 
                  titulo={t("factor_operacional")}
                  subtitulo={t("promedio")} 
                  dataTurnos={kpiPorcentajes.factor_operacional}
                  tooltipMessage = {<div>{t("tiempo_efectivo")}<hr/>{t("tiempo_operativo")}</div>}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <PercentageKpiCard 
                  titulo={t("utilidad_efectiva")}
                  subtitulo={t("promedio")} 
                  dataTurnos={kpiPorcentajes.utilidad_efectiva}
                  tooltipMessage = {<div>{t("tiempo_efectivo")}<hr/>{t("tiempo_disponible")}</div>}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <PercentageKpiCard 
                  titulo={t("eficiencia_global")}
                  subtitulo={t("promedio")} 
                  dataTurnos={kpiPorcentajes.eficiencia_global}
                  tooltipMessage = {<div>{t("tiempo_efectivo")}<hr/>{t("tiempo_nominal")}</div>}
                />
              </Grid>
            </Grid> 

            <br/>
            <br/>
            <br/>

            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item lg = {10} md={10} xs={10}>
                <SectionHeader
                  title={t("prod_por_turnos_a_la_fecha")}
                  subtitle={""}
                  icon={<img src={IconoProdTotal} width="35px" alt=" Icono Resumen Diario"/>}
                />
              </Grid>
              <Grid item>
                <InfoTooltip>
                  {t("info_grafico_produccion_acumulada")}
                </InfoTooltip>
              </Grid>
            </Grid>

            <br/>
            <br/>

            <Grid container justifyContent="space-between" alignItems="center" sx={{padding:"0px 30px 0px 20px"}}>
              <Grid item >
                <span style={{fontWeight:"bold",color: "#2f4554", fontSize: "12px"}}>
                  {`${t("produccion")} (${t("tonelaje")})`}
                </span>
              </Grid>
              <Grid item>
                <span style={{fontWeight:"bold",color: "#ff6600", fontSize: "12px"}}>
                  {`${t("produccion_acumulada")} (${t("tonelaje")})`}
                </span>
              </Grid>
            </Grid>
            <StackedChart data={seriesPorTurno}/>

            <br/>
            <br/>

            <Calendar
              formats={formats}
              selectable={true}
              toolbar={false}
              localizer={localizer}
              events={eventos}
              startAccessor="start"
              endAccessor="end"
              date={fecha}
              onSelectEvent={(dato)=>onClickDia(dato)}
              style = {{height:"1000px"}}
              // scrollToTime
              culture={i18n.language}
            />
          </CardContent>
        </Card>  

        <br/>

        <PantallaCarga loading={loading}/>
      </Container>
    </>

  )
};

export default Equipo;