export const getVueltasMaquinaRango = async (idMina,idMaquina,inicio,fin) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: idMina,
      id_maquina: idMaquina,
      fecha_inicial: inicio,
      fecha_final: fin
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/get-vueltas-maquina",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getEncuentrosMovMaquina = async (idMaquina, fechaSeleccionada) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_maquina: idMaquina,
      fecha: fechaSeleccionada
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const res = await fetch(
        'https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dev-get-movimiento-maquina',
        requestOptions
    );

    if (!res.ok) throw new Error(res.text);
    const json = await res.json();
    return { success: true, data: json };
  } catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
};

export const getMovimientosPorMaquina = async (idMina,idMaquina,inicio,fin) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: idMina,
      id_maquina: idMaquina,
      fecha_inicial: inicio,
      fecha_final: fin
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/maquina/get-movimientos",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}
