import React, { useRef, useState } from 'react'

import {
    Box,
    Button,
    Grid,
    makeStyles,
  } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useGetTiposDeGeocerca } from '../../../hooks/administracion/gps/useGetTiposDeGeocerca';
import { TablaTiposGeocercas } from './TablaTiposGeocercas';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { TiposGeocercasForm } from '../Formularios/TiposGeocercasForm';

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "px 0",
      textAlign:'center',
      color: "white",
      "&:hover": {
        backgroundColor: "rgb(0, 183, 196)"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    }
  });

export const AdministracionTipoGeocercas = ({ id_area_operacion, nombreMina }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const location = useLocation();
    const refForm = useRef(null);
    const {loadingTiposGeocerca,tiposDeGeocerca} = useGetTiposDeGeocerca(id_area_operacion);

    const [FormActions, setFormActions] = useState({
        isAdding:false,
        isEditting:false,
        isEdittingRowSelected:false
      });

      let editInitialState={
        id:0,
        id_area_operacion: id_area_operacion,
        nombreMina: nombreMina,
        nombre:'',
        editing:false,
      };
      const [dataFormEditTipoGeocerca, setDataFormEditTipoGeocerca] = useState(editInitialState);

      const onHandleAddNewCiclo = () =>{
        setDataFormEditTipoGeocerca(editInitialState);
        setFormActions({isEditting:false,isAdding:true, isEdittingRowSelected:false});
      }
    
      const onHandleEditNewCiclo = () =>{
        setDataFormEditTipoGeocerca(editInitialState);
        setFormActions({isEditting:true,isAdding:false,isEdittingRowSelected:false})
      }
    
      const onHandleEditNewCicloRowSelected= () =>{
        setFormActions({...FormActions,isEdittingRowSelected:true})
      }
    
      const onHandleCancelOperations = () =>{
        setDataFormEditTipoGeocerca(editInitialState);
        setFormActions({isEditting:false,isAdding:false,isEdittingRowSelected:false});
      }

      const onHandleSetFormikEditValues= (dataRow) =>{
        setDataFormEditTipoGeocerca({...dataFormEditTipoGeocerca,
          id:dataRow.id,
          nombre:dataRow.nombre,
          editing:true
        })
  
    }
  return (
    <div>

    <Grid container justifyContent='space-between'>
        <Grid container direction='row' wrap='nowrap' alignItems='center' justifyContent='flex-start' style={{marginBottom:'15px'}}>
          <Box sx={{ minWidth: '240px' }}>
                <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    className={classes.root}
                    disabled={FormActions.isEditting}
                    onClick={() =>{
                      onHandleEditNewCiclo()
                    }
                    }
                    >
                    <Grid container alignItems="center" spacing={7}>
                        <Grid item>
                            <BorderColorIcon style={{ color: 'white', display:'block', margin:'auto' }} />
                        </Grid>
                        <Grid item>
                            {t("editar")}
                        </Grid>
                    </Grid>
                </Button>
            </Box>
            <Box sx={{ minWidth: '240px' ,marginLeft:20}}>
                <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    className={classes.root}
                    disabled={FormActions.isAdding}
                    onClick={() =>{
                      onHandleAddNewCiclo();
                    }
                    }
                    >
                    <Grid container alignItems="center" spacing={4}>
                        <Grid item>
                            <ControlPointIcon style={{ color: 'white', display:'block', margin:'auto' }} />
                        </Grid>
                        <Grid item>
                            {t("agregar")}
                        </Grid>
                    </Grid>
                </Button>
            </Box>
        </Grid>
      </Grid>

        <TablaTiposGeocercas 
            id_area_operacion={id_area_operacion}
            isEditing={FormActions.isEditting}
            nombreMina={nombreMina} 
            onHandleCancelOperations={onHandleCancelOperations}
            onHandleEditNewCicloRowSelected={onHandleEditNewCicloRowSelected}
            onHandleSetFormikEditValues={onHandleSetFormikEditValues}
            pathMina={(location.pathname.split("/"))[2]}
            refForm={refForm}
            data={tiposDeGeocerca} loading={loadingTiposGeocerca}
        />

        {
            (FormActions.isAdding || (FormActions.isEditting && FormActions.isEdittingRowSelected)) &&
            <TiposGeocercasForm 
                data={tiposDeGeocerca}
                formDataEdit={dataFormEditTipoGeocerca}
                id_area_operacion={id_area_operacion}
                isEditing={FormActions.isEditting}
                onHandleCloseForm={onHandleCancelOperations}
                nombreMina={nombreMina}
                pathMina={(location.pathname.split("/"))[2]}
                refForm={refForm}
            />
        }
    </div>
  )
}
