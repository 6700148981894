import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TablaDeCarga } from '../../../components/organisms/TablaDeCarga';
import { Box, Grid, Paper, TablePagination, Tooltip, makeStyles, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityIcon from '@mui/icons-material/Visibility';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#2f4554",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles({
    table: {
      width:"100%"
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642"
      },
      "&:disabled": {
        backgroundColor: "gray"
      }
    },
    ColumnMaxSpeed:{
      backgroundColor:'rgb(255, 165, 0)',
      borderRadius:'50px',
      color:'white',
      display:'inline-block',
      padding:'1.5px 25px'
    },
    ColumnControlAcceso:{
      backgroundColor:'rgb(0, 183, 196)',
      borderRadius:'50px',
      color:'white',
      display:'inline-block',
      padding:'1.5px 25px'
    }
  });
export const TablaGeocercas = ({loading,geocercas, setUbicacionGeoCerca, showPopupConfirmDelete, setGeocercaSeleccionada, geocercasGps=[], loadingGeoCercasVelocidad=false}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    // console.log([...geocercasGps, ...geocercas])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
      };

    if (loading || loadingGeoCercasVelocidad) {
    return (
        <>
            <TablaDeCarga columnas={["ID",t("nombre")]}/>
        </>
    )
    }

  return (
    <>
       <Grid container style={{marginBottom:'20px'}}>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">{t("nombre_geocerca")}</StyledTableCell>
                        <StyledTableCell align="center">{t("tipo_control_geocerca")}</StyledTableCell>
                        <StyledTableCell align="center">{t("velocidad_maxima")}</StyledTableCell>
                        <StyledTableCell align="center">{t("tipo_geocerca")}</StyledTableCell>
                        <StyledTableCell align="center">{t("accion")}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    (!loading && geocercas.length < 1) &&
                    <StyledTableRow>
                        <StyledTableCell colSpan={6} align='center'><b>{t("sin_info")}</b></StyledTableCell>
                    </StyledTableRow>
                }
                {
                    [...geocercas, ...geocercasGps].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((geocerca) => {

                    return (
                        <StyledTableRow key={geocerca.id}>
                            <StyledTableCell align="center" component="th" scope="row">{geocerca.nombre}</StyledTableCell>
                            <StyledTableCell align="center" component="th" scope="row">{<p className={geocerca?.velocidad_maxima ? classes.ColumnMaxSpeed : classes.ColumnControlAcceso}>{geocerca?.velocidad_maxima ? t('velocidad') : 'acceso'}</p>}</StyledTableCell>
                            <StyledTableCell align="center" component="th" scope="row">{geocerca?.controla_velocidad ? geocerca?.velocidad_maxima+" km/h" : 'no aplica'}</StyledTableCell>
                            <StyledTableCell align="center" component="th" scope="row">{!geocerca?.controla_velocidad ? geocerca?.tipo_geocerca : "no aplica"}</StyledTableCell>
                            <StyledTableCell align="center" component="th" scope="row" style={{width:'200px'}}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-evenly"
                              >
                                <Box
                                  onClick={() => {
                                    // onHandleEditVicons(true);
                                    // onHandleViewViconForm(true, false);
                                    setUbicacionGeoCerca(geocerca.coords[Math.floor(geocerca.coords.length/2)])
                                  }}
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "rgb(0, 183, 196)",
                                      borderRadius: "50%",
                                      padding: "7px",
                                    },
                                    padding: "7px",
                                  }}
                                >
                                  <Tooltip title={t("ver")}>
                                    <VisibilityIcon
                                      style={{
                                        color: "#2f4554",
                                        margin: "auto",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                                <Box
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "rgb(0, 183, 196)",
                                      borderRadius: "50%",
                                      padding: "7px",
                                    },
                                    padding: "7px",
                                  }}
                                  onClick={() => {
                                    setGeocercaSeleccionada({id:geocerca.id,nombre:geocerca.nombre})
                                    setPage(0);
                                    showPopupConfirmDelete(true)
                                  }}
                                >
                                  <Tooltip title={t("eliminar")}
                                    // onClick={()=> onHandleShowPopUpDelete(true)}
                                  >
                                    <DeleteOutlineOutlinedIcon
                                      style={{
                                        color: "#2f4554",
                                        margin: "auto",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              </Grid>
                            </StyledTableCell>
                        </StyledTableRow>
                    )
                    })
                }
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={[...geocercas, ...geocercasGps].length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t("filas_por_pagina")}
            />
            </TableContainer>
        </Grid>
    </>
  )
}
