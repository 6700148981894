import React from 'react';
import Box from '@mui/material/Box';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';



export const ComboboxGenerico = ({ name, valueSelected, label, onChange, datos = [{ id: 1, nombre: 'test',value:1 }], Icono = undefined, onblur, showTopLabel=false, error,helperText,primeraOpcion="Todos"  }) => {

  return (
    <>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: Icono ? '#f0f0f0' : '',
        height: '45px',
        borderRadius:'3px',
        width:'100%'
      }}>
        <Box>
          {Icono}
        </Box>
        {
          showTopLabel ? 
          (
          <TextField
            id="outlined-select-currency"
            select
            error={error ? true : false}
            fullWidth
            // id="input-with-sx"
            placeholder={label}
            label={label}
            name={name}
            onChange={onChange}
            value={valueSelected}
            size='small'
            onBlur={onblur}
            helperText={helperText ? helperText : ""}
            FormHelperTextProps={{sx:{position:'absolute',top:'35px'}}}
          >
            {
              datos?.map((el,index) => (
                <MenuItem value={el.id} key={el.id+"-"+index}>{el.nombre}</MenuItem>
              ))
            }
          </TextField>
          ) 
            : 
          (
            <FormControl style={{ height: '100%', width: '100%' }} variant="standard">
              <Select
                displayEmpty
                renderValue={
                  !valueSelected ? () => <div style={{textAlign:'center',marginRight:'20px'}}>{label}</div> : undefined
                }
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={valueSelected}
                onChange={onChange}
                name={name}
                disableUnderline
                MenuProps={{ disableScrollLock: true, style: { backdropFilter: 'none', height: '100%' } }}
                style={{ height: '100%' }}
                sx={{textAlign:'center'}}
              >
                <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} key={0} value="">{primeraOpcion}</MenuItem>
                {datos.map(dato => (
                  <MenuItem sx={{ display: 'flex', justifyContent: 'center' }} key={dato.id} value={dato?.value ?? dato.nombre}>{dato.nombre}</MenuItem>
                ))}
              </Select>
            </FormControl>

          )
        }
      </Box>
    </>
  );
};
