import * as React from 'react';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { HtmlTooltip } from '../atoms/StyledComponents';

export default function InfoTooltip({children}) {
  return (
      <HtmlTooltip
        enterDelay={0}
        title={
          <React.Fragment>
            <Typography color="inherit" fontSize="12px" alignItems="center" textAlign="center">
              {children}
            </Typography>
          </React.Fragment>
        }
      >
        <InfoIcon sx={{color:"#2f4554", marginRight:"30px", cursor:"help"}}/>
      </HtmlTooltip>
  );
}
