import { useEffect, useState } from 'react'
import { getAllTiposDeMaquinas } from '../../services/general';

export const useGetAllTiposDeMaquinas = () => {
    const [dataAllTiposDeMaquinas, setDataAllTiposDeMaquinas] = useState();
    const [loadingAllTiposDeMaquinas, setLoadingAllTiposDeMaquinas] = useState(false)
  
    useEffect(() => {
      getAllTiposDeMaquinas().then((resp) =>{
        setDataAllTiposDeMaquinas(resp.data);
        setLoadingAllTiposDeMaquinas(false);
      }).catch(e =>{
        console.log("error: "+e);
        setLoadingAllTiposDeMaquinas(false);
      })
    }, [])
    
    return {dataAllTiposDeMaquinas, loadingAllTiposDeMaquinas}
}
