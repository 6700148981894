import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ListItemButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { useContext } from "react";
import { GeneralMinaContext } from "../../context/GeneralMina";

const useStyles = makeStyles({
  list: {
    width: 180,
  },
  info: {
    "text-align": "center",
    "font-weight": "bold",
  },
  paper: {
    background: "#2f4554"
  }
});

const NavDrawerEmpresaAdmin = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const {onHandleGetEndpoint} = useContext(GeneralMinaContext);

  const handleClickMinas = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      anchor="left"
      open={props.drawerOpened}
      onClose={props.toggleDrawer(false)}
    >
      <div
        className={classes.list}
        /* onClick={props.toggleDrawer(false)} */
        onKeyDown={props.toggleDrawer(false)}
        style={{height:"100%", color:"white"}}
      >
        <List>
          <ListItem className={classes.info}>
            <ListItemText primary="Menú" />
          </ListItem>
        </List>
        <Divider />

      <ListItemButton onClick={handleClickMinas}>
        <ListItemText primary="Minas" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {
          onHandleGetEndpoint().map(ruta =>(
            <List key={ruta.id} component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} component={Link} to={`/produccion/${ruta.endpoint}"`}>
                <ListItemText primary={ruta.nombre_publico} />
              </ListItemButton>
            </List>
          ))
        }
      </Collapse>
        <List>
          <ListItem button component={Link} to="/encuentros">
            <ListItemText primary="Encuentros" />
          </ListItem>
        </List>
        <List>
          <ListItem button component={Link} to="/registros">
            <ListItemText primary="Registros" />
          </ListItem>
        </List>
         <List>
          <ListItem button component={Link} to="/registrarusuarioempresa">
            <ListItemText primary="Registrar Usuarios" />
          </ListItem>
        </List>
        <List>
          <ListItem button component={Link} to="/tablausuarioempresa">
            <ListItemText primary="Administrar Usuarios" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};
export default NavDrawerEmpresaAdmin;