export function toHoursAndMinutes(totalMinutesAux) {
  const totalMinutes = totalMinutesAux.toFixed(0);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function convertHoursToHoursAndMinutes(horas) {
  // Obtén la parte entera de las horas
  const horasEnteras = Math.floor(horas);

  // Calcula los minutos a partir de la parte decimal de las horas
  const minutos = Math.round((horas - horasEnteras) * 60);

  // Formatea el resultado en hh:mm
  const horaFormateada = String(horasEnteras).padStart(2, '0');
  const minutosFormateados = String(minutos).padStart(2, '0');

  return `${horaFormateada}:${minutosFormateados}`;
}


export function calcularDiferenciaHoras(fechaInicioStr, fechaFinalStr) {
  // Convertir las cadenas de fecha a objetos Date
  const fechaInicio = new Date(fechaInicioStr);
  const fechaFinal = new Date(fechaFinalStr);

  // Calcular la diferencia en milisegundos
  const diferenciaEnMilisegundos = fechaFinal - fechaInicio;

  // Convertir la diferencia a minutos si es menos de una hora
  if (diferenciaEnMilisegundos < 60 * 60 * 1000) {
    const diferenciaEnMinutos = Math.floor(diferenciaEnMilisegundos / (1000 * 60));
    return `${diferenciaEnMinutos} min/s`;
  }

  // Convertir la diferencia a horas y minutos si es mayor a una hora
  const horas = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60));
  const minutos = Math.floor((diferenciaEnMilisegundos % (1000 * 60 * 60)) / (1000 * 60));

  return `${horas} hr/s y ${minutos} min/s`;
}

export function milisegundosAHoras(milisegundos) {
  // Convertir milisegundos a horas
  const horas = milisegundos / (1000 * 60 * 60);

  // Formatear la cantidad de horas con cuatro decimales
  const horasFormateadas = horas.toFixed(4);

  return `${horasFormateadas.replace(".", ",")}`;
}


export function ConvertMinutosToHoras(minutos) {
  const horas = minutos / 60;
  return Number(horas.toFixed(4));
}

export function convertirSegundosToHorasMinutosSegundos(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hora " : " horas ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minuto " : " minutos ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " segundo" : " segundos") : "";
  return hDisplay + mDisplay + sDisplay;
}

export function secondsToHours(seconds) {
  // Convierte segundos a horas
  let hours = seconds / 3600;
  // Redondea a un máximo de tres decimales
  hours = Math.round(hours * 1000) / 1000;
  return hours;
}

export function calcularDiferenciaDeDias(fechaInicio, fechaFin) {
  // Convertir ambas fechas a objetos Date
  const dateInicio = new Date(fechaInicio);
  const dateFin = new Date(fechaFin);

  // Calcular la diferencia en milisegundos
  const diferenciaEnMilisegundos = dateFin - dateInicio;

  // Calcular la diferencia en días
  const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));

  return diferenciaEnDias;
}

export function getCurrentDatetime(diffSegundos){
  const adjustedDate = (Math.floor(new Date().getTime() / 1000) + diffSegundos) * 1000;

  return new Date(adjustedDate);
}
