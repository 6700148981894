export async function getInfoMina(idAreaOperacion, signal) {
  try {
    const headers = new Headers({
      "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      "Content-Type": "application/json"
    });

    const requestParams = {
      headers: headers,
      method: "POST",
      body: JSON.stringify({
        id_area_operacion: idAreaOperacion
      }),
      signal
    };

    const response = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/area-operacion/get-subminas",
      requestParams
    );

    if (!response.ok) {
      return { success: false, data: [], error: response.statusText };
    }

    const json = await response.json();

    return { success: true, data: json, error: null };
  } catch (err) {
    return {
      success: false,
      data: [],
      error: err.message
    };
  }
}