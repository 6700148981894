import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, FormControl, Grid, MenuItem, Select, TablePagination } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { useMaquinasJustificacion } from '../../hooks/useMaquinasJustificacion';

import { getLocaleLanguage } from '../../commons/Idiomas';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import { formatearFechaISOtoUSFormat, formatearMinutos } from '../../commons/FormatearFecha';

import { TablaDeCarga } from './TablaDeCarga';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2f4554",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width:"100%"
  },
  root: {
    backgroundColor: "#2f4554",
    margin: "8px 0",
    color: "white",
    "&:hover": {
      backgroundColor: "#253642"
    },
    "&:disabled": {
      backgroundColor: "gray"
    }
  }
});

const tiposMovimientos = {
  2:"en_mantencion_justificacion",
  3:"fuera_de_servicio_justificacion",
  5:"ralenti_justificacion",
}

export const TablaEquipos = ({abrirModal, id_area_operacion, equipos, formik, recargarTabla, abrirModalCustom,abrirDetalle, setMostrarTiempoAJustificar}) => {

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [fecha, setFecha] = useState(new Date());
  
  const {data:Maquinas,loading,setMaquinaSelected,maquinaSelected,alarmaSelected,setAlarmaSelected} = useMaquinasJustificacion(fecha,id_area_operacion,recargarTabla);
  const arregloMovimientos =[
    {id:2,nombre:t("en_mantencion_justificacion")},
    {id:3,nombre:t("fuera_de_servicio_justificacion")},
    {id:5,nombre:t("ralenti_justificacion")}
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onHandleSeleccionarMaquina = (e) => {

    setPage(0);
    setMaquinaSelected(e.target.value);

  }

  const onHandleSeleccionarAlarma = (e) => {
    setPage(0);
    setAlarmaSelected(e.target.value);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onHandleClickOpenModal = (maquina,fecha_inicio,fecha_termino,id_registro_movimiento,id_movimiento) =>{
    formik.setFieldValue("id_tipo_justificacion",0);
    formik.setFieldValue("fecha_inicio",fecha_inicio);
    formik.setFieldValue("fecha_termino",fecha_termino);
    formik.setFieldValue("id_maquina",maquina);
    formik.setFieldValue("id_registro_movimiento",id_registro_movimiento);
    formik.setFieldValue("id_movimiento",id_movimiento);
    abrirModal(id_registro_movimiento,false);
  }

  if(loading){
    return (

      <>
       <TablaDeCarga columnas={[t("equipo"),t("Fecha inicio"),t("Fecha termino"),t("Duración"),t("Tipo alarma"),t("Acción")]}/>
      </>
    )
  }

  return (
    <>
    <Grid container style={{marginBottom:'20px'}}>      
      <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
        <KeyboardDatePicker
          cancelLabel={t("cancelar")}
          className={classes.datepicker}
          size="small"
          inputVariant="outlined"
          openTo="month"
          minDate={new Date('2021-11-01 00:00:00')}
          maxDate={(new Date().setHours(23,59,59))}
          views={["year", "month"]}
          value={fecha}
          onChange={(newValue) => {
            setPage(0);
            setFecha(newValue);
          }}
        />
      </MuiPickersUtilsProvider>

      <Box sx={{ minWidth: 240 ,marginLeft:20}}>
          <FormControl fullWidth>
          <Select
              style={{height:'40px'}}
              name='id_maquina'
              defaultValue={0}
              labelId="razon-select-label"
              id="razon-select"
              value={maquinaSelected}
              label="Seleccione una opción"
              onChange={(e) =>{
                onHandleSeleccionarMaquina(e);
              }}
          >
            <MenuItem key={0} value={0}>{t("todos_los_equipos")}</MenuItem>
            {
              equipos.map(equipo =>(
                <MenuItem key={equipo.id} value={equipo.id}>{equipo.nombre}</MenuItem>
              ))
            }
          </Select>
          </FormControl>
      </Box> 

      <Box sx={{ minWidth: 240 ,marginLeft:20}}>
          <FormControl fullWidth>
          <Select
              style={{height:'40px'}}
              name='id_maquina'
              defaultValue={0}
              labelId="razon-select-label"
              id="razon-select"
              value={alarmaSelected}
              label="Seleccione una opción"
              onChange={(e) =>{
                onHandleSeleccionarAlarma(e);
              }}
          >
            <MenuItem key={0} value={0}>{t("todas_las_alarmas")}</MenuItem>
            {
              arregloMovimientos.map(mov =>(
                <MenuItem key={mov.id} value={mov.id}>{mov.nombre}</MenuItem>
              ))
            }
          </Select>
          </FormControl>
      </Box> 
      <Box sx={{ minWidth: 240 ,marginLeft:20}}>
        <Button
            type="button"
            variant="contained"
            className={classes.root}
            onClick={() =>{
              formik.resetForm();
              abrirModalCustom();
            }
            }
            >
            {t("justificacion_personalizada")}
        </Button>
      </Box>

    </Grid>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">{t("equipo")}</StyledTableCell>
            <StyledTableCell align="left">{t("fecha_inicio")}</StyledTableCell>
            <StyledTableCell align="left">{t("fecha_fin")}</StyledTableCell>
            <StyledTableCell align="left">{t("duracion")}</StyledTableCell>
            <StyledTableCell align="left">{t("tipo_alarma")}</StyledTableCell>
            <StyledTableCell align="left">{t("accion")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (!loading && Maquinas.length < 1) &&
            <StyledTableRow>
              <StyledTableCell colSpan={6} align='center'><b>{t("sin_info")}</b></StyledTableCell>
            </StyledTableRow>
          }
          {
            Maquinas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((maquina) => {
            
              return (
                <StyledTableRow key={maquina.id}>
                  <StyledTableCell align="left" component="th" scope="row">{t(maquina.nombre_maquina)}</StyledTableCell>
                  <StyledTableCell align="left">{formatearFechaISOtoUSFormat(maquina.inicio_movimiento)}</StyledTableCell>
                  <StyledTableCell align="left">{formatearFechaISOtoUSFormat(maquina.fin_movimiento)}</StyledTableCell>
                  <StyledTableCell align="left">{formatearMinutos(maquina.duracion_minutos_movimiento)}</StyledTableCell>
                  <StyledTableCell align="left">{t(tiposMovimientos[maquina.id_tipo_movimiento])}</StyledTableCell>
                  <StyledTableCell align="left"> 
                    <Grid container alignItems='center' justifyContent='space-evenly'>
                      <Button
                          type="button"
                          variant="contained"
                          className={classes.root}
                          onClick={() =>{
                            onHandleClickOpenModal(
                            maquina.id_maquina,
                            formatearFechaISOtoUSFormat(maquina.inicio_movimiento),
                            formatearFechaISOtoUSFormat(maquina.fin_movimiento),
                            maquina.id,
                            maquina.id_tipo_movimiento)}
                          }
                          >
                          {t("justificar")}
                      </Button>
                      <VisibilityIcon 
                        sx={{cursor:'pointer'}}
                        onClick={() =>{

                          const fechaHoraString = maquina.dia;
                          const fechaHora = new Date(fechaHoraString);
                          const fechaFormateada = fechaHora.toISOString().split('T')[0];

                          localStorage.setItem('idMina',id_area_operacion);
                          localStorage.setItem('idMaquina',maquina.id_maquina)
                          localStorage.setItem('diaJustificacionDetalle',fechaFormateada);
                          localStorage.setItem('nombreMaquinaJustificado',t(maquina.nombre_maquina));
                          localStorage.setItem('fechaInicioJustificacionPendiente',formatearFechaISOtoUSFormat(maquina.inicio_movimiento));
                          setMostrarTiempoAJustificar(true);
                          abrirDetalle(true);
                        }}
                      />
                    </Grid>
                  </StyledTableCell>
                </StyledTableRow>
              )
            })
          }
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={Maquinas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("filas_por_pagina")}
      />
    </TableContainer>
    </>
  );
}