import React, { useContext, useEffect } from 'react';
import { GeneralChartsContext } from '../../context/GeneralCharts';
import Chart from 'react-apexcharts';
import { formatearFechaISOtoUSFormat } from '../../commons/FormatearFecha';

const BaldadasChart = ({ datos, limites }) => {
    const oneHourAsMS = 3600000;
    const fifteenMinutesToMs = 900000; // 15 minutos en ms
    const { horoumeterLimits, onHandleSetHoroumeterLimits } = useContext(GeneralChartsContext);
    const labels = [];

    useEffect(() => {
        onHandleSetHoroumeterLimits(limites?.min?.getTime(), limites?.max?.getTime());
    }, [limites?.min, limites?.max]);

    // Definir el rango de tiempo ajustado a la zona horaria local
     const startHour = horoumeterLimits?.min || limites?.min?.getTime();
     const endHour = horoumeterLimits?.max || limites?.max?.getTime();

    // Generar etiquetas solo para las horas completas (para el eje X)
    for (let time = startHour; time <= endHour; time += oneHourAsMS) {
        labels.push(new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    }

    // Inicializar baldadas agrupadas cada 15 minutos
    const baldadasCada15Min = new Array(Math.ceil((endHour - startHour) / fifteenMinutesToMs)).fill(0);

    // Agrupar los datos por intervalos de 15 minutos
    datos.forEach(({ fecha_inicio, fecha_fin }) => {
        const inicio = new Date(formatearFechaISOtoUSFormat(fecha_inicio)).getTime();
        const fin = new Date(formatearFechaISOtoUSFormat(fecha_fin)).getTime();

        // Determinar en qué intervalo de 15 minutos cae el inicio
        const intervaloInicio = Math.floor((inicio - startHour) / fifteenMinutesToMs);
        if (intervaloInicio >= 0 && intervaloInicio < baldadasCada15Min.length) {
            baldadasCada15Min[intervaloInicio] += 1; // Sumar una baldada al intervalo correspondiente
        }
    });

    const options = {
        chart: {
            type: 'area',
            toolbar: { show: false },
            zoom: { enabled: false },
        },
        stroke:{
            width:2
        },
        xaxis: {
            type: 'datetime',
            categories: Array(baldadasCada15Min.length).fill().map((_, idx) => startHour + idx * fifteenMinutesToMs),
            tickAmount: labels.length,
            min: startHour,
            max: endHour, 
            tickPlacement: 'on',
            labels: {
            datetimeUTC: false,
            format: 'HH:mm',
            style: {
                fontSize: "12px",
                fontWeight: 'normal' // Asegúrate de que este valor esté correctamente configurado
            },
        }
        },
        yaxis: {
            title: {
                text: 'Baldadas',
            },
            labels:{
                maxWidth: 180,
                minWidth:65,
                style: {
                  fontSize: "12px",
                  fontWeight: 'normal'
                },
              },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            x: {
                format: 'dd MMM HH:mm',
            }
        }
    };

    return (
        <div id="chart">
            <Chart
                width={'100%'}
                options={options}
                series={[{ name: 'Baldadas', data: baldadasCada15Min }]}
                type="area"
                height={300}
            />
        </div>
    );
};

export default BaldadasChart;
