import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";

import NavBar from '../../components/organisms/NavBar';
import SectionHeader from '../../components/molecules/SectionHeader';
import { ComboboxGenerico } from '../../components/atoms/ComboboxGenerico';
import { GenericDateRange } from '../../components/organisms/GenericDateRange';
import { TablaGenerica } from '../../components/organisms/TablaGenerica';

import { useLocation } from "react-router";

import { Box, Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Container } from '@material-ui/core';
import { Card, Grid } from '@mui/material';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';

import { format } from 'date-fns';

import { useGetActividadUsuario } from '../../hooks/analytics/useGetActividadUsuario';
import { useGetallAreaOperacion } from '../../hooks/general/useGetAllAreaOperacion';
import { usePageTimingContext } from '../../context/PageTimingContext';
import ModalDescargaAnalytics from './ModalDescargaAnalytics';
import { BreadCrumbsGenerico } from '../../components/molecules/BreadCrumbsGenerico';

const useStyles = makeStyles({
    iconClick:{
      width:"25px",
      color: "#2f4554",
      cursor: "pointer",
      "&:hover": {
        color: "#00b7c4"
      }
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642",
      },
      "&:disabled": {
        backgroundColor: "gray",
      },
    },
    buttonSelected: {
      backgroundColor: "#2f4554",
      color: "white",
      padding: "5px 30px",
      fontSize: "12px",
      textTransform: "none",
      borderRadius: "4px",
      margin: "0px",
      height: "38px",
      width:"150px",
      alignSelf: "center",
      pointerEvents:"none"
    },
    buttonUnselected: {
      backgroundColor: "#f4f4f4",
      padding: "5px 30px",
      fontSize: "12px",
      textTransform: "none",
      borderRadius: "4px",
      margin: "0px",
      height: "38px",
      width:"150px",
      alignSelf: "center",
      "&:hover": {
        backgroundColor: "#00B7C4",
        color:"#fff"
      }
    },
    prodDes:{
      color: "#2f4554",
      fontSize: "12px !important",
    },
    prodValue:{
      fontSize: "20px",
      fontWeight:"bold"
    },
    datepicker: {
      width: "190px",
      backgroundColor:"white",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#00B7C4"
      },
      "& .MuiInputBase-root": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        "& .MuiButtonBase-root": {

          borderColor: "error.main"

        },
        "& .MuiInputBase-input": {
          backgroundColor: "#ffffff",
          borderColor: "error.main",
          /* fontSize:"14.4px" */
        }
      },

      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: "#00B7C4",
        }
      },
    }
  });


export const Analytics = ({idMina, nombreMina}) => {
    const location = useLocation();
    const { sendTime } = usePageTimingContext();
    const { t } = useTranslation();
    const classes = useStyles();

    const [openModalDate, setOpenModalDate] = useState(false);
    const [fechaInicio, setfechaInicio] = useState(new Date());
    const [fechaTermino, setfechaTermino] = useState(new Date())
    const [idFiltroMina, setIdFiltroMina] = useState('');
    const [nombreFiltroMina, setNombreFiltroMina] = useState('');
    const [openModalDescarga, setOpenModalDescarga] = useState(false);

    const { actividadUsuario,loadingActividadUsuario } = useGetActividadUsuario(idFiltroMina,format(fechaInicio,"yyyy-MM-dd"),format(fechaTermino,"yyyy-MM-dd"),null,false,false,true);
    const { allAreaOperacion,loadingAreaOperacion } = useGetallAreaOperacion(true,true);

    let dataRutaBreadCrumb = [
        { id: 1, endpoint: "/", texto: `Mine-Watch` },
        { id: 2, texto: `${t("minaSingular")} ${nombreMina}` },
        { id: 3, texto: t("Analytics"), destacar:true },
    ];

    const onHandleChangeComboBoxRegsistro = (event) =>{
      let searchForMineName = allAreaOperacion.find(el => el?.id == event.target.value);
      setNombreFiltroMina(searchForMineName?.nombre)
      setIdFiltroMina(event.target.value)
    }

   useEffect(() => {
      return () => {
        sendTime(location.pathname)
      }
    }, [])

    

  return (
    <div>
        <NavBar />
        <Container>
        <br />
        <br />
        <Grid container alignItems="center" justifyContent="start">
          <BreadCrumbsGenerico
            dataRuta={dataRutaBreadCrumb}
            nombreMina={nombreMina}
          />
        </Grid>
        <br/>
        <br/>
        <Card sx={{padding:"25px"}}>
            <Grid container justifyContent={'space-between'} >
                <Grid item xs={6}>
                    <SectionHeader 
                        title={t("Analytics")}
                        subtitle={t("monitoreo_dashboard")}
                        icon={<QueryStatsOutlinedIcon style={{ fontSize: "50px",color:'#000000'}}/>}
                        colorTitle="#00B7C4"
                        aumentarFuente={3}
                        bold={true}
                      />
                </Grid>
                <Grid container item xs={6} spacing={2} alignItems={'center'} justifyContent={'right'}>
                    <Grid item>
                        <CalendarMonthIcon onClick={() => setOpenModalDate(true)} style={{ fontSize: "30px", marginRight: "10px", cursor:'pointer', color:'#000000'}}/>
                    </Grid>
                    <Grid item sx={{minWidth:'300px'}} >
                      {
                        !loadingAreaOperacion && (
                          <ComboboxGenerico
                              label={t("filtrar_por_mina")}
                              name={'filtro-minas'}
                              onChange={onHandleChangeComboBoxRegsistro}
                              valueSelected={idFiltroMina}
                              datos={allAreaOperacion}
                              primeraOpcion='Selección mina'
                              Icono={<FilterAltOutlinedIcon sx={{mr:1}} style={{color:'rgba(0, 0, 0, 0.54)',marginLeft:'10px'}}/>}
                          />
                        )
                      }
                    </Grid>
                </Grid>
            </Grid>
            {
              (!idFiltroMina || idFiltroMina == 0) ?
              (
                <>
                  <br/>
                  <br/>
                  <Grid item xs={12} container wrap='nowrap' justifyContent='center' alignItems='center'>
                    <Box sx={{backgroundColor:'rgb(0, 183, 196)'}}>
                          <InfoOutlinedIcon  fontSize='large' sx={{color:'white',padding:'5px'}}/>
                    </Box>
                    <Box sx={{color:'#666666' ,padding:'10.5px 25px', backgroundColor:'#eaedf1'}}>{t('seleccion_opcion_mina')}</Box>
                  </Grid>
                  <br/>
                  <br/>
                </>
              ) :
              (
                <>
                  <br/>
                  <br/>
                  <Grid container item xs={12} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid>
                      <Grid item xs={6}>
                        <SectionHeader
                        title={t("tiempo_en_sesion")}
                        icon={<TimerOutlinedIcon/>}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} justifyContent={'flex-end'}>
                      <Button
                          size='medium'
                          type="button"
                          variant="contained"
                          className={classes.root}
                          style={{fontSize:'12px',padding:'11px',maxWidth:'180px'}}
                          fullWidth   
                          onClick={()=>setOpenModalDescarga(true)}        
                      >
                            <span style={{marginRight:'5px'}}>
                              {t("descargar")}
                            </span>
                            <DownloadIcon fontSize='small'/>
                      </Button>
                    </Grid>
                  </Grid>
                  <TablaGenerica
                    columns={["usuario","primer_registro","ultimo_registro","clicks_presionados","teclas_presionadas","tiempo_total"]}
                    columnMapping={{
                      "usuario": "usuario",
                      "primer_registro": "primer_registro",
                      "ultimo_registro": "ultimo_registro",
                      "clicks_presionados":"count_clicks",
                      "teclas_presionadas":"count_keys",
                      "tiempo_total":"total_segundos_transcurridos"
                    }}
                    loadingData={loadingActividadUsuario}
                    data={actividadUsuario}
                  />
                </>
              )
            }
        </Card>
        </Container>

        <ModalDescargaAnalytics
          idMina={idFiltroMina}
          nombreMina={nombreFiltroMina}
          onClickClose={setOpenModalDescarga}
          open={openModalDescarga}
        />

        <GenericDateRange
          open={openModalDate}
          onClickClose={setOpenModalDate}
          fechaFin={fechaTermino}
          setFechaFin={setfechaTermino}
          fechaInicio={fechaInicio}
          setFechaInicio={setfechaInicio}
          titulo={"Fechas"}
          subtitulo={"Máximo 5 dias"}
          icono={<CalendarMonthIcon style={{ fontSize: "25px", marginRight: "10px"}}/>}
          iconoTextoFooter={<DoneOutlineIcon style={{marginRight:'5px'}}/>}
          rangoMaximo={30}
        />
    </div>
  )
}

export default Analytics;
