import React, { useState } from "react";

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TablePagination,
  TableBody,
  TableSortLabel,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { formatoDecimales } from "../../commons/Formatos";
import { useTranslation } from "react-i18next";
import { formatearFechaISO } from "../../commons/FormatearFecha";
import { getComparator, nullLastIds, stableSort } from "./utils/sort";
import { translateMachineName } from "../../commons/utiles";


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2f4554",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableSortLabel = withStyles(() => ({
  root: {
    color: "#a1a1aa",
    "&:hover": {
      color: "#fafafa",
    },
    "&.MuiTableSortLabel-active": {
      color: "#fafafa"
    },
    "&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: "#fafafa"
    },
  },
}))(TableSortLabel)

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  root: {
    backgroundColor: "#2f4554",
    margin: "8px 0",
    color: "white",
    "&:hover": {
      backgroundColor: "#253642",
    },
    "&:disabled": {
      backgroundColor: "gray",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
    opacity: 0.9
  },
});

export const TablaEstadosEquipos = ({
  isJumbo = false,
  dataVueltas,
  jumbosMotorYPercutor,
  accion,
  flotaSeleccionada,
  tipoFlota,
  idMina,
  columnasTipoVuelta = []
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id_tipo_movimiento');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  
  let headCellsDumpers = [
    { id: "vueltas", label: t("vueltas") },
    { id: "toneladas", label: t("toneladas") },
  ]

  //si es LC se agrega N° de vueltas por tipo de vuelva a tabla
  if(idMina == 24 && columnasTipoVuelta.length > 0) {
    headCellsDumpers = [...[...new Set(columnasTipoVuelta)].map(col =>({"id":col,"label":col})),...headCellsDumpers] 
  }    

  const headCells = [
    { id: "nombre", label: t("maquina") },
    { id: "id_tipo_movimiento", label: t("estado") },
    ...(isJumbo
      ? [
          { id: "horometroMotor", label: t("horometro_motor_brazos") },
          { id: "horometroPercutor", label: t("horometro_percutor_brazos") },
        ]
      : tipoFlota == "LHD" 
        ? [
            { id: "cargas", label: t("cargas") },
          ]
        
        : headCellsDumpers),
    { id: "tipo_ultima_ubicacion", label: t("ultima_ubicacion") },
    { id: "hora_ultima_ubicacion", label: t("fecha_ultima_ubicacion") },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const HandleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getDescripcion = (key) => {
    const estados = {
      operativo: t("operativo"),
      ralenti: t("ralenti"),
      mantencion: t("en_mantencion"),
      "fuera de servicio": t("fuera_de_servicio"),
      sininfo: t("sin_info"),
    };
    return estados[key];
  };

  const getIconoEstado = (key) => {
    let baseEstado = {
      margin: idMina == 24 ? "0px 5px 0px 10px" : "0px 5px 0px 5px",
      height:"13px",
      width:"13px",
      backgroundColor: "#01b7c4",
      borderRadius:"50%",
      display: "inline-block"
      }
    const estados = {
      operativo:<span 
                    style={{
                    ...baseEstado,
                    backgroundColor: "#01b7c4",
                    }}
                />
      ,
      ralenti: <span 
                    style={{
                    ...baseEstado,
                    backgroundColor: "#E9724C",
                    }}
                />,
      mantencion:<span 
                    style={{
                    ...baseEstado,
                    backgroundColor: "#ffbf00"
                    }}
                />,
      "fuera de servicio":<span 
                    style={{
                    ...baseEstado,
                    backgroundColor: "#C5283D",
                    }}
                />,
      sininfo:<span 
                  style={{
                    ...baseEstado,
                    backgroundColor: "#d6dce4",
                  }}
                />
      ,
    };
    return estados[key];
  };

  const marginSortLabel = {
    "nombre": "14px",
    "id_tipo_movimiento": "0px",
    "vueltas": "10px",
    "toneladas": "10px",
    "horometroMotor": "22px",
    "horometroPercutor": "22px",
    "tipo_ultima_ubicacion": "22px",
    "hora_ultima_ubicacion": "26px",
  }

  return (
    <Grid>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
          {headCells.map((headCell) => (
                <StyledTableCell 
                  key={headCell.id} 
                  align="center"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <StyledTableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    style={{ marginInlineStart: marginSortLabel[headCell?.id] ? marginSortLabel[headCell?.id] : "5px"}}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </StyledTableSortLabel>
                </StyledTableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(flotaSeleccionada, getComparator(order, orderBy, nullLastIds))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((el,index) => {
              let iconoEstado = getIconoEstado(el.id_tipo_movimiento)
              return (
                <StyledTableRow
                  key={el.nombre}
                  tabIndex={-1}
                  style={{ cursor: "pointer" }}
                  onClick={() => accion(el)}
                >
                  <StyledTableCell align="center">{translateMachineName(el.nombre,t,idMina)}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Grid container justifyContent={'flex-start'} wrap="nowrap">
                          <Grid item xs={10}>
                              {getDescripcion(el.id_tipo_movimiento)}
                          </Grid>
                          <Grid item xs={3}>
                              {iconoEstado}
                          </Grid>
                      </Grid>
                  </StyledTableCell>
                  {!isJumbo ? (
                    tipoFlota == "LHD" ?
                    <>
                      <StyledTableCell align="center">
                        {dataVueltas[el.nombre]?.vueltas >= 0 ? dataVueltas[el.nombre]?.vueltas : 'No aplica' }
                      </StyledTableCell>
                    </> :
                    <>
                      {
                       idMina == 24 && [...new Set(columnasTipoVuelta)].map((tipoVuelta) =>(
                          <StyledTableCell key={tipoVuelta} align="center">
                            {dataVueltas[el?.nombre]?.[tipoVuelta] > 0 ? dataVueltas[el?.nombre]?.[tipoVuelta] : 0 }
                          </StyledTableCell>
                        ))
                      }
                      <StyledTableCell align="center">
                        {dataVueltas[el.nombre]?.vueltas >= 0 ? dataVueltas[el.nombre]?.vueltas : 'No aplica' }
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {dataVueltas[el.nombre]?.toneladas >= 0 ? dataVueltas[el.nombre]?.toneladas?.toFixed(2) : 'No aplica'}
                      </StyledTableCell>
                    </>  
                  ) : (
                        <>
                        <StyledTableCell align="center">
                          {(jumbosMotorYPercutor[el.nombre]?.horometroMotor
                            ? formatoDecimales(
                                jumbosMotorYPercutor[el.nombre]?.horometroMotor
                              )
                            : 0) + " hrs"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {(jumbosMotorYPercutor[el.nombre]?.horometroPercutor
                            ? formatoDecimales(
                                jumbosMotorYPercutor[el.nombre]?.horometroPercutor
                              )
                            : 0) + " hrs"}
                        </StyledTableCell>
                      </>
                    )
                  }
                  <StyledTableCell align="center">
                    {el.tipo_ultima_ubicacion}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {formatearFechaISO(
                      el?.hora_ultima_ubicacion
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[30, 40]}
        component={"div"}
        count={flotaSeleccionada.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={HandleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("filas_por_pagina")}
      />
    </TableContainer>
  </Grid>
  );
};
