import { createContext, useState } from "react";
import { useGetMaquinasByIdMina } from "../hooks/useGetMaquinasByIdMina";

const GeneralMinaContext = createContext();

const GeneralMina = (props) => {
    let initialState={
        endpoint:[],
        esSubterrane:true,
        nombreMina:'',
        idMina:0       
    };
    const [infoGeneral, setInfoGeneral] = useState(initialState);
    const {maquinas:ListadoMaquinas,loadingMaquinas} = useGetMaquinasByIdMina(infoGeneral?.idMina);
    const [NavDrawerEquipo, setNavDrawerEquipo] = useState(false);
    
    const onHandleSetDataGeneral =(esSubterrane,nombreMina,idMina) =>{
        setInfoGeneral((prevState) =>{
            return {...prevState,esSubterrane,nombreMina,idMina}
        })
    }
    

    const onHandleGetMaquinasTipo = () => {
        const maquinasPorTipo = {};
      
        ListadoMaquinas.forEach((maquina) => {
          const tipo = maquina.tipo;
      
          // Verificar si ya hay un array para ese tipo, si no, crearlo
          if (!maquinasPorTipo[tipo]) {
            maquinasPorTipo[tipo] = [];
          }
      
          // Agregar la máquina al array correspondiente al tipo
          maquinasPorTipo[tipo].push(maquina);
        });
      
        return maquinasPorTipo;
      }

    const onHandleGetGeneralData = () =>{
        return infoGeneral
    }

    const onHandleSetEndpoint = (data) =>{
        setInfoGeneral((prevState) => {
            return {...prevState,endpoint:data}
        })
    }

    const onHandleGetEndpoint = () =>{
        return infoGeneral.endpoint
    }

return (
    <GeneralMinaContext.Provider value={{ 
                                onHandleSetEndpoint,
                                onHandleGetEndpoint, 
                                onHandleSetDataGeneral,
                                onHandleGetGeneralData,
                                NavDrawerEquipo, 
                                setNavDrawerEquipo,
                                ListadoMaquinas,
                                loadingMaquinas ,
                                onHandleGetMaquinasTipo                            
                                }}>
      {props.children}
    </GeneralMinaContext.Provider>
)
}

export {GeneralMina, GeneralMinaContext}
