import React, { useState } from "react";

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TablePagination,
  TableBody,
  TableSortLabel,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { formatearFechaISO } from "../../../commons/FormatearFecha";
import { getComparator, nullLastIds, stableSort } from "../utils/sort";
import { translateMachineName } from "../../../commons/utiles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2f4554",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableSortLabel = withStyles(() => ({
  root: {
    color: "#a1a1aa",
    "&:hover": {
      color: "#fafafa",
    },
    "&.MuiTableSortLabel-active": {
      color: "#fafafa"
    },
    "&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon": {
      color: "#fafafa"
    },
  },
}))(TableSortLabel)

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  root: {
    backgroundColor: "#2f4554",
    margin: "8px 0",
    color: "white",
    "&:hover": {
      backgroundColor: "#253642",
    },
    "&:disabled": {
      backgroundColor: "gray",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
    opacity: 0.9
  },
});

export const TablaEstadosTodosLosEquipos = ({
  data = {},
  accionJumbo,
  accionNoJumbo,
  fechaComodin
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id_tipo_movimiento');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const HandleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const headCells = [
    { id: "nombre", label: t("maquina") },
    { id: "id_tipo_movimiento", label: t("estado") },
    { id: "tipo_ultima_ubicacion", label: t("ultima_ubicacion") },
    { id: "hora_ultima_ubicacion", label: t("fecha_ultima_ubicacion") },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const getDescripcion = (key) => {
    const estados = {
      operativo: t("operativo"),
      ralenti: t("ralenti"),
      mantencion: t("en_mantencion"),
      "fuera de servicio": t("fuera_de_servicio"),
      sininfo: t("sin_info"),
    };
    return estados[key];
  };

  const getIconoEstado = (key) => {
    const estados = {
      operativo:<span
                    style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#01b7c4",
                    borderRadius:"50%",
                    display: "inline-block"
                    }}
                />
      ,
      ralenti: <span
                    style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#C5283D", 
                    borderRadius:"50%",
                    display: "inline-block"
                    }}
                />,
      mantencion:<span
                    style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#ffbf00",
                    borderRadius:"50%",
                    display: "inline-block"
                    }}
                />,
      "fuera de servicio":<span
                    style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#C5283D",
                    borderRadius:"50%",
                    display: "inline-block"
                    }}
                />
        ,
      sininfo:<span
                  style={{
                    margin:"0px 5px 0px 0px",
                    height:"13px",
                    width:"13px",
                    backgroundColor: "#d6dce4",
                    borderRadius:"50%",
                    display: "inline-block"
                  }}
                />
      ,
    };
    return estados[key];
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  
  const marginSortLabel = {
    "nombre": "14px",
    "id_tipo_movimiento": "0px",
    "tipo_ultima_ubicacion": "22px",
    "hora_ultima_ubicacion": "26px",
  }

  return (
    <Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <StyledTableCell 
                  key={headCell.id} 
                  align="center"
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{ color: "#BFBFC7" }}
                >
                  <StyledTableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    style={{ marginInlineStart: marginSortLabel[headCell.id]}}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </StyledTableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(data, getComparator(order, orderBy, nullLastIds))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((maquina, indice) => {
                let iconoEstado = getIconoEstado(maquina.id_tipo_movimiento)
                return (
                  <StyledTableRow
                    key={maquina.nombre}
                    tabIndex={-1}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if(maquina.tipo_maquina == 3 || maquina.tipo_maquina == 4){
                        accionJumbo(maquina);
                      }else{
                        accionNoJumbo(maquina);
                      }
                    }}
                  >
                    <StyledTableCell align="center">{translateMachineName(maquina.nombre,t,maquina?.id_area_operacion)}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Grid container spacing={1} justifyContent={'space-evenly'}>
                        <Grid item xs={11}>
                          {getDescripcion(maquina.id_tipo_movimiento)}
                        </Grid>
                        <Grid item xs={1}>
                          {iconoEstado}
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {maquina.tipo_ultima_ubicacion}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      { formatearFechaISO(maquina?.hora_ultima_ubicacion) }
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[30, 40]}
          component={"div"}
          count={Object.entries(data).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={HandleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("filas_por_pagina")}
        />
      </TableContainer>
    </Grid>
  );
};
