import React, { useContext, useReducer} from "react";
import ReactDOM from "react-dom";
import { Account } from "./authentication/Account";
import Store from "./context";
import reducer from "./reducer";
import { usePersistedContext, usePersistedReducer } from "./usePersist";

// Metarial-UI Theme(Dark or Light)
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme, CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./components/Main";
import { createStore } from "redux";
import { Provider} from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import { GeneralMina } from "./context/GeneralMina";
import { GeneralCharts } from "./context/GeneralCharts";


const App = () => {
  const globalStore = usePersistedContext(useContext(Store), "state");

  const [state, dispatch] = usePersistedReducer(
    useReducer(reducer, globalStore),
    "state"
  );

  const theme = createTheme({
    palette: {
      primary:{
        main: "#2f4554"
      },
      type: state.myTheme, // "light" or "dark"
    },
  });

  // store
  const store = createStore(rootReducer, composeWithDevTools());

  return (
    <Router>
        <Provider store={store}>
          <Store.Provider value={{ state, dispatch }}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Account>
                  <GeneralCharts>
                <GeneralMina>
                    <Main/>
                </GeneralMina>
                  </GeneralCharts>
              </Account>
            </ThemeProvider>
          </Store.Provider>
        </Provider>
    </Router>
  );
};
ReactDOM.render(<App />, document.querySelector("#root"));
