import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
/* import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box'; */
import NavBar from "../../components/organisms/NavBar";
import {
  Container
} from "@material-ui/core";
import {
  Grid,
  Typography
} from "@mui/material";
import ModalBase from "../../components/organisms/ModalBase";
import WarningIcon from '@mui/icons-material/WarningAmber';
import { TabGenerica } from "../../components/organisms/TabGenerica";
import { useTranslation } from "react-i18next";
import { AdministracionVicons } from "./vicons/AdministracionVicons";
import { AdministracionGeocercas } from "./geocercas/AdministracionGeocercas";
import { AdministracionCiclosGps } from "./ciclosGps/AdministracionCiclosGps";
import { AdministracionTipoGeocercas } from "./tiposGeocercas/AdministracionTipoGeocercas";
import { usePageTimingContext } from "../../context/PageTimingContext";
import { BreadCrumbsGenerico } from "../../components/molecules/BreadCrumbsGenerico";


const Administracion = ({ idMina,nombreMina, esMinaSubterranea}) => {

  const { t } = useTranslation();
  const location = useLocation();
  const { sendTime } = usePageTimingContext();

  const [openModalConfirmar, setOpenModalConfirmar] = useState(false);

  let tabsAux =[{
    id:1,
    label:t('vicons'),
    children:<AdministracionVicons nombreMina={nombreMina} id_area_operacion={idMina}/>
  }
]

if(!esMinaSubterranea){
  tabsAux.push({
    id:2,
    label:t('geocercas').toUpperCase(),
    children:<AdministracionGeocercas nombreMina={nombreMina} id_area_operacion={idMina}/>
  },{
    id:4,
    label:t('tipo_geocerca').toUpperCase(),
    children:<AdministracionTipoGeocercas nombreMina={nombreMina} id_area_operacion={idMina}/>
  },{
    id:3,
    label:t('ciclo_gps').toUpperCase(),
    children:<AdministracionCiclosGps nombreMina={nombreMina} id_area_operacion={idMina}/>    
  });
  
}

useEffect(() => {
  return () => {
    sendTime(location.pathname)
  }
}, [])

  return (
    <>
      <NavBar/>
      <Container>
        <br/>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <BreadCrumbsGenerico
            nombreMina={nombreMina}
            dataRuta={ [
              { id: 1, endpoint: "/", texto: `Mine-Watch` },
              { id: 2, texto: `${t('minaSingular')} ${nombreMina}` },
              { id: 3, texto: t("administracion"),destacar:true }
            ]}
            />
          </Grid>
        </Grid>
        
        <br/>
        <br/>

        {/* TABS */}
        <TabGenerica
            tabs={tabsAux}
          />

        <ModalBase 
          open={openModalConfirmar} 
          onClickClose={()=>{setOpenModalConfirmar(false)}} 
          titulo={"Confirmar Datos"}
          icon={
            <WarningIcon sx={{
              fontSize: "25px",
              marginRight:"10px"
            }}/>
          }
        >
          <Grid container 
            direction="column" 
            justifyContent="center" 
            alignItems="center" 
          >
            <Grid item >
              <Typography fontSize={"14.4px"}>
                Está a un paso de guardar los datos.
              </Typography>
            </Grid>
            <Grid item >
              <Typography fontSize={"14.4px"}>
                ¿Desea confirmar la acción?
              </Typography>             
            </Grid>
          </Grid>
        </ModalBase>
      </Container>
    </>
  );
}

export default Administracion;