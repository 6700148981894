import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export const TextfieldGenerico = ({ value, onChange, name, label, disabled = false, Icon, onBlur, variant="standard",helperText,error,type='text',showLabelOnTop = false }) => {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      width:'100%',
      backgroundColor: Icon ? '#f0f0f0' : '',
      height:'45px'
    }}>
      {
        showLabelOnTop ?
      (
        <>
          {Icon && <Box sx={{ marginRight: '10px' }}>{Icon}</Box>}
          <Box sx={{ width: '100%' }}>
            <TextField
            type={type}
              error={error ? true : false}
              fullWidth
              disabled={disabled}
              id="outlined-basic"
              label={label}
              variant={variant}
              name={name}
              onChange={onChange}
              value={value}
              size='small'
              onBlur={onBlur}
              sx={Icon &&{
                fontWeight: 'normal',
                '& input::placeholder': {
                  textAlign: 'center',
                  marginRight:'35px'
                },
              }}
              helperText={helperText ? helperText : ""}
              FormHelperTextProps={{sx:{position:'absolute',top:'35px'}}}
            />
          </Box>
        </>
      )
      :
      (
      <>
        {Icon && <Box sx={{ marginRight: '10px' }}>{Icon}</Box>}
        <Box sx={{ width: '100%' }}>
          <TextField
            type={type}
            error={error ? true : false}
            fullWidth
            disabled={disabled}
            id="input-with-sx"
            placeholder={label}
            variant={variant}
            name={name}
            onChange={onChange}
            value={value}
            size='small'
            onBlur={onBlur}
            sx={Icon &&{
              fontWeight: 'normal',
              '& input::placeholder': {
                textAlign: 'center',
                marginRight:'35px'
              },
            }}
            helperText={helperText ? helperText : ""}
            FormHelperTextProps={{sx:{position:'absolute',top:'35px'}}}
          />
        </Box>
      </>
      )
      }
    </Box>
  );
};
