import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import IconMina from "../..//assets/icons/icono-prod-portal.png";
import IconFrentes from "../../assets/icons/frentes_icon.svg";
import IconoControlTiempo from "../../assets/icons/icono-control-tiempo.png";
import IconKpi from "../../assets/icons/icono-kpi.png";

import { Container, makeStyles } from "@material-ui/core";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { formatDate, getDateInTimezone } from "../../commons/FormatearFecha";
import { getLocaleLanguage } from "../../commons/Idiomas";
import { getCurrentShift } from "../../commons/utiles";
import {
  InfoCard,
  InfoContent,
  InfoIcon,
  InfoText,
  InfoTitle
} from "../../components/atoms/InfoCard";
import { BreadCrumbsGenerico } from "../../components/molecules/BreadCrumbsGenerico";
import NavBar from "../../components/organisms/NavBar";
import { useGetDataPlanControlFrentes } from "../../hooks/controlFrentes/useGetDataPlanControlFrentes";
import { usePDF } from "../../hooks/general/usePDF";
import { useScreenshots } from "../../hooks/general/useScreenshots";
import { useGetInfoGeneralMina } from "../../hooks/useGetInfoGeneralMina";
import { DownloadImageButton } from "./DownloadImageButton";
import { LeyendaControlFrente } from "./LeyendaControlFrente";
import { LogosEmpresas } from "./LogosEmpresas";
import { TableControlFrentes } from "./TableControlFrentes/TableControlFrentes";
import { CustomPopover } from "./TemporalEmails/CustomPopover";
import { FileTextIcon } from "./TemporalEmails/IconsPopover";
import { MinasTabs } from "./TemporalEmails/MinasTabs";
import { useInfoMina } from "../../context/InfoMina";
import { upperCaseFirstLetter } from "../../commons/Formatos";

const useStyles = makeStyles({
  shiftButton: {
    backgroundColor: "#2f4554",
    color: "#fff",
    padding: "5px 5px",
    fontSize: "14px",
    borderRadius: "4px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    border: "none",
    margin: "3px",
    height: "38px",
    width: "150px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#00b7c4"
    },
    transition: "background-color 0.3s ease"
  },
  unSelectedShiftButton: {
    backgroundColor: "#f4f4f4",
    color: "#2f4554"
  },
  datepicker: {
    width: "190px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {
        borderColor: "error.main"
      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main"
        /* fontSize:"14.4px" */
      }
    },

    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#00B7C4"
      }
    }
  }
});

export default function ControlDeFrentes({ nombreMina, idEmpresa }) {
  const [date, setDate] = useState(() => getDateInTimezone(new Date()));
  const [idSubMina, setIdSubMina] = useState(26);
  const [idTurno, setIdTurno] = useState(() => {
    const formatedDate = getDateInTimezone(new Date());
    const currentShift = getCurrentShift(formatedDate);

    return currentShift === "dia" ? 57 : 58;
  });

  const [selectedShift, setSelectedShift] = useState(() => {
    const formatedDate = getDateInTimezone(new Date());
    const currentShift = getCurrentShift(formatedDate);

    return currentShift;
  });
  const [selectedNombreMina, setSelectedNombreMina] = useState("mina_santa_rosa");
  const [selectedGroup, setSelectedGroup] = useState("Todos");

  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const dataRutaBreadCrumb = [
    { id: 1, endpoint: "/", texto: `Mine-Watch` },
    { id: 2, texto: `${t("minaSingular")} ${nombreMina}` },
    { id: 3, texto: t("control_frentes"), destacar: true }
  ];

  const formatedDate = formatDate(
    date,
    i18n.language === "es" ? "es-CL" : "en-EN",
    {
      day: "numeric",
      month: "long"
    }
  );

  const { infoMina } = useInfoMina();
  
  const {
    groupsPlanControlFrentes,
    dataPlanControlFrentes,
    loadingPlanControlFrentes,
  } = useGetDataPlanControlFrentes(idSubMina, date, idTurno);
  
  const handleChangeDate = (newDate) => {
    const adjustedDate = getDateInTimezone(newDate);
    setDate(adjustedDate);
  }
  
  const handleChangeIdSubMina = (newIdSubMina) => {
    setIdSubMina(newIdSubMina);
  }

  const handleClickNombreMina = (newNombreMina) => {
    setSelectedNombreMina(newNombreMina);

    const idTurnoHardcoded = infoMina?.[newNombreMina]?.[`id_turno_${selectedShift}`];
    setIdTurno((prevIdTurno) => {
      if (prevIdTurno !== idTurnoHardcoded) {
        return idTurnoHardcoded;
      }
      return prevIdTurno;
    });
  }
  
  const handleChangeTurno = (newTurno) => {
    setSelectedShift(newTurno);
    const idTurnoHardcoded = infoMina?.[selectedNombreMina]?.[`id_turno_${newTurno}`];
    
    setIdTurno(idTurnoHardcoded);
  }
  
  const filteredData =
  selectedGroup === "Todos"
  ? dataPlanControlFrentes || []
  : dataPlanControlFrentes?.filter(
    (item) => item.grupo_frente === selectedGroup
  ) || [];
  
  const noActivity =
  filteredData.length > 0 &&
  filteredData.every((el) => el.estado_ejecucion === "Nada");
  
  const {
    screenshots,
    loading,
    handleTakeScreenshoot,
    handleRemoveScreenshot
  } = useScreenshots();
  
  const {
    pdfs,
    generatePDFByMina,
    deletePDFByMina,
    getPDFAsBase64,
    downloadPDF,
    openPDFInNewTab
  } = usePDF();

  return (
    <>
      <NavBar />
      <Container>
        <Grid
          container
          alignItems="center"
          justifyContent="start"
          sx={{ pt: 3 }}>
          <BreadCrumbsGenerico
            dataRuta={dataRutaBreadCrumb}
            nombreMina={nombreMina}
          />
        </Grid>
        <Grid
          columnGap={3}
          container
          minWidht="100%"
          rowSpacing={1}
          flexWrap={"wrap"}
          justifyContent="space-evenly"
          alignItems={"center"}
          flexDirection="row"
          marginTop={3}
          marginBottom={2}>
          <Grid item container xs={2}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={getLocaleLanguage(i18n.language)}>
              <KeyboardDatePicker
                className={classes.datePicker}
                maxDate={new Date()}
                format="dd/MM/yyyy"
                cancelLabel={t("cancelar")}
                size="small"
                inputVariant="outlined"
                value={date}
                onChange={(date) => handleChangeDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item container width="fit-content" xs={2}>
            <FormControl
              size="small"
              fullWidth
              sx={{ maxWidth: 220 }}
              variant="outlined">
              <InputLabel>Mina</InputLabel>
              <Select
                label="Mina"
                value={idSubMina}
                onChange={(event) => handleChangeIdSubMina(event.target.value)}>
                <MenuItem disabled value="">
                  Selecciona una Mina
                </MenuItem>
                {Object.entries(infoMina)?.map((mina) => {
                  const [nombreMina, { id_area_operacion: id }] = mina;
                  const formatedNombreMina = nombreMina
                  .split("_")
                  .slice(1)
                  .map((word) => word
                    .charAt(0)
                    .toUpperCase() + word.slice(1))
                    .join(" ");

                  return (
                    <MenuItem 
                      key={id} 
                      value={id}
                      onClick={() => handleClickNombreMina(nombreMina)}>
                      {formatedNombreMina}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item container sx={"maxHeight:40"} width="fit-content" xs={2}>
            <FormControl
              size="small"
              fullWidth
              sx={{ maxWidth: 210 }}
              variant="outlined">
              <InputLabel>Frentes</InputLabel>
              <Select
                label="Frentes"
                value={selectedGroup}
                onChange={(e) => setSelectedGroup(e.target.value)}>
                <MenuItem disabled>Selecciona un frente</MenuItem>
                {groupsPlanControlFrentes?.map((grupo) => {
                  return (
                    <MenuItem key={grupo} value={grupo}>
                      {grupo}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            container
            xs={2}
            flexWrap="nowrap"
            width="fit-content"
            alignItems="center">
            <button
              disabled={idSubMina.length < 1}
              className={`${classes.shiftButton} ${idTurno !== "" && selectedShift !== "dia" && classes.unSelectedShiftButton}`}
              onClick={() => handleChangeTurno("dia")}>
              Turno dia
            </button>
            <button
              disabled={idSubMina.length < 1}
              className={`${classes.shiftButton} ${idTurno !== "" && selectedShift !== "noche" && classes.unSelectedShiftButton}`}
              onClick={() => handleChangeTurno("noche")}>
              Turno noche
            </button>
          </Grid>
          <Grid>
            <DownloadImageButton
              elementId="control-de-frentes-container"
              currentShift={selectedShift}
              nombreSubMina={selectedNombreMina}
              date={date}
            />
          </Grid>
        </Grid>
        <Card
          id="control-de-frentes-container"
          sx={{
            padding: "25px",
            position: "relative"
          }}>
          <Grid
            container
            flexDirection="row"
            flexWrap="wrap"
            width="100%"
            justifyContent="space-evenly"
            alignItems="center"
            columnGap={5}>
            <InfoCard>
              <InfoIcon style={{ width: "100px" }} src={IconFrentes} />
              <InfoContent>
                <InfoTitle>Control de Frentes</InfoTitle>
                <InfoText
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    flexWrap: "nowrap !important"
                  }}>
                  {formatedDate}
                  <CalendarMonthIcon
                    style={{
                      fontSize: "25px",
                      cursor: "pointer",
                      color: "#000000",
                      pointerEvents: "none"
                    }}
                  />
                </InfoText>
              </InfoContent>
            </InfoCard>

            <InfoCard>
              <InfoIcon style={{ width: "40px" }} src={IconoControlTiempo} />
              <InfoContent>
                <InfoTitle>Turno</InfoTitle>
                <InfoText>{upperCaseFirstLetter(selectedShift)}</InfoText>
              </InfoContent>
            </InfoCard>

            <InfoCard>
              <InfoIcon style={{ width: "40px" }} src={IconMina} />
              <InfoContent>
                <InfoTitle>Mina</InfoTitle>
                <InfoText>
                  {selectedNombreMina
                    .split("_")
                    .slice(1)
                    .map((word) => upperCaseFirstLetter(word))
                    .join(" ")
                  }
                </InfoText>
              </InfoContent>
            </InfoCard>

            <InfoCard>
              <InfoIcon style={{ width: "43px" }} src={IconKpi} />
              <InfoContent>
                <InfoTitle>Metros en el Turno</InfoTitle>
                <InfoText>
                  {dataPlanControlFrentes?.reduce((acc, item) => {
                    const { metros_avance: metros } = item;

                    return acc + metros;
                  }, 0)}
                </InfoText>
              </InfoContent>
            </InfoCard>
          </Grid>

          <Box sx={{ mt: "2.5rem", mb: "1.5rem" }}>
            <LeyendaControlFrente />
          </Box>
          <section
            style={{ width: "100%", overflowX: "scroll", overflowY: "hidden" }}>
            {loadingPlanControlFrentes ? (
              <Typography variant="subtitle1" align="center">
                Cargando...
              </Typography>
            ) : filteredData.length === 0 ? (
              <Typography variant="subtitle1" align="center">
                No hay datos disponibles.
              </Typography>
            ) : noActivity ? (
              <Typography variant="subtitle1" align="center">
                No hay planes de avance registrados para el turno seleccionado.
              </Typography>
            ) : (
              <TableControlFrentes frentes={filteredData} />
            )}
          </section>
          <LogosEmpresas />
        </Card>
        {idEmpresa === 1 && (
          <CustomPopover
            id="control-de-frentes-popover"
            content={
              <section style={{ width: "410px", height: "360px" }}>
                <MinasTabs
                  loading={loading}
                  screenshots={screenshots}
                  pdfs={pdfs}
                  date={date}
                  handleTakeScreenshot={handleTakeScreenshoot}
                  handleRemoveScreenshot={handleRemoveScreenshot}
                  generatePDFByMina={generatePDFByMina}
                  deletePDFByMina={deletePDFByMina}
                  downloadPDF={downloadPDF}
                  openPDFInNewTab={openPDFInNewTab}
                  getPDFAsBase64={getPDFAsBase64}
                />
              </section>
            }>
            <FileTextIcon />
          </CustomPopover>
        )}
      </Container>
    </>
  );
}
