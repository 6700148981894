import React from 'react'
import { useTranslation } from 'react-i18next';
import {Grid,Link,Typography,} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";

export const BreadCrumbsGenerico = ({dataRuta=[{id:0,endpoint:"/",texto:"Mine-watch",destacar:false}],nombreMina}) => {
    const { t, i18n } = useTranslation();

  return (
    <>
        <Grid item>
                <Typography
                style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    color: "#00B7C4",
                    textTransform:"uppercase"
                }}
                >
                {`${t("minaSingular")} ${nombreMina}`}
                </Typography>
                <Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
                    {
                        dataRuta.map((ruta) => (
                            <Link
                                key={ruta.id}
                                underline="hover"
                                sx={{ display: "flex", alignItems: "center" }}
                                style={{fontSize:"14.4px", color:ruta.destacar && "#00b7c4"}}
                                color="inherit"
                                href={ruta.endpoint && ruta.endpoint}
                            >
                                {ruta.texto}
                            </Link>
                        ))
                    }
                </Breadcrumbs>
            </Grid>
    </>
  )
}
