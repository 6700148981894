import * as React from 'react';
import { HtmlTooltip } from '../atoms/StyledComponents';

export default function GenericTooltip({hoverElement, children, hide}) {
  return (
      <HtmlTooltip
        enterDelay={0}
        disableHoverListener={hide}
        title={
          <React.Fragment>
            
              {children}
            
          </React.Fragment>
        }
      >
        {hoverElement}
      </HtmlTooltip>
  );
}