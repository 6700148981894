import React, { useState } from 'react'
import { Grid } from '@mui/material'
import {
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TablePagination,
    TableBody,
    makeStyles,
    withStyles,
    Box
  } from "@material-ui/core";
  import Paper from "@material-ui/core/Paper";
import { useTranslation } from 'react-i18next';

  const useStyles = makeStyles({
    table: {
      width: "100%",
    },
    root: {
      backgroundColor: "#2f4554",
      margin: "8px 0",
      color: "white",
      "&:hover": {
        backgroundColor: "#253642",
      },
      "&:disabled": {
        backgroundColor: "gray",
      },
    },
  });
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#2f4554",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

export const TablaControlDiario = ({data}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const getColor = (key) => {
        const estados = {
          "operativo": "#01b7c4",
          "mantencion": "#ffbf00",
          "fuera de servicio": "#C5283D",
          "sininfo": "#d6dce4"
        }
        return estados[key]
      }

      const HandleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
      };

  return (
    <div>
        <Grid>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">{t("maquina")}</StyledTableCell>
                            <StyledTableCell align="center">{t("portal")}</StyledTableCell>
                            <StyledTableCell align="center">{t("hora")}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        Object.entries(data).map(([key,value],index) =>{
                        return(
                            <StyledTableRow key={index+key}>
                                <StyledTableCell align='center'>
                                    <Grid container justifyContent={'center'} wrap="nowrap">
                                        <Grid item xs = {5}>
                                            <b style={{color:getColor(((value.ubicacion==="-") && (value.fecha==="-"))?"sininfo":"operativo"), filter:"brightness(75%)"}}>{key}</b>
                                        </Grid>
                                        <Grid item xs = {1}>
                                            <span 
                                                style={{
                                                margin:"0px 5px 0px 0px",
                                                height:"13px",
                                                width:"13px",
                                                backgroundColor: getColor(((value.ubicacion==="-") && (value.fecha==="-"))?"sininfo":"operativo"),
                                                borderRadius:"50%",
                                                display: "inline-block"
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </StyledTableCell>
                                <StyledTableCell align='center'>{value.ubicacion}</StyledTableCell>
                                <StyledTableCell align='center'>{value.fecha}</StyledTableCell>
                            </StyledTableRow>
                        )
                        })
                    }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[15, 25]}
                    component={"div"}
                    count={Object.entries(data).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={HandleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("filas_por_pagina")}
                    />
            </TableContainer>
        </Grid>
    </div>
  )
}
