import { useEffect, useState } from 'react';
import { getMetasEquiposByAreaOp } from '../services/operaciones';

export const useGetMetasEquipoByAreaOp = (idAreaOperacion) => {
    const [metas, setMetas] = useState({});

    useEffect(() => {
        getMetasEquiposByAreaOp(idAreaOperacion)
        .then((resp) =>{
            setMetas(resp.data);
        })
    }, []);
    
    return { metas }
}
