import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Grid,
    Checkbox,
    FormControlLabel
  } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetMaquinasByIdMina } from "../../hooks/useGetMaquinasByIdMina";
import { useEffect } from "react";

import moment from "moment";
import { convertirFechadeCLaUS, generateDatabaseDateTimeES } from "../../commons/FormatearFecha";
import { getMotivosJustificaciones, insertJustificacion } from "../../services/justificacion";
import { useContext } from "react";
import { GeneralMinaContext } from "../../context/GeneralMina";

export const PopupRegistroJustificacion = ({
  open,
  loadingPopUp,
  FormJustificados,
  classes,
  gruposJustificaciones,
  verCheckbox  = false,
  stateInicialFormJustificaciones,
  setReloadCharts,
  setLoadHorizontalChart,
  setOpen,
  setAlert
}) => {
    const paperStyle = {
        padding: 20,
        width: 480,
      };

    const initialStateInfo = {
      id_t_registro_movimiento:undefined,
      fecha_inicio:undefined,
      fecha_termino:undefined
    }

    const [unlockDates, setUnlockDates] = useState(true);
    const [unlockOtroMotivo, setUnlockOtroMotivo] = useState(false);
    const [prevInfo, setPrevInfo] = useState(initialStateInfo);
    const [ListadoMotivos, setListadoMotivos] = useState([]);
    const { t } = useTranslation();

    const {onHandleGetGeneralData} = useContext(GeneralMinaContext);
    const {idMina} = onHandleGetGeneralData()
    const {maquinas:ListadoMaquinas,loadingMaquinas} = useGetMaquinasByIdMina(idMina);
    
    const initialStateFormik ={
      id_area_operacion: idMina,
      id_tipo_justificacion: 0,
      id_grupo_tipo_justificacion:0,
      observacion: "",
      fecha_inicio: "",
      fecha_termino: "",
      id_maquina: 0,
      id_registro_movimiento:0,
      id_movimiento:0,
      ubicacion:"",
      nombre_motivo:"",
      otro_motivo:false
    };

    const formik = useFormik({
      initialValues: initialStateFormik,
      validationSchema: Yup.object({
        id_tipo_justificacion: Yup.number().test(
          "seleccionar-motivo",
          t("validacion_campo_justificacion"),
          function (value) {
            if(formik.values.otro_motivo){
              return true
            }else{
              return value !== undefined && value !== 0;
            }
          }
        ),
        observacion: Yup.string().when("id_tipo_justificacion", {
          is:(value) => [7 ,17 , 27 ,32, 40, 43 , 49].includes(value),
          then: () => Yup.string().required(t("validacion_justificacion")),
        }),
        fecha_inicio: Yup.string().required(t("validacion_fecha_inicio")),
        fecha_termino: Yup.string()
          .required(t("validacion_fecha_fin"))
          .test(
            "fecha-termino",
            t("validacion_fecha_inicio_mayor_fecha_fin"),
            function (value) {
              const { fecha_inicio } = this.parent; // Obtenemos el valor de fecha_inicio del objeto actual
              return (
                !fecha_inicio ||
                !value ||
                moment(value).isSameOrAfter(fecha_inicio)
              );
            }
          ),
        id_maquina: Yup.number().test(
          "seleccionar-equipo",
          t("validacion_equipo"),
          function (value) {
            return value !== undefined && value !== 0;
          }
        ),
        id_movimiento:Yup.number().test(
          "seleccionar-tipo-alarma",
          t("validacion_tipo_alarma"),
          function (value) {
            return value !== undefined && value !== 0;
          }
        ), 
        ubicacion: Yup.string().test(
          "Enviar-ubicacion",
          t("validacion_ubicacion"),
          function(value){
            if(formik.values.otro_motivo){
              return true
            }else{
              return value !== undefined && value !== ""
            }
          }
          //Yup.string().required(t("validacion_ubicacion"))
        )
        ,
        observacion:Yup.string().test(
          "validacion_otro_motivo",
          t("validacion_justificacion"),
          function(value){
            if(formik.values.otro_motivo){
              return value !== undefined && value !==""
            }else{
              return true
            }
          }
        )
      }),
      async onSubmit(values) {
        let dateInicioESCL = generateDatabaseDateTimeES(values.fecha_inicio);
        let dateFinESCL = generateDatabaseDateTimeES(values.fecha_termino); 
  
        try {
          const resp = await insertJustificacion(
            values.id_area_operacion,
            values.id_tipo_justificacion,
            values.observacion,
            convertirFechadeCLaUS(dateInicioESCL),
            convertirFechadeCLaUS(dateFinESCL),
            values.id_maquina,
            values.id_registro_movimiento,
            values.id_movimiento,
            values.ubicacion
          );
  
          if (!resp.success) {
            setAlert({
              open: true,
              message: "Algo salio mal?",
              severity: "error",
            });
            throw new Error("error");
          }else{
            setReloadCharts((prevState)  => prevState+=1);
            setLoadHorizontalChart(true);
            setAlert({
              open: true,
              message: "Justificación ingresada con exito?",
              severity: "success",
            });
            handleClose();
            formik.resetForm();
            return;
          }
        } catch (error) {
         console.log(error)
        }
      },
    });

    const handleErrorForm = () => {
      if (
        !formik.values.otro_motivo &&
        !formik.touched.fecha_inicio &&
        !formik.touched.fecha_termino &&
        !formik.touched.id_maquina &&
        !formik.touched.id_tipo_justificacion
      ) {
        setAlert({
          open: true,
          message: t("validacion_form_campos_vacios")+"?",
          severity: "warning",
        });
        return;
      } else if (!formik.isValid) {
        let msg = "";
        const claves = Object.keys(formik.errors);
        for (const clave of claves) {
          const valor = "* " + formik.errors[clave] + "?";
          msg += valor;
        }
        setAlert({
          open: true,
          message: msg,
          severity: "warning",
        });
        return;
      }
    };

    const handleClose = () => {
      formik.resetForm();
      setOpen(false);
    };


    const onHandleChangeCheckboxOtroMovito = (event) => {
      setUnlockOtroMotivo(event.target.checked)
      formik.initialValues['otro_motivo'] = event.target.checked;
      formik.setFieldValue('otro_motivo',event.target.checked)

      formik.initialValues['id_grupo_tipo_justificacion'] = 0;
      formik.initialValues['id_tipo_justificacion'] = 0;
      formik.setFieldValue('id_grupo_tipo_justificacion',0)
      formik.setFieldValue('id_tipo_justificacion',0)
    }

    const handleChangeCheckbox = (event) => {
      setUnlockDates(!(event.target.checked));
      //se guarda la informacion seleccionada si pasan de justificacion personalizada a no personalizada
      if(event.target.checked){
        setPrevInfo({
          fecha_inicio:formik.values.fecha_inicio,
          fecha_termino:formik.values.fecha_termino,
          id_t_registro_movimiento:formik.values.id_registro_movimiento
        });
        formik.initialValues['id_registro_movimiento'] = 0;
        formik.setFieldValue('id_registro_movimiento', 0);
      }else{
        formik.initialValues['fecha_inicio'] = prevInfo.fecha_inicio;
        formik.initialValues['fecha_termino'] = prevInfo.fecha_termino;
        formik.initialValues['id_registro_movimiento'] = prevInfo.id_t_registro_movimiento;
        formik.setFieldValue('fecha_inicio', prevInfo.fecha_inicio);
        formik.setFieldValue('fecha_termino', prevInfo.fecha_termino);
        formik.setFieldValue('id_registro_movimiento', prevInfo.id_t_registro_movimiento);
        setPrevInfo(initialStateInfo);
      }
    };

    useEffect(() => {
   
      if(stateInicialFormJustificaciones){
        formik.initialValues['fecha_inicio'] = stateInicialFormJustificaciones?.fecha_inicio
        formik.initialValues['fecha_termino'] = stateInicialFormJustificaciones?.fecha_termino;
        formik.initialValues['id_movimiento'] = stateInicialFormJustificaciones?.id_movimiento
        formik.initialValues['id_registro_movimiento'] = stateInicialFormJustificaciones?.id_t_registro_movimiento;
        formik.initialValues['id_maquina'] = stateInicialFormJustificaciones?.id_maquina;
        formik.initialValues['ubicacion'] = stateInicialFormJustificaciones?.ubicacion;
        formik.initialValues['id_area_operacion'] = idMina;
      }

  
    }, [open, stateInicialFormJustificaciones, stateInicialFormJustificaciones?.ubicacion, idMina])


    useEffect(() => {
      if(formik.values.id_grupo_tipo_justificacion && formik.values.id_grupo_tipo_justificacion !== 0){
        getMotivosJustificaciones(formik.values.id_grupo_tipo_justificacion).then(resp => {
          setListadoMotivos(resp.data);
        })
        .catch(err => console.log(err));
      }
    }, [formik.values.id_grupo_tipo_justificacion])
    
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Grid container item xs direction="row" spacing={0}>
          <Paper elevation={0} style={paperStyle}>
            <form onSubmit={formik.handleSubmit}>
              <Grid>
                {!loadingPopUp ? (
                  <TextField
                    id="id_ultima_ubicacion"
                    name="ultima_ubicacion"
                    label={t("ubicacion")}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    //onChange={(e) =>formik.setFieldValue("fecha_inicio",e.target.value.toString())}
                    value={formik?.values?.ubicacion ? formik.values.ubicacion : t("sin_info")}
                    style={{ marginTop: "15px" }}
                    fullWidth
                    disabled
                  />
                ) : (
                  <Skeleton animation="wave" />
                )}

                <br />

                <Box sx={{ minWidth: 120 }} style={{ marginTop: "15px" }}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      {t("equipo")}
                    </InputLabel>
                    <Select
                      name="id_maquina"
                      onBlur={formik.handleBlur}
                      defaultValue={0}
                      labelId="razon-select-label"
                      id="razon-select"
                      value={formik.values.id_maquina}
                      label="Seleccione una opción"
                      onChange={(e) =>
                        formik.setFieldValue("id_maquina", e.target.value)
                      }
                      disabled
                    >
                      <MenuItem key={0} value={0}>
                        {t("seleccione_opcion")}
                      </MenuItem>
                      {ListadoMaquinas.map((maquina) => (
                        <MenuItem key={maquina.id} value={maquina.id}>
                          {maquina.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <TextField
                  id="datetime-local-desde"
                  name="fecha_inicio"
                  onBlur={formik.handleBlur}
                  label={t("fecha_inicio")}
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // onChange={formik.handleChange}
                  onChange={(e) =>{
                    formik.setFieldValue(
                      "fecha_inicio",
                      e.target.value.toString()
                    )
                  }
                  }
                  value={formik.values.fecha_inicio}
                  style={{ marginTop: "15px" }}
                  fullWidth
                  disabled={unlockDates}
                />

                <TextField
                  id="datetime-local-hasta"
                  name="fecha_termino"
                  onBlur={formik.handleBlur}
                  label={t("fecha_fin")}
                  type="datetime-local"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // onChange={formik.handleChange}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "fecha_termino",
                      e.target.value.toString()
                    )
                  }
                  value={formik.values.fecha_termino}
                  style={{ marginTop: "15px" }}
                  fullWidth
                  disabled={unlockDates}
                />
                {formik.values.nombre_motivo ? (
                  <>
                    <TextField
                      id="id_ultima_ubicacion"
                      name="ultima_ubicacion"
                      label={t("motivo")}
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //onChange={(e) =>formik.setFieldValue("fecha_inicio",e.target.value.toString())}
                      value={formik.values.nombre_motivo}
                      style={{ marginTop: "15px" }}
                      fullWidth
                      disabled
                    />
                  </>
                ) : (
                  <>
                    <Box sx={{ minWidth: 120 }} style={{ marginTop: "15px" }}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true} id="demo-simple-select-label">
                          {t("motivo")}
                        </InputLabel>
                        <Select
                          disabled={FormJustificados || unlockOtroMotivo}
                          name="id_grupo_tipo_justificacion"
                          onBlur={formik.handleBlur}
                          defaultValue={0}
                          labelId="id_grupo_tipo_justificacion"
                          id="id_grupo_tipo_justificacion"
                          value={formik.values.id_grupo_tipo_justificacion}
                          label="Seleccione una opción"
                          onChange={(e) => {
                            formik.setFieldValue("observacion", "");
                            formik.setFieldValue("id_tipo_justificacion", 0);
                            formik.setFieldValue(
                              "id_grupo_tipo_justificacion",
                              e.target.value
                            );
                          }}
                        >
                          <MenuItem key={0} value={0}>
                            {t("seleccione_opcion")}
                          </MenuItem>
                          {gruposJustificaciones.map((grupoMotivo) => (
                            <MenuItem
                              key={grupoMotivo.id}
                              value={grupoMotivo.id}
                            >
                              {/* {t(`justificacion_grupo_id_${grupoMotivo.id}`)} */}
                              {grupoMotivo.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                )}
                <Box
                  sx={{ minWidth: 120 }}
                  style={{
                    marginTop: "15px",
                    display: !formik.values.id_grupo_tipo_justificacion
                      ? "none"
                      : "",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel shrink={true} id="demo-simple-select-label">
                      {t("motivo_detalle")}
                    </InputLabel>
                    <Select
                      disabled={FormJustificados}
                      name="id_tipo_justificacion"
                      onBlur={formik.handleBlur}
                      defaultValue={0}
                      labelId="razon-select-label"
                      id="razon-select"
                      value={formik.values.id_tipo_justificacion}
                      label="Seleccione una opción"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "id_tipo_justificacion",
                          e.target.value
                        );
                      }}
                    >
                      <MenuItem key={0} value={0}>
                        {t("seleccione_opcion")}
                      </MenuItem>
                      {ListadoMotivos.map((motivo) => (
                        <MenuItem key={motivo.id} value={motivo.id}>
                          {motivo.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {[7, 17, 27, 32, 40, 43, 49].includes(formik.values.id_tipo_justificacion)&& (
                  <TextField
                    disabled={FormJustificados}
                    onBlur={formik.handleBlur}
                    name="observacion"
                    type="text"
                    value={formik.values.observacion}
                    onChange={formik.handleChange}
                    label={t("otro_motivo")}
                    fullWidth
                    multiline
                    minRows={1}
                    maxRows={4}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ marginTop: "15px" }}
                  />
                )}

                    {
                      unlockOtroMotivo && (
                      <TextField
                        disabled={FormJustificados}
                        onBlur={formik.handleBlur}
                        name="observacion"
                        type="text"
                        value={formik.values.observacion}
                        onChange={formik.handleChange}
                        label={t("otro_motivo")}
                        fullWidth
                        multiline
                        minRows={1}
                        maxRows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ marginTop: "15px" }}
                      />
                      )
                    }


                <FormControlLabel
                    style={{marginTop:'10px'}}
                    control={
                      <Checkbox size="small" color="primary" value={unlockOtroMotivo} onChange={onHandleChangeCheckboxOtroMovito} defaultChecked={unlockOtroMotivo}/>
                    }
                    label={t("otro_motivo")}
                  />
                {/* <Checkbox defaultChecked /> */}
                {
                  verCheckbox &&
                  <FormControlLabel
                    style={{marginTop:'10px'}}
                    control={
                      <Checkbox size="small" color="primary" value={unlockDates} onChange={handleChangeCheckbox} defaultChecked={!unlockDates}/>
                    }
                    label={t("modificar_intervalo_tiempo")}
                  />
                }
                <Button
                  onClick={handleErrorForm}
                  style={{
                    marginTop: "35px",
                    display: FormJustificados ? "none" : "",
                  }}
                  type="submit"
                  variant="contained"
                  /* style={btnstyle} */
                  fullWidth
                  disabled={formik.isSubmitting}
                  className={classes.root}
                >
                  {t("registrar")}
                </Button>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            formik.setValues(initialStateFormik);
            handleClose();
          }}
        >
          {t("cancelar")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
