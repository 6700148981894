import React from "react";
import { useSendManualEmail } from "../../../hooks/controlFrentes/useSendManualEmail";
import { CustomButton } from "./ButtonsPopover";
import { CircleCheckIcon, DownloadIcon, EmailIcon, EyeIcon, Spinner, TrashIcon } from "./IconsPopover";

export function PdfSection({
  screenshots,
  tab,
  pdfs,
  date,
  generatePDFByMina,
  deletePDFByMina,
  downloadPDF,
  openPDFInNewTab,
  getPDFAsBase64
}) {
  const disabledGeneratePdf =
    screenshots[tab].dia.length + screenshots[tab].noche.length < 2 ||
    pdfs[tab] !== null;

  const disabledButtons = pdfs[tab] === null;

  const { sendReport, emailError, emailLoading, success } = useSendManualEmail();

  const normalizeTabs = {
    "lasChispas": "Las Chispas",
    "santaRosa": "Santa Rosa"
  }

  return (
    <section
      style={{
        paddingBlock: "6px",
        display: "flex",
        flexDirection: "column",
        gap: "6px"
      }}>
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
        <h4>PDFs</h4>
        <p>
          Imágenes:{" "}
          {screenshots[tab].dia.length + screenshots[tab].noche.length} / 2
        </p>
      </section>
      <CustomButton
        disabled={!disabledGeneratePdf}
        text="Generar PDF"
        onClick={() => generatePDFByMina(tab, screenshots)}
        backgroundColor="#18181B"
        color="#fff"
        width="100%"
        paddingBlock="12px"
      />
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "6px",
          justifyContent: "space-between"
        }}>
        <CustomButton
          icon={<EyeIcon />}
          disabled={!disabledButtons}
          text="Preview"
          onClick={() => openPDFInNewTab(tab)}
          backgroundColor="#fff"
          color="#000"
          width="100%"
          paddingBlock="10px"
          style={{ border: "1px solid #a5a5a5" }}
        />
        <CustomButton
          icon={<DownloadIcon />}
          disabled={!disabledButtons}
          onClick={() => downloadPDF(tab)}
          text="Descargar"
          backgroundColor="#fff"
          color="#000"
          width="100%"
          paddingBlock="10px"
          style={{ border: "1px solid #a5a5a5" }}
        />
        <CustomButton
          icon={<TrashIcon />}
          disabled={!disabledButtons}
          onClick={() => deletePDFByMina(tab)}
          text="Eliminar"
          width="100%"
          backgroundColor="#EF4444"
          color="#fff"
          paddingBlock="10px"
        />
      </section>
      <CustomButton
        icon={emailLoading ? <Spinner /> : success ? <CircleCheckIcon /> : <EmailIcon />}
        disabled={!disabledButtons || emailLoading}
        onClick={async () => {
          if (emailLoading || success) return;

          sendReport({
            mina: normalizeTabs[tab],
            fecha: date.toISOString().split("T")[0],
            jpgFiles: Object.values(screenshots[tab]).flat(),
            pdfFile: [await getPDFAsBase64(tab)]
          })
        }}
        text={emailLoading ? "" : emailError ? emailError?.message : success ? "Correo enviado exitosamente" : "Enviar correo"}
        width="100%"
        backgroundColor="#fff"
        color="#000"
        paddingBlock="10px"
        style={{ border: "1px solid #a5a5a5" }}
      />
    </section>
  );
}
