import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";

const Status = () => {
  const [status, setStatus] = useState(false);

  const { getSession, logout } = useContext(AccountContext);

  useEffect(() => {
    getSession().then((session) => {
      setStatus(true);
    });
  }, []);

  const handleLogout =(e)=>{
    e.preventDefault();
    logout();
    setStatus(false);
  }

  return (
    <div style={{ fontSize: "24px" }}>
      {status ? <button onClick={handleLogout}>Logout</button> : ""}
    </div>
  );
};
export default Status;
