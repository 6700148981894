import { useEffect, useState } from "react";
import { getViconsByIdOperacion } from "../services/general";

export const useGetViconsByIdOperacion = (id_area_operacion) => {
    const [vicons, setVicons] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if(!id_area_operacion) return;
      setLoading(true);
      getViconsByIdOperacion(id_area_operacion)
      .then((res) =>{
        setVicons(res.data);
        setLoading(false);
      })
      .catch((err) =>{
        console.log(err);
        setLoading(false);
      })
    }, [id_area_operacion])
    
    return {vicons, loading, setVicons}    

}
