export const getViconsVersion = async (id_area_operacion) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_area_operacion
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/administracion/vicon-version/obtener",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const getSolicitudesReglasVueltasGPS = async (id_area_operacion) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_area_operacion
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/reglas_vueltas_solicitudes",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const getSolicitudesTipoGeocercaGPS = async (id_area_operacion) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        id_area_operacion
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/get-solicitud-tipo-geocerca",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

