import React, { useEffect } from "react";
import "./marker.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { drawStyle, extendDrawBar } from "./mapUtils";

export const MapaGPSEdicion = ({
GeoCercas, centroMapa, ReferenciaIniciarDibujoGeocerca,onHandleSaveGeoCercaEnMemoria,ReferenciaMapa,onHandleUpdateGeoCercaEnMemoria,
GeoCercasVelocidad
}) => {
  useEffect(() => {
    mapboxgl.accessToken =process.env.REACT_APP_MAP_TOKEN;
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/satellite-v9",
      center: centroMapa,
      zoom: 15.5,
      attributionControl: false,
    });
    ReferenciaMapa.current = map;

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: false,
        trash: false,
      },
      styles: drawStyle,
    });
    ReferenciaIniciarDibujoGeocerca.current = draw
  
    var drawBar = new extendDrawBar({
      draw: draw,
    //   buttons: [
    //     {
    //       on: "click",
    //       action: save,
    //       classes: [],
    //       content: "",
    //     },
    //   ],
    });
    
    function save() {
      alert("saved");
      let xd = drawBar.draw.getAll();
    }

    map.addControl(drawBar);


    map.on("draw.create", async (event) => {
        onHandleSaveGeoCercaEnMemoria(event)
    });

    map.on("draw.update", async (event) => {
        onHandleUpdateGeoCercaEnMemoria(event);
    });
    
    const divMarker = document.createElement("div");
    divMarker.className = "marker";

    

      map.on("load", () => {

        if(GeoCercasVelocidad?.coordsGeoCerca?.length >0){
          GeoCercasVelocidad.coordsGeoCerca.forEach((geocerca) => {
            const nombre = geocerca.nombre;
            const coordinates = geocerca.coords;
            const id = geocerca.id;

            const geojsonFeature = {
              id:id.toString(),
              type: "Feature",
              properties: {
                id,
                nombre,
              },
              geometry: {
                type: "Polygon",
                coordinates: [coordinates],
              },
            };

            // Agrega una fuente de datos GeoJSON
            map.addSource(nombre, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [geojsonFeature],
              },
            });

            //Agrega una capa para mostrar el polígono en el mapa
            map.addLayer({
              id: id.toString(),
              type: "fill",
              source: nombre,
              layout: {},
              paint: {
                "fill-color": "rgb(255, 165, 0)",
                "fill-opacity": 0.5,
              },
            });

            map.addLayer({
              id: `${id.toString()}-label`,
              type: "symbol",
              source: nombre,
              layout: {
                "text-field": nombre,
                "text-font": ["Open Sans Regular"],
                "text-size": 13,
              },
              paint: {
                "text-color": "white", // Color del texto (blanco)
                "text-halo-color": "black", // Color del fondo del texto (oscuro)
                "text-halo-width": 1, // Grosor del fondo del texto
              },
            });


          });

        }
        //GEOCERCAS
        if (GeoCercas?.coordsGeoCerca?.length) {
          // Itera sobre las geocercas y agrégalas al mapa
          GeoCercas.coordsGeoCerca.forEach((geocerca) => {
            const nombre = geocerca.nombre;
            const coordinates = geocerca.coords;
            const id = geocerca.id;

            // map.on("click", id.toString(), (e) => {

            //     const geocercaId = e.features[0].properties.id.toString();
            //     if(geocercaId){
            //         // Activa el modo de edición de la geocerca seleccionada
            //         draw.changeMode("direct_select", { featureId: geocercaId });
            //     }
            //   });

            // Crea un GeoJSON Feature para la geocerca
            const geojsonFeature = {
              id:id.toString(),
              type: "Feature",
              properties: {
                id,
                nombre,
              },
              geometry: {
                type: "Polygon",
                coordinates: [coordinates],
              },
            };

            // Agrega una fuente de datos GeoJSON
            map.addSource(nombre, {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [geojsonFeature],
              },
            });

            //Agrega una capa para mostrar el polígono en el mapa
            map.addLayer({
              id: id.toString(),
              type: "fill",
              source: nombre,
              layout: {},
              paint: {
                "fill-color": "rgb(0, 183, 196)", // Color del polígono (verde)
                "fill-opacity": 0.5,
              },
            });

            // Agregar eventos o interacción con las geocercas si es necesario
            // map.on("click", nombre, (e) => {
            //   const geocercaNombre = e.features[0].properties.nombre;
            //   alert(
            //     `Haz hecho clic en la geocerca: ${geocercaNombre} id ${id}`
            //   );
            // });

            map.addLayer({
              id: `${id.toString()}-label`,
              type: "symbol",
              source: nombre,
              layout: {
                "text-field": nombre,
                "text-font": ["Open Sans Regular"],
                "text-size": 13,
              },
              paint: {
                "text-color": "white", // Color del texto (blanco)
                "text-halo-color": "black", // Color del fondo del texto (oscuro)
                "text-halo-width": 1, // Grosor del fondo del texto
              },
            });

          });
        }
      });
  }, [GeoCercas,GeoCercasVelocidad, centroMapa]);
  return (
    <div>
      <div id="map" style={{ width: "100%", height: "400px" }} />
    </div>
  );
};
