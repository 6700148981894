import React, { useEffect, useContext, useState } from "react";
import { AccountContext } from "../authentication/Account";
import NavBar from "../components/organisms/NavBar";
import Main from "../components/Main";
import { DataGrid } from "@material-ui/data-grid";
import { Grid } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MaterialUIPickers from "./Produccion/DatePicker";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Link } from "@material-ui/core";
import PantallaCarga from "../components/molecules/PantallaCarga";
import Container from "@mui/material/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@mui/material/Divider";


const useStyles = makeStyles({
  root3: {
    backgroundColor: "#2f4554",
    color: "white",
    width: "85px",
    fontSize: "0.8em",
    textTransform: "none",
    borderRadius: "20px",
    padding: "5px 30px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#af5103"
    }
  }
});

const Registros = () => {
  const { getSession } = useContext(AccountContext);
  const [loggedIn, setLoggedIn] = useState(false);

  const [rows, setRows] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const moment = require("moment-timezone");
  const classes = useStyles();
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "id_vibot",
      headerName: "ID Vibot",
      width: 120,
    },
    {
      field: "beacon_mac",
      headerName: "Mac",
      width: 129,
    },
    {
      field: "beacon_minor",
      headerName: "Minor",
      type: "number",
      width: 150,
    },
    {
      field: "beacon_major",
      headerName: "Major",
      type: "number",
      width: 150,
    },
    {
      field: "beacon_rssi",
      headerName: "RSSI",
      type: "number",
      width: 120,
    },
    {
      field: "beacon_fecha",
      headerName: "Fecha",
      width: 170,
      valueFormatter: ({ value }) =>
        moment(value).utc().format("DD/MM/YYYY"),
    },
    {
      field: "beacon_hora",
      headerName: "Hora",
      width: 170/* ,
      valueFormatter: ({ value }) =>
        moment(value).utc().format("DD/MM/YYYY"), */
    }
  ];

  const getRegistrosVibotBeacon = () => {
    setLoadingFetch(true);
    let headers = new Headers();
    headers.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);

    let rawBody = JSON.stringify({
        fecha_inicio: fechaInicio,
        fecha_fin:  fechaFin
      });

    fetch(
      `${process.env.REACT_APP_API_URL}demo/get_registros_vibot_beacon`, {
        method: "POST",
        headers: headers,
        redirect: "follow",
        body: rawBody
      }
    )
      .then((response) => response.json())
      .then((result) => {
        //console.log(result)
        setRows(result);
        setLoading(false);
        //console.log("FETch LISTO");
        setLoadingFetch(false);
      })
      .catch((error) => {
        //console.log("error FETCH", error);
        setLoadingFetch(false);
      });
  };

  useEffect(() => {
    getSession().then((data) => {
      setLoggedIn(true);
    });
    getRegistrosVibotBeacon();
  }, [loggedIn, loading]);

  return (
    <div className="settings">
      {true ? (
        <>
          <PantallaCarga loading ={loadingFetch}/>
          <NavBar />
          <Container>
          <Grid container spacing={0} columns={10}>
            <br/>
            <Grid item xs={12}>
              <Typography
                style={{ color: "#323b41", fontWeight: "bold", fontSize: "18px" }}
              >
                REGISTROS
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Breadcrumbs style = {{fontStyle:"italic"}} aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  style={{fontSize:"14.4px"}}
                  color="inherit"
                  href="/inicio"
                >
                  Inicio
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  style={{fontSize:"14.4px"}}
                  color="inherit"
                  href="/registros"
                >
                  Registros
                </Link>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <br/>
          <Divider/>
          <br/>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing = {5}
          >
            <Grid item>
              <MaterialUIPickers
                leyenda={"Fecha Inicio"}
                fecha={fechaInicio}
                setfecha={setFechaInicio}
              />
            </Grid>
            <Grid item>
            <MaterialUIPickers
                leyenda={"Fecha Fin"}
                fecha={fechaFin}
                setfecha={setFechaFin}
              />
            </Grid>
            <Grid>
              <Button 
                className = {classes.root3}
                onClick = {getRegistrosVibotBeacon}
                style = {{marginTop:"25px"}}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
          
            <div style={{ height: 550, width: "100%", marginTop: "20px" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={40}
                rowsPerPageOptions={[40]}
                density={"compact"}
                //checkboxSelection
                disableSelectionOnClick
              />
            </div>
          </Container>
        </>
      ) : (
        <Main />
      )}
    </div>
  )
};

export default Registros;
